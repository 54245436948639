import {
  IconCheckmarkCircle,
  IconClock,
  IconInfo,
  IconXCircle,
} from "@veneer/core";
import PropTypes from "prop-types";
import React from "react";
import { STATUS_TYPES } from "../../../common/constants";
import { TagIndicator } from "./TagIndicator";

// Status mapping
const STATUS_MAP = {
  [STATUS_TYPES.Active]: {
    type: "positive",
    label: "Active",
    icon: <IconCheckmarkCircle size={16} filled />,
  },
  [STATUS_TYPES.Available]: {
    type: "positive",
    label: "Available",
    icon: <IconCheckmarkCircle size={16} filled />,
  },
  [STATUS_TYPES.InActive]: {
    type: "negative",
    label: "Inactive",
    icon: <IconXCircle size={16} filled />,
  },
  [STATUS_TYPES.DeleteFailed]: {
    type: "negative",
    label: "Delete Failed",
    icon: <IconXCircle size={16} filled />,
  },
  [STATUS_TYPES.CreateFailed]: {
    type: "negative",
    label: "Create Failed",
    icon: <IconXCircle size={16} filled />,
  },
  [STATUS_TYPES.Error]: {
    type: "negative",
    label: "Error",
    icon: <IconXCircle size={16} filled />,
  },
  [STATUS_TYPES.Creating]: {
    type: "neutral",
    label: "Creating",
    icon: <IconInfo size={16} filled />,
  },
  [STATUS_TYPES.Pending]: {
    type: "neutral",
    label: "Pending",
    icon: <IconClock filled />,
  },
};

const mapStatusType = (status) => STATUS_MAP[status]?.type || "disabled";
const mapStatusLabel = (status) => STATUS_MAP[status]?.label || "Unknown";
const mapStatusIcon = (status) => STATUS_MAP[status]?.icon || undefined;

export const AlertsTag = ({ status, displayValue }) => {
  const label = displayValue || mapStatusLabel(status);
  const type = mapStatusType(status);
  const leadingIcon = mapStatusIcon(status);

  return <TagIndicator label={label} type={type} leadingIcon={leadingIcon} />;
};

AlertsTag.propTypes = {
  status: PropTypes.oneOf(Object.values(STATUS_TYPES)),
  displayValue: PropTypes.string,
};
