import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";

const browserHistory = createBrowserHistory({ basename: "" });
const instrumentationKey = import.meta.env
  .REACT_APP_APPINSIGHTS_INSTRUMENTATIONKEY;
var reactPlugin = new ReactPlugin();
let appInsights;
if (!instrumentationKey) {
  // Mock appInsights for local development
  appInsights = {
    trackEvent: () => {},
    trackException: () => {},
    trackPageView: () => {},
    loadAppInsights: () => {},
    // Add other methods as needed
  };
} else {
  appInsights = new ApplicationInsights({
    config: {
      connectionString: instrumentationKey,
      extensions: [reactPlugin],
      enableAutoRouteTracking: true,
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory },
      },
    },
  });
}

appInsights.loadAppInsights();
export default appInsights;
