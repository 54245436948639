// import { w3cwebsocket as W3CWebSocket } from "websocket";
import { WEBSOCKET_SERVER_URL } from "../constants";

export default class WebSocketService {
  clients = {};
  static staticClients = {};

  static getStaticClient = (websocketUrl, forceReConnect) => {
    if (!WebSocketService.staticClients[websocketUrl] || forceReConnect) {
      WebSocketService.staticClients[websocketUrl] = new WebSocket(
        `${WEBSOCKET_SERVER_URL}${websocketUrl}`,
      );
    } else if (
      WebSocketService.staticClients[websocketUrl].readyState ===
      WebSocket.CLOSED
    ) {
      WebSocketService.staticClients[websocketUrl] = new WebSocket(
        `${WEBSOCKET_SERVER_URL}${websocketUrl}`,
      );
    }
    return WebSocketService.staticClients[websocketUrl];
  };

  getClient = (websocketUrl) => {
    if (!this.clients[websocketUrl]) {
      this.clients[websocketUrl] = new WebSocket(
        `${WEBSOCKET_SERVER_URL}${websocketUrl}`,
      );
    }
    return this.clients[websocketUrl];
  };
}
