export const B2C_TENANT = import.meta.env.REACT_APP_B2C_TENANT;
export const B2C_AUTH_DOMAIN = import.meta.env.REACT_APP_B2C_AUTH_DOMAIN;
export const B2C_SPA_CLIENT_ID = import.meta.env.REACT_APP_B2C_SPA_CLIENT_ID;
export const B2C_ACCOUNT_KEY = import.meta.env.REACT_APP_B2C_ACCOUNT_KEY;
export const B2C_API_AUTHORITY = import.meta.env.REACT_APP_B2C_API_AUTHORITY;
export const B2C_FORGOT_PASSWORD_API_AUTHORITY = import.meta.env
  .REACT_APP_B2C_FORGOT_PASSWORD_API_AUTHORITY
  ? import.meta.env.REACT_APP_B2C_FORGOT_PASSWORD_API_AUTHORITY
  : "B2C_1A_SpotPASSWORDRESET";
export const B2C_URL = import.meta.env.REACT_APP_B2C_URL;

export const userWorkflow = {
  SIGN_IN_SIGN_UP: {
    name: "B2C_1_SignUp_SignIn_generic",
    redirect: `${getDomainURL()}`,
    authority: `${B2C_URL}/${B2C_API_AUTHORITY}`,
    forgotPasswordAuthority: `${B2C_URL}/${B2C_FORGOT_PASSWORD_API_AUTHORITY}`,
  },
};

export const b2cSignInAuth = {
  auth: {
    clientId: B2C_SPA_CLIENT_ID,
    authority: userWorkflow.SIGN_IN_SIGN_UP.authority,
    knownAuthorities: [B2C_AUTH_DOMAIN],
    redirectUri: userWorkflow.SIGN_IN_SIGN_UP.redirect,
    prompt: "select_account",
  },
  forgotPassword: {
    clientId: B2C_SPA_CLIENT_ID,
    authority: userWorkflow.SIGN_IN_SIGN_UP.forgotPasswordAuthority,
    knownAuthorities: [B2C_AUTH_DOMAIN],
    redirectUri: userWorkflow.SIGN_IN_SIGN_UP.redirect,
  },
};

export function getB2CAccount() {
  return JSON.parse(localStorage.getItem(B2C_ACCOUNT_KEY));
}

export function setB2CAccount(account) {
  return localStorage.setItem(B2C_ACCOUNT_KEY, JSON.stringify(account));
}

export function getDomainURL() {
  return (
    window.location.protocol +
    "//" +
    window.location.hostname +
    (window.location.port ? ":" + window.location.port : "")
  );
}
