import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { HTTP_METHODS } from "../../common/constants";
import HTTPClient from "../../common/servicers/httpClient";

export const getAdminAccounts = createAsyncThunk(
  "getAdminAccounts",
  async (
    { useCache = true, selectedOrganization, selectedTenant },
    { getState, requestId, rejectWithValue },
  ) => {
    const {
      getAdminAccountsRequestId,
      loadingAdminAccounts,
      adminAccounts,
      adminAccountsAvailable,
    } = getState().adminActions.adminAccounts;
    if (!loadingAdminAccounts || requestId !== getAdminAccountsRequestId) {
      return;
    }
    if (useCache && adminAccountsAvailable[selectedTenant] === true) {
      return { data: adminAccounts[selectedTenant], selectedTenant };
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${selectedOrganization}/tenants/${selectedTenant}/adminAccounts/identities`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const initialState = {
  adminAccounts: {},
  adminAccountsAvailable: {},
  loadingAdminAccounts: null,
  getAdminAccountsRequestId: null,
  getAdminAccountsError: null,
};

export const adminAccountsSlice = createSlice({
  name: "adminAccounts",
  initialState,
  reducers: {
    resetErrors: (state) => {
      state.getAdminAccountsError = null;
    },
  },
  extraReducers: {
    [getAdminAccounts.pending]: (state, action) => {
      state.loadingAdminAccounts = true;
      state.getAdminAccountsRequestId = action.meta.requestId;
      state.getAdminAccountsError = null;
    },
    [getAdminAccounts.fulfilled]: (state, action) => {
      state.loadingAdminAccounts = false;
      state.getAdminAccountsRequestId = null;
      state.getAdminAccountsError = null;
      if (action.payload.selectedTenant) {
        _.set(
          state.adminAccounts,
          `${action.payload.selectedTenant}`,
          action.payload.data,
        );
        _.set(
          state.adminAccountsAvailable,
          `${action.payload.selectedTenant}`,
          true,
        );
      }
    },
    [getAdminAccounts.rejected]: (state, action) => {
      state.loadingAdminAccounts = false;
      state.getAdminAccountsError = action.payload;
      state.getAdminAccountsRequestId = null;
    },
  },
});

export const { resetErrors } = adminAccountsSlice.actions;

export const adminAccountsSliceSelector = (state) =>
  state.adminActions.adminAccounts;
export default adminAccountsSlice.reducer;
