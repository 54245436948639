import { Divider, Grid, styled, useTheme } from "@mui/material";
import { Button, Card, IconCopy, IconPencil, IconPlus } from "@veneer/core";
import PropTypes from "prop-types";
import React from "react";
import noData from "../../images/loaders/noData.svg";
import VeneerButton from "./VeneerButton";

const PREFIX = "CardDetails";

const classes = {
  content: `${PREFIX}-content`,
  title: `${PREFIX}-title`,
  divider: `${PREFIX}-divider`,
  details: `${PREFIX}-details`,
  detail: `${PREFIX}-detail`,
  label: `${PREFIX}-label`,
};

const CardDetailsRoot = styled("div")(({ theme }) => ({
  width: "100%",
  minHeight: "100px",
  overflowX: "auto",
  overflowY: "auto",
  [`& .${classes.content}`]: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    padding: "16px",
  },

  [`& .${classes.title}`]: {
    overflow: "hidden",
    color: theme.palette.foreground.medium,
    textOverflow: "ellipsis",
    fontFamily: "Forma DJR UI",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px",
  },

  [`& .${classes.divider}`]: {
    width: "100%",
    backgroundColor: theme.palette.primary.inverse,
    marginTop: "0rem",
  },

  [`& .${classes.details}`]: {
    display: "flex",
    justifyContent: "space-between",
  },

  [`& .${classes.detail}`]: {
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.label}`]: {
    overflow: "hidden",
    color: theme.palette.foreground.medium,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontFamily: "Forma DJR UI",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
  },

  "& a": {
    color: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.primary.hover,
      textDecoration: "none",
    },
  },
}));

const CardDetails = ({
  title,
  details,
  isCopiable = false,
  isEditable = false,
  onAddClick = () => {},
}) => {
  const theme = useTheme();
  const copyValue = (value) => {
    navigator.clipboard.writeText(value);
  };

  const cardStyles = {
    display: "flex",
    flexDirection: "column",
    maxWidth: "555px",
    padding: "16px",
    gap: "24px",
    borderRadius: "12px",
    border: `1px solid ${theme.palette.primary.inverseActive}`,
    backgroundColor: theme.palette.primary.light,
  };

  return (
    <CardDetailsRoot>
      <Card
        customStyle={cardStyles}
        background="filled"
        border="outlined"
        content={
          details?.length > 0 ? (
            <div className={classes.content}>
              <p className={classes.title}>
                {title}
                {isEditable && (
                  <VeneerButton
                    appearance="secondary"
                    leadingIcon={<IconPencil />}
                    small
                    style={{ border: "none", marginLeft: "8px" }}
                    onClick={onAddClick}
                  />
                )}
              </p>
              {details.map(
                (detail, index) =>
                  detail.value && (
                    <React.Fragment key={index}>
                      <Divider className={classes.divider} />
                      <Grid
                        container
                        className={classes.details}
                        justifyContent="space-between"
                        alignItems="center"
                        display="flex"
                      >
                        <Grid item xs={4}>
                          <p className={classes.label}>{detail.label}</p>
                        </Grid>
                        <Grid
                          item
                          xs={7}
                          display="flex"
                          alignItems="center"
                          justifyContent="start"
                        >
                          <p className={classes.label}>{detail.value}</p>
                          {isCopiable && (
                            <Button
                              data-testid={`${detail.label.replace(/\s+/g, "-").toLowerCase()}-copy-button`}
                              leadingIcon={<IconCopy />}
                              onClick={() => copyValue(detail.value)}
                              small
                              appearance="ghost"
                            />
                          )}
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  ),
              )}
            </div>
          ) : (
            <>
              <div className={classes.content}>
                <span className={classes.title}>{title}</span>

                <Divider className={classes.divider} />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <img
                    style={{ paddingBottom: "16px" }}
                    src={noData}
                    alt="noData"
                  />
                  <h7>No data to display</h7>
                  {title && (
                    <span
                      style={{
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 400,
                      }}
                    >
                      Add {title} to display them here.
                    </span>
                  )}
                  <VeneerButton
                    appearance="primary"
                    leadingIcon={<IconPlus />}
                    small
                    style={{ marginTop: "20px" }}
                    onClick={onAddClick}
                  >
                    Add
                  </VeneerButton>
                </div>
              </div>
            </>
          )
        }
      />
    </CardDetailsRoot>
  );
};

CardDetails.propTypes = {
  title: PropTypes.string.isRequired,
  details: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.node,
    }),
  ).isRequired,
  isCopiable: PropTypes.bool,
  isEditable: PropTypes.bool,
  onAddClick: PropTypes.func,
};

export default CardDetails;
