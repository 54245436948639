import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAzureB2C } from "../../azureB2C/factories/azure-ad-factory";
import {
  ALL_CUSTOMERS,
  SOCKET_ENTITIES,
  WEBSOCKET_SERVER_DOMAIN,
} from "../constants";
import { useAuthContext } from "../contexts/AuthContext";
import WebSocketService from "../servicers/webSocketService";
import {
  broadCastReleases,
  broadCastTenantOnboardingStatus,
  broadCastTenantResourceStatus,
  broadcastAlerts,
  clearState,
} from "../socketMapper";
import { getAccessToken, getIdentityId, getTenantId } from "../utils";

export const NotificationsSocket = ({ children }) => {
  //On changing the tenant selection we have to establish the socket connection
  const dispatch = useDispatch();
  const selectedPartner = useSelector(
    (state) => state.partners.selectedPartner,
  );
  const selectedIdentity = useSelector(
    (state) => state.identities.selectedIdentity,
  );
  const onboardingTenantId = useSelector((state) => state.onboarding.tenantId);
  const authB2CService = getAzureB2C();
  const account = authB2CService.getAccount();
  const userId = account?.idTokenClaims?.sub;
  const [releaseSocket, setReleaseSocket] = useState(null);
  const [alertsSocket, setAlertsSocket] = useState(null);
  const [tenantResourceSocket, setTenantResourceSocket] = useState(null);
  const [tenantOnboardingSocket, setTenantOnboardingSocket] = useState(null);
  const tenantId = getTenantId(selectedPartner);
  const { state: authData } = useAuthContext();
  const tenants = useSelector((state) => state.partners.tenants);
  const selectedTenant = useSelector((state) => state.partners.selectedPartner);

  const connect = async (url, socket, setSocket, entity, forceReConnect) => {
    if (socket != null) {
      if (![WebSocket.CLOSED, WebSocket.CLOSING].includes(socket.readyState)) {
        await new Promise((resolve) => {
          socket.send("DISCONNECT");
          socket.onclose = null;
          socket.close();
          setSocket(null);
          resolve();
        });
      }
    }

    const accessToken = await getAccessToken();
    document.cookie = `authorization=;domain=${WEBSOCKET_SERVER_DOMAIN};path=/`; //Added it for those conditions where bigger size cookie already set because of previous implementation
    const socketClient = WebSocketService.getStaticClient(url, forceReConnect);
    setSocket(socketClient);
    socketClient.onopen = function () {
      if (socketClient.readyState === WebSocket.OPEN) {
        socketClient.send(JSON.stringify({ token: accessToken }));
      }
    };
    socketClient.onmessage = function (event) {
      const url = event.target.url;
      if (url.includes(SOCKET_ENTITIES.RELEASE_ANNOUNCEMENTS)) {
        broadCastReleases(event.data, dispatch);
      } else if (url.includes(SOCKET_ENTITIES.GLOBAL_ALERT_NOTIFICATIONS)) {
        broadcastAlerts(event.data, dispatch);
      } else if (
        url.includes(SOCKET_ENTITIES.TENANT_ONBOARDING_NOTIFICATIONS)
      ) {
        broadCastTenantOnboardingStatus(event.data, dispatch);
      } else if (url.includes(SOCKET_ENTITIES.TENANT_RESOURCE_STATUS_UPDATES)) {
        broadCastTenantResourceStatus(event.data, dispatch);
      } else {
        clearState(event.data, tenantId, dispatch);
      }
    };
    socketClient.onclose = function () {
      setTimeout(
        function () {
          if (!socket) {
            if (entity === SOCKET_ENTITIES.RELEASE_ANNOUNCEMENTS) {
              const url = `/${SOCKET_ENTITIES.RELEASE_ANNOUNCEMENTS}/subscribe?userId=${userId}`;
              connect(
                url,
                releaseSocket,
                setReleaseSocket,
                SOCKET_ENTITIES.RELEASE_ANNOUNCEMENTS,
                true,
              );
            } else if (entity === SOCKET_ENTITIES.GLOBAL_ALERT_NOTIFICATIONS) {
              const url = `/${SOCKET_ENTITIES.GLOBAL_ALERT_NOTIFICATIONS}/subscribe?organizationId=${getIdentityId(
                selectedIdentity,
              )}&userId=${userId}`;
              connect(
                url,
                alertsSocket,
                setAlertsSocket,
                SOCKET_ENTITIES.GLOBAL_ALERT_NOTIFICATIONS,
                true,
              );
            } else if (
              entity === SOCKET_ENTITIES.TENANT_ONBOARDING_NOTIFICATIONS
            ) {
              const url = `/${SOCKET_ENTITIES.TENANT_ONBOARDING_NOTIFICATIONS}/subscribe?organizationId=${getIdentityId(
                selectedIdentity,
              )}&tenantId=${onboardingTenantId}`;
              connect(
                url,
                tenantOnboardingSocket,
                setTenantOnboardingSocket,
                SOCKET_ENTITIES.TENANT_ONBOARDING_NOTIFICATIONS,
                true,
              );
            } else if (
              entity === SOCKET_ENTITIES.TENANT_RESOURCE_STATUS_UPDATES
            ) {
              const url = `/${SOCKET_ENTITIES.TENANT_RESOURCE_STATUS_UPDATES}/subscribe?tenantId=${tenantId}`;
              connect(
                url,
                tenantResourceSocket,
                setTenantResourceSocket,
                SOCKET_ENTITIES.TENANT_RESOURCE_STATUS_UPDATES,
                true,
              );
            } else {
              const url = `/${SOCKET_ENTITIES.GLOBAL_NOTIFICATIONS}/subscribe?organizationId=${getIdentityId(
                selectedIdentity,
              )}&tenantId=${tenantId}&userId=${userId}`;
              connect(url, socket, setSocket, null, true);
            }
          }
        },
        import.meta.env.NOTIFICATION_SOCKET_RETRY_INTERVAL
          ? import.meta.env.NOTIFICATION_SOCKET_RETRY_INTERVAL
          : 3000,
      );
    };
  };

  useEffect(() => {
    if (onboardingTenantId && selectedIdentity) {
      const url = `/${SOCKET_ENTITIES.TENANT_ONBOARDING_NOTIFICATIONS}/subscribe?organizationId=${getIdentityId(
        selectedIdentity,
      )}&tenantId=${onboardingTenantId}`;
      connect(
        url,
        tenantOnboardingSocket,
        setTenantOnboardingSocket,
        SOCKET_ENTITIES.TENANT_ONBOARDING_NOTIFICATIONS,
      );
    } else {
      if (tenantOnboardingSocket != null) {
        if (tenantOnboardingSocket.readyState === WebSocket.CONNECTING) {
          tenantOnboardingSocket.addEventListener("open", () => {
            tenantOnboardingSocket.send("DISCONNECT");
            tenantOnboardingSocket.close();
          });
        } else if (
          ![WebSocket.CLOSED, WebSocket.CLOSING].includes(
            tenantOnboardingSocket.readyState,
          )
        ) {
          tenantOnboardingSocket.send("DISCONNECT");
          tenantOnboardingSocket.close();
        }
        setTenantOnboardingSocket(null);
      }
    }
  }, [onboardingTenantId, selectedIdentity]);

  const isTenantSelectedAvailable =
    tenants[getIdentityId(selectedIdentity)]?.find(
      (tenant) => tenant.id === selectedTenant,
    )?.status === "Available";

  useEffect(() => {
    if (
      selectedPartner &&
      selectedPartner !== ALL_CUSTOMERS &&
      isTenantSelectedAvailable
    ) {
      //all tenant resources status update uses this socket connection
      const tenantResourceUrl = `/${SOCKET_ENTITIES.TENANT_RESOURCE_STATUS_UPDATES}/subscribe?tenantId=${tenantId}`;
      connect(
        tenantResourceUrl,
        tenantResourceSocket,
        setTenantResourceSocket,
        SOCKET_ENTITIES.TENANT_RESOURCE_STATUS_UPDATES,
      );
    }
  }, [selectedPartner]);

  useEffect(() => {
    if (selectedIdentity) {
      const url = `/${SOCKET_ENTITIES.GLOBAL_ALERT_NOTIFICATIONS}/subscribe?organizationId=${getIdentityId(
        selectedIdentity,
      )}&userId=${userId}`;
      connect(
        url,
        alertsSocket,
        setAlertsSocket,
        SOCKET_ENTITIES.GLOBAL_ALERT_NOTIFICATIONS,
      );
    }
  }, [selectedIdentity]);

  useEffect(() => {
    if (authData.isAuthenticated) {
      const url = `/${SOCKET_ENTITIES.RELEASE_ANNOUNCEMENTS}/subscribe?userId=${userId}`;
      connect(
        url,
        releaseSocket,
        setReleaseSocket,
        SOCKET_ENTITIES.RELEASE_ANNOUNCEMENTS,
      );
    }
  }, [authData]);

  return children;
};

export default NotificationsSocket;
