import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HTTP_METHODS } from "../common/constants";
import HTTPClient from "../common/servicers/httpClient";
import { getIdentityId } from "../common/utils";
import { transformRegionResponse } from "./onboardingUtils";

/**************************************
 *********** API ACTIONS ***************
 ***************************************/

export const fetchAzureRegions = createAsyncThunk(
  "azureRegion",
  async (
    { useCache = true, organizationId },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loading, azureRegions } = getState().azureRegions;
    if (!loading || requestId !== currentRequestId) {
      return;
    }
    if (useCache && azureRegions && azureRegions.length > 0) {
      return { data: azureRegions, useCache };
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/locations/${getIdentityId(organizationId)}`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

/**************************************
 ************** Reducers ***************
 ***************************************/

export const initialState = {
  azureRegions: [],
  requesting: false,
  geographies: [],
};

export const azureRegionSlice = createSlice({
  name: "azureRegion",
  initialState,
  reducers: {
    setRequesting: (state, action) => {
      state.requesting = action.payload;
    },
    setGeographies: (state, action) => {
      state.geographies = action.payload;
    },
    resetData: (state) => {
      state.azureRegions = [];
      state.geographies = [];
    },
  },
  extraReducers: {
    [fetchAzureRegions.pending]: (state, action) => {
      state.loading = true;
      state.currentRequestId = action.meta.requestId;
    },
    [fetchAzureRegions.fulfilled]: (state, action) => {
      state.loading = false;
      state.currentRequestId = null;
      state.azureRegions = action.payload.data;
      state.geographies = transformRegionResponse(action.payload.data);
    },
    [fetchAzureRegions.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.currentRequestId = null;
    },
  },
});
export const { setGeographies, resetData, setRequesting } =
  azureRegionSlice.actions;
export default azureRegionSlice.reducer;
