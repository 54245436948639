import { getSession, storeSession } from "../utils";

class SessionActivity {
  constructor(store) {
    const session = getSession() || {};
    this.lastActive = session.lastActive || Date.now();
  }
  // date time is time elapsed (ms) since unix epoch
  setLastActive(dateTime) {
    this.lastActive = dateTime;
    storeSession({ lastActive: this.lastActive });
  }
  getLastActive() {
    return this.lastActive;
  }
  clearLastActive() {
    this.lastActive = null;
    storeSession({ lastActive: undefined });
  }
}

const instance = new SessionActivity();

export default instance;
