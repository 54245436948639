import React from "react";
import ParentRoute from "../../common/components/ParentRoute";
import { ProtectedRouteSuspenseContainer } from "../../common/components/ProtectedRoute";
import {
  LAYOUTS,
  MODULES_LIST,
  PERMISSIONS_LIST,
  PERMISSIONS_TYPE,
} from "../../common/constants";
import { componentLoader } from "../../common/components/SuspenseComponent";
import DashBoardRouteTitle from "../../common/components/DashboardRouteTitle";
import {
  getDataVolumneTitle,
  hideDataVolumeDirectoryValues,
} from "./selectors";

/*------Data Volumes Component------*/

const DataVolumesContainer = React.lazy(() =>
  componentLoader(() => import("./components/DataVolumesContainer")),
);
const DataVolumeDetails = React.lazy(() =>
  componentLoader(() => import("./components/DataVolumeDetails")),
);
const DataVolumeDirectJoinProperties = React.lazy(() =>
  componentLoader(() => import("./components/DataVolumeDirectJoinProperties")),
);

const DataVolumesRoutes = [
  {
    path: "/dataVolumes",
    authRequired: true,
    title: "Data Volumes",
    component: ParentRoute,
    routeTitleComponent: () => (
      <DashBoardRouteTitle title="Data Volumes" showQuickActions={true} />
    ),
    permissionSet: [
      PERMISSIONS_LIST[`${MODULES_LIST.DATA_VOLUMES}`][PERMISSIONS_TYPE.VIEW],
    ],
    layoutStyle: LAYOUTS.MENU_SCREEN,
    routes: [
      {
        path: "/dataVolumes",
        exact: true,
        title: "Data Volumes",
        component: () => (
          <ProtectedRouteSuspenseContainer component={DataVolumesContainer} />
        ),
        permissionSet: [
          PERMISSIONS_LIST[`${MODULES_LIST.DATA_VOLUMES}`][
            PERMISSIONS_TYPE.VIEW
          ],
        ],
      },
      {
        path: "/dataVolumes/:dataVolumeName/:siteName/:type",
        title: getDataVolumneTitle,
        isSubTabs: true,
        component: ParentRoute,
        nobreadcrumbs: true,
        permissionSet: [
          PERMISSIONS_LIST[`${MODULES_LIST.DATA_VOLUMES}`][
            PERMISSIONS_TYPE.VIEW
          ],
        ],
        routes: [
          {
            path: "/dataVolumes/:dataVolumeName/:siteName/:type",
            exact: true,
            isTab: true,
            title: "Details",
            permissionSet: [
              PERMISSIONS_LIST[
                `${MODULES_LIST.DATA_VOLUMES}.${MODULES_LIST.SUMMARY}`
              ][PERMISSIONS_TYPE.VIEW],
            ],
            component: () => (
              <ProtectedRouteSuspenseContainer component={DataVolumeDetails} />
            ),
          },
          {
            path: "/dataVolumes/:dataVolumeName/:siteName/:type/directJoinProperties",
            exact: true,
            isTab: true,
            title: "Direct Join Properties",
            hideTabSelector: hideDataVolumeDirectoryValues,
            component: () => (
              <ProtectedRouteSuspenseContainer
                component={DataVolumeDirectJoinProperties}
              />
            ),
            permissionSet: [
              PERMISSIONS_LIST[
                `${MODULES_LIST.DATA_VOLUMES}.${MODULES_LIST.DIRECTJOIN_PROPERTIES}`
              ][PERMISSIONS_TYPE.VIEW],
            ],
          },
        ],
      },
    ],
  },
];

export default DataVolumesRoutes;
