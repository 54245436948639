import {
  IconCheckmarkCircle,
  IconClock,
  IconInfo,
  IconXCircle,
} from "@veneer/core";
import PropTypes from "prop-types";
import React from "react";
import { ProgressIcon } from "../../../icons/ProgressIcon";
import { TagIndicator } from "./TagIndicator";

// Custom non-Veneer icons

// Status mapping
const STATUS_MAP = {
  inProgress: {
    type: "help",
    label: "In Progress",
    icon: <ProgressIcon width={16} height={16} />,
  },
  notStarted: {
    type: "disabled",
    label: "Not Started",
    icon: <IconClock filled />,
  },
  completed: {
    type: "positive",
    label: "Completed",
    icon: <IconCheckmarkCircle size={16} filled />,
  },
  error: {
    type: "negative",
    label: "Error",
    icon: <IconXCircle size={16} filled />,
  },
  processing: {
    type: "neutral",
    label: "Processing",
    icon: <IconInfo size={16} filled />,
  },
};

const mapStatusType = (status) => STATUS_MAP[status]?.type || "disabled";
const mapStatusLabel = (status) => STATUS_MAP[status]?.label || "Unknown";
const mapStatusIcon = (status) => STATUS_MAP[status]?.icon || undefined;

export const WizardTag = ({ status }) => {
  const label = mapStatusLabel(status);
  const type = mapStatusType(status);
  const leadingIcon = mapStatusIcon(status);

  return <TagIndicator label={label} type={type} leadingIcon={leadingIcon} />;
};

WizardTag.propTypes = {
  status: PropTypes.oneOf([
    "inProgress",
    "notStarted",
    "completed",
    "error",
    "processing",
  ]),
};
