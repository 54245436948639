import React from "react";
import { styled } from "@mui/material/styles";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import ReportProblemRoundedIcon from "@mui/icons-material/ReportProblemRounded";
import { Typography } from "@mui/material";
import {
  CI_ALERTS_SEVERITY_TYPES,
  LOADING_TEXT,
  SEVERITY_LEVELS,
} from "../constants";
import redDot from "../../icons/reddot.svg";
import orangeDot from "../../icons/orangeDot.svg";
import yellowDot from "../../icons/yellowdot.svg";

const PREFIX = "SeverityIndicatorSummaryCard";

const classes = {
  container: `${PREFIX}-container`,
  errorIcon: `${PREFIX}-errorIcon`,
  warningIcon: `${PREFIX}-warningIcon`,
  summaryTitle: `${PREFIX}-summaryTitle`,
  lowSeverityLevel: `${PREFIX}-lowSeverityLevel`,
  incidationIcon: `${PREFIX}-incidationIcon`,
};

const Root = styled("div")(() => ({
  [`&.${classes.container}`]: {
    display: "flex",
    alignItems: "center",
  },

  [`& .${classes.errorIcon}`]: {
    fontSize: "16px",
    color: "#FF0000",
    marginRight: "3px",
  },

  [`& .${classes.warningIcon}`]: {
    fontSize: "16px",
    marginRight: "3px",
    color: "#FFA726",
  },

  [`& .${classes.summaryTitle}`]: {
    color: "#939393",
    textAlign: "center",
  },

  [`& .${classes.lowSeverityLevel}`]: {
    fontSize: "16px",
    marginRight: "3px",
    color: "#FFE60B",
  },

  [`& .${classes.incidationIcon}`]: {
    height: "10px",
    width: "10px",
  },
}));

export default function SeverityIndicator(props) {
  const { severityLevel } = props;

  return (
    <Root className={classes.container}>
      {(severityLevel === SEVERITY_LEVELS.Medium ||
        severityLevel === CI_ALERTS_SEVERITY_TYPES.WARNING) && (
        <ReportProblemRoundedIcon className={classes.warningIcon} />
      )}
      {(severityLevel === SEVERITY_LEVELS.High ||
        severityLevel === CI_ALERTS_SEVERITY_TYPES.CRITICAL) && (
        <ErrorRoundedIcon className={classes.errorIcon} />
      )}
      {severityLevel === SEVERITY_LEVELS.Low && (
        <ErrorRoundedIcon className={classes.lowSeverityLevel} />
      )}
      <Typography variant="span">{severityLevel}</Typography>
    </Root>
  );
}

export const SeverityIndicatorSummaryCard = (props) => {
  const { severityLevel, loading, customLoader } = props;

  return (
    <div>
      <Typography variant="subtitle1" className={classes.summaryTitle}>
        {severityLevel === SEVERITY_LEVELS.Medium && (
          <img
            className={classes.incidationIcon}
            src={orangeDot}
            alt={""}
          ></img>
        )}
        {severityLevel === SEVERITY_LEVELS.High && (
          <img className={classes.incidationIcon} src={redDot} alt={""}></img>
        )}
        {severityLevel === SEVERITY_LEVELS.Low && (
          <img
            className={classes.incidationIcon}
            src={yellowDot}
            alt={""}
          ></img>
        )}
        {" Severity"}
      </Typography>
      {loading ? (
        customLoader ? (
          customLoader
        ) : (
          LOADING_TEXT
        )
      ) : (
        <Typography variant="h3">{severityLevel}</Typography>
      )}
    </div>
  );
};
