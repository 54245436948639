import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import _ from "lodash";
import { HTTP_METHODS } from "../../common/constants";
import HTTPClient from "../../common/servicers/httpClient";

export const getCustomActions = createAsyncThunk(
  "getCustomActions",
  async (
    { useCache = true, selectedOrganization },
    { getState, requestId, rejectWithValue },
  ) => {
    const {
      currentRequestId,
      loadingCustomActions,
      customActions,
      customActionsAvailable,
    } = getState().adminActions.customActions;
    if (!loadingCustomActions || requestId !== currentRequestId) {
      return;
    }
    if (useCache && customActionsAvailable[selectedOrganization] === true) {
      return {
        data: customActions[selectedOrganization],
        selectedOrganization,
        cachedData: true,
      };
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${selectedOrganization}/customActions`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, selectedOrganization };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getTenantCustomActions = createAsyncThunk(
  "getTenantCustomActions",
  async (
    { useCache = true, selectedOrganization, selectedTenant },
    { getState, requestId, rejectWithValue },
  ) => {
    const {
      currentRequestId,
      loadingTenantCustomActions,
      tenantCustomActions,
      tenantCustomActionsAvailable,
    } = getState().adminActions.customActions;
    if (!loadingTenantCustomActions || requestId !== currentRequestId) {
      return;
    }
    if (
      useCache &&
      tenantCustomActionsAvailable?.[selectedOrganization]?.[selectedTenant] ===
        true
    ) {
      return {
        data: tenantCustomActions[selectedTenant],
        selectedOrganization,
        selectedTenant,
        cachedData: true,
      };
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${selectedOrganization}/tenants/${selectedTenant}/customActions`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, selectedOrganization, selectedTenant };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getTenantCustomActionTriggers = createAsyncThunk(
  "getTenantCustomActionTriggers",
  async (
    { useCache = true, selectedOrganization, selectedTenant, resourceId },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loadingTriggers, triggers } =
      getState().adminActions.customActions;
    if (!loadingTriggers || requestId !== currentRequestId) {
      return;
    }
    if (useCache && triggers[selectedTenant]?.length > 0) {
      return {
        data: triggers[selectedTenant],
        selectedOrganization,
        selectedTenant,
        cachedData: true,
      };
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${selectedOrganization}/tenants/${selectedTenant}/customActions/triggers?resourceId=${resourceId}`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, selectedOrganization, selectedTenant };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getCustomActionDetail = createAsyncThunk(
  "getCustomActionDetail",
  async (
    { useCache = true, selectedOrganization, customActionId },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loadingCustomActionDetail, customActions } =
      getState().adminActions.customActions;
    if (!loadingCustomActionDetail || requestId !== currentRequestId) {
      return;
    }
    if (
      useCache &&
      customActions[selectedOrganization] &&
      customActions[selectedOrganization].find(
        (i) => i.customActionId === customActionId,
      )
    ) {
      return {
        data: customActions[selectedOrganization].find(
          (i) => i.customActionId === customActionId,
        ),
        selectedOrganization,
        customActionId,
        cachedVersion: true,
      };
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${selectedOrganization}/customActions/${customActionId}`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, selectedOrganization, customActionId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const postNotesForCustomActionVersion = createAsyncThunk(
  "postNotesForCustomActionVersion",
  async (
    { notes, selectedOrganization, customActionId, version },
    { getState, requestId, rejectWithValue },
  ) => {
    const { postCustomActionVersionNotesRequestId, postingCustomActionNotes } =
      getState().adminActions.customActions;
    if (
      !postingCustomActionNotes ||
      requestId !== postCustomActionVersionNotesRequestId
    ) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${selectedOrganization}/customActions/${customActionId}/versions/${version}/notes`,
        method: HTTP_METHODS.PUT,
        data: { notes: notes },
      }).callAuthorizedAPI();
      return {
        ...response.data,
        selectedOrganization,
        customActionId,
        version,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteCustomAction = createAsyncThunk(
  "deleteCustomAction",
  async (
    { selectedOrganization, customActionId },
    { getState, requestId, rejectWithValue },
  ) => {
    const { deletingCustomAction, deleteCustomActionRequestId } =
      getState().adminActions.customActions;
    if (!deletingCustomAction || requestId !== deleteCustomActionRequestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${selectedOrganization}/customActions/${customActionId}`,
        method: HTTP_METHODS.DELETE,
      }).callAuthorizedAPI();
      return { ...response.data, selectedOrganization, customActionId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const saveCustomActions = createAsyncThunk(
  "saveCustomActions",
  async (
    { selectedOrganization, data },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loadingAction } =
      getState().adminActions.customActions;
    if (!loadingAction || requestId !== currentRequestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${selectedOrganization}/customActions`,
        method: HTTP_METHODS.POST,
        data: data,
      }).callAuthorizedAPI();
      return { ...response.data, selectedOrganization: selectedOrganization };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const saveCustomActionsVersion = createAsyncThunk(
  "saveCustomActionsVersion",
  async (
    { selectedOrganization, version, customActionId, data },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loadingAction } =
      getState().adminActions.customActions;
    if (!loadingAction || requestId !== currentRequestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${selectedOrganization}/customActions/${customActionId}/versions/${version}`,
        method: HTTP_METHODS.POST,
        data: data,
      }).callAuthorizedAPI();
      return {
        ...response.data,
        selectedOrganization: selectedOrganization,
        customActionId: customActionId,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const updateCustomActions = createAsyncThunk(
  "updateCustomActions",
  async (
    { selectedOrganization, data, id },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loadingAction } =
      getState().adminActions.customActions;
    if (!loadingAction || requestId !== currentRequestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${selectedOrganization}/customActions/${id}`,
        method: HTTP_METHODS.PUT,
        data: data,
      }).callAuthorizedAPI();
      return {
        ...response.data,
        selectedOrganization: selectedOrganization,
        id: id,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const initialState = {
  currentRequestId: null,
  loadingAction: null,
  createCustomActionSuccessResponse: null,
  createCustomActionError: null,
  updateCustomActionSuccessResponse: null,
  updateCustomActionError: null,

  refreshCustomActions: null,
  silentRefresh: false,

  loadingCustomActions: null,
  customActions: {},
  customActionserror: null,

  loadingTenantCustomActions: null,
  tenantCustomActions: {},
  tenantCustomActionserror: null,
  tenantCustomActionsAvailable: {},

  loadingTriggers: null,
  triggers: {},
  triggersError: null,

  loadingCustomActionDetail: null,
  customActionsAvailable: {},
  customActionDetail: null,
  customActionDetailsError: null,

  deleteStatus: null,
  deletingCustomAction: null,
  deletingCustomActionSuccess: null,
  deleteCustomActionError: null,
  deleteCustomActionRequestId: null,

  postingCustomActionNotes: null,
  postCustomActionVersionNotesRequestId: null,
  postingCustomActionNotesError: null,
};

export const customActionsSlice = createSlice({
  name: "customAction",
  initialState,
  reducers: {
    resetCustomActionsErrors: (state) => {
      state.customActionserror = null;
      state.customActionDetailsError = null;
      state.deleteCustomActionError = null;
      state.postingCustomActionNotesError = null;
      state.createCustomActionError = null;
      state.createCustomActionSuccessResponse = null;
      state.updateCustomActionError = null;
      state.updateCustomActionSuccessResponse = null;
    },
    clearCustomActions: (state, action) => {
      if (action.payload !== undefined && action.payload.selectedOrganization) {
        if (!action.payload.silentRefresh) {
          state.customActions[action.payload.selectedOrganization] = [];
        } else {
          state.silentRefresh = true;
        }
        state.refreshCustomActions = true;
        state.customActionsAvailable[action.payload.selectedOrganization] =
          false;
        if (action.payload.selectedTenant) {
          state.tenantCustomActionsAvailable[action.payload.selectedTenant] =
            false;
        }
      }
    },
  },
  extraReducers: {
    [saveCustomActions.pending]: (state, action) => {
      state.loadingAction = true;
      state.createCustomActionSuccessResponse = null;
      state.createCustomActionError = null;
      state.currentRequestId = action.meta.requestId;
    },
    [saveCustomActions.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.createCustomActionError = null;
      state.loadingAction = false;
      if (
        action.payload &&
        action.payload.data &&
        state.customActions &&
        state.customActions[action.payload.selectedOrganization]
      ) {
        state.customActions[action.payload.selectedOrganization] = [
          ...state.customActions[action.payload.selectedOrganization],
          action.payload.data,
        ];
      }
      state.createCustomActionSuccessResponse = action.payload?.data;
    },
    [saveCustomActions.rejected]: (state, action) => {
      state.loadingAction = false;
      state.createCustomActionError = action.payload;
      state.currentRequestId = null;
      state.createCustomActionSuccessResponse = null;
    },
    [saveCustomActionsVersion.pending]: (state, action) => {
      state.loadingAction = true;
      state.createCustomActionSuccessResponse = null;
      state.createCustomActionError = null;
      state.currentRequestId = action.meta.requestId;
    },
    [saveCustomActionsVersion.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.createCustomActionError = null;
      state.loadingAction = false;
      if (
        action.payload.selectedOrganization &&
        state.customActions[action.payload.selectedOrganization]
      ) {
        const selectedOrganization = action.payload.selectedOrganization;
        state.customActions[selectedOrganization] = state.customActions[
          selectedOrganization
        ].map((p) => {
          if (p.customActionId === action.payload.customActionId) {
            return action.payload?.data;
          }
          return p;
        });
      }
      state.createCustomActionSuccessResponse = action.payload?.data;
    },
    [saveCustomActionsVersion.rejected]: (state, action) => {
      state.loadingAction = false;
      state.createCustomActionError = action.payload;
      state.currentRequestId = null;
      state.createCustomActionSuccessResponse = null;
    },
    [updateCustomActions.pending]: (state, action) => {
      state.loadingAction = true;
      state.updateCustomActionSuccessResponse = null;
      state.updateCustomActionError = null;
      state.currentRequestId = action.meta.requestId;
    },
    [updateCustomActions.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.updateCustomActionError = null;
      state.loadingAction = false;
      if (action.payload.selectedOrganization) {
        const selectedOrganization = action.payload.selectedOrganization;
        state.customActions[selectedOrganization] = state.customActions[
          selectedOrganization
        ].map((p) => {
          if (p.customActionId === action.payload.id) {
            return action.payload?.data;
          }
          return p;
        });
      }
      state.updateCustomActionSuccessResponse = action.payload?.data;
    },
    [updateCustomActions.rejected]: (state, action) => {
      state.loadingAction = false;
      state.updateCustomActionError = action.payload;
      state.currentRequestId = null;
      state.updateCustomActionSuccessResponse = null;
    },
    [getCustomActions.pending]: (state, action) => {
      state.loadingCustomActions = true;
      state.refreshCustomActions = false;
      state.currentRequestId = action.meta.requestId;
      state.customActionserror = null;
    },
    [getCustomActions.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.loadingCustomActions = false;
      state.silentRefresh = false;
      state.customActionserror = null;
      if (action.payload.selectedOrganization && !action.payload.cachedData) {
        _.set(
          state.customActions,
          `${action.payload.selectedOrganization}`,
          action.payload.data,
        );
        _.set(
          state.customActionsAvailable,
          `${action.payload.selectedOrganization}`,
          true,
        );
      }
    },
    [getCustomActions.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.silentRefresh = false;
      state.customActionserror = action.payload;
      state.loadingCustomActions = false;
    },
    [getTenantCustomActions.pending]: (state, action) => {
      state.loadingTenantCustomActions = true;
      state.currentRequestId = action.meta.requestId;
      state.tenantCustomActionserror = null;
    },
    [getTenantCustomActions.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.loadingTenantCustomActions = false;
      state.tenantCustomActionserror = null;
      if (
        action.payload.selectedOrganization &&
        action.payload.selectedTenant &&
        !action.payload.cachedData
      ) {
        _.set(
          state.tenantCustomActions,
          `${action.payload.selectedTenant}`,
          action.payload.data,
        );
        _.set(
          state.tenantCustomActionsAvailable,
          `${action.payload.selectedOrganization}.${action.payload.selectedTenant}`,
          true,
        );
      }
    },
    [getTenantCustomActions.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.tenantCustomActionserror = action.payload;
      state.loadingTenantCustomActions = false;
    },
    [getTenantCustomActionTriggers.pending]: (state, action) => {
      state.loadingTriggers = true;
      state.currentRequestId = action.meta.requestId;
      state.triggersError = null;
    },
    [getTenantCustomActionTriggers.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.loadingTriggers = false;
      state.triggersError = null;
      if (
        action.payload.selectedOrganization &&
        action.payload.selectedTenant &&
        !action.payload.cachedData
      ) {
        _.set(
          state.triggers,
          `${action.payload.selectedTenant}`,
          action.payload.data,
        );
      }
    },
    [getTenantCustomActionTriggers.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.triggersError = action.payload;
      state.loadingTriggers = false;
    },
    [getCustomActionDetail.pending]: (state, action) => {
      state.loadingCustomActionDetail = true;
      state.refreshCustomActions = false;
      state.currentRequestId = action.meta.requestId;
      state.customActionDetailsError = null;
    },
    [getCustomActionDetail.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.loadingCustomActionDetail = false;
      state.silentRefresh = false;
      state.customActionDetailsError = null;
      if (!action.payload.cachedData) {
        if (state.customActions[action.payload.selectedOrganization]) {
          let currentCustomActions = current(
            state.customActions[action.payload.selectedOrganization],
          );
          state.customActions[action.payload.selectedOrganization] = [
            ...(currentCustomActions
              ? currentCustomActions.filter(
                  (d) => d.customActionId !== action.payload.customActionId,
                )
              : []),
            action.payload.data,
          ];
        } else {
          _.set(state.customActions, `${action.payload.selectedOrganization}`, [
            action.payload.data,
          ]);
        }
      }
    },
    [getCustomActionDetail.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.silentRefresh = false;
      state.customActionDetailsError = action.payload;
      state.loadingCustomActionDetail = false;
    },

    [postNotesForCustomActionVersion.pending]: (state, action) => {
      state.postCustomActionVersionNotesRequestId = action.meta.requestId;
      state.postingCustomActionNotes = true;
      state.postingCustomActionNotesError = null;
    },
    [postNotesForCustomActionVersion.fulfilled]: (state, action) => {
      state.postCustomActionVersionNotesRequestId = null;
      state.postingCustomActionNotes = false;
      state.postingCustomActionNotesError = "";
      if (state.customActions[action.payload.selectedOrganization]) {
        state.customActions[action.payload.selectedOrganization] =
          state.customActions[action.payload.selectedOrganization].map(
            (customAction) => {
              if (
                customAction.customActionId === action.payload.customActionId
              ) {
                return action.payload.data;
              } else {
                return customAction;
              }
            },
          );
      }
    },
    [postNotesForCustomActionVersion.rejected]: (state, action) => {
      state.postCustomActionVersionNotesRequestId = null;
      state.postingCustomActionNotes = false;
      state.postingCustomActionNotesError = action.payload;
    },

    [deleteCustomAction.pending]: (state, action) => {
      state.deletingCustomAction = true;
      state.deleteCustomActionRequestId = action.meta.requestId;
      state.deleteCustomActionError = null;
    },
    [deleteCustomAction.fulfilled]: (state, action) => {
      state.deletingCustomAction = false;
      state.deleteCustomActionRequestId = null;
      state.deleteCustomActionError = "";
      state.deleteStatus = true;
      state.deletingCustomActionSuccess = true;
      if (state.customActions[action.payload.selectedOrganization]) {
        _.set(
          state.customActions,
          `${action.payload.selectedOrganization}`,
          state.customActions[action.payload.selectedOrganization].filter(
            (i) => {
              return i?.customActionId !== action.payload.customActionId;
            },
          ),
        );
      }
    },
    [deleteCustomAction.rejected]: (state, action) => {
      state.deletingCustomAction = false;
      state.deleteCustomActionRequestId = null;
      state.deleteCustomActionError = action.payload;
      state.deleteStatus = false;
    },
  },
});

export const { resetCustomActionsErrors } = customActionsSlice.actions;

export default customActionsSlice.reducer;
