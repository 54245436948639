import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import React from "react";

const PREFIX = "ErrorPage";

const classes = {
  root: `${PREFIX}-root`,
  iconStyle: `${PREFIX}-iconStyle`,
  descriptionStyle: `${PREFIX}-descriptionStyle`,
};

const Root = styled("div")(() => ({
  [`&.${classes.root}`]: {
    height: "100%",
    background: "white",
    borderColor: "white",
    boxShadow: "none",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    position: "absolute",
    left: "0px",
    right: "0px",
  },

  [`& .${classes.iconStyle}`]: {
    fontSize: "6em",
    color: "grey",
  },

  [`& .${classes.descriptionStyle}`]: {
    fontSize: "1em",
    fontWeight: "normal",
    fontFamily: "sans-serif",
    color: "#666",
  },
}));

export default function ErrorPage({
  title = "Uh oh, something's off here.",
  description = "You shouldn't be seeing this message - we'll get to work on it right away!",
}) {
  return (
    <Root className={classes.root}>
      <SentimentVeryDissatisfiedIcon className={classes.iconStyle} />
      <span className={classes.headerStyle}>{title}</span>
      <p className={classes.descriptionStyle}>{description}</p>
    </Root>
  );
}

ErrorPage.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};
