import { Typography, Divider, Grid } from "@mui/material";
import { styled } from '@mui/material/styles';
import AnywareIcon from "../../icons/anyware-icon.svg";
import React from "react";
import { useRef, useState, useEffect } from "react";
import QuickActionDropdown from "./QuickActionDropdown";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { hasAccessToRoute, getTenantId, checkTenantPermissions, checkAdminPermissions } from "../utils";
import { MODULES_LIST, PERMISSIONS_LIST, PERMISSIONS_TYPE, ORG_ROLES } from "../constants";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "../../icons/deleteIcon.svg";
import UpdateIcon from "../../icons/updateIcon.svg";
import EyeIcon from "../../icons/eyeIcon.svg";
import AddProfileIcon from "../../icons/addprofileIcon.svg";
import EditIcon from "../../icons/edit.svg";
import AdminAccountIcon from "../../icons/adminAccountIcon.svg";
import ApiAccountIcon from "../../icons/apiAccount.svg";
import { CONFIG_ACTIONS_PERMISSIONS_SET, ADMIN_CONFIG_ACTIONS_PERMISSIONS_SET } from "../../dashboard/dashboardConstants";
import { List } from "@mui/icons-material";
import { useLocation, matchPath } from "react-router-dom";

const PREFIX = 'DashboardRouteTitle';

const classes = {
  titleContainer: `${PREFIX}-titleContainer`,
  divider: `${PREFIX}-divider`,
  root: `${PREFIX}-root`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.titleContainer}`]: {
    marginBottom: "7px",
    paddingBottom: "0px",
    display: "flex",
  },

  [`& .${classes.divider}`]: {
    marginTop: "12px",
    color: "black",
  },

  [`& .${classes.root}`]: {
    display: "flex",
    alignItems: "center",
  }
}));

const ADMIN_DROPDOWN_DATA = [
  {
    group: "Custom Actions",
    options: [
      {
        icon: () => <List style={{ color: "#4D52E6" }} />,
        label: "List",
        href: "/custom-actions",
        value: "List Custom Actions",
      },
      {
        icon: () => <AddIcon style={{ color: "#4D52E6" }} />,
        label: "Create",
        href: "/custom-actions/action/create",
        value: "Create Custom Actions",
      },
      {
        icon: () => (
          <img
            src={EditIcon}
            alt="emptyList"
            height={"15"}
            style={{ marginLeft: "5px", paddingRight: "5px" }}
          />
        ),
        label: "Edit",
        href: "/custom-actions/action/edit",
        value: "Edit Custom Actions",
      },
      {
        icon: () => (
          <img src={DeleteIcon} alt="emptyList" height={"22"} style={{ paddingRight: "3px" }} />
        ),
        label: "Delete",
        href: "/custom-actions/actions/delete",
        value: "Delete Custom Actions",
      },
    ],
  },
];

const DROPDOWN_DATA = [
  {
    group: "Images",
    permissionSet: [PERMISSIONS_LIST[`${MODULES_LIST.IMAGES}`][PERMISSIONS_TYPE.VIEW]],
    options: [
      {
        icon: () => <List style={{ color: "#4D52E6" }} />,
        label: "List",
        href: "/image-sets",
        value: "List Images",
        permissionSet: [PERMISSIONS_LIST[`${MODULES_LIST.IMAGES}`][PERMISSIONS_TYPE.VIEW]],
      },
      {
        icon: () => <AddIcon style={{ color: "#4D52E6" }} />,
        label: "Create",
        href: "/image-sets/action/create",
        value: "Create Images",
        permissionSet: [PERMISSIONS_LIST[`${MODULES_LIST.IMAGES}`][PERMISSIONS_TYPE.CREATE]],
      },
      {
        icon: () => (
          <img
            src={EditIcon}
            alt="emptyList"
            height={"15"}
            style={{ marginLeft: "5px", paddingRight: "5px" }}
          />
        ),
        label: "Edit",
        href: "/image-sets/action/edit",
        value: "Edit Images",
        permissionSet: [PERMISSIONS_LIST[`${MODULES_LIST.IMAGES}`][PERMISSIONS_TYPE.EDIT]],
      },
      {
        icon: () => (
          <img src={DeleteIcon} alt="emptyList" height={"22"} style={{ paddingRight: "3px" }} />
        ),
        label: "Delete",
        href: "/image-sets/actions/delete",
        value: "Delete Images",
        permissionSet: [PERMISSIONS_LIST[`${MODULES_LIST.IMAGES}`][PERMISSIONS_TYPE.DELETE]],
      },
    ],
  },
  {
    group: "Spot Groups",
    permissionSet: [PERMISSIONS_LIST[`${MODULES_LIST.SPOT_GROUPS}`][PERMISSIONS_TYPE.VIEW]],
    options: [
      {
        icon: () => <List style={{ color: "#4D52E6" }} />,
        label: "List",
        href: "/spot-group",
        value: "List Spot Groups",
        permissionSet: [PERMISSIONS_LIST[`${MODULES_LIST.SPOT_GROUPS}`][PERMISSIONS_TYPE.VIEW]],
      },
      {
        icon: () => <AddIcon style={{ color: "#4D52E6" }} />,
        label: "Create",
        href: "/spot-group/action/create",
        value: "Create Spot Group",
        permissionSet: [PERMISSIONS_LIST[`${MODULES_LIST.SPOT_GROUPS}`][PERMISSIONS_TYPE.CREATE]],
      },
      {
        icon: () => (
          <img
            src={EditIcon}
            alt="emptyList"
            height={"15"}
            style={{ marginLeft: "5px", paddingRight: "5px" }}
          />
        ),
        label: "Edit",
        href: "/spot-group/action/edit",
        value: "Edit Spot Groups",
        permissionSet: [PERMISSIONS_LIST[`${MODULES_LIST.SPOT_GROUPS}`][PERMISSIONS_TYPE.EDIT]],
      },
      {
        icon: () => (
          <img src={UpdateIcon} alt="emptyList" height={"20"} style={{ paddingRight: "3px" }} />
        ),
        label: "Update",
        href: "/spot-group/action/update",
        value: "Update Spot Groups",
        permissionSet: [PERMISSIONS_LIST[`${MODULES_LIST.SPOT_GROUPS}`][PERMISSIONS_TYPE.EDIT]],
      },
      {
        icon: () => (
          <img src={AddProfileIcon} alt="emptyList" height={"20"} style={{ paddingRight: "4px" }} />
        ),
        label: "Assign Users",
        href: "/spot-group/action/assignUsers",
        value: "Assign users to Spot Groups",
        permissionSet: [PERMISSIONS_LIST[`${MODULES_LIST.SPOT_GROUPS}`][PERMISSIONS_TYPE.EDIT]],
      },
      {
        icon: () => (
          <img src={DeleteIcon} alt="emptyList" height={"22"} style={{ paddingRight: "3px" }} />
        ),
        label: "Delete",
        href: "/spot-group/action/delete",
        value: "Delete Spot Groups",
        permissionSet: [PERMISSIONS_LIST[`${MODULES_LIST.SPOT_GROUPS}`][PERMISSIONS_TYPE.DELETE]],
      },
    ],
  },
  {
    group: "Data Volumes",
    permissionSet: [PERMISSIONS_LIST[`${MODULES_LIST.DATA_VOLUMES}`][PERMISSIONS_TYPE.VIEW]],
    options: [
      {
        icon: () => <List style={{ color: "#4D52E6" }} />,
        label: "List",
        href: "/dataVolumes",
        value: "List",
        permissionSet: [PERMISSIONS_LIST[`${MODULES_LIST.DATA_VOLUMES}`][PERMISSIONS_TYPE.VIEW]],
      },
    ],
  },
  {
    group: "Notification Profiles",
    permissionSet: [
      PERMISSIONS_LIST[`${MODULES_LIST.NOTIFICATION_PROFILES}`][PERMISSIONS_TYPE.VIEW],
    ],
    options: [
      {
        icon: () => <List style={{ color: "#4D52E6" }} />,
        label: "List",
        href: "/notification-profiles",
        value: "List Notification Profiles",
        permissionSet: [
          PERMISSIONS_LIST[`${MODULES_LIST.NOTIFICATION_PROFILES}`][PERMISSIONS_TYPE.VIEW],
        ],
      },
      {
        icon: () => <AddIcon style={{ color: "#4D52E6" }} />,
        label: "Create",
        href: "/notification-profiles/action/create",
        value: "Create Notification Profiles",
        permissionSet: [
          PERMISSIONS_LIST[`${MODULES_LIST.NOTIFICATION_PROFILES}`][PERMISSIONS_TYPE.CREATE],
        ],
      },
      {
        icon: () => (
          <img
            src={EditIcon}
            alt="emptyList"
            height={"15"}
            style={{ marginLeft: "5px", paddingRight: "5px" }}
          />
        ),
        label: "Edit",
        href: "/notification-profiles/action/edit",
        value: "Edit Notification Profiles",
        permissionSet: [
          PERMISSIONS_LIST[`${MODULES_LIST.NOTIFICATION_PROFILES}`][PERMISSIONS_TYPE.EDIT],
        ],
      },
      {
        icon: () => <img src={DeleteIcon} alt="emptyList" height={"22"} />,
        label: "Delete",
        href: "/notification-profiles/action/delete",
        value: "Delete Notification Profiles",
        permissionSet: [
          PERMISSIONS_LIST[`${MODULES_LIST.NOTIFICATION_PROFILES}`][PERMISSIONS_TYPE.DELETE],
        ],
      },
    ],
  },

  {
    group: "Monitors",
    permissionSet: [PERMISSIONS_LIST[`${MODULES_LIST.MONITORS}`][PERMISSIONS_TYPE.VIEW]],
    options: [
      {
        icon: () => <List style={{ color: "#4D52E6" }} />,
        label: "List",
        href: "/monitors/userDefined",
        value: "List Monitors",
        permissionSet: [PERMISSIONS_LIST[`${MODULES_LIST.MONITORS}`][PERMISSIONS_TYPE.VIEW]],
      },
      {
        icon: () => <AddIcon style={{ color: "#4D52E6" }} />,
        label: "Create",
        href: "/monitors/userDefined/action/create",
        value: "Create Monitor",
        permissionSet: [PERMISSIONS_LIST[`${MODULES_LIST.MONITORS}`][PERMISSIONS_TYPE.CREATE]],
      },
      {
        icon: () => (
          <img
            src={EditIcon}
            alt="emptyList"
            height={"15"}
            style={{ marginLeft: "5px", paddingRight: "5px" }}
          />
        ),
        label: "Edit",
        href: "/monitors/userDefined/action/edit",
        value: "Edit Monitor",
        permissionSet: [PERMISSIONS_LIST[`${MODULES_LIST.MONITORS}`][PERMISSIONS_TYPE.EDIT]],
      },
      {
        icon: () => (
          <img src={DeleteIcon} alt="emptyList" height={"22"} style={{ paddingRight: "3px" }} />
        ),
        label: "Delete",
        href: "/monitors/userDefined/action/delete",
        value: "Delete Monitor",
        permissionSet: [PERMISSIONS_LIST[`${MODULES_LIST.MONITORS}`][PERMISSIONS_TYPE.DELETE]],
      },
    ],
  },
  {
    group: "Sites",
    permissionSet: [PERMISSIONS_LIST[`${MODULES_LIST.SITES}`][PERMISSIONS_TYPE.VIEW]],
    options: [
      {
        icon: () => <List style={{ color: "#4D52E6" }} />,
        label: "List",
        href: "/sites",
        value: "List Sites",
        permissionSet: [PERMISSIONS_LIST[`${MODULES_LIST.SITES}`][PERMISSIONS_TYPE.VIEW]],
      },
      {
        icon: () => <AddIcon style={{ color: "#4D52E6" }} />,
        label: "Create",
        href: "/sites/action/create",
        value: "Create Site",
        permissionSet: [PERMISSIONS_LIST[`${MODULES_LIST.SITES}`][PERMISSIONS_TYPE.CREATE]],
      },
      {
        icon: () => (
          <img
            src={EditIcon}
            alt="emptyList"
            height={"15"}
            style={{ marginLeft: "5px", paddingRight: "5px" }}
          />
        ),
        label: "Edit",
        href: "/sites/action/edit",
        value: "Edit Site",
        permissionSet: [PERMISSIONS_LIST[`${MODULES_LIST.SITES}`][PERMISSIONS_TYPE.EDIT]],
      },
      {
        icon: () => (
          <img src={DeleteIcon} alt="emptyList" height={"22"} style={{ paddingRight: "3px" }} />
        ),
        label: "Delete",
        href: "/sites/action/delete",
        value: "Delete Site",
        permissionSet: [PERMISSIONS_LIST[`${MODULES_LIST.SITES}`][PERMISSIONS_TYPE.DELETE]],
      },
    ],
  },
  {
    group: "Windows 365",
    options: [
      {
        group: "Connections",
        permissionSet: [PERMISSIONS_LIST[`${MODULES_LIST.CONNECTIONS}`][PERMISSIONS_TYPE.VIEW]],
        options: [
          {
            icon: () => <img src={EyeIcon} alt="emptyList" height={"25"} />,
            label: "View Connections",
            href: "/windows365/connections",
            value: "List",
            permissionSet: [PERMISSIONS_LIST[`${MODULES_LIST.CONNECTIONS}`][PERMISSIONS_TYPE.VIEW]],
          },
          {
            icon: () => <AddIcon style={{ color: "#4D52E6" }} />,
            label: "Create Connection",
            href: "/windows365/connections/create-connection",
            value: "Create",
            permissionSet: [
              PERMISSIONS_LIST[`${MODULES_LIST.CONNECTIONS}`][PERMISSIONS_TYPE.CREATE],
            ],
          },
          {
            icon: () => (
              <img
                src={EditIcon}
                alt="emptyList"
                height={"15"}
                style={{ marginLeft: "5px", paddingRight: "5px" }}
              />
            ),
            label: "Edit Connection",
            href: "/windows365/connections/edit",
            value: "Edit",
            permissionSet: [PERMISSIONS_LIST[`${MODULES_LIST.CONNECTIONS}`][PERMISSIONS_TYPE.EDIT]],
          },
          {
            icon: () => (
              <img src={DeleteIcon} alt="emptyList" height={"22"} style={{ paddingRight: "3px" }} />
            ),
            label: "Delete Connection",
            href: "/windows365/connections/delete",
            value: "Delete",
            permissionSet: [
              PERMISSIONS_LIST[`${MODULES_LIST.CONNECTIONS}`][PERMISSIONS_TYPE.DELETE],
            ],
          },
        ],
      },
      {
        group: "Device Images",
        permissionSet: [PERMISSIONS_LIST[`${MODULES_LIST.DEVICE_IMAGES}`][PERMISSIONS_TYPE.VIEW]],
        options: [
          {
            icon: () => <img src={EyeIcon} alt="emptyList" height={"25"} />,
            label: "View Device Images",
            href: "/windows365/device-images",
            value: "List Device Images",
            permissionSet: [
              PERMISSIONS_LIST[`${MODULES_LIST.DEVICE_IMAGES}`][PERMISSIONS_TYPE.VIEW],
            ],
          },
          {
            icon: () => <AddIcon style={{ color: "#4D52E6" }} />,
            label: "Create Device Image",
            href: "/windows365/device-images/create",
            value: "Create Device Images",
            permissionSet: [
              PERMISSIONS_LIST[`${MODULES_LIST.DEVICE_IMAGES}`][PERMISSIONS_TYPE.CREATE],
            ],
          },
          {
            icon: () => (
              <img src={DeleteIcon} alt="emptyList" height={"22"} style={{ paddingRight: "3px" }} />
            ),
            label: "Delete Device Image",
            href: "/windows365/device-images/delete",
            value: "Delete Device Images",
            permissionSet: [
              PERMISSIONS_LIST[`${MODULES_LIST.DEVICE_IMAGES}`][PERMISSIONS_TYPE.DELETE],
            ],
          },
        ],
      },
      {
        group: "Provisioning Policies",
        permissionSet: [
          PERMISSIONS_LIST[`${MODULES_LIST.PROVISIONING_POLICY}`][PERMISSIONS_TYPE.VIEW],
        ],
        options: [
          {
            icon: () => <img src={EyeIcon} alt="emptyList" height={"25"} />,
            label: "View Provisioning Policies",
            href: "/windows365/provisioning-policies",
            value: "List Provisioning Policies",
            permissionSet: [
              PERMISSIONS_LIST[`${MODULES_LIST.PROVISIONING_POLICY}`][PERMISSIONS_TYPE.VIEW],
            ],
          },
          {
            icon: () => <AddIcon style={{ color: "#4D52E6" }} />,
            label: "Create Provisioning Policy",
            href: "/windows365/provisioning-policies/create",
            value: "Create Provisioning Policies",
            permissionSet: [
              PERMISSIONS_LIST[`${MODULES_LIST.PROVISIONING_POLICY}`][PERMISSIONS_TYPE.CREATE],
            ],
          },
          {
            icon: () => (
              <img
                src={EditIcon}
                alt="emptyList"
                height={"15"}
                style={{ marginLeft: "5px", paddingRight: "5px" }}
              />
            ),
            label: "Edit Provisioning Policy",
            href: "/windows365/provisioning-policies/edit",
            value: "Edit Provisioning Policy",
            permissionSet: [
              PERMISSIONS_LIST[`${MODULES_LIST.PROVISIONING_POLICY}`][PERMISSIONS_TYPE.EDIT],
            ],
          },
          {
            icon: () => (
              <img src={DeleteIcon} alt="emptyList" height={"22"} style={{ paddingRight: "3px" }} />
            ),
            label: "Delete Provisioning Policy",
            href: "/windows365/provisioning-policies/delete",
            value: "Delete Provisoning Policy",
            permissionSet: [
              PERMISSIONS_LIST[`${MODULES_LIST.PROVISIONING_POLICY}`][PERMISSIONS_TYPE.DELETE],
            ],
          },
        ],
      },
    ],
  },
  {
    group: "Business Servers",
    permissionSet: [PERMISSIONS_LIST[`${MODULES_LIST.BUSINESS_SERVERS}`][PERMISSIONS_TYPE.VIEW]],

    options: [
      {
        icon: () => <List style={{ color: "#4D52E6" }} />,
        label: "List",
        href: "/business-servers",
        value: "List Business Server",
        permissionSet: [
          PERMISSIONS_LIST[`${MODULES_LIST.BUSINESS_SERVERS}`][PERMISSIONS_TYPE.VIEW],
        ],
      },
      {
        icon: () => <AddIcon style={{ color: "#4D52E6" }} />,
        label: "Create",
        href: "/business-servers/action/create",
        value: "Create Business Server",
        permissionSet: [
          PERMISSIONS_LIST[`${MODULES_LIST.BUSINESS_SERVERS}`][PERMISSIONS_TYPE.CREATE],
        ],
      },
      {
        icon: () => (
          <img
            src={EditIcon}
            alt="emptyList"
            height={"15"}
            style={{ marginLeft: "5px", paddingRight: "5px" }}
          />
        ),
        label: "Edit",
        href: "/business-servers/action/edit",
        value: "Edit Business Server",
        permissionSet: [
          PERMISSIONS_LIST[`${MODULES_LIST.BUSINESS_SERVERS}`][PERMISSIONS_TYPE.EDIT],
        ],
      },
      {
        icon: () => (
          <img src={DeleteIcon} alt="emptyList" height={"22"} style={{ paddingRight: "3px" }} />
        ),
        label: "Delete",
        href: "/business-servers/action/delete",
        value: "Delete Business Server",
        permissionSet: [
          PERMISSIONS_LIST[`${MODULES_LIST.BUSINESS_SERVERS}`][PERMISSIONS_TYPE.DELETE],
        ],
      },
    ],
  },
  {
    group: "Extensibility",
    permissionSet: [
      PERMISSIONS_LIST[`${MODULES_LIST.API_ACCOUNTS}`][PERMISSIONS_TYPE.VIEW],
      PERMISSIONS_LIST[`${MODULES_LIST.ADMIN_ACCOUNTS}`][PERMISSIONS_TYPE.VIEW],
    ],

    options: [
      {
        icon: () => <img src={ApiAccountIcon} alt="emptyList" height={"22"} />,
        label: "Accounts",
        href: "/extensibility/accounts",
        value: "List Users",
        permissionSet: [PERMISSIONS_LIST[`${MODULES_LIST.API_ACCOUNTS}`][PERMISSIONS_TYPE.VIEW]],
      },
      {
        icon: () => <img src={AdminAccountIcon} alt="emptyList" height={"22"} />,
        label: "Tenant Admins",
        href: "/extensibility/tenant-admins",
        value: "List Tenant Accounts",
        permissionSet: [PERMISSIONS_LIST[`${MODULES_LIST.ADMIN_ACCOUNTS}`][PERMISSIONS_TYPE.VIEW]],
      },
    ],
  },
];
export default function DashBoardRouteTitle(props) {

  const history = useHistory();
  const { title, showQuickActions, showAdminQuickActions, childRoutes } = props;
  const { organizationIdentity } = useSelector((state) => state.organizationIdentity);
  const { auth, userSupportView } = useSelector((state) => state.auth);
  const [pathRestricted, setPathRestricted] = useState(false);
  const selectedPartner = useSelector((state) => state.partners.selectedPartner);
  const tenant = useSelector((state) => state.dashboard.tenant.tenant);
  const userPermissionsList = useSelector((state) => state.permissions.userPermissionsList);
  const { allPermissionsList } = useSelector((state) => state.permissions);
  const { pathname } = useLocation();
  const handleActionChange = (option) => {
    if (option.href) {
      history.push(option.href);
    }
  };

  const [configActionHeight, setConfigActionHeight] = useState(window.screen.height - 350);

  const containerRef = useRef();

  const adjustConfigActionHeight = () => {
    setConfigActionHeight(window.screen.height - containerRef.current?.offsetTop - 100);
  };
  useEffect(() => {
    window.addEventListener("resize", adjustConfigActionHeight);
  }, []);
  useEffect(() => {
    let restrictPath = false;
    if (childRoutes?.length > 0) {
      childRoutes.forEach((childRoute) => {
        if (!restrictPath && childRoute.hideConfigActions && matchPath(pathname, childRoute)) {
          restrictPath = true;
        }
      });
    }
    setPathRestricted(restrictPath);
  }, [pathname]);

  return (
    <Root className={classes.titleContainer} ref={containerRef}>
      <Grid container>
        <Grid item lg={9} md={8} sm={12} xs={12}>
          {
            title.toLowerCase() !== "sites" ?
            <Typography variant="h4" component="h4" className={classes.root}>
              <img src={AnywareIcon} alt="anywarepc" height={"25"} />
              &nbsp;&nbsp;<b>Anyware PC</b>&nbsp;&nbsp;{title}
            </Typography>
            :
            <Typography
              variant="h3"
              component="h4"
              className={classes.root}
              style={{
                fontSize: '2.5rem',
                fontWeight: '500',
                padding: '0 .5rem',
                height: '100%',
              }}
            >
              {title}
            </Typography>
          }
        </Grid>
        {showAdminQuickActions &&
          hasAccessToRoute(
            [],
            auth?.userRoles,
            userSupportView,
            false,
            false,
            tenant && selectedPartner ? tenant[getTenantId(selectedPartner)] : null,
            [ORG_ROLES.ORG_ADMIN],
            organizationIdentity.role
          ) && checkAdminPermissions(
            ADMIN_CONFIG_ACTIONS_PERMISSIONS_SET,
            allPermissionsList,
            userSupportView
          ) && (
            <Grid item lg={3} md={4} sm={12} xs={12}>
              <QuickActionDropdown
                configActionHeight={configActionHeight}
                placeholder="Config Actions"
                onChange={handleActionChange}
                dontShowBorder={true}
                dropdownData={ADMIN_DROPDOWN_DATA}
                expandAll={true}
              />
            </Grid>
          )}
        <Divider className={classes.divider} />
      </Grid>
    </Root>
  );
}
