import React from "react";
import { HP_ANYWARE_AZURE_MARKETPLACE_OFFER_URL } from "../constants";

/**
 * Handles redirection based on query parameters.
 * If the "src" query parameter is provided, it redirects to the corresponding page. Each src value has a different behavior.
 * If the "src" query parameter is not provided, it redirects to the home page. *
 * @returns {JSX.Element} The JSX element representing the redirection message.
 */
export default function Redirection() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const src = urlParams.get("src") || "";

  // Display a message and redirect to the home page after 5 seconds.
  // Ideal to use when there is an error and the user needs to be redirected to the home page.
  const redirectHome = (message) => {
    setTimeout(() => {
      window.location.href = "/";
    }, 5000);
    return (
      <p>
        {message}
        <br />
        Redirecting to the home page in 5 seconds...
      </p>
    );
  }

  if (!src) {
    return redirectHome("Error: No src query parameter was provided.");
  }


  if (src.toLowerCase() === "workforceexp") {
    // Save to local storage that this was a referene from workforceexp
    localStorage.setItem("srcReferrer", src);

    if (!HP_ANYWARE_AZURE_MARKETPLACE_OFFER_URL) {
      return redirectHome("Error: HP_ANYWARE_AZURE_MARKETPLACE_OFFER_URL is not defined!");
    }
  
    // Redirect to external URL to Azure Marketplace offer
    window.location.href = HP_ANYWARE_AZURE_MARKETPLACE_OFFER_URL;

    return (
      <p>
        Redirecting to HP Anyware Azure Marketplace offer...
      </p>
    );
  }  
}
