import { useSelector } from "react-redux";
import { getIdentityId, getTenantId } from "../utils";

export const useOrgAndTenantId = () => {
  const selectedPartner = useSelector(
    (state) => state.partners.selectedPartner,
  );
  const selectedIdentity = useSelector(
    (state) => state.identities.selectedIdentity,
  );
  const tenantId = getTenantId(selectedPartner);
  const organizationId = getIdentityId(selectedIdentity);

  return { organizationId, tenantId };
};
