import React, { useState, useEffect, useSelector } from "react";
import { SESSION_INACTIVITY_TIMEOUT, SESSION_INACTIVITY_RESPONSE } from "../constants";
import { getAzureB2C } from "../../azureB2C/factories/azure-ad-factory";
import InactivityModal from "./InactivityModal";
import sessionActivity from "../utils/session-activity";
import { getTenantId } from "../../common/utils";
import _ from "lodash";
const REFRESH_INTERVAL = 1000;

export default function InactivityTimer(props) {
  const [showModal, setShowModal] = useState(false);
  const [timeLeft, setTimeLeft] = useState(null);
  const [intervalId, setIntervalId] = useState(null);
  const [defaultSessionTimeOut, setDefaultSessionTimeout] = useState(SESSION_INACTIVITY_TIMEOUT);
  const { selectedIdentity, identities, tenant, selectedPartner } = props;

  const handleUserInputFunction = () => {
    sessionActivity.setLastActive(Date.now());
  };

  const handleUserInput = _.debounce(handleUserInputFunction, 100);

  useEffect(() => {
    const now = Date.now();
    sessionActivity.setLastActive(now);
    handleInactivityCheck(defaultSessionTimeOut);
    document.addEventListener("mousemove", handleUserInput);
    document.addEventListener("keydown", handleUserInput);

    setIntervalFunction(defaultSessionTimeOut);

    return () => {
      document.removeEventListener("mousemove", handleUserInput);
      document.removeEventListener("keydown", handleUserInput);

      clearIntervalFunction();
    };
  }, []);

  useEffect(() => {
    if (
      selectedPartner &&
      tenant &&
      tenant[getTenantId(selectedPartner)] &&
      tenant[getTenantId(selectedPartner)]?.sessionIdleTimeoutMinutes
    ) {
      const expirationTime = tenant[getTenantId(selectedPartner)]?.sessionIdleTimeoutMinutes;
      setDefaultSessionTimeout(expirationTime * 60);
      clearIntervalFunction();
      setIntervalFunction(expirationTime * 60);
    } else if (selectedIdentity && identities) {
      identities.forEach((identity) => {
        if (identity.id === selectedIdentity && identity.sessionIdleTimeoutMinutes) {
          const expirationTime = identity.sessionIdleTimeoutMinutes * 60;
          setDefaultSessionTimeout(expirationTime);
          clearIntervalFunction();
          setIntervalFunction(expirationTime);
        }
      });
    }
  }, [tenant, identities, selectedPartner, selectedIdentity]);

  const handleInactivityCheck = (defaultSessionTimeOut) => {
    const client = getAzureB2C();
    const lastActive = sessionActivity.getLastActive();
    const now = Date.now();
    const elapsed = (now - lastActive) / 1000;
    const hasExpired = elapsed >= defaultSessionTimeOut;
    const warningWindow = {
      start: defaultSessionTimeOut - SESSION_INACTIVITY_RESPONSE,
      end: defaultSessionTimeOut,
    };
    const showWarning = elapsed >= warningWindow.start && elapsed < warningWindow.end;
    const timeLeft = warningWindow.end - elapsed;

    if (showWarning) {
      setShowModal(true);
      setTimeLeft(timeLeft);
    } else if (hasExpired) {
      sessionActivity.setLastActive(null);
      client.logout();
    }
  };

  const setIntervalFunction = (defaultSessionTimeOut) => {
    setTimeout(() => {
      setIntervalId(
        setInterval(() => {
          handleInactivityCheck(defaultSessionTimeOut);
        }, REFRESH_INTERVAL)
      );
    });
  };

  const clearIntervalFunction = () => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
  };

  const handleToggleModal = () => {
    const shouldShow = !showModal;

    if (shouldShow) {
      clearIntervalFunction();
    }

    setShowModal(shouldShow);

    if (!shouldShow) {
      window.location.reload();
    }
  };

  const handleConfirmInactivity = (confirmed, noToggle) => {
    const client = getAzureB2C();

    handleToggleModal();

    if (!confirmed) {
      sessionActivity.setLastActive(null);
      client.logout();
    } else {
      sessionActivity.setLastActive(Date.now());
      setIntervalFunction(defaultSessionTimeOut);
    }
  };

  return (
    <React.Fragment>
      {showModal ? (
        <React.Fragment>
          <InactivityModal
            onClose={handleToggleModal}
            inactivityTimeOut={defaultSessionTimeOut}
            open={showModal}
            timeLeft={timeLeft}
            onConfirm={handleConfirmInactivity}
          />
        </React.Fragment>
      ) : (
        <React.Fragment />
      )}
    </React.Fragment>
  );
}
