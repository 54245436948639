import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";
import InfoIcon from "@mui/icons-material/Info";
import CancelIcon from "@mui/icons-material/Cancel";
import { Typography } from "@mui/material";
import PropTypes from "prop-types";
import { AlertTitle, Alert as MuiAlert } from "@mui/material";

const iconMapping = {
  success: <CheckCircleIcon fontSize="inherit" />,
  warning: <WarningIcon fontSize="inherit" />,
  info: <InfoIcon fontSize="inherit" />,
  error: <CancelIcon fontSize="inherit" />,
};

const Alert = (props) => {
  const {
    severity,
    message,
    messageVariant,
    messageComponent,
    alertTitle,
    dataTestIdPrefix,
    ...rest
  } = props;
  return (
    <MuiAlert
      style={{ alignItems: "center" }}
      iconMapping={iconMapping}
      severity={severity}
      {...rest}
    >
      {alertTitle && <AlertTitle>{alertTitle}</AlertTitle>}
      <Typography
        variant={messageVariant}
        component={messageComponent}
        data-testid={`${dataTestIdPrefix}-text`}
      >
        {message}
      </Typography>
    </MuiAlert>
  );
};

Alert.propTypes = {
  message: PropTypes.oneOfType([
    PropTypes.string, 
    PropTypes.object,  
    PropTypes.element, 
    PropTypes.arrayOf(PropTypes.element)
  ]).isRequired,
  // This list can be extended based on the usage requirements of the component
  messageVariant: PropTypes.oneOf(["subtitle1", "subtitle2", "body1", "body2"]),
  severity: PropTypes.oneOf(["success", "error", "warning", "info"]),
  messageComponent: PropTypes.string,
};

Alert.defaultProps = {
  severity: "success",
  messageVariant: "subtitle1",
  messageComponent: "span",
};

export default Alert;
