import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HTTP_METHODS } from "../common/constants";
import HTTPClient from "../common/servicers/httpClient";

/**************************************
 *********** API ACTIONS ***************
 ***************************************/

export const getStates = createAsyncThunk(
  "states",
  async ({ useCache = true }, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, loading, states } = getState().states;
    if (!loading || requestId !== currentRequestId) {
      return;
    }
    if (useCache && states && states.length > 0) {
      return { data: states };
    }
    try {
      const response = await new HTTPClient({
        endpoint: "/states/",
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return response.data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  },
);

export const initialState = {
  states: [],
  requesting: false,
  loading: false,
};

export const states = createSlice({
  name: "states",
  initialState,
  reducers: {
    setRequesting: (state, action) => {
      state.requesting = action.payload;
    },
  },
  extraReducers: {
    [getStates.pending]: (state, action) => {
      state.loading = true;
      state.currentRequestId = action.meta.requestId;
    },
    [getStates.fulfilled]: (state, action) => {
      state.loading = false;
      state.currentRequestId = null;
      state.states = action.payload
        ? action.payload.data
          ? action.payload.data
          : []
        : [];
    },
    [getStates.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.currentRequestId = null;
    },
  },
});

export default states.reducer;
