import axios from "axios";

import { HTTP_METHODS, PROXY_API_URL } from "../constants";
import { getAzureB2C } from "../../azureB2C/factories/azure-ad-factory";
import { REQ } from "../utils";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import appInsights from "../../appInsights";

axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.put["Content-Type"] = "application/json";
axios.defaults.baseURL = PROXY_API_URL;

export default class HTTPClient {
  constructor(config) {
    this.config = {
      endpoint: config.params
        ? this.generateURL(config.endpoint, config.params)
        : config.endpoint,
      method: config.method || HTTP_METHODS.GET,
      data: config.data,
      signal: config.signal,
      headers: {
        ...config.headers,
        "Content-Type":
          config.data instanceof FormData
            ? "multipart/form-data"
            : "application/json",
      },
    };
  }

  trackRetryFailure = (endpoint, method, statusCode) => {
    appInsights.trackEvent({
      name: "RetryApiCallFailedAfterMaxAttempts",
      properties: { endpoint, method, statusCode },
    });
  };

  trackRetrySucceeded = (endpoint, method) => {
    appInsights.trackEvent({
      name: "RetryApiCallSucceeded",
      properties: { endpoint, method },
    });
  };

  trackException = (exception) => {
    appInsights.trackException({
      exception: exception,
      properties: {
        apiCallFailed: true,
        endpoint: this.config.endpoint,
      },
    });
  };

  waitBeforeRetry = async (attempt) => {
    const MIN_WAIT_TIME_BEFORE_RETRY = 1000;
    const MAX_WAIT_TIME_BEFORE_RETRY = 5000;
    const BACKOFF_FACTOR = 2;

    let waitTime = Math.min(
      MIN_WAIT_TIME_BEFORE_RETRY * Math.pow(BACKOFF_FACTOR, attempt),
      MAX_WAIT_TIME_BEFORE_RETRY,
    );

    waitTime = waitTime / 2 + (Math.random() * waitTime) / 2;

    console.debug(`Waiting ${waitTime} ms before retry #${attempt}`);
    await new Promise((resolve) => setTimeout(resolve, waitTime));
  };

  callAuthorizedAPI = async () => {
    const azureClient = getAzureB2C();
    const azureAccount = azureClient.getAccount();
    azureClient.setActiveAccount(azureAccount);
    try {
      const azureData = await azureClient.acquireTokenSilent({
        azureAccount,
        ...REQ,
        forceRefresh: false,
      });
      this.config.headers = {
        ...this.config.headers,
        Authorization: `Bearer ${azureData.accessToken}`,
      };
    } catch (e) {
      if (e instanceof InteractionRequiredAuthError) {
        this.trackException(e);
        window.location.reload();
      }
    }

    const MAX_RETRIES = 3;
    let attempt = 0;
    const HTTP_CODES_TO_RETRY = [500, 502, 503, 504, 429];

    while (attempt < MAX_RETRIES) {
      try {
        const response = await axios(this.config.endpoint, this.config);

        if (attempt > 0) {
          this.trackRetrySucceeded(this.config.endpoint, this.config.method);
        }

        return response;
      } catch (err) {
        let shouldRetry =
          err.response &&
          HTTP_CODES_TO_RETRY.includes(err.response.status) &&
          this.config.method.toUpperCase() === "GET";

        if (!shouldRetry || attempt === MAX_RETRIES - 1) {
          if (attempt === MAX_RETRIES - 1) {
            this.trackRetryFailure(
              this.config.endpoint,
              this.config.method,
              err.response.status,
            );
          }
          this.trackException(err);
          throw err;
        }

        attempt++;

        await this.waitBeforeRetry(attempt);
      }
    }
  };

  callUnauthorizedAPI = async () => {
    return new Promise((resolve, reject) => {
      axios(this.config.endpoint, this.config)
        .then((r) => {
          resolve(r);
        })
        .catch((err) => {
          this.trackException(err);
          reject(err);
        });
    });
  };

  generateURL = (endpoint, queryParams) => {
    const URL = endpoint + "?";
    const params = [];
    Object.entries(queryParams).forEach(([key, value]) => {
      params.push(`${key}=${value}`);
    });
    return URL + params.join("&");
  };
}
