import { styled } from "@mui/material/styles";
import React from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { useToast } from "@veneer/core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CheckboxField from "../../../common/components/form/CheckboxField";
import VeneerButton from "../../../common/components/VeneerButton";
import { SNACKBAR_DURATION_MS } from "../../../common/constants";
import { getIdentityId, getTenantId } from "../../../common/utils";
import {
  rebootMachine,
  resetErrors,
  setOpenRequestRebootModal,
} from "../../slices/machineSlice";

const PREFIX = "RequestRebootModal";

const classes = {
  root: `${PREFIX}-root`,
  titleContainer: `${PREFIX}-titleContainer`,
  paper: `${PREFIX}-paper`,
  closeButton: `${PREFIX}-closeButton`,
  title: `${PREFIX}-title`,
  buttons: `${PREFIX}-buttons`,
  description2: `${PREFIX}-description2`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    margin: 0,
    padding: theme.spacing(1, 2),
    boxShadow: "none",
  },

  [`& .${classes.titleContainer}`]: {
    display: "flex",
    padding: theme.spacing(1, 2),
  },

  [`& .${classes.paper}`]: {
    minWidth: "600px",
  },

  [`& .${classes.closeButton}`]: {
    color: theme.palette.grey[500],
    padding: "3px 0 0 0",
  },

  [`& .${classes.title}`]: {
    fontWeight: "500",
    flex: 1,
  },

  [`& .${classes.buttons}`]: {
    float: "right",
    marginLeft: "2%",
  },

  [`& .${classes.description2}`]: {
    marginTop: "10px",
  },
}));

const RequestRebootModal = (props) => {
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const { open } = props;
  const { machineName, siteName } = useParams();
  const [forceReboot, setForceReboot] = useState(false);
  const [machineNamePassed, setMachineNamePassed] = useState(null);
  const [siteNamePassed, setSiteNamePassed] = useState(null);

  useEffect(() => {
    if (props.virtualMachineId) {
      let splittedData = props.virtualMachineId.split("/");
      if (splittedData.length > 7) {
        setSiteNamePassed(splittedData[6]);
        setMachineNamePassed(splittedData[8]);
      } else {
        setMachineNamePassed(null);
        setSiteNamePassed(null);
      }
    } else {
      setMachineNamePassed(null);
      setSiteNamePassed(null);
    }
  }, [props.virtualMachineId]);

  const { requestingReboot, errorWhileRebooting, rebootRequested } =
    useSelector((state) => state.dashboard.machines);
  const selectedPartner = useSelector(
    (state) => state.partners.selectedPartner,
  );
  const selectedIdentity = useSelector(
    (state) => state.identities.selectedIdentity,
  );

  const reboot = async () => {
    const { meta } = await dispatch(
      rebootMachine({
        selectedTenant: getTenantId(selectedPartner),
        organizationId: getIdentityId(selectedIdentity),
        machineName: machineNamePassed ? machineNamePassed : machineName,
        siteName: siteNamePassed ? siteNamePassed : siteName,
        forceReboot: forceReboot,
      }),
    );
    if (meta.requestStatus === "fulfilled") {
      dispatch(setOpenRequestRebootModal(false));
    }
    setTimeout(() => dispatch(resetErrors()), SNACKBAR_DURATION_MS);
  };

  const onClose = () => {
    dispatch(setOpenRequestRebootModal(false));
  };

  useEffect(() => {
    const name = machineNamePassed ? machineNamePassed : machineName;
    if (rebootRequested === true && name) {
      addToast({
        text: `Reboot Requested for ${name}`,
        type: "positive",
        timeout: 6,
        id: Date.now(),
      });
    }
  }, [addToast, machineName, machineNamePassed, rebootRequested]);

  useEffect(() => {
    const name =
      !errorWhileRebooting && machineNamePassed
        ? machineNamePassed
        : machineName;
    if (errorWhileRebooting && name) {
      const message = errorWhileRebooting
        ? errorWhileRebooting
        : `Unable to reboot machine ${name}. Unknown Error.`;
      addToast({
        text: message,
        type: "positive",
        timeout: 6,
        id: Date.now(),
      });
    }
  }, [addToast, errorWhileRebooting, machineName, machineNamePassed]);

  return (
    <Root>
      <Dialog
        classes={{ paper: classes.paper, root: classes.root }}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <MuiDialogTitle className={classes.titleContainer}>
          <Typography className={classes.title} variant="h6">
            Reboot Machine
          </Typography>
          {onClose ? (
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onClose}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </MuiDialogTitle>
        <MuiDialogContent dividers>
          <div>
            <div>
              <Typography variant="body3" component="div">
                This action will reboot the{" "}
                <b>{machineNamePassed ? machineNamePassed : machineName}</b>{" "}
                machine. Any end users that are logged in will see a prompt
                indicating that they have 5 minutes to save their work before
                the machine will be rebooted. Optionally, you may confirm that
                you want to force a reboot immediately, which would be
                disruptive to end user sessions.
              </Typography>
              <CheckboxField
                label="Force reboot"
                name="forceReboot"
                color="info"
                defaultChecked={forceReboot}
                onChange={setForceReboot}
              />
            </div>

            <VeneerButton
              disabled={requestingReboot}
              appearance="primary"
              className={classes.buttons}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                reboot();
              }}
              loading={requestingReboot}
            >
              {requestingReboot ? "Requesting reboot..." : "Confirm"}
            </VeneerButton>
            <VeneerButton
              disabled={requestingReboot}
              appearance="secondary"
              type="button"
              className={classes.buttons}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onClose();
              }}
            >
              Cancel
            </VeneerButton>
          </div>
        </MuiDialogContent>
      </Dialog>
    </Root>
  );
};

export default RequestRebootModal;
