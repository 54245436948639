import { useToast } from "@veneer/core";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  HYPERVISOR_TYPES,
  RESOURCE_POOL_TYPE,
} from "../../../../common/constants";
import useCTA from "../../../../common/hooks/useCTA";
import { useOrgAndTenantId } from "../../../../common/hooks/useOrgAndTenantId";
import { statusMessages } from "../../../../common/utils";
import {
  checkExistingPool,
  composeValidators,
  required,
} from "../../../../common/validators";
import AssignmentUsersForm from "../../../../onboarding/components/AssignmentUsersForm";
import MachineNamingPatternForm from "../../../../onboarding/components/MachineNamingPatternForm";
import QuantityForm from "../../../../onboarding/components/QuantityForm";
import SelectTemplateForm from "../../../../onboarding/components/SelectTemplateForm";
import PoolNameForm from "../../../../onboarding/components/poolNameForm";
import { getPoolsCTAContent } from "../../../../onboarding/components/utils/utils";
import { validatePoolName } from "../../../../onboarding/onboardingUtils";
import {
  createPool,
  setAddPoolName,
  setAssignmentPolicy,
  setAssignmentUser,
  setPassword,
  setPrefix,
  setProvisioningMethod,
  setQuantity,
  setSuffixLength,
  setSuffixNumberingPattern,
  setTemplate,
  setUsername,
} from "../../poolsSlice";
import {
  findLastTrueWithAllPreviousTrue,
  generateRandomNumber,
} from "../../poolsUtils";

//validates whether the form is enabled to move to the next step
export const useCreatePoolVsphereValidation = () => {
  const {
    name,
    template,
    // assignmentPolicy,
    // provisioningMethod,
    assignmentUser,
    quantity,
    prefix,
    suffixLength,
    suffixNumberingPattern,
    username,
    password,
  } = useSelector((state) => state.adminActions.pools.addPoolVsphere);

  const { pools } = useSelector((state) => state.adminActions.pools);

  const { tenantId } = useOrgAndTenantId();
  const tenantPools = pools[tenantId] || [];

  const poolExists = Boolean(checkExistingPool(tenantPools)(name));

  const isNameValid =
    !composeValidators(required, validatePoolName)(name) && !poolExists;

  const isUsernameAndPasswordValid = !!(!!username && !!password);
  const isTemplateValid = !!(template && isUsernameAndPasswordValid);
  const isAssignmentUserValid = assignmentUser?.length > 0;

  // TSW-218685 TODO: Uncomment when the backend is ready to receive these values
  // const isAssignmentPolicyValid = !!assignmentPolicy;
  // const isProvisioningMethodValid = !!provisioningMethod;
  const isQuantityValid = !!(quantity && quantity > 0);
  const isMachineNamePatterningValid = !!(
    prefix &&
    suffixLength &&
    suffixLength >= 3 &&
    suffixNumberingPattern
  );

  const validSteps = {
    isNameValid,
    isTemplateValid,
    // isProvisioningMethodValid,
    isAssignmentUserValid,
    isQuantityValid,
    isMachineNamePatterningValid,
  };

  validSteps.isValidToCreatePool = !!Object.values(validSteps).every(
    (i) => i === true,
  );

  return validSteps;
};

export const useVsphereStepContent = (stepNumber) => {
  const dispatch = useDispatch();
  const {
    name,
    template,
    // assignmentPolicy,
    // provisioningMethod,
    assignmentUser,
    quantity,
    prefix,
    suffixLength,
    suffixNumberingPattern,
    username,
    password,
  } = useSelector((state) => state.adminActions.pools.addPoolVsphere);
  const { loadingTemplates, templates } = useSelector(
    (state) => state.adminActions.pools,
  );

  const { loadingUsers, users } = useSelector(
    (state) => state.adminActions.pools,
  );

  //generates example machine paterning data based on selected options
  const dataCardsExamples = useMemo(() => {
    const resultGenerateCode =
      suffixNumberingPattern === "Sequential"
        ? Array.from({ length: 3 }, (_, index) =>
            (index + 1).toString().padStart(Number(suffixLength), "0"),
          )
        : Array.from({ length: 3 }, () => generateRandomNumber(suffixLength));
    const result = resultGenerateCode?.map((i) => `${prefix}${i}`);
    return (
      !!(prefix && suffixLength && suffixNumberingPattern) &&
      (result.toString() || "")
    );
  }, [prefix, suffixLength, suffixNumberingPattern]);

  const templateOptions = templates
    ?.map((i) => ({
      value: i?.templateName,
      label: i?.templateName,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const usersOptions = users
    ?.filter(
      (i) => i?.userPrincipalName && !i.userPrincipalName.includes("#EXT#"),
    )
    .map((i) => ({
      value: i?.userPrincipalName,
      label: i?.displayName,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  //This function updates the redux state, it takes the data that the user is changing and which function should be executed
  const formUpdater = {
    poolNameForm: (data) => {
      dispatch(setAddPoolName(data?.name));
    },
    selectTemplateForm: (data) => {
      dispatch(setTemplate(data?.template));
      dispatch(setUsername(data?.username));
      dispatch(setPassword(data?.password));
    },
    assignmentPolicyForm: (data) => {
      dispatch(setAssignmentPolicy(data?.assignmentPolicy));
    },
    provisioningMethodForm: (data) => {
      dispatch(setProvisioningMethod(data?.provisioningMethod));
    },
    assignmentUsersForm: (data) => {
      dispatch(setAssignmentUser(data?.assignmentUser));
      dispatch(
        setQuantity(Object.keys(data?.assignmentUser).length.toString()),
      );
    },
    quantityForm: (data) => {
      dispatch(setQuantity(data?.quantity?.replace(/\D/g, "")));
    },
    machineNamingPatternForm: (data) => {
      dispatch(setPrefix(data?.prefix));
      dispatch(setSuffixLength(data?.suffixLength?.replace(/\D/g, "")));
      dispatch(setSuffixNumberingPattern(data?.suffixNumberingPattern));
    },
  };

  const handleChange = (data, form) => {
    const updateForm = formUpdater[form];
    updateForm(data);
  };

  //Creation of components to be displayed in each step.
  const steps = {
    0: <PoolNameForm onChange={handleChange} initialValues={{ name }} />,
    1: (
      <SelectTemplateForm
        onChange={handleChange}
        initialValues={{
          template,
          username,
          password,
        }}
        options={templateOptions}
        loading={loadingTemplates}
      />
    ),
    2: (
      <AssignmentUsersForm
        initialValues={{ assignmentUser }}
        onChange={handleChange}
        options={usersOptions}
        loading={loadingUsers}
      />
    ),
    // TSW-218685 TODO: Uncomment when the backend is ready to receive these values
    // 2: (
    //   <AssignmentPolicyForm
    //     initialValues={{ assignmentPolicy }}
    //     onChange={handleChange}
    //   />
    // ),
    // 3: (
    //   <ProvisioningMethodForm
    //     initialValues={{
    //       provisioningMethod,
    //     }}
    //     onChange={handleChange}
    //   />
    // ),
    3: <QuantityForm initialValues={{ quantity }} onChange={handleChange} />,
    4: (
      <MachineNamingPatternForm
        initialValues={{ prefix, suffixLength, suffixNumberingPattern }}
        onChange={handleChange}
        dataCardsExamples={dataCardsExamples}
      />
    ),
  };
  return steps[stepNumber] || "Unknown step";
};

//data for creating the step, enabling the button to move to next step(based on the validator), configuring the header, and customizing the button label
export const useVsphereStepsMetaData = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { setCTA } = useCTA();
  const { addToast } = useToast();

  const [stepErrors, setStepErrors] = React.useState({});

  const {
    name,
    template,
    assignmentUser,
    username,
    password,
    quantity,
    prefix,
    suffixLength,
    suffixNumberingPattern,
  } = useSelector((state) => state.adminActions.pools.addPoolVsphere);

  const { selectedSite } = useSelector((state) => state.adminActions.pools);

  const { organizationId, tenantId } = useOrgAndTenantId();

  const validatorForm = useCreatePoolVsphereValidation();

  const handleCreatePool = async () => {
    const payload = {
      name: name,
      resourcePoolType: RESOURCE_POOL_TYPE.VDI,
      site: selectedSite?.label,
      deviceImage: template?.label,
      users: assignmentUser,
      localUserName: username,
      localPassword: password,
      hypervisorType: HYPERVISOR_TYPES.VCENTER,
      targetServers: Number(quantity),
      serverNaming: {
        prefix: prefix,
        minNumOfDigits: suffixLength,
        numberingMethod: suffixNumberingPattern,
      },
    };

    const resultAction = await dispatch(
      createPool({ organizationId, tenantId, payload }),
    );

    return resultAction;
  };

  const stepsMetadata = [
    {
      heading: "Enter pool name",
      canMoveToNextStep: validatorForm.isNameValid,
      onStepSubmit: async () => {},
    },
    {
      heading: "Select template",
      canMoveToNextStep: validatorForm.isTemplateValid,
      onStepSubmit: async () => {},
    },
    {
      heading: "Select users",
      canMoveToNextStep: validatorForm.isAssignmentUserValid,
      stepSubmitButtonLabel: "Create pool",
      onStepSubmit: async () => {},
    },
    // TSW-218685 TODO: Uncomment when the backend is ready to receive these values
    // {
    //   heading: "Select assignment policy",
    //   canMoveToNextStep: validatorForm.isAssignmentPolicyValid,
    //   onStepSubmit: async () => { },
    // },
    // {
    //   heading: "Select provisioning method",
    //   canMoveToNextStep: validatorForm.isProvisioningMethodValid,
    //   onStepSubmit: async () => { },
    // },
    {
      heading: "Select quantity",
      canMoveToNextStep: validatorForm.isQuantityValid,
      onStepSubmit: async () => {},
    },
    {
      heading: "Define machine naming pattern",
      canMoveToNextStep:
        validatorForm.isMachineNamePatterningValid &&
        validatorForm.isValidToCreatePool,
      onStepSubmit: async () => {
        setStepErrors({});
        try {
          const { meta, payload, error } = await handleCreatePool();
          if (meta.requestStatus === "fulfilled") {
            setStepErrors((prevErrors) => ({ ...prevErrors, 4: false }));

            addToast({
              text: `${name} has been added to Pools.`,
              type: "positive",
              timeout: 6,
              id: Date.now(),
            });

            setCTA(getPoolsCTAContent(name));

            history.push("/dashboard/pools/progress");

            return { error: false };
          }
          if (meta.requestStatus === "rejected") {
            setStepErrors((prevErrors) => ({ ...prevErrors, 4: true }));
            const messageError =
              payload?.detail ||
              error?.message ||
              statusMessages[payload?.status] ||
              "An error occurred while creating the pool.";
            addToast({
              text: messageError,
              type: "negative",
              timeout: 6,
              id: Date.now(),
            });
            return { error: true };
          }
          return { error: true };
        } catch (error) {
          addToast({
            text: `Failed to create pool: ${error}`,
            type: "negative",
            timeout: 6,
            id: Date.now(),
          });
          setStepErrors((prevErrors) => ({ ...prevErrors, 4: true }));
          return { error: true };
        }
      },
    },
  ];

  return {
    stepErrors,
    stepsMetadata,
  };
};

//identifies the most recent step if the user has already started filling out the form.
export const useCurrentStep = () => {
  const validatorForm = useCreatePoolVsphereValidation();
  const currentStep = useMemo(
    () => findLastTrueWithAllPreviousTrue(Object.values(validatorForm)),
    [],
  );
  return currentStep;
};
