import React, { useEffect } from "react";
import { styled } from '@mui/material/styles';
import MuiTabs from "@mui/material/Tabs";
import { Typography, Tab, Grid } from "@mui/material";
import { useHistory, useParams } from "react-router-dom";
import QuickActionDropdown from "./QuickActionDropdown";
import Tooltip from "./Tooltip";
import { hasAccessToRoute, getTenantId, checkTenantPermissions } from "../utils";
import { useSelector } from "react-redux";

const PREFIX = 'SubTabs';

const classes = {
  root: `${PREFIX}-root`,
  tab: `${PREFIX}-tab`,
  tooltip: `${PREFIX}-tooltip`,
  header: `${PREFIX}-header`
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    padding: `0 0`,
    borderBottom: "2px solid #C4C4C4",
  },

  [`& .${classes.tab}`]: {
    justifyContent: "flex-end",
    "& .MuiTabs-scrollable": {
      borderBottom: "0 !important",
    },
    "& .MuiTab-root": {
      minWidth: "90px",
    },
  },

  [`& .${classes.tooltip}`]: {
    marginLeft: theme.spacing(1),
  },

  [`& .${classes.header}`]: {
    alignSelf: "center",
    fontWeight: 400,
    fontSize: "24px",
    lineHeight: "30px",
  }
}));

const DROPDOWN_DATA = [
  {
    group: "Users",
    options: [
      {
        label: "Assign",
        value: "Assign",
      },
      {
        label: "Edit",
        value: "Edit",
      },
      {
        label: "Remove",
        value: "Remove",
      },
    ],
  },
  {
    group: "Apps",
    options: [
      {
        label: "Assign",
        value: "Assign",
      },
      {
        label: "Edit",
        value: "Edit",
      },
      {
        label: "Remove",
        value: "Remove",
      },
    ],
  },
];
function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

function LinkTab(props) {
  const history = useHistory();
  return (
    <Tab
      onClick={(event) => {
        history.push(props.href);
        event.preventDefault();
      }}
      color="secondary"
      {...props}
    />
  );
}

export default function SubTabs(props) {

  const params = useParams();
  const { title, tooltip, routes, actions } = props;
  const selectedPartner = useSelector((state) => state.partners.selectedPartner);
  const tenant = useSelector((state) => state.dashboard.tenant.tenant);
  const state = useSelector((state) => state);
  const userPermissionsList = useSelector((state) => state.permissions.userPermissionsList);
  var headerTitle = "";
  if (typeof title === "function") {
    headerTitle = title();
  } else {
    headerTitle = title;
  }
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { auth, userSupportView } = useSelector((state) => state.auth);

  useEffect(() => {
    routes.forEach((route, i) => {
      if (window.location.pathname === encodeURI(getUrlPath(route.path))) {
        setValue(i);
      }
    });
  }, [routes]);

  const getUrlPath = (path) => {
    const paramsArray = Object.keys(params);
    var pathValue = path;
    paramsArray.forEach((paramKey) => {
      if (params[paramKey]) pathValue = pathValue.replace(":" + paramKey, params[paramKey]);
    });
    return pathValue;
  };

  return (
    <StyledGrid container spacing={0} className={classes.root}>
      <Grid item lg={3} md={3} sm={12} xs={12}>
        <Typography variant="body2" className={classes.header} noWrap={true}>
          {headerTitle}
          {tooltip ? <Tooltip {...tooltip} /> : ""}
        </Typography>
      </Grid>
      <Grid item lg={7} md={7} sm={12} xs={12} justifyContent="flex-end">
        <MuiTabs
          value={value}
          onChange={handleChange}
          classes={{ root: classes.tab }}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs"
        >
          {props.routes
            .filter((r) => !r.hideTabSelector || !r.hideTabSelector(state))
            .map((route, i) => {
              if (route.isTab)
                return (
                  <LinkTab
                    key={i}
                    label={route.title}
                    href={getUrlPath(route.path)}
                    disabled={route.checkDisabled ? route.checkDisabled() : route.disabled}
                    {...a11yProps(i)}
                    style={{
                      display:
                        hasAccessToRoute(
                          route?.roles,
                          auth?.userRoles,
                          userSupportView,
                          route?.isPrivate,
                          route?.adminView,
                          tenant && selectedPartner ? tenant[getTenantId(selectedPartner)] : null
                        ) &&
                        checkTenantPermissions(
                          route?.permissionSet,
                          userPermissionsList,
                          getTenantId(selectedPartner),
                          userSupportView
                        )
                          ? "inherit"
                          : "none",
                    }}
                  />
                );
            })}
        </MuiTabs>
      </Grid>
      <Grid item lg={2} md={2} sm={12} xs={12}>
        {actions}
      </Grid>
    </StyledGrid>
  );
}
