import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HTTP_METHODS } from "../common/constants";
import HTTPClient from "../common/servicers/httpClient";

/**************************************
 *********** API ACTIONS ***************
 ***************************************/

export const getOrganizationIdentity = createAsyncThunk(
  "organizationIdentity",
  async (
    dataToBeUsedInAPIPayload,
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loading } = getState().organizationIdentity;
    if (!loading || requestId !== currentRequestId) {
      return;
    }
    try {
      let url = "/organizations/identity/{orgId}";
      dataToBeUsedInAPIPayload =
        dataToBeUsedInAPIPayload.organizationId.replace("/organizations/", "");
      url = url.replace("{orgId}", encodeURI(dataToBeUsedInAPIPayload));
      const response = await new HTTPClient({
        endpoint: url,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

/**************************************
 ************** Reducers ***************
 ***************************************/

export const initialState = {
  organizationIdentity: {},
  requesting: false,
};

export const organizationIdentitySlice = createSlice({
  name: "organizationIdentity",
  initialState,
  reducers: {
    setRequesting: (state, action) => {
      state.requesting = action.payload;
    },
  },
  extraReducers: {
    [getOrganizationIdentity.pending]: (state, action) => {
      state.loading = true;
      state.currentRequestId = action.meta.requestId;
    },
    [getOrganizationIdentity.fulfilled]: (state, action) => {
      state.loading = false;
      state.currentRequestId = null;
      state.organizationIdentity = action.payload.data;
    },
    [getOrganizationIdentity.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.currentRequestId = null;
    },
  },
});

export default organizationIdentitySlice.reducer;
