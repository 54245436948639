import React, { useEffect } from "react";
import { styled } from '@mui/material/styles';

import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import { Grid, Link } from "@mui/material";
import PropTypes from "prop-types";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { checkTenantPermissions, getTenantId } from "../utils";

const PREFIX = 'EntityActionDropdown';

const classes = {
  root: `${PREFIX}-root`,
  borderred: `${PREFIX}-borderred`,
  dropdownContainer: `${PREFIX}-dropdownContainer`,
  expandMoreIcon: `${PREFIX}-expandMoreIcon`,
  optionSubLabel: `${PREFIX}-optionSubLabel`,
  optionSubLabelSpan: `${PREFIX}-optionSubLabelSpan`,
  optionSubLabelWarningSpan: `${PREFIX}-optionSubLabelWarningSpan`,
  optionSubLabelInactiveSpan: `${PREFIX}-optionSubLabelInactiveSpan`,
  warningIcon: `${PREFIX}-warningIcon`,
  dropdownItem: `${PREFIX}-dropdownItem`,
  dropdownLabel: `${PREFIX}-dropdownLabel`,
  dropdownTitle: `${PREFIX}-dropdownTitle`,
  icon: `${PREFIX}-icon`,
  menuItem: `${PREFIX}-menuItem`
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    borderRadius: "2px",
    backgroundColor: "#4D52E6",
    "&:hover": {
      backgroundColor: "#4D52E6",
    },
  },

  [`& .${classes.borderred}`]: {
    border: "1px solid #C4C4C4",
  },

  [`& .${classes.dropdownContainer}`]: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },

  [`& .${classes.expandMoreIcon}`]: {
    textAlign: "right",
    height: "25px",
  },

  [`& .${classes.optionSubLabel}`]: {
    width: "100%",
    textAlign: "right",
  },

  [`& .${classes.optionSubLabelSpan}`]: {
    marginRight: "2px",
    border: "1px solid #28C78A",
    padding: "2px",
    color: "#28C78A",
    borderRadius: "5px",
    fontWeight: "bold",
  },

  [`& .${classes.optionSubLabelWarningSpan}`]: {
    marginRight: "2px",
    border: "1px solid #FFBB57",
    padding: "2px",
    color: "#FFBB57",
    borderRadius: "5px",
    fontWeight: "bold",
  },

  [`& .${classes.optionSubLabelInactiveSpan}`]: {
    marginRight: "2px",
    border: "1px solid #E2E2E2",
    padding: "2px",
    color: "#FFFFFF",
    background: "#E2E2E2",
    borderRadius: "5px",
    fontWeight: "bold",
  },

  [`& .${classes.warningIcon}`]: {
    color: theme.palette.warning.main,
  },

  [`& .${classes.dropdownItem}`]: {
    height: "33px",
  },

  [`& .${classes.dropdownLabel}`]: {
    color: "#4D52E6",
    textDecorationLine: "none",
    marginLeft: "-5px",
  },

  [`& .${classes.dropdownTitle}`]: {
    flexGrow: 1,
    fontWeight: "700",
    color: "white",
  },

  [`& .${classes.icon}`]: {
    paddingRight: "10px",
  },

  [`& .${classes.menuItem}`]: {
    width: "230px",
  }
}));

export default function EntityActionDropdown(props) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedOption, setSelectedOption] = React.useState(null);
  const groupDropdown = React.createRef();
  const [width, setWidth] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [permittedActions, setPermittedActions] = React.useState([]);

  const {
    label,
    displayText,
    dropdownData,
    onChange,
    defaultValue,
    dontShowBorder,
    actionParams,
    ...rest
  } = props;
  const state = useSelector((state) => state);
  const userPermissionsList = useSelector((state) => state.permissions.userPermissionsList);
  const userSupportView = useSelector((state) => state.auth.userSupportView);
  const selectedPartner = useSelector((state) => state.partners.selectedPartner);
  const params = useParams();

  const handleClick = (event) => {
    setWidth(groupDropdown.current.offsetWidth);
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (selectedPartner) {
      let allowedActions = {};
      dropdownData.forEach((item) => {
        if (
          !item.permissionSet ||
          checkTenantPermissions(
            item.permissionSet,
            userPermissionsList,
            getTenantId(selectedPartner),
            userSupportView
          )
        ) {
          allowedActions[item.value] = true;
        }
      });
      setPermittedActions(allowedActions);
    }
  }, [dropdownData, selectedPartner]);

  const handleOptionCLick = async (option) => {
    setSelectedOption(option);
    setAnchorEl(null);
    if (option.handleClick) {
      setLoading(true);
      await option.handleClick(dispatch, { state, params, ...(actionParams || {}) });
      setLoading(false);
    }
    if (onChange) {
      onChange(option);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (defaultValue) setSelectedOption(defaultValue);
  }, [defaultValue]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const optionElement = (option, index) => {
    if (!permittedActions[option.value]) {
      return null;
    }
    return (
      <MenuItem
        data-testid={option?.["data-testid"]}
        key={index}
        onClick={() => handleOptionCLick(option)}
        disabled={
          option.disabled && typeof option.disabled === "function" && option.disabled(actionParams)
        }
        className={classes.dropdownItem}
        style={
          selectedOption && selectedOption.value === option.value
            ? { background: "rgba(0, 0, 0, 0.04)" }
            : {}
        }
      >
        <Typography
          variant="body2"
          component="span"
          className={classes.dropdownLabel}
          align="center"
        >
          {option.href ? (
            <Link href={option.href}>
              {option.icon ? <span className={classes.icon}> {option.icon}</span> : ""}
              {option.label}
            </Link>
          ) : option.icon ? (
            <span className={classes.icon}>
              {" "}
              {option.icon} {option.label}
            </span>
          ) : (
            <span>{option.label}</span>
          )}
        </Typography>
      </MenuItem>
    );
  };

  return (
    <StyledGrid
      container
      spacing={3}
      style={{ display: Object.keys(permittedActions).length === 0 ? "none" : "inherit" }}
    >
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{ display: "flex", flexDirection: "row-reverse" }}
      >
        <Typography variant="body2" component="div" style={{ marginRight: "0px" }}>
          {label}
        </Typography>
        <MenuItem
          data-testid={rest?.["data-testid"]}
          ref={groupDropdown}
          onClick={handleClick}
          className={clsx(classes.root, classes.menuItem, {
            [classes.borderred]: !dontShowBorder,
          })}
        >
          <div className={classes.dropdownContainer}>
            <Typography
              variant="subtitle2"
              component="span"
              className={classes.dropdownTitle}
              align="center"
            >
              {displayText}
              <span className={classes.expandMoreIcon}>
                {" "}
                <ArrowDropDownIcon />
              </span>
            </Typography>
          </div>
        </MenuItem>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          PaperProps={{
            style: {
              marginTop: "2px",
              width: width + "px",
            },
          }}
        >
          {dropdownData.map((option, index) => {
            return option.hideOption !== undefined
              ? option.hideOption(useSelector(option.hideOptionDataSelector), {
                ...params,
                tenantId: getTenantId(selectedPartner),
              })
                ? null
                : optionElement(option, index)
              : optionElement(option, index);
          })}
        </Popover>
      </Grid>
    </StyledGrid>
  );
}

EntityActionDropdown.propTypes = {
  label: PropTypes.string,
  displayText: PropTypes.string,
  dropdownData: PropTypes.array,
  onChange: PropTypes.func,
  dontShowBorder: PropTypes.bool,
};
EntityActionDropdown.defaultProps = {
  displayText: "Text",
  dropdownData: [],
  dontShowBorder: false,
};
