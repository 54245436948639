import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import {
  EMAIL_LIST_NOTIFICATIONS_TYPES,
  HTTP_METHODS,
  MONITOR_STATUSES,
  MONITOR_TYPES,
} from "../../common/constants";
import HTTPClient from "../../common/servicers/httpClient";

export const getCiAlerts = createAsyncThunk(
  "getCiAlerts",
  async (
    {
      useCache = true,
      tenantId,
      organizationId,
      pageNumber,
      itemsPerPage,
      timeRange = "",
      sort = "-created",
      expression = "",
      search = "",
    },
    { getState, requestId, rejectWithValue },
  ) => {
    const { loadingAlertsRequestId, loadingAlerts, ciAlerts } =
      getState().dashboard.ciAlerts;
    if (!loadingAlerts || loadingAlertsRequestId !== requestId) {
      return;
    }
    const limit = itemsPerPage;
    const offset = itemsPerPage * pageNumber;
    if (
      useCache &&
      ciAlerts[tenantId] &&
      ciAlerts[tenantId].slice(offset, limit + offset).length > 0
    ) {
      return { data: ciAlerts[tenantId], tenantId, cachedData: true };
    }
    if (search) {
      expression = `(monitor.name=*${search}* OR name=*${search}*) ${
        expression ? `AND ${expression}` : ""
      }`;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${tenantId}/ci/alerts?limit=${limit}&offset=${offset}&timeRange=${timeRange}&sort=${sort}&expression=${expression}`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, tenantId, pageNumber, itemsPerPage, useCache };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getCiAlertMonitors = createAsyncThunk(
  "getCiAlertMonitors",
  async (
    { useCache = true, selectedOrganization, selectedTenant },
    { getState, requestId, rejectWithValue },
  ) => {
    const {
      getMonitorsRequestId,
      loadingMonitors,
      monitorsAvailable,
      monitors,
    } = getState().dashboard.ciAlerts;
    if (!loadingMonitors || requestId !== getMonitorsRequestId) {
      return;
    }
    if (useCache && monitorsAvailable[selectedTenant]) {
      return {
        data: monitors[selectedTenant],
        selectedTenant,
        cachedData: true,
      };
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${selectedOrganization}/tenants/${selectedTenant}/cloudInsights/CIMonitor`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getCiAlertMonitorDetails = createAsyncThunk(
  "getCiAlertMonitorDetails",
  async (
    { useCache = true, selectedOrganization, selectedTenant, monitorId },
    { getState, requestId, rejectWithValue },
  ) => {
    const { getMonitorDetailsRequestId, loadingMonitorDetails, monitors } =
      getState().dashboard.ciAlerts;
    if (!loadingMonitorDetails || requestId !== getMonitorDetailsRequestId) {
      return;
    }
    if (
      useCache &&
      monitors[selectedTenant] &&
      monitors[selectedTenant].find((m) => m.id === monitorId)
    ) {
      return {
        data: monitors[selectedTenant].find((m) => m.id === monitorId),
        selectedTenant,
        cachedData: true,
      };
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${selectedOrganization}/tenants/${selectedTenant}/cloudInsights/CIMonitor/${monitorId}`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant, monitorId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getCiMonitorAlertMetricsList = createAsyncThunk(
  "getCiMonitorAlertMetricsList",
  async (
    { useCache = true, selectedOrganization, selectedTenant },
    { getState, requestId, rejectWithValue },
  ) => {
    const { metricListRequestId, loadingMetricList, metricList } =
      getState().dashboard.ciAlerts;
    if (!loadingMetricList || requestId !== metricListRequestId) {
      return;
    }
    if (useCache && metricList[selectedTenant]) {
      return {
        data: metricList[selectedTenant],
        selectedTenant,
        cachedData: true,
      };
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${selectedOrganization}/tenants/${selectedTenant}/cloudInsights/monitorMetrics`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getSubscriptionDetailsForAMonitor = createAsyncThunk(
  "getSubscriptionDetailsForAMonitor",
  async (
    {
      useCache = true,
      selectedOrganization,
      selectedTenant,
      monitorId,
      subscriptionId,
    },
    { getState, requestId, rejectWithValue },
  ) => {
    const {
      subscriptionDetailsRequestId,
      loadingSubscriptionDetails,
      subscriptions,
    } = getState().dashboard.ciAlerts;
    if (
      !loadingSubscriptionDetails ||
      requestId !== subscriptionDetailsRequestId
    ) {
      return;
    }
    if (useCache && subscriptions[monitorId]) {
      return {
        data: subscriptions[monitorId],
        selectedTenant,
        cachedData: true,
      };
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${selectedOrganization}/tenants/${selectedTenant}/cloudInsights/notifications/subscriptions/${subscriptionId}`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant, monitorId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

const updateSubscriptionForNotification = async (
  emailList,
  monitorId,
  selectedOrganization,
  selectedTenant,
  subscriptionIdsMap,
) => {
  let subscriptionCalls = [];
  if (emailList && emailList.length > 0) {
    Object.keys(EMAIL_LIST_NOTIFICATIONS_TYPES).map((key) => {
      if (subscriptionIdsMap[key]) {
        const callToSub = new HTTPClient({
          endpoint: `/organizations/${selectedOrganization}/tenants/${selectedTenant}/cloudInsights/notifications/subscriptions/${subscriptionIdsMap[key]}`,
          method: HTTP_METHODS.PUT,
          data: {
            id: subscriptionIdsMap[key],
            event: {
              name: `${monitorId}_${EMAIL_LIST_NOTIFICATIONS_TYPES[key].name}`,
            },
            templateGroup: {
              id: EMAIL_LIST_NOTIFICATIONS_TYPES[key].templateGroup,
            },
            recipientList: {
              recipients: emailList.map((e) => ({
                engine: "EMAIL",
                name: e,
                address: e,
                description: e,
              })),
            },
          },
        }).callAuthorizedAPI;
        subscriptionCalls.push(callToSub);
      }
    });
  }
  return Promise.all(subscriptionCalls.map((c) => c()));
};

export const editCiCustomMonitor = createAsyncThunk(
  "editCiCustomMonitor",
  async (
    {
      selectedOrganization,
      selectedTenant,
      payload,
      emailList,
      subscriptionIdsMap,
    },
    { getState, requestId, rejectWithValue },
  ) => {
    const { editMonitorRequestId, editingMonitor } =
      getState().dashboard.ciAlerts;
    if (!editingMonitor || requestId !== editMonitorRequestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${selectedOrganization}/tenants/${selectedTenant}/cloudInsights/CIMonitor/${payload.id}`,
        method: HTTP_METHODS.PUT,
        data: payload,
      }).callAuthorizedAPI();
      await updateSubscriptionForNotification(
        emailList,
        response.data.data.id,
        selectedOrganization,
        selectedTenant,
        subscriptionIdsMap,
      );
      return { ...response.data, selectedTenant, emailList };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const changeMonitorStatus = createAsyncThunk(
  "changeMonitorStatus",
  async (
    { selectedOrganization, selectedTenant, status, monitorId },
    { getState, requestId, rejectWithValue },
  ) => {
    const { updateStatusRequestId, updatingStatus } =
      getState().dashboard.ciAlerts;
    if (!updatingStatus || requestId !== updateStatusRequestId) {
      return;
    }
    try {
      await new HTTPClient({
        endpoint: `/organizations/${selectedOrganization}/tenants/${selectedTenant}/cloudInsights/CIMonitor/${monitorId}/${MONITOR_STATUSES[status].apiKey}`,
        method: HTTP_METHODS.PUT,
      }).callAuthorizedAPI();
      return { selectedTenant, status, monitorId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const changeMultipleMonitorStatus = createAsyncThunk(
  "changeMultipleMonitorStatus",
  async (
    { selectedOrganization, selectedTenant, status, monitorIds },
    { getState, requestId, rejectWithValue },
  ) => {
    const {
      updateMultipleMonitorStatusRequestId,
      updatingMultipleMonitorStatus,
    } = getState().dashboard.ciAlerts;
    if (
      !updatingMultipleMonitorStatus ||
      requestId !== updateMultipleMonitorStatusRequestId
    ) {
      return;
    }
    try {
      await new HTTPClient({
        endpoint: `/organizations/${selectedOrganization}/tenants/${selectedTenant}/cloudInsights/CIMonitors/${MONITOR_STATUSES[status].apiKey}`,
        method: HTTP_METHODS.PUT,
        data: monitorIds,
      }).callAuthorizedAPI();
      return { selectedTenant, status, monitorIds };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

const subscribeToNotification = async (
  emailList,
  monitorId,
  selectedOrganization,
  selectedTenant,
) => {
  let subscriptionCalls = [];
  if (emailList && emailList.length > 0) {
    Object.keys(EMAIL_LIST_NOTIFICATIONS_TYPES).map((key) => {
      const callToSub = new HTTPClient({
        endpoint: `/organizations/${selectedOrganization}/tenants/${selectedTenant}/cloudInsights/notifications/subscriptions`,
        method: HTTP_METHODS.POST,
        data: {
          event: {
            name: `${monitorId}_${EMAIL_LIST_NOTIFICATIONS_TYPES[key].name}`,
          },
          templateGroup: {
            id: EMAIL_LIST_NOTIFICATIONS_TYPES[key].templateGroup,
          },
          recipientList: {
            recipients: emailList.map((e) => ({
              engine: "EMAIL",
              name: e,
              address: e,
              description: e,
            })),
          },
        },
      }).callAuthorizedAPI;
      subscriptionCalls.push(callToSub);
    });
  }
  return Promise.all(subscriptionCalls.map((c) => c()));
};

export const createCiCustomMonitor = createAsyncThunk(
  "createCiCustomMonitor",
  async (
    { selectedOrganization, selectedTenant, payload, emailList },
    { getState, requestId, rejectWithValue },
  ) => {
    const { createMonitorRequestId, creatingMonitor } =
      getState().dashboard.ciAlerts;
    if (!creatingMonitor || requestId !== createMonitorRequestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${selectedOrganization}/tenants/${selectedTenant}/cloudInsights/CIMonitor`,
        method: HTTP_METHODS.POST,
        data: payload,
      }).callAuthorizedAPI();
      const responseFromCreateMonitor = response.data;
      const subscriptions = await subscribeToNotification(
        emailList,
        responseFromCreateMonitor.data.id,
        selectedOrganization,
        selectedTenant,
      );
      return {
        ...responseFromCreateMonitor,
        selectedTenant,
        subscriptions,
        emailList,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteCiCustomMonitor = createAsyncThunk(
  "deleteCiCustomMonitor",
  async (
    { selectedOrganization, selectedTenant, monitorId },
    { getState, requestId, rejectWithValue },
  ) => {
    const { deletingMonitor, deleteMonitorRequestId } =
      getState().dashboard.ciAlerts;
    if (!deletingMonitor || requestId !== deleteMonitorRequestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${selectedOrganization}/tenants/${selectedTenant}/cloudInsights/CIMonitor/${monitorId}`,
        method: HTTP_METHODS.DELETE,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant, monitorId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const initialState = {
  ciAlerts: {},
  ciAlertsTotalCount: {},
  loadingAlerts: false,
  loadingAlertsRequestId: null,
  error: null,

  getMonitorsRequestId: null,
  loadingMonitors: null,
  monitorsAvailable: {},
  monitors: {},
  getMonitorsError: null,

  getMonitorDetailsRequestId: null,
  loadingMonitorDetails: null,
  getMonitorDetailsError: null,

  metricListRequestId: null,
  loadingMetricList: null,
  metricList: {},
  metricListError: null,

  createStatus: null,
  createMonitorRequestId: null,
  createMonitorError: null,
  creatingMonitor: null,

  editStatus: null,
  editMonitorRequestId: null,
  editMonitorError: null,
  editingMonitor: null,

  deletingMonitor: null,
  deleteMonitorRequestId: null,
  deletingMonitorError: null,
  deletingMonitorSuccess: null,

  subscriptionDetailsRequestId: null,
  loadingSubscriptionDetails: null,
  subscriptions: {},
  subscriptionDetailsError: null,

  updateStatusRequestId: null,
  updatingStatus: null,
  updateStatusError: null,

  updateMultipleMonitorStatusRequestId: null,
  updatingMultipleMonitorStatus: null,
  updateMultipleMonitorStatusError: null,

  unreadAlerts: {},
  refreshAlerts: {},
  refreshTenants: {},
};

export const ciAlertsSlice = createSlice({
  name: "ciAlerts",
  initialState,
  reducers: {
    resetErrors: (state) => {
      state.error = null;
      state.metricListError = null;
      state.createStatus = null;
      state.editStatus = null;
      state.updateStatusError = null;
      state.deletingMonitorError = null;
      state.deletingMonitorSuccess = null;
    },
    resetRefreshTenants: (state) => {
      state.refreshTenants = {};
    },
    modifyAlertNotification: (state, action) => {
      if (action.payload !== undefined && action.payload.data) {
        let orgId = action.payload.data.OrgID;
        state.refreshAlerts[action.payload.data.TenantID] = true;
        if (action.payload.data?.EmailDisabled) {
          state.refreshTenants[orgId] = action.payload.data.TenantID;
        }
        state.refreshAlerts[action.payload.data.TenantID] = true;
        if (
          action.payload.data?.Severity?.toLowerCase() === "warning" ||
          action.payload.data?.Severity?.toLowerCase() === "critical"
        ) {
          if (state.unreadAlerts[orgId]) {
            state.unreadAlerts[orgId] = state.unreadAlerts[orgId].concat(
              action.payload.data,
            );
          } else {
            state.unreadAlerts[orgId] = [action.payload.data];
          }
        }
      }
    },
    removeUnreadAlerts: (state, action) => {
      if (
        action.payload.orgId &&
        action.payload.tenantId &&
        state.unreadAlerts[action.payload.orgId]
      ) {
        let orgId = action.payload.orgId;
        let tenantId = action.payload.tenantId;
        state.unreadAlerts[orgId] = state.unreadAlerts[orgId].filter(
          (ua) => ua.TenantID !== tenantId,
        );
      }
    },
  },
  extraReducers: {
    [getCiAlerts.pending]: (state, action) => {
      state.loadingAlerts = true;
      state.loadingAlertsRequestId = action.meta.requestId;
      state.error = null;
    },
    [getCiAlerts.fulfilled]: (state, action) => {
      const pageNumber = action.payload?.pageNumber;
      const dataSetSize = action.payload.data.limit;
      const offset = action.payload.data.offset;
      state.loadingAlertsRequestId = null;
      state.loadingAlerts = false;
      state.refreshAlerts[action.payload.tenantId] = false;
      if (action.payload.cachedData) {
        return;
      }
      _.set(
        state.ciAlertsTotalCount,
        `${action.payload.tenantId}`,
        action.payload.data.count,
      );
      if (
        !state.ciAlerts[action.payload.tenantId] ||
        !action.payload.useCache
      ) {
        if (action?.payload?.data?.results) {
          _.set(
            state.ciAlerts,
            `${action.payload.tenantId}`,
            action.payload.data.results,
          );
        }
      } else {
        if (pageNumber === 0) {
          _.set(state.ciAlerts, `${action.payload.tenantId}`, [
            ...action.payload.data.results,
            state.ciAlerts[action.payload.tenantId].slice(dataSetSize),
          ]);
        } else {
          _.set(state.ciAlerts, `${action.payload.tenantId}`, [
            ...state.ciAlerts[action.payload.tenantId].slice(0, offset),
            ...action.payload.data.results,
            ...state.ciAlerts[action.payload.tenantId].slice(
              offset + dataSetSize,
            ),
          ]);
        }
      }
    },
    [getCiAlerts.rejected]: (state, action) => {
      state.loadingAlertsRequestId = null;
      state.error = action.payload || true;
      state.loadingAlerts = false;
    },
    [getCiAlertMonitors.pending]: (state, action) => {
      state.loadingMonitors = true;
      state.getMonitorsRequestId = action.meta.requestId;
      state.getMonitorsError = null;
    },
    [getCiAlertMonitors.fulfilled]: (state, action) => {
      state.getMonitorsRequestId = null;
      state.loadingMonitors = false;
      state.getMonitorsError = null;
      if (action.payload.selectedTenant && !action.payload.cachedData) {
        _.set(
          state.monitors,
          `${action.payload.selectedTenant}`,
          action.payload.data,
        );
        _.set(
          state.monitorsAvailable,
          `${action.payload.selectedTenant}`,
          true,
        );
      }
    },
    [getCiAlertMonitors.rejected]: (state, action) => {
      state.getMonitorsRequestId = null;
      state.getMonitorsError = action.payload;
      state.loadingMonitors = false;
    },
    [getCiAlertMonitorDetails.pending]: (state, action) => {
      state.loadingMonitorDetails = true;
      state.getMonitorDetailsRequestId = action.meta.requestId;
      state.getMonitorDetailsError = null;
    },
    [getCiAlertMonitorDetails.fulfilled]: (state, action) => {
      state.getMonitorDetailsRequestId = null;
      state.loadingMonitorDetails = false;
      state.getMonitorDetailsError = null;
      if (action.payload.selectedTenant && !action.payload.cachedData) {
        const currentMonitors =
          state.monitors[action.payload.selectedTenant] || [];
        _.set(state.monitors, `${action.payload.selectedTenant}`, [
          ...currentMonitors.filter((m) => m.id === action.payload.monitorId),
          action.payload.data,
        ]);
      }
    },
    [getCiAlertMonitorDetails.rejected]: (state, action) => {
      state.getMonitorDetailsRequestId = null;
      state.getMonitorDetailsError = action.payload;
      state.loadingMonitorDetails = false;
    },
    [changeMonitorStatus.pending]: (state, action) => {
      state.updatingStatus = true;
      state.updateStatusRequestId = action.meta.requestId;
      state.updateStatusError = null;
    },
    [changeMonitorStatus.fulfilled]: (state, action) => {
      state.updateStatusRequestId = null;
      state.updatingStatus = false;
      state.updateStatusError = null;
      if (action.payload.selectedTenant) {
        const currentMonitors =
          state.monitors[action.payload.selectedTenant] || [];
        _.set(
          state.monitors,
          `${action.payload.selectedTenant}`,
          currentMonitors.map((m) => {
            if (m.id === action.payload.monitorId) {
              return { ...m, status: action.payload.status };
            } else {
              return m;
            }
          }),
        );
      }
    },
    [changeMonitorStatus.rejected]: (state, action) => {
      state.updateStatusRequestId = null;
      state.updateStatusError = action.payload;
      state.updatingStatus = false;
    },

    [changeMultipleMonitorStatus.pending]: (state, action) => {
      state.updatingMultipleMonitorStatus = true;
      state.updateMultipleMonitorStatusRequestId = action.meta.requestId;
      state.updateMultipleMonitorStatusError = null;
    },
    [changeMultipleMonitorStatus.fulfilled]: (state, action) => {
      state.updateMultipleMonitorStatusRequestId = null;
      state.updatingMultipleMonitorStatus = false;
      state.updateMultipleMonitorStatusError = null;
      if (action.payload.selectedTenant) {
        const currentMonitors =
          state.monitors[action.payload.selectedTenant] || [];
        let monitorsToUpdate = {};
        action.payload?.monitorIds.map((a) => {
          monitorsToUpdate[a] = true;
        });
        let modifiedMonitors = [];
        currentMonitors.map((m) => {
          if (monitorsToUpdate[m.id]) {
            modifiedMonitors.push({
              ...m,
              status: action.payload.status,
            });
          } else {
            modifiedMonitors.push({ ...m });
          }
        });
        _.set(
          state.monitors,
          `${action.payload.selectedTenant}`,
          modifiedMonitors,
        );
      }
    },
    [changeMultipleMonitorStatus.rejected]: (state, action) => {
      state.updateMultipleMonitorStatusRequestId = null;
      state.updateMultipleMonitorStatusError = action.payload;
      state.updatingMultipleMonitorStatus = false;
    },

    [createCiCustomMonitor.pending]: (state, action) => {
      state.creatingMonitor = true;
      state.createMonitorRequestId = action.meta.requestId;
      state.createMonitorError = null;
      state.createStatus = null;
    },
    [createCiCustomMonitor.fulfilled]: (state, action) => {
      state.createMonitorRequestId = null;
      state.creatingMonitor = false;
      state.createMonitorError = null;
      if (action.payload.selectedTenant) {
        const currentMonitors =
          state.monitors[action.payload.selectedTenant] || [];
        _.set(state.monitors, `${action.payload.selectedTenant}`, [
          ...currentMonitors,
          action.payload.data,
        ]);
        _.set(
          state.subscriptions,
          action.payload.data.id,
          action.payload.emailList,
        );
      }
      state.createStatus = true;
    },
    [createCiCustomMonitor.rejected]: (state, action) => {
      state.createMonitorRequestId = null;
      state.createMonitorError = action.payload;
      state.creatingMonitor = false;
      state.createStatus = false;
    },
    [editCiCustomMonitor.pending]: (state, action) => {
      state.editingMonitor = true;
      state.editMonitorRequestId = action.meta.requestId;
      state.editMonitorError = null;
      state.editStatus = null;
    },
    [editCiCustomMonitor.fulfilled]: (state, action) => {
      state.editMonitorRequestId = null;
      state.editingMonitor = false;
      state.editMonitorError = null;
      if (
        action.payload.selectedTenant &&
        state.monitors[action.payload.selectedTenant]
      ) {
        const currentMonitors =
          state.monitors[action.payload.selectedTenant] || [];
        _.set(
          state.monitors,
          `${action.payload.selectedTenant}`,
          currentMonitors.map((m) => {
            if (m.id === action.payload.data.id) {
              return action.payload.data;
            } else {
              return m;
            }
          }),
        );
      }
      state.editStatus = true;
    },
    [editCiCustomMonitor.rejected]: (state, action) => {
      state.editMonitorRequestId = null;
      state.editMonitorError = action.payload;
      state.editingMonitor = false;
      state.editStatus = false;
    },

    [getCiMonitorAlertMetricsList.pending]: (state, action) => {
      state.loadingMetricList = true;
      state.metricListRequestId = action.meta.requestId;
      state.metricListError = null;
    },
    [getCiMonitorAlertMetricsList.fulfilled]: (state, action) => {
      state.metricListRequestId = null;
      state.loadingMetricList = false;
      state.metricListError = null;
      if (action.payload.selectedTenant && !action.payload.cachedData) {
        const measurements = action.payload.data.spotPcMetrics.measurements;
        const metricListToStore = [];
        measurements.forEach((measurement) => {
          if (measurement.metricSet) {
            Object.keys(measurement.metricSet).forEach((metricKey) => {
              metricListToStore.push({
                ...measurement.metricSet[metricKey],
                name: `${measurement.category}.${measurement.name}.${measurement.metricSet[metricKey].name}`,
                value: `${measurement.category}.${measurement.name}.${measurement.metricSet[metricKey].name}`,
                units: measurement.metricSet[metricKey].unitLabel,
                category: measurement.category,
                measurement: measurement.name,
                type: "METRIC",
              });
            });
          }
        });
        // ci metrics
        const ciMetricsObject = action.payload.data.ciMetrics;
        Object.entries(ciMetricsObject).forEach(([moduleKey, moduleObject]) => {
          if (moduleObject.searchProperties) {
            Object.entries(moduleObject.searchProperties).forEach(
              ([metricKey, metricObject]) => {
                if (metricKey.split(".")[0] === "performance") {
                  metricListToStore.push({
                    ...metricObject,
                    name: `${moduleKey}.${metricKey}`,
                    value: `${moduleKey}.${metricKey}`,
                    units: metricObject.unitType,
                    measurement: moduleKey,
                    type: MONITOR_TYPES.PERFORMANCE.key,
                    displayName: metricKey,
                  });
                }
              },
            );
          }
        });
        _.set(
          state.metricList,
          `${action.payload.selectedTenant}`,
          metricListToStore,
        );
      }
    },
    [getCiMonitorAlertMetricsList.rejected]: (state, action) => {
      state.metricListRequestId = null;
      state.metricListError = action.payload;
      state.loadingMetricList = false;
    },
    [deleteCiCustomMonitor.pending]: (state, action) => {
      state.deletingMonitor = true;
      state.deletingMonitorError = null;
      state.deleteMonitorRequestId = action.meta.requestId;
      state.deletingMonitorSuccess = null;
    },
    [deleteCiCustomMonitor.fulfilled]: (state, action) => {
      state.deletingMonitorSuccess = true;
      state.deletingMonitor = false;
      state.deletingMonitorError = null;
      state.deleteMonitorRequestId = null;
      state.monitors[action.payload.selectedTenant] = state.monitors[
        action.payload.selectedTenant
      ].filter((u) => u.id !== action.payload.monitorId);
    },
    [deleteCiCustomMonitor.rejected]: (state, action) => {
      state.deletingMonitor = null;
      state.deletingMonitorError = action.payload;
      state.deleteMonitorRequestId = null;
      state.deletingMonitorSuccess = false;
    },
    [getSubscriptionDetailsForAMonitor.pending]: (state, action) => {
      state.loadingSubscriptionDetails = true;
      state.subscriptionDetailsRequestId = action.meta.requestId;
      state.subscriptionDetailsError = null;
    },
    [getSubscriptionDetailsForAMonitor.fulfilled]: (state, action) => {
      state.subscriptionDetailsRequestId = null;
      state.loadingSubscriptionDetails = false;
      state.subscriptionDetailsError = null;
      if (action.payload.monitorId && !action.payload.cachedData) {
        _.set(
          state.subscriptions,
          `${action.payload.monitorId}`,
          action.payload.data.recipientList?.recipients?.map((r) => r.name) ||
            [],
        );
      }
    },
    [getSubscriptionDetailsForAMonitor.rejected]: (state, action) => {
      state.subscriptionDetailsRequestId = null;
      state.subscriptionDetailsError = action.payload;
      state.loadingSubscriptionDetails = false;
    },
  },
});
export const {
  resetErrors,
  modifyAlertNotification,
  removeUnreadAlerts,
  resetRefreshTenants,
} = ciAlertsSlice.actions;
export default ciAlertsSlice.reducer;
