import React, { Component } from "react";

export default class AzureADAuthProvider extends Component {
  constructor(props) {
    super(props);
    this.client = this.props.client;
    this.handleLoginPopup = this.handleLoginPopup.bind(this);
    this.handleLoginRedirect = this.handleLoginRedirect.bind(this);
    this.handleAcquireTokenRedirect = this.handleAcquireTokenRedirect.bind(this);
    this.handleAcquireTokenSilent = this.handleAcquireTokenSilent.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.handleIsLoggedIn = this.handleIsLoggedIn.bind(this);
  }
  async handleLoginRedirect(config) {
    return this.client.loginRedirect(config);
  }
  async handleLoginPopup(config) {
    return this.client.loginPopup(config);
  }
  async handleAcquireTokenRedirect(config) {
    return this.client.acquireTokenRedirect(config);
  }
  async handleAcquireTokenPopup(config) {
    return this.client.acquireTokenPopup(config);
  }
  async handleAcquireTokenSilent(config) {
    return this.client.acquireTokenSilent(config);
  }
  async handleLogout(config) {
    return this.client.logout(config);
  }
  async handleIsLoggedIn() {
    return await !!this.client.getActiveAccount();
  }
  render() {
    const { component: C, componentProps } = this.props;
    const cProps = {
      loginPopup: this.handleLoginPopup,
      loginRedirect: this.handleLoginRedirect,
      isLoggedIn: this.handleIsLoggedIn,
      acquireTokenRedirect: this.handleAcquireTokenRedirect,
      acquireTokenPopup: this.handleAcquireTokenRedirect,
      acquireTokenSilent: this.handleAcquireTokenSilent,
      getRedirectURI: this.client.getRedirectURI,
      getAccount: this.client.getAccount,
      setActiveAccount: this.client.setActiveAccount,
      setRedirectCallback: this.client.setRedirectCallback,
      logout: this.handleLogout,
      ...componentProps,
    };
    return <C {...cProps} />;
  }
}
