import React from "react";
import styled from "@mui/system/styled";
import error404 from "/src/images/loaders/error-404.png";
import VeneerButton from "../common/components/VeneerButton";
import { useHistory } from "react-router-dom";
import { HPIconLarge } from "../common/components/ame-components/sidebar/logos";
import PropTypes from "prop-types";

const Container = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  height: "100vh",
  flexDirection: "column",
  ["& main"]: {
    height: "calc(100vh - 30px)",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  ["& footer"]: {
    padding: "25px 0",
  },
  ["& img"]: {
    width: "350px",
  },
  ["& p"]: {
    margin: "20px 0",
  },
}));

export default function ErrorNotFoundView(props) {
  const history = useHistory();

  return (
    <Container>
      <main>
        <img
          src={error404}
          alt="Error 404"
          title="Error 404 - Page not found"
        />
        <p>
          <small>{props.error ? props.error : "404 - Not found"}</small>
        </p>
        <h3>{props.title ? props.title : "Hmm... page not found"}</h3>
        <p>
          {props.description
            ? props.description
            : "That URL doesn’t exist at the moment."}
        </p>
        {props.hasBackButton && (
          <VeneerButton
            appearance="secondary"
            title="Click to go to the home page"
            onClick={() => {
              history.push("/admin");
            }}
          >
            Back
          </VeneerButton>
        )}
      </main>
      <footer>
        <HPIconLarge />
      </footer>
    </Container>
  );
}

ErrorNotFoundView.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  hasBackButton: PropTypes.bool,
  error: PropTypes.string,
};
