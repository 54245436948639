import React from "react";
import { styled } from '@mui/material/styles';
import Popover, { popoverClasses } from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import { List, ListItem } from "@mui/material";

import PropTypes from "prop-types";

const PREFIX = 'MouseOverPopover';

const classes = {
  label: `${PREFIX}-label`
};

const Root = styled('div')(({theme}) => ({
  [`& .${classes.label}`]: {
    color: theme.palette.primary.main,
    display: "inline-block",
    padding: "0 5px",
  }
}));

const StyledPopover = styled(Popover)(({ theme }) => ({
  [`&.${popoverClasses.root}`]: {
    pointerEvents: "none",
  },

  [`& .${popoverClasses.paper}`]: {
    padding: theme.spacing(1),
    maxWidth: "30%",
  },
}));

const MouseOverPopover = (props) => {
  const { label, data, component } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    (<Root>
      <Typography
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        className={classes.label}
      >
        {component ? component : label}
      </Typography>
      <StyledPopover
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        disableRestoreFocus
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <span>
          {_.isArray(data) ? (
            <List>
              {data.map((item, i) => (
                <ListItem key={i}>{item}</ListItem>
              ))}
            </List>
          ) : (
            data
          )}
        </span>
      </StyledPopover>
    </Root>)
  );
};

MouseOverPopover.propTypes = {
  label: PropTypes.string,
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
};

export default MouseOverPopover;