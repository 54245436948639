import { DASHBOARD_ROUTE } from "../../../admin-actions/adminActionsConstants";
import stillWorkingImage from "../../../images/loaders/emptyDataStillWorking.svg";

export const getOnboardingCTAContent = ({ data, isVSphere }) => {
  return {
    content: {
      title: "Finalizing your build",
      caption:
        "The environment will be ready for configuration in a few minutes. You will also receive an email notification once the environment is ready",
      image: {
        src: stillWorkingImage,
        alt: "Finishing build",
      },
      action: {
        title: "Tenants dashboard",
        navigateTo: "/admin",
        color: "primary",
      },
    },
    page: DASHBOARD_ROUTE,
    sideContent: {
      component: "tenant-progress",
      props: {
        isVSphere,
      },
    },
    headerProps: {
      tenant: data,
    },
  };
};

export const getPoolsCTAContent = () => {
  return {
    content: {
      title: "Creating your pool",
      caption:
        "Once your pool is ready, the details of this pool will be displayed here",
      image: {
        src: stillWorkingImage,
        alt: "Finishing pool creation",
      },
      action: {
        title: "View all pools",
        navigateTo: "/dashboard/pools",
        color: "primary",
      },
    },
    page: "/dashboard/pools/progress",
  };
};
