import { styled } from "@mui/material/styles";
import React from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { useToast } from "@veneer/core";
import _ from "lodash";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import VeneerButton from "../../../common/components/VeneerButton";
import { SNACKBAR_DURATION_MS } from "../../../common/constants";
import { getIdentityId, getTenantId } from "../../../common/utils";
import {
  enableDisableLogins,
  resetErrors,
  setOpenRequestLoginsModal,
} from "../../slices/machineSlice";

const PREFIX = "RequestLoginsModal";

const classes = {
  root: `${PREFIX}-root`,
  titleContainer: `${PREFIX}-titleContainer`,
  paper: `${PREFIX}-paper`,
  closeButton: `${PREFIX}-closeButton`,
  title: `${PREFIX}-title`,
  buttons: `${PREFIX}-buttons`,
  description2: `${PREFIX}-description2`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    margin: 0,
    padding: theme.spacing(1, 2),
    boxShadow: "none",
  },

  [`& .${classes.titleContainer}`]: {
    display: "flex",
    padding: theme.spacing(1, 2),
  },

  [`& .${classes.paper}`]: {
    minWidth: "600px",
  },

  [`& .${classes.closeButton}`]: {
    color: theme.palette.grey[500],
    padding: "3px 0 0 0",
  },

  [`& .${classes.title}`]: {
    fontWeight: "500",
    flex: 1,
  },

  [`& .${classes.buttons}`]: {
    float: "right",
    marginLeft: "2%",
  },

  [`& .${classes.description2}`]: {
    marginTop: "10px",
  },
}));

const RequestLoginsModal = (props) => {
  const dispatch = useDispatch();
  const { open, operation } = props;
  const { machineName, siteName } = useParams();
  const { addToast } = useToast();

  const {
    requestingLogins,
    errorWhileEnablingDisablingLogins,
    loginsRequested,
    openRequestLoginsModal,
  } = useSelector((state) => state.dashboard.machines);
  const selectedPartner = useSelector(
    (state) => state.partners.selectedPartner,
  );
  const selectedIdentity = useSelector(
    (state) => state.identities.selectedIdentity,
  );

  const spotGroupName = useSelector((state) =>
    _.get(
      state,
      `dashboard.machines.machine.${getTenantId(
        selectedPartner,
      )}.${machineName.toLowerCase()}.properties.spotGroupName`,
    ),
  );
  const sessionHostName = useSelector((state) =>
    _.get(
      state,
      `dashboard.machines.machine.${getTenantId(
        selectedPartner,
      )}.${machineName.toLowerCase()}.sessionHostProperties.name`,
    ),
  );

  const handleEnableDisableLogins = async (operation) => {
    const { meta } = await dispatch(
      enableDisableLogins({
        selectedTenant: getTenantId(selectedPartner),
        organizationId: getIdentityId(selectedIdentity),
        machineName,
        siteName,
        spotGroupName,
        sessionHostName,
        allowNewSession: operation === "Disable" ? false : true,
      }),
    );
    if (meta.requestStatus === "fulfilled") {
      dispatch(
        setOpenRequestLoginsModal({ value: false, operation: operation }),
      );
    }
    setTimeout(() => dispatch(resetErrors()), SNACKBAR_DURATION_MS);
  };

  const onClose = () => {
    dispatch(setOpenRequestLoginsModal({ value: false, operation: operation }));
  };

  useEffect(() => {
    if (
      loginsRequested === true &&
      openRequestLoginsModal.operation &&
      machineName
    ) {
      const message = `${openRequestLoginsModal.operation} logins request is successful for ${machineName}`;
      addToast({
        text: message,
        type: "positive",
        timeout: 6,
        id: Date.now(),
      });
    }
  }, [
    addToast,
    loginsRequested,
    machineName,
    openRequestLoginsModal.operation,
  ]);

  useEffect(() => {
    if (errorWhileEnablingDisablingLogins && machineName) {
      const message = errorWhileEnablingDisablingLogins
        ? errorWhileEnablingDisablingLogins
        : `Unable to ${operation?.toLowerCase()} logins for machine ${machineName}. Unknown Error.`;
      addToast({
        text: message,
        type: "negative",
        timeout: 6,
        id: Date.now(),
      });
    }
  }, [addToast, errorWhileEnablingDisablingLogins, machineName, operation]);

  return (
    <Root>
      <Dialog
        classes={{ paper: classes.paper, root: classes.root }}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <MuiDialogTitle className={classes.titleContainer}>
          <Typography className={classes.title} variant="h6">
            {operation} Logins
          </Typography>
          {onClose ? (
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onClose}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </MuiDialogTitle>
        <MuiDialogContent dividers>
          <div>
            <div>
              <Typography variant="body3" component="div">
                Are you sure you want to {operation?.toLowerCase()} the user
                logins in the <b>{machineName}</b> machine?
              </Typography>
            </div>

            <VeneerButton
              disabled={requestingLogins}
              appearance="primary"
              className={classes.buttons}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleEnableDisableLogins(operation);
              }}
              loading={requestingLogins}
            >
              {requestingLogins ? "Requesting logins..." : operation}
            </VeneerButton>
            <VeneerButton
              disabled={requestingLogins}
              appearance="secondary"
              type="button"
              className={classes.buttons}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onClose();
              }}
            >
              Cancel
            </VeneerButton>
          </div>
        </MuiDialogContent>
      </Dialog>
    </Root>
  );
};

export default RequestLoginsModal;
