import { styled } from "@mui/material";
import { Button } from "@veneer/core";
import React from "react";

const IconButton = styled(Button)(() => ({
  minWidth: "unset",
  padding: "unset",
  border: "unset",
  backgroundColor: "unset",
  ["&:hover"]: {
    backgroundColor: "unset",
  },
}));

const StyledButton = styled(Button)({
  borderRadius: "360px",
});

const VeneerButton = React.forwardRef(({ iconButton, ...other }, ref) => {
  const ButtonToRender = iconButton ? IconButton : StyledButton;

  return <ButtonToRender small ref={ref} {...other} />;
});

VeneerButton.displayName = "VeneerButton";

export default VeneerButton;
