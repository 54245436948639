import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import ParentRoute from "../../../common/components/ParentRoute";
import { getIdentityId, getTenantId } from "../../../common/utils";
import { getUserSessions } from "../../slices";

export default function UserSessionWrapper(props) {
  const dispatch = useDispatch();
  const authSession = useSelector((state) => state.auth.auth);
  const selectedPartner = useSelector((state) => state.partners.selectedPartner);
  const selectedIdentity = useSelector((state) => state.identities.selectedIdentity);

  useEffect(() => {
    if (authSession && selectedPartner && selectedIdentity) {
      dispatch(
        getUserSessions({
          useCache: true,
          organizationId: getIdentityId(selectedIdentity),
          tenantId: getTenantId(selectedPartner),
        })
      );
    }
  }, [authSession, selectedPartner, selectedIdentity]);

  return <ParentRoute {...props} />;
}
