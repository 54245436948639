import React from "react";
import { Redirect, useParams, useLocation, useHistory } from "react-router-dom";
import { useAuthContext } from "../contexts/AuthContext";
import { setPartner } from "../partnersSlice";
import { getTenantPermissions } from "../permissionsSlice";
import { getOrganizationIdentity } from "../../registration/organizationIdentitySlice";
import { setIdentity } from "../identitiesSlice";
import { setItem } from "../../storage";
import { useDispatch } from "react-redux";
import AzureADAuthWrapper from "../../azureB2C/components/login";

function AlertNotificationRoute({ component: Component, ...rest }) {
  const query = useLocation().search;
  const history = useHistory();
  const queryObject = new URLSearchParams(query);
  const redirectUri = queryObject.get("redirectUri");
  const { state: authData } = useAuthContext();
  const sub = authData?.account?.idTokenClaims?.sub;
  const { tenantId, orgId, alertId } = useParams();
  const dispatch = useDispatch();

  const getOrganizationIdentityMethod = async (org) => {
    const response = await dispatch(getOrganizationIdentity({ organizationId: org }));

    if (response?.meta?.requestStatus === "fulfilled") {
      dispatch(setPartner("/tenants/" + tenantId));
      dispatch(
        getTenantPermissions({
          useCache: true,
          selectedTenantId: tenantId,
          selectedOrganizationId: orgId,
        })
      );
    }
  };

  if (authData.isAuthenticated) {
    const org = "/organizations/" + orgId;
    setItem(sub + "org", org);
    dispatch(setIdentity(org));
    getOrganizationIdentityMethod(org);
    history.push(redirectUri);
  }
  return <AzureADAuthWrapper noDisplay={true} />;
}
export default AlertNotificationRoute;
