/* eslint-disable no-case-declarations */
import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import propTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  ACTION_REQUIRED_TOOLTIP_TEXT,
  mapPoolsTagLabel,
} from "../../dashboard/components/pools/utils/poolStatusMap";
import { PoolTag } from "../../dashboard/components/tags/PoolTag";
import { TenantsTag } from "../../dashboard/components/tags/TenantsTag";
import { MODULES_LIST, PERMISSIONS_LIST, PERMISSIONS_TYPE } from "../constants";
import useCTA from "../hooks/useCTA";
import useCurrentMachine from "../hooks/useCurrentMachine";
import useCurrentPool from "../hooks/useCurrentPool";
import useCurrentTenant from "../hooks/useCurrentTenant";
import { formatLabelToCamelCase } from "../utils";
import GenericPageHeader from "./GenericPageHeader";
import OptionsMenuDropdownButton from "./OptionsMenuDropdownButton";
import VeneerButton from "./VeneerButton";

const PREFIX = "header";

const classes = {
  container: `${PREFIX}-container`,
};

const StyledHeaderContainer = styled("div")(() => ({
  [`&.${classes.container}`]: {
    margin: "10px 32px",
    marginTop: "70px",
  },
}));

const Header = ({ hideActions = false }) => {
  const history = useHistory();
  const currentTenant = useCurrentTenant();
  const currentMachine = useCurrentMachine();
  const currentPool = useCurrentPool();
  const { data, shouldRender } = useCTA();

  const { loadingPoolDetails } = useSelector(
    (state) => state.adminActions?.pools,
  );

  const isCTAEnabled = shouldRender && !!data?.headerProps?.tenant;
  const tenantToUse = isCTAEnabled ? data.headerProps.tenant : currentTenant;
  const tenantId = tenantToUse?.id || "";
  const currentPath = location.pathname.split("?")[0];

  const handleViewTenantDetailsClick = () => {
    const cleanedTenantId = tenantId.replace("/tenants/", "");
    history.push(`/tenants/edit/${cleanedTenantId}`);
  };

  const urlHeaderMap = [
    { url: "/admin", headerType: "Tenants" },
    { url: "/onboarding.*", headerType: "Add tenant" },
    { url: "/selectOnboardingWizard", headerType: "Add tenant" },
    { url: "/tenants/edit/.*", headerType: "<tenantDetail>" },
    { url: "/admin/manage/accounts", headerType: "Accounts" },
    { url: "/dashboard/.*", headerType: "<tenantName>" },
    { url: "/dashboard/machines/.*/.*", headerType: "<machineName>" },
    { url: "/dashboard/pools/.*/.*", headerType: "<poolName>" },
    { url: "/dashboard/pools/progress", headerType: "<poolName>" },
    { url: "/dashboard/pools/addPool/.*", headerType: "Add Pool" },
    { url: "/sites", headerType: "Sites" },
    { url: "/sites/action/edit", headerType: "<sites>" },
    { url: "/sites/action/create/win365", headerType: "Add site" },
    { url: "/sites/action/create/vmware", headerType: "Add site" },
    { url: "/settings/general", headerType: "Settings" },
    { url: "/settings/licenses", headerType: "Settings" },
    { url: "/extensibility/accounts", headerType: "API accounts" },
  ];
  // sort by the longest url first
  urlHeaderMap.sort((a, b) => b.url.length - a.url.length);

  let matchingUrl;
  // If the url map item is regex, try to match the regex
  matchingUrl = urlHeaderMap.find((b) =>
    new RegExp(b.url.toLowerCase()).test(currentPath.toLowerCase()),
  );

  // Define elements based on the type
  let displayTitle,
    displaySubtitle,
    actions,
    displayTag,
    copyOptions,
    statusTooltipText;

  switch (matchingUrl?.headerType) {
    // Tenant details and tenant name are the same, except for the hideActions
    case "<tenantName>":
    case "<tenantDetail>":
      displayTitle = tenantToUse?.displayName;
      displaySubtitle = tenantToUse?.brokerFqdn;
      actions = (
        <Grid container alignItems="center" justifyContent="end" spacing={1}>
          <Grid item>
            <VeneerButton
              appearance="secondary"
              onClick={handleViewTenantDetailsClick}
            >
              View details
            </VeneerButton>
          </Grid>
          <Grid item>
            <OptionsMenuDropdownButton
              title="Manage"
              options={[
                {
                  title: "Sites",
                  permissionSet: [
                    PERMISSIONS_LIST[`${MODULES_LIST.SITES}`][
                      PERMISSIONS_TYPE.VIEW
                    ],
                  ],
                  href: "/sites",
                },
                {
                  title: "API Accounts",
                  permissionSet: [
                    PERMISSIONS_LIST[MODULES_LIST.API_ACCOUNTS][
                      PERMISSIONS_TYPE.VIEW
                    ],
                  ],
                  href: "/extensibility/accounts",
                },
              ]}
            />
          </Grid>
        </Grid>
      );
      const tenantStatus = formatLabelToCamelCase(tenantToUse?.status);
      if (tenantStatus) {
        displayTag = <TenantsTag status={tenantStatus} />;
      }
      copyOptions = {
        allowed: true,
        message: "Broker FQDN copied to clipboard!",
      };
      break;
    case "<machineName>":
      displayTitle = currentMachine?.name;
      break;
    case "<poolName>":
      const poolStatus = formatLabelToCamelCase(currentPool?.status);
      const knownStatus = mapPoolsTagLabel(poolStatus);
      const isActionRequired = knownStatus === "Action required";

      statusTooltipText = isActionRequired
        ? ACTION_REQUIRED_TOOLTIP_TEXT[poolStatus]
        : undefined;
      displayTitle = currentPool?.name;
      if (poolStatus) {
        displayTag = <PoolTag status={poolStatus} />;
      }
      break;
    case "<sites>":
      displayTitle = "";
      break;
    // ... other elements that has specific names
    default:
      displayTitle = matchingUrl?.headerType;
      displaySubtitle = "";
      actions = null;
      hideActions = true;
      displayTag = null;
      copyOptions = {};
      statusTooltipText = undefined;
  }

  // Other specific conditions
  if (matchingUrl?.headerType === "<tenantDetail>") {
    hideActions = true;
  }

  const isGenericHeaderLoading = loadingPoolDetails;

  return (
    <StyledHeaderContainer className={classes.container}>
      <GenericPageHeader
        loading={isGenericHeaderLoading}
        title={displayTitle}
        subtitle={displaySubtitle}
        tagComponent={displayTag}
        tooltipText={statusTooltipText}
        copyOptions={{
          allowed: copyOptions?.allowed,
          message: copyOptions?.message,
        }}
      >
        {!hideActions && actions}
      </GenericPageHeader>
    </StyledHeaderContainer>
  );
};

Header.propTypes = {
  hideActions: propTypes.bool,
};

export default Header;
