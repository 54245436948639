import Skeleton from "@mui/material/Skeleton";
import { styled } from "@mui/material/styles";
import { Avatar, IconBell, IconGear, IconPersonCircle } from "@veneer/core";
import PropTypes from "prop-types";
import React, { forwardRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  getIdentityId,
  getSelectedOrganizationId,
} from "../../../../../common/utils";
import { setIdentity } from "../../../../identitiesSlice";

const PREFIX = "OrganizationModal";

const classes = {
  avatar: `${PREFIX}-avatar`,
  bottomInfo: `${PREFIX}-bottomInfo`,
  profileIcon: `${PREFIX}-profileIcon`,
  titleTenant: `${PREFIX}-titleTenant`,
  infoTenant: `${PREFIX}-infoTenant`,
  bellIcon: `${PREFIX}-bellIcon`,
  notification: `${PREFIX}-notification`,
};

const StyledModal = styled("div")(({ theme }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  display: "inline-flex",
  padding: "8px",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "4px",
  borderRadius: "4px",
  border: `1px solid ${theme.palette.background.stroke}`,
  background: theme.palette.background.container,
  zIndex: 9999,
  boxShadow:
    "0px -2px 8px 0px rgba(50, 50, 71, 0.08), 0px 8px 8px 0px rgba(50, 50, 71, 0.08), 0px 8px 16px 0px rgba(50, 50, 71, 0.06)",
  [`& .${classes.avatar}`]: {
    aspectRatio: "1/1",
    width: "40px",
    objectFit: "cover",
  },
  [`& .${classes.bottomInfo}`]: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
    alignSelf: "stretch",
  },
  [`& .${classes.profileIcon}`]: {
    width: "10px",
  },
  [`& .${classes.bellIcon}`]: {
    width: "12px",
  },
  [`& .${classes.titleTenant}`]: {
    overflow: "hidden",
    color: theme.palette.foreground.default,
    textOverflow: "ellipsis",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "100%",
  },
  [`& .${classes.infoTenant}`]: {
    color: theme.palette.foreground.medium,
    fontSize: "12px",
    lineHeight: "100%",
  },
}));

const StyledNotificationBadge = styled("div")(({ theme }) => ({
  display: "grid",
  placeItems: "center",
  borderRadius: "9px",
  backgroundColor: theme.palette.primary.main,
  height: "18px",
  minWidth: "18px",
  color: theme.palette.primary.contrastText,
  fontSize: "12px",
  fontWeight: 500,
  padding: "0 3px",
}));

const StyledOrganizationItem = styled(NavLink, {
  shouldForwardProp: (prop) => prop !== "isHovered" && prop !== "isSelected",
})(({ theme, isSelected, isHovered }) => ({
  display: "flex",
  width: "336px",
  padding: "8px",
  alignItems: "center",
  gap: "4px",
  borderRadius: "8px",
  border: isSelected
    ? `1px solid ${theme.palette.primary.main}`
    : "1px solid transparent",
  background: isSelected
    ? theme.palette.background.stroke
    : theme.palette.background.container,
  textDecoration: "none",
  color: "inherit",
  cursor: "pointer",
  boxShadow:
    !isSelected && !isHovered
      ? `0px 1px 4px 1px ${theme.palette.background.boxShadow}`
      : "none",
}));

const InformationWrapper = styled("div")(() => ({
  display: "flex",
  width: "100%",
  flexDirection: "column",
  alignItems: "flex-start",
}));

const StyledIconGear = styled(IconGear)(({ theme }) => ({
  display: "none",
  color: theme.palette.primary.main,
}));

const OrganizationItem = ({
  id,
  name,
  totalTenants,
  totalAlerts,
  isSelected,
  onMouseEnter,
  onMouseLeave,
  onClick,
}) => {
  const initials = name
    ?.split(".")
    .map((part) => part[0]?.toUpperCase())
    .join("");

  return (
    <StyledOrganizationItem
      to={id}
      isSelected={isSelected}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
    >
      <Avatar className={classes.avatar} label={initials} />
      <InformationWrapper>
        <h3 className={classes.titleTenant}>{name}</h3>
        <div className={classes.bottomInfo}>
          {totalTenants !== undefined ? (
            <>
              <IconPersonCircle className={classes.profileIcon} />
              <span className={classes.infoTenant}>
                Total Tenants: {totalTenants}
              </span>
            </>
          ) : (
            <Skeleton variant="text" width="100px" height="20px" />
          )}
          {totalAlerts && (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              &#183;
              <IconBell className={classes.bellIcon} />
              <StyledNotificationBadge>{totalAlerts}</StyledNotificationBadge>
            </div>
          )}
        </div>
      </InformationWrapper>
      <StyledIconGear size={36} />
    </StyledOrganizationItem>
  );
};

OrganizationItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  totalTenants: PropTypes.number,
  totalAlerts: PropTypes.number,
  isSelected: PropTypes.bool,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onClick: PropTypes.func,
};

export const OrganizationModal = forwardRef(
  ({ position, open, ...props }, ref) => {
    const dispatch = useDispatch();
    const [hoveredOrganization, setHoveredOrganization] = useState(null);

    const identities = useSelector((state) => state.identities.identities);
    const tenantsByOrgList = useSelector(
      (state) => state.partners.tenantsByOrg,
    );
    const { unreadAlerts } = useSelector((state) => state.dashboard.ciAlerts);
    const selectedOrgIdFromLocalStorage = getSelectedOrganizationId();

    const handleSelectOrganization = (org) => {
      dispatch(setIdentity(org.id));
    };

    return (
      <StyledModal
        style={{
          top: position.top,
          left: position.left,
          minWidth: "336px",
          display: open ? "inline-flex" : "none",
        }}
        ref={ref}
        {...props}
      >
        {!identities || identities.length === 0 ? (
          <Skeleton variant="rounded" height="45px" width="100%" />
        ) : (
          identities?.map((org) => (
            <OrganizationItem
              key={org.id}
              id={org.id}
              name={org.name}
              totalTenants={
                tenantsByOrgList[getIdentityId(org.id)]?.data?.length
              }
              totalAlerts={unreadAlerts[getIdentityId(org.id)]}
              isSelected={
                org.id === selectedOrgIdFromLocalStorage ||
                org.id === hoveredOrganization
              }
              onMouseEnter={() => setHoveredOrganization(org.id)}
              onMouseLeave={() => setHoveredOrganization(null)}
              onClick={() => handleSelectOrganization(org)}
            />
          ))
        )}
      </StyledModal>
    );
  },
);

OrganizationModal.displayName = "OrganizationModal";
OrganizationModal.propTypes = {
  position: PropTypes.object.isRequired,
};
