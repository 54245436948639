import React, { useMemo } from "react";
import { useAuthContext } from "../contexts/AuthContext";
import axios from "axios";
import { useHistory } from "react-router-dom";

const AxiosInterceptor = ({ children }) => {
  const { dispatch } = useAuthContext();
  const history = useHistory();

  useMemo(() => {
    axios.interceptors.request.use(
      (request) => {
        if (request.endpoint.includes(`/tenants/undefined/`)) {
          const CancelToken = axios.CancelToken;
          history.push("/admin");
          return {
            ...request,
            cancelToken: new CancelToken((cancel) =>
              cancel("Tenant id is not selected")
            ),
          };
        } else {
          return request;
        }
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (
          error.response &&
          (error.response.status === 401 ||
            (error.response.status === 403 &&
              error?.response?.config?.baseURL?.includes(
                import.meta.env.REACT_APP_B2C_AUTH_DOMAIN
              )))
        ) {
          // dispatch({ type: "unAuthenticate" });
        }
        throw error;
      }
    );
  }, []);

  return children;
};

export default AxiosInterceptor;
