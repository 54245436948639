import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HTTP_METHODS } from "../../common/constants";
import HTTPClient from "../../common/servicers/httpClient";
import _ from "lodash";

export const getReleaseAnnouncements = createAsyncThunk(
  "getReleaseAnnouncements",
  async (
    { useCache = true, filter },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loading, releaseAnnouncements } =
      getState().dashboard.releaseAnnouncements;
    if (!loading || requestId !== currentRequestId) {
      return;
    }
    if (useCache && releaseAnnouncements.length > 0) {
      return { data: releaseAnnouncements };
    }
    try {
      const response = await new HTTPClient({
        endpoint: "/internal-support/releaseAnnouncements/" + filter,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const addReleaseAnnouncement = createAsyncThunk(
  "addReleaseAnnouncement",
  async ({ payload }, { getState, requestId, rejectWithValue }) => {
    const { addingReleaseAnnouncementRequestId, addingReleaseAnnouncement } =
      getState().dashboard.releaseAnnouncements;
    if (
      !addingReleaseAnnouncement ||
      requestId !== addingReleaseAnnouncementRequestId
    ) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: "/internal-support/releaseAnnouncements",
        method: HTTP_METHODS.POST,
        data: payload,
      }).callAuthorizedAPI();
      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const editReleaseAnnouncement = createAsyncThunk(
  "editReleaseAnnouncement",
  async ({ payload }, { getState, requestId, rejectWithValue }) => {
    const { editingReleaseAnnouncementRequestId, editingReleaseAnnouncement } =
      getState().dashboard.releaseAnnouncements;
    if (
      !editingReleaseAnnouncement ||
      requestId !== editingReleaseAnnouncementRequestId
    ) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: "/internal-support/releaseAnnouncements",
        method: HTTP_METHODS.PUT,
        data: payload,
      }).callAuthorizedAPI();
      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteReleaseAnnouncement = createAsyncThunk(
  "deleteReleaseAnnouncement",
  async ({ ids }, { getState, requestId, rejectWithValue }) => {
    const { deleteReleaseAnnouncementRequestId, deletingReleaseAnnouncement } =
      getState().dashboard.releaseAnnouncements;
    if (
      !deletingReleaseAnnouncement ||
      requestId !== deleteReleaseAnnouncementRequestId
    ) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: "/internal-support/releaseAnnouncements",
        method: HTTP_METHODS.DELETE,
        data: ids,
      }).callAuthorizedAPI();
      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const initialState = {
  latestReleaseAnnouncements: [],
  releaseAnnouncements: [],
  hiddenReleases: [],
  loading: false,
  error: null,
  currentRequestId: null,
  loaderText: null,
  addingReleaseAnnouncement: null,
  addingReleaseAnnouncementError: null,
  addingReleaseAnnouncementRequestId: null,
  deletingReleaseAnnouncement: null,
  deleteReleaseAnnouncementError: null,
  deleteReleaseAnnouncementRequestId: null,
  editingReleaseAnnouncement: null,
  editingReleaseAnnouncementError: null,
  editingReleaseAnnouncementRequestId: null,
  hideRelease: null,
};

export const releaseAnnouncementsSlice = createSlice({
  name: "releaseAnnouncements",
  initialState,
  reducers: {
    resetErrors: (state) => {
      state.error = null;
      state.addingReleaseAnnouncementError = null;
      state.deleteReleaseAnnouncementError = null;
      state.editingReleaseAnnouncementError = null;
    },
    setHiddenReleases: (state, action) => {
      state.hiddenReleases.push(action.payload.id);
    },
    updateLatestReleaseAnnouncements: (state, action) => {
      if (action.payload !== undefined && action.payload.data) {
        const data = JSON.parse(action?.payload?.data);
        const releaseUpdate = JSON.parse(data?.MetaData);
        let index;
        switch (data.Status) {
          case "Created":
            state.latestReleaseAnnouncements.push(releaseUpdate);
            break;
          case "Updated":
            index = state.latestReleaseAnnouncements.findIndex(
              (release) => release.id === releaseUpdate.id,
            );
            state.latestReleaseAnnouncements[index] = releaseUpdate;
            _.remove(
              state.hiddenReleases,
              (releaseId) => releaseId === releaseUpdate.id,
            );
            break;
          case "Deleted":
            _.remove(
              state.latestReleaseAnnouncements,
              (release) => release.id === releaseUpdate.id,
            );
            _.remove(
              state.hiddenReleases,
              (releaseId) => releaseId === releaseUpdate.id,
            );
            break;
        }
      }
    },
  },
  extraReducers: {
    [getReleaseAnnouncements.pending]: (state, action) => {
      state.loading = true;
      state.currentRequestId = action.meta.requestId;
      state.error = null;
    },
    [getReleaseAnnouncements.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.loading = false;
      if (action.meta?.arg?.filter === "All") {
        state.releaseAnnouncements = action.payload?.data;
      } else if (action.meta?.arg?.filter === "latest") {
        state.latestReleaseAnnouncements = action.payload?.data;
      }
    },
    [getReleaseAnnouncements.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.error = action.payload || true;
      state.loading = false;
    },
    [addReleaseAnnouncement.pending]: (state, action) => {
      state.addingReleaseAnnouncement = true;
      state.addingReleaseAnnouncementRequestId = action.meta.requestId;
      state.addingReleaseAnnouncementError = null;
    },
    [addReleaseAnnouncement.fulfilled]: (state, action) => {
      state.addingReleaseAnnouncementRequestId = null;
      state.addingReleaseAnnouncement = false;
      state.addingReleaseAnnouncementError = "";
      state.releaseAnnouncements = [
        ...state.releaseAnnouncements,
        action.payload.data[0],
      ];
    },
    [addReleaseAnnouncement.rejected]: (state, action) => {
      state.addingReleaseAnnouncementRequestId = null;
      state.addingReleaseAnnouncementError = action.payload || true;
      state.addingReleaseAnnouncement = false;
    },
    [editReleaseAnnouncement.pending]: (state, action) => {
      state.editingReleaseAnnouncement = true;
      state.editingReleaseAnnouncementRequestId = action.meta.requestId;
      state.editingReleaseAnnouncementError = null;
    },
    [editReleaseAnnouncement.fulfilled]: (state, action) => {
      state.editingReleaseAnnouncement = false;
      state.editingReleaseAnnouncementRequestId = null;
      state.editingReleaseAnnouncementError = "";
      if (action.payload.data) {
        const releaseId = action.meta?.arg?.payload?.id;
        state.releaseAnnouncements = state.releaseAnnouncements.map(
          (release) => {
            return release.id === releaseId ? action.payload.data[0] : release;
          },
        );
      }
    },
    [editReleaseAnnouncement.rejected]: (state, action) => {
      state.editingReleaseAnnouncement = false;
      state.editingReleaseAnnouncementRequestId = null;
      state.editingReleaseAnnouncementError = action.payload?.message || true;
    },
    [deleteReleaseAnnouncement.pending]: (state, action) => {
      state.deletingReleaseAnnouncement = true;
      state.deleteReleaseAnnouncementRequestId = action.meta.requestId;
      state.deletingReleaseAnnouncementError = null;
    },
    [deleteReleaseAnnouncement.fulfilled]: (state, action) => {
      state.deletingReleaseAnnouncement = false;
      state.deleteReleaseAnnouncementRequestId = null;
      state.deletingReleaseAnnouncementError = "";
      _.remove(
        state.releaseAnnouncements,
        (release) => release.id === action.meta.arg.ids[0],
      );
      _.remove(
        state.latestReleaseAnnouncements,
        (release) => release.id === action.meta.arg.ids[0],
      );
    },
    [deleteReleaseAnnouncement.rejected]: (state, action) => {
      state.deletingReleaseAnnouncement = false;
      state.deleteReleaseAnnouncementRequestId = null;
      state.deletingReleaseAnnouncementError = action.payload?.message || true;
    },
  },
});

export const {
  resetErrors,
  setHiddenReleases,
  updateLatestReleaseAnnouncements,
} = releaseAnnouncementsSlice.actions;

export default releaseAnnouncementsSlice.reducer;
