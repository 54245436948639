import React from "react";
import { styled } from '@mui/material/styles';
import { Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router-dom";

const PREFIX = 'Back';

const classes = {
  titleContainer: `${PREFIX}-titleContainer`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.titleContainer}`]: {
    marginBottom: "7px",
    paddingBottom: "0px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    width: "fit-content",
  }
}));

export default function Back(props) {

  const history = useHistory();
  const { label, backUrl } = props;
  const handleBack = () => {
    if (backUrl) {
      history.push(backUrl);
      return;
    }
    history.goBack();
  };
  return (
    (<Root>
      {/* eslint-disable */}
      <div className={classes.titleContainer} onClick={handleBack}>
        <ArrowBackIcon style={{ fontSize: "14px" }} />
        <Typography variant="body1" style={{ fontWeight: "600", fontSize: "12px" }}>
          {label}
        </Typography>
      </div>
      {/* eslint-enable */}
    </Root>)
  );
}
