import React from "react";
import ParentRoute from "../../common/components/ParentRoute";
import { ProtectedRouteSuspenseContainer } from "../../common/components/ProtectedRoute";
import {
  LAYOUTS,
  MODULES_LIST,
  PERMISSIONS_LIST,
  PERMISSIONS_TYPE,
} from "../../common/constants";
import DashBoardRouteTitle from "../../common/components/DashboardRouteTitle";
import { componentLoader } from "../../common/components/SuspenseComponent";

/*------Spot Groups Component------*/
const SpotGroupsList = React.lazy(() =>
  componentLoader(() => import("./components/spotGroups/SpotGroupsList")),
);
const DeleteSpotGroup = React.lazy(() =>
  componentLoader(() => import("./components/spotGroups/DeleteSpotGroup")),
);
const EditSpotGroup = React.lazy(() =>
  componentLoader(() => import("./components/spotGroups/EditSpotGroup")),
);
const SpotGroupDetail = React.lazy(() =>
  componentLoader(() => import("./components/spotGroups/SpotGroupDetail")),
);
const CreateSpotGroupContainer = React.lazy(() =>
  componentLoader(
    () => import("./components/spotGroups/CreateSpotGroupContainer"),
  ),
);
const UpdateSpotGroup = React.lazy(() =>
  componentLoader(() => import("./components/spotGroups/UpdateSpotGroup")),
);

const AssingUsersSpotGroup = React.lazy(() =>
  componentLoader(
    () => import("./components/spotGroups/AssignUsersToSpotGroup"),
  ),
);

const SpotGroupRoutes = [
  {
    path: "/spot-group",
    authRequired: true,
    title: "Spot groups",
    component: ParentRoute,
    routeTitleComponent: (props) => (
      <DashBoardRouteTitle
        title="Spot Groups"
        showQuickActions={true}
        {...props}
      />
    ),
    permissionSet: [
      PERMISSIONS_LIST[MODULES_LIST.SPOT_GROUPS][PERMISSIONS_TYPE.VIEW],
    ],
    layoutStyle: LAYOUTS.MENU_SCREEN,
    routes: [
      {
        path: "/spot-group",
        exact: true,
        title: "Spot Groups",
        component: () => (
          <ProtectedRouteSuspenseContainer component={SpotGroupsList} />
        ),
        permissionSet: [
          PERMISSIONS_LIST[MODULES_LIST.SPOT_GROUPS][PERMISSIONS_TYPE.VIEW],
        ],
      },
      {
        path: "/spot-group/action/edit",
        exact: true,
        title: "Edit",
        component: () => (
          <ProtectedRouteSuspenseContainer component={EditSpotGroup} />
        ),
        permissionSet: [
          PERMISSIONS_LIST[MODULES_LIST.SPOT_GROUPS][PERMISSIONS_TYPE.EDIT],
        ],
      },
      {
        path: "/spot-group/action/detail/:siteName/:groupName",
        exact: true,
        title: "Spot Groups",
        component: () => (
          <ProtectedRouteSuspenseContainer component={SpotGroupDetail} />
        ),
        permissionSet: [
          PERMISSIONS_LIST[`${MODULES_LIST.SPOT_GROUPS}`][
            PERMISSIONS_TYPE.VIEW
          ],
        ],
      },
      {
        path: "/spot-group/action/edit/:groupName",
        exact: true,
        title: "Edit",
        component: () => (
          <ProtectedRouteSuspenseContainer component={EditSpotGroup} />
        ),
        permissionSet: [
          PERMISSIONS_LIST[MODULES_LIST.SPOT_GROUPS][PERMISSIONS_TYPE.EDIT],
        ],
      },
      {
        path: "/spot-group/action/update",
        exact: true,
        title: "Update",
        component: () => (
          <ProtectedRouteSuspenseContainer component={UpdateSpotGroup} />
        ),
        permissionSet: [
          PERMISSIONS_LIST[MODULES_LIST.SPOT_GROUPS][PERMISSIONS_TYPE.EDIT],
        ],
      },
      {
        path: "/spot-group/action/update/:groupName",
        exact: true,
        title: "Update",
        component: () => (
          <ProtectedRouteSuspenseContainer component={UpdateSpotGroup} />
        ),
        permissionSet: [
          PERMISSIONS_LIST[MODULES_LIST.SPOT_GROUPS][PERMISSIONS_TYPE.EDIT],
        ],
      },
      {
        path: "/spot-group/action/assignUsers",
        exact: true,
        title: "Assign Users",
        component: () => (
          <ProtectedRouteSuspenseContainer component={AssingUsersSpotGroup} />
        ),
        permissionSet: [
          PERMISSIONS_LIST[MODULES_LIST.SPOT_GROUPS][PERMISSIONS_TYPE.EDIT],
        ],
      },
      {
        path: "/spot-group/action/assignUsers/:groupName",
        exact: true,
        title: "Assign Users",
        component: () => (
          <ProtectedRouteSuspenseContainer component={AssingUsersSpotGroup} />
        ),
        permissionSet: [
          PERMISSIONS_LIST[MODULES_LIST.SPOT_GROUPS][PERMISSIONS_TYPE.EDIT],
        ],
      },
      {
        path: "/spot-group/action/create",
        exact: true,
        title: "Create",
        component: () => (
          <ProtectedRouteSuspenseContainer
            component={CreateSpotGroupContainer}
          />
        ),
        permissionSet: [
          PERMISSIONS_LIST[MODULES_LIST.SPOT_GROUPS][PERMISSIONS_TYPE.CREATE],
        ],
      },
      {
        path: "/spot-group/action/delete",
        exact: true,
        title: "Delete",
        component: () => (
          <ProtectedRouteSuspenseContainer component={DeleteSpotGroup} />
        ),
        permissionSet: [
          PERMISSIONS_LIST[MODULES_LIST.SPOT_GROUPS][PERMISSIONS_TYPE.DELETE],
        ],
      },
    ],
  },
];

export default SpotGroupRoutes;
