import { InlineNotification } from "@veneer/core";
import PropTypes from "prop-types";
import React from "react";

export const Notification = ({
  title,
  type,
  description,
  action,
  closeButton,
  hideIcon,
  onClose,
}) => {
  // Future adjustments if needed
  return (
    <InlineNotification
      title={title}
      type={type}
      description={description}
      closeButton={closeButton}
      hideIcon={hideIcon}
      action={action}
      onClose={onClose}
    />
  );
};

Notification.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    "help",
    "negative",
    "positive",
    "informative",
    "warning",
    "caution",
  ]).isRequired,
  description: PropTypes.string,
  action: PropTypes.shape({
    action: PropTypes.func,
    label: PropTypes.string,
  }),
  closeButton: PropTypes.bool,
  hideIcon: PropTypes.bool,
  onClose: PropTypes.func,
};
