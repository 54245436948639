import React, { useCallback, useEffect, useState } from "react";
import { getB2CAccount, setB2CAccount } from "../azureB2C/utils/azure-b2c";
import { useAuthContext } from "../common/contexts/AuthContext";
import { getAzureB2C } from "../azureB2C/factories/azure-ad-factory";
import { useDispatch } from "react-redux";
import DialogWithLoader from "../common/components/DialogWithLoader";
import { useHistory } from "react-router-dom";

const Logout = () => {
  const { logout } = useAuthContext();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const handleLogout = useCallback(async () => {
    setIsLoading(true);
    try {
      const account = getB2CAccount();
      const azureClient = getAzureB2C();
      if (account) {
        await azureClient.logout();
        setB2CAccount(null);
        azureClient.setActiveAccount(null);
        sessionStorage.clear();
        history.replace("/");
      }
    } catch (error) {
      console.error("Logout error:", error);
    } finally {
      setIsLoading(false);
    }
  }, [logout, dispatch]);
  useEffect(() => {
    handleLogout();
  }, []);

  return (
    <div style={{ textAlign: "center", marginTop: "20px" }}>
      <DialogWithLoader
        open={isLoading}
        title={"Please Wait"}
        loaderText={"Logging out..."}
      ></DialogWithLoader>
    </div>
  );
};

export default Logout;
