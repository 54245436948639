import { BOOLEAN_OPTIONS, HYPERVISOR_DETAILS } from "../common/constants";
import { getTimezoneOffSet } from "../common/utils";
import {
  checkCustomNetBIOSNameFormat,
  checkMaxLength,
} from "../common/validators";
import {
  AD_DOMAIN_CONTROLLER_USAGE_TYPES,
  AZURE_AD_DOMAIN_PREFIX_CHARACTER_LIMIT,
  REGION_RECOMMENDED,
} from "./onboardingConstants";

export const validateDomainUsername = (value) =>
  /@|\\/g.test(value)
    ? "Please provide your domain join account username.(Do not include the domain)"
    : null;

export const combineHostAndClusterValues = (hypervisorDetails) => {
  const createDataObject = (element, type) => ({
    label: element,
    value: element,
    recommended: true,
    type: type,
    // eslint-disable-next-line camelcase
    sub_labels: [],
  });

  const hosts = [];
  const clusters = [];

  if (hypervisorDetails) {
    if (hypervisorDetails[HYPERVISOR_DETAILS.HOSTS]) {
      hypervisorDetails[HYPERVISOR_DETAILS.HOSTS].forEach((element) => {
        hosts.push(createDataObject(element, "Host"));
      });
    }
    if (hypervisorDetails[HYPERVISOR_DETAILS.CLUSTERS]) {
      hypervisorDetails[HYPERVISOR_DETAILS.CLUSTERS].forEach((element) => {
        clusters.push(createDataObject(element, "Cluster"));
      });
    }
  }

  const sortedHosts = hosts.sort((a, b) => a.label.localeCompare(b.label));
  const sortedClusters = clusters.sort((a, b) =>
    a.label.localeCompare(b.label),
  );

  const result = [
    { label: "Hosts", value: "Hosts", disabled: true },
    ...sortedHosts,
    { label: "Clusters", value: "Clusters", disabled: true },
    ...sortedClusters,
  ];

  return result;
};

export const returnArraysDifference = (fullArray, partialArray) => {
  const fArray = new Set(fullArray);
  const pArray = new Set(partialArray);
  return [...fArray].filter((x) => !pArray.has(x));
};

export const validateSelectActiveDirectoryStep = (values) => {
  if (!values.activeDirectoryDomainController) {
    return false;
  }
  if (
    values.activeDirectoryDomainController !==
      AD_DOMAIN_CONTROLLER_USAGE_TYPES.AZURE_AD_DIRECT_JOIN &&
    !values.domain
  ) {
    return false;
  }
  if (
    values.domainNetBIOS &&
    (!!checkMaxLength(15)(values?.domainNetBIOS) ||
      !!checkCustomNetBIOSNameFormat(values?.domainNetBIOS))
  ) {
    return false;
  }
  if (
    (values.activeDirectoryDomainController ===
      AD_DOMAIN_CONTROLLER_USAGE_TYPES.EXISTING ||
      values.activeDirectoryDomainController ===
        AD_DOMAIN_CONTROLLER_USAGE_TYPES.EXISTING_AZURE_AD) &&
    !values.isAdConnectedToAzure &&
    !values.azureADDomainServices
  ) {
    return false;
  }
  if (
    values.isAdConnectedToAzure === BOOLEAN_OPTIONS.YES &&
    (!(
      values.domainUsername && !validateDomainUsername(values.domainUsername)
    ) ||
      !values.domainPassword)
  ) {
    return false;
  }
  if (values.isAdConnectedToAzure === BOOLEAN_OPTIONS.NO) {
    return false;
  }
  if (
    values.azureADDomainServices === true &&
    (!(
      values.domainUsername && !validateDomainUsername(values.domainUsername)
    ) ||
      !values.domainPassword)
  ) {
    return false;
  }
  return true;
};

export const validatevCenterDetails = (values) => {
  if (
    !values.vCenterUrl &&
    !values.vCenterUsername &&
    !values.vCenterPassword
  ) {
    return false;
  }
  return true;
};
export const setRegionOnGeography = (geographies, geography) => {
  var recommended = [];
  var others = [];
  recommended = geographies[geography]?.filter((region) => region?.recommended);
  others = geographies[geography]?.filter((region) => !region?.recommended);
  var DROPDOWN_DATA = [
    {
      group: "Anyware Recommended",
      options: recommended,
    },
    {
      group: "Anyware Supported (Not Recommended)",
      options: others,
    },
  ];
  return DROPDOWN_DATA;
};

export const transformRegionResponse = (regions) => {
  var others = [];
  var geographies = [];

  if (regions) {
    regions.forEach((element) => {
      var data = { ...element };
      const geography = element.metadata.geography;
      if (!geographies[geography]) {
        geographies[geography] = [
          {
            group: "Anyware Recommended",
            options: [],
          },
          {
            group: "Anyware Supported (Not Recommended)",
            options: [],
          },
        ];
      }
      data.label = element.displayName;
      data.value = element.name;
      // eslint-disable-next-line camelcase
      data.sub_labels = [];
      if (
        data.metadata.regionCategory === REGION_RECOMMENDED &&
        data.metadata.geography === geography
      ) {
        data.recommended = true;
        geographies[geography][0].options.push(data);
      } else if (data.metadata.geography === geography) {
        others.push(data);
        geographies[geography][1].options.push(data);
      }
    });
  }
  return geographies;
};

export const deploymentPayload = (values) => {
  const type = values["activeDirectoryDomainController"];
  let typeForPayload = type;
  if (type === AD_DOMAIN_CONTROLLER_USAGE_TYPES.EXISTING_AZURE_AD) {
    typeForPayload = AD_DOMAIN_CONTROLLER_USAGE_TYPES.EXISTING;
  }
  const timeZone = getTimezoneOffSet();
  const payload = {
    type: typeForPayload,
    timeZone,
    properties: {},
  };
  if (
    type === AD_DOMAIN_CONTROLLER_USAGE_TYPES.EXISTING ||
    type === AD_DOMAIN_CONTROLLER_USAGE_TYPES.EXISTING_AZURE_AD
  ) {
    payload.properties = {
      existingActiveDirectoryProperties: {
        domain: values.domain,
        domainUsername: values.domainUsername,
        domainPassword: values.domainPassword,
        networkResourceId: values?.network?.resourceId || "",
        azureADDomainServices:
          type === AD_DOMAIN_CONTROLLER_USAGE_TYPES.EXISTING_AZURE_AD,
        domainNetBIOS: values?.domainNetBIOS,
      },
    };
  } else if (type === AD_DOMAIN_CONTROLLER_USAGE_TYPES.AZURE_AD_DIRECT_JOIN) {
    payload.properties = {
      azureADDirectJoinProperties: {
        networkResourceId: values?.network?.resourceId || "",
      },
    };
  } else {
    payload.properties = {
      newActiveDirectoryProperties: {
        domain: values.domain,
        domainNetBIOS: values?.domainNetBIOS,
      },
    };
  }
  return payload;
};

export const formRadioOptions = (options) => {
  return options.map((option) => ({
    label: option.displayName,
    value: option.type,
    disabled: option.disabled,
  }));
};

export const validateAzureRegionAdvanced = (values) => {
  if (!values.azureRegion) {
    return false;
  }
  if (!values.preferredGeography) {
    return false;
  }
  return true;
};

export const validateDisplayNameAdvanced = (values) => {
  if (!values.displayName || values.displayName.match(/^ *$/) !== null) {
    return false;
  }
  return true;
};

export const validatePoolNameAdvanced = (values) => {
  if (!values.poolName) {
    return false;
  }
  return true;
};

const validateCommonFields = (values) => {
  if (!values.folder) {
    return false;
  }

  if (!values.hostOrClusterValue) {
    return false;
  }

  if (!values.selectedDataStores) {
    return false;
  }

  return true;
};

export const validatevCenterSiteDetails = (values) => {
  return !!values?.siteName && validateCommonFields(values);
};

export const validatevCenterSiteDetailsStep = (values) => {
  return validateCommonFields(values);
};

export const validatevSphereConductorAdvanced = (values) => {
  if (!values.conductorInstallCommand) {
    return false;
  }
  return true;
};

export const validateDeviceImageAdvanced = (values) => {
  if (!values.deviceImage) {
    return false;
  }
  return true;
};
export const validatePcoipRegistrationAdvanced = (values) => {
  if (!values.pcoipRegistrationCode) {
    return false;
  }
  return true;
};

export const updateDomainDropdown = (domains) =>
  domains?.map((d) => {
    const firstNCharacter = d?.name
      ?.split(".")[0]
      .slice(0, AZURE_AD_DOMAIN_PREFIX_CHARACTER_LIMIT);
    const domain =
      firstNCharacter + "." + d?.name?.split(".").slice(1).join(".");
    return { name: domain };
  }) || [];

export const validateTenantId = (value) =>
  value && value.length === 36 && /^[a-zA-Z0-9-]*$/.test(value)
    ? null
    : "Invalid Tenant ID.";

export const validatePoolName = (value) => {
  if (typeof value !== "string" || value.length === 0) {
    return null;
  }
  const regex = /[<>|&"]/g;
  return value.length <= 64 && !regex.test(value) ? null : "Invalid Pool Name.";
};

export const validatePcoipRegistrationCode = (value) => {
  const regex = /^[a-zA-Z0-9]{12}@([a-fA-F0-9]{4}-){3}[a-fA-F0-9]{4}$/;
  // registration code is optional, so return null if empty
  return !value || regex.test(value)
    ? null
    : "Invalid PCoIP Registration Code.";
};

export const validateConductorName = (value) => {
  const regex = /[<>|&"]/g;
  return typeof value === "string" &&
    value.length > 0 &&
    value.length <= 64 &&
    !regex.test(value)
    ? null
    : "Invalid Conductor Name.";
};

export const validateTenantName = (value) =>
  typeof value === "string" && value.length > 0 && value.length <= 64
    ? null
    : "Invalid Tenant Name.";
