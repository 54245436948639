import React from "react";
import ParentRoute from "../../common/components/ParentRoute";
import { ProtectedRouteSuspenseContainer } from "../../common/components/ProtectedRoute";
import {
  LAYOUTS,
  MODULES_LIST,
  PERMISSIONS_LIST,
  PERMISSIONS_TYPE,
} from "../../common/constants";
import DashBoardRouteTitle from "../../common/components/DashboardRouteTitle";
import { componentLoader } from "../../common/components/SuspenseComponent";
/*------accounts Components------*/
const APIAccountsList = React.lazy(() =>
  componentLoader(() => import("./components/APIAccountsList")),
);
const CreateApiAccountContainer = React.lazy(() =>
  componentLoader(() => import("./components/CreateApiAccountContainer")),
);
const DeleteApiAccountContainer = React.lazy(() =>
  componentLoader(() => import("./components/DeleteApiAccountContainer")),
);
const EditApiAccountContainer = React.lazy(() =>
  componentLoader(() => import("./components/EditApiAccountContainer")),
);

/*------Tenant Admin Components------*/
const AdminAccountsContainer = React.lazy(() =>
  componentLoader(() => import("./components/AdminAccountsContainer")),
);

const APIUsersRoutes = [
  {
    path: "/extensibility",
    authRequired: true,
    component: ParentRoute,
    redirectTo: "/extensibility/connections",
    routeTitleComponent: () => (
      <DashBoardRouteTitle title="Extensibility" showQuickActions={true} />
    ),
    permissionSet: [
      PERMISSIONS_LIST[MODULES_LIST.API_ACCOUNTS][PERMISSIONS_TYPE.VIEW],
      PERMISSIONS_LIST[MODULES_LIST.ADMIN_ACCOUNTS][PERMISSIONS_TYPE.VIEW],
    ],
    layoutStyle: LAYOUTS.MENU_SCREEN,
    onlyExactPathTabs: true,
    routes: [
      {
        path: "/extensibility/accounts",
        authRequired: true,
        isSubComponent: true,
        isTab: true,
        title: "Accounts",
        component: ParentRoute,
        permissionSet: [
          PERMISSIONS_LIST[MODULES_LIST.API_ACCOUNTS][PERMISSIONS_TYPE.VIEW],
        ],
        routes: [
          {
            path: "/extensibility/accounts",
            exact: true,
            title: "Accounts",
            component: () => (
              <ProtectedRouteSuspenseContainer component={APIAccountsList} />
            ),
            permissionSet: [
              PERMISSIONS_LIST[MODULES_LIST.API_ACCOUNTS][
                PERMISSIONS_TYPE.VIEW
              ],
            ],
          },
          {
            path: "/extensibility/accounts/action/create",
            exact: true,
            title: "Create",
            component: () => (
              <ProtectedRouteSuspenseContainer
                component={CreateApiAccountContainer}
              />
            ),
            permissionSet: [
              PERMISSIONS_LIST[MODULES_LIST.API_ACCOUNTS][
                PERMISSIONS_TYPE.CREATE
              ],
            ],
          },
          {
            path: "/extensibility/accounts/action/edit/:apiUserId?",
            exact: true,
            title: "Edit",
            component: () => (
              <ProtectedRouteSuspenseContainer
                component={EditApiAccountContainer}
              />
            ),
            permissionSet: [
              PERMISSIONS_LIST[MODULES_LIST.API_ACCOUNTS][
                PERMISSIONS_TYPE.EDIT
              ],
            ],
          },
          {
            path: "/extensibility/accounts/actions/delete",
            exact: true,
            title: "Delete",
            component: () => (
              <ProtectedRouteSuspenseContainer
                component={DeleteApiAccountContainer}
              />
            ),
            permissionSet: [
              PERMISSIONS_LIST[MODULES_LIST.API_ACCOUNTS][
                PERMISSIONS_TYPE.DELETE
              ],
            ],
          },
        ],
      },
      {
        path: "/extensibility/tenant-admins",
        component: ParentRoute,
        title: "Tenant Admins",
        isSubComponent: true,
        isTab: true,
        permissionSet: [
          PERMISSIONS_LIST[MODULES_LIST.ADMIN_ACCOUNTS][PERMISSIONS_TYPE.VIEW],
        ],
        routes: [
          {
            path: "/extensibility/tenant-admins",
            exact: true,
            isTab: true,
            title: "Tenant Admins",
            component: () => (
              <ProtectedRouteSuspenseContainer
                component={AdminAccountsContainer}
              />
            ),
            permissionSet: [
              PERMISSIONS_LIST[MODULES_LIST.ADMIN_ACCOUNTS][
                PERMISSIONS_TYPE.VIEW
              ],
            ],
          },
        ],
      },
    ],
  },
];

export default APIUsersRoutes;
