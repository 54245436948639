import React from "react";
import ParentRoute from "../../common/components/ParentRoute";
import { ProtectedRouteSuspenseContainer } from "../../common/components/ProtectedRoute";
import {
  LAYOUTS,
  MODULES_LIST,
  PERMISSIONS_LIST,
  PERMISSIONS_TYPE,
} from "../../common/constants";
import DashBoardRouteTitle from "../../common/components/DashboardRouteTitle";
import { componentLoader } from "../../common/components/SuspenseComponent";
/*------Image Sets Components------*/
import ImageSetDetailsWrapper from "./components/image-sets/ImageSetDetailsWrapper";
import { getImageName } from "../../dashboard/selectors";

const CustomActionsContainer = React.lazy(() =>
  componentLoader(
    () =>
      import(
        "../../dashboard/components/custom-actions/CustomActionsContainer"
      ),
  ),
);
const ImageSetsList = React.lazy(() =>
  componentLoader(() => import("./components/image-sets/ImageSetsList")),
);
const DeleteImageSets = React.lazy(() =>
  componentLoader(() => import("./components/image-sets/DeleteImageSets")),
);
const CreateImageSetContainer = React.lazy(() =>
  componentLoader(
    () => import("./components/image-sets/CreateImageSetContainer"),
  ),
);
const EditImageSetContainer = React.lazy(() =>
  componentLoader(
    () => import("./components/image-sets/EditImageSetContainer"),
  ),
);
const ViewImageSet = React.lazy(() =>
  componentLoader(() => import("./components/image-sets/ViewImageSet")),
);
const CreateImageSetVersionContainer = React.lazy(() =>
  componentLoader(
    () => import("./components/image-sets/CreateImageSetVersionContainer"),
  ),
);

const ImageSetRoutes = [
  {
    path: "/image-sets",
    authRequired: true,
    title: "Image Sets",
    component: ParentRoute,
    routeTitleComponent: () => (
      <DashBoardRouteTitle title="Image Sets" showQuickActions={true} />
    ),
    layoutStyle: LAYOUTS.MENU_SCREEN,

    routes: [
      {
        path: "/image-sets",
        exact: true,
        title: "Image Sets",
        component: () => (
          <ProtectedRouteSuspenseContainer component={ImageSetsList} />
        ),
        permissionSet: [
          PERMISSIONS_LIST[`${MODULES_LIST.IMAGES}`][PERMISSIONS_TYPE.VIEW],
        ],
      },
      {
        path: "/image-sets/detail/:imageSetName",
        component: ImageSetDetailsWrapper,
        isSubTabs: true,
        title: getImageName,
        routes: [
          {
            path: "/image-sets/detail/:imageSetName",
            exact: true,
            isTab: true,
            title: "Versions",
            component: () => (
              <ProtectedRouteSuspenseContainer component={ViewImageSet} />
            ),
            permissionSet: [
              PERMISSIONS_LIST[`${MODULES_LIST.IMAGES}`][PERMISSIONS_TYPE.VIEW],
            ],
          },
          {
            path: "/image-sets/detail/:imageSetName/customActions",
            exact: true,
            isTab: true,
            title: "Custom Actions",
            component: () => (
              <ProtectedRouteSuspenseContainer
                component={CustomActionsContainer}
                label={"IMAGE_SET_DETAIL_VIEW"}
              />
            ),
            permissionSet: [
              PERMISSIONS_LIST[`${MODULES_LIST.IMAGES}`][PERMISSIONS_TYPE.VIEW],
            ],
          },
          {
            path: "/image-sets/detail/:imageSetName/add-version",
            exact: true,
            title: "Add Image Set Version",
            component: () => (
              <ProtectedRouteSuspenseContainer
                component={CreateImageSetVersionContainer}
              />
            ),
            permissionSet: [
              PERMISSIONS_LIST[`${MODULES_LIST.IMAGES}`][
                PERMISSIONS_TYPE.CREATE
              ],
            ],
          },
        ],
      },
      {
        path: "/image-sets/actions/delete",
        exact: true,
        title: "Delete",
        component: () => (
          <ProtectedRouteSuspenseContainer component={DeleteImageSets} />
        ),
        permissionSet: [
          PERMISSIONS_LIST[`${MODULES_LIST.IMAGES}`][PERMISSIONS_TYPE.DELETE],
        ],
      },
      {
        path: "/image-sets/action/create",
        exact: true,
        title: "Create",
        component: () => (
          <ProtectedRouteSuspenseContainer
            component={CreateImageSetContainer}
          />
        ),
        permissionSet: [
          PERMISSIONS_LIST[`${MODULES_LIST.IMAGES}`][PERMISSIONS_TYPE.CREATE],
        ],
      },
      {
        path: "/image-sets/action/edit/:imageSetName?",
        exact: true,
        title: "Edit",
        component: () => (
          <ProtectedRouteSuspenseContainer component={EditImageSetContainer} />
        ),
        permissionSet: [
          PERMISSIONS_LIST[`${MODULES_LIST.IMAGES}`][PERMISSIONS_TYPE.EDIT],
        ],
      },
    ],
  },
];

export default ImageSetRoutes;
