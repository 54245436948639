import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { Tabs } from "@veneer/core";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  checkTenantPermissions,
  getTenantId,
  hasAccessToRoute,
} from "../utils";

const PREFIX = "PageTabs";

const classes = {
  container: `${PREFIX}-container`,
  tabsContainer: `${PREFIX}-tabsContainer`,
};

const StyledGrid = styled(Grid)(() => ({
  [`&.${classes.container}`]: {
    marginBottom: "16px",
    display: "flex",
  },

  [`& .${classes.tabsContainer}`]: {
    display: "inline-flex",
    "& *": {
      borderRadius: "360px",
    },
  },
}));

export default function PageTabs(props) {
  const { routes, onlyExactPathTabs, location } = props;
  const tabRoutes = routes.filter((r) => r.isTab);
  const [selectedTabId, setSelectedTabId] = React.useState(0);
  const userPermissionsList = useSelector(
    (state) => state.permissions.userPermissionsList,
  );
  const { auth, userSupportView } = useSelector((state) => state.auth);
  const selectedPartner = useSelector(
    (state) => state.partners.selectedPartner,
  );
  const tenant = useSelector((state) => state.dashboard.tenant.tenant);
  const { organizationIdentity } = useSelector(
    (state) => state.organizationIdentity,
  );
  const state = useSelector((state) => state);
  const history = useHistory();

  const handleChange = (index) => {
    const route = tabRoutes[index];

    let href;
    if (typeof route.redirectTo === "function") {
      href = route.redirectTo(state);
    } else {
      href = route.redirectTo || route.path;
    }

    history.push(typeof href === "function" ? href() : href);
    setSelectedTabId(index);
  };

  useEffect(() => {
    tabRoutes.forEach((route, i) => {
      if (window.location.pathname.indexOf(route.path) !== -1) {
        setSelectedTabId(i);
      }
    });
  }, [tabRoutes, window.location.pathname]);

  if (
    onlyExactPathTabs &&
    tabRoutes.findIndex((t) => t.path === location.pathname) === -1
  ) {
    return <></>;
  }

  const tabDisplay = (route) => {
    return (
      hasAccessToRoute(
        route?.roles,
        auth?.userRoles,
        userSupportView,
        route?.isPrivate,
        route?.adminView,
        tenant && selectedPartner ? tenant[getTenantId(selectedPartner)] : null,
        route?.orgRoles,
        organizationIdentity.role,
      ) &&
      checkTenantPermissions(
        route?.permissionSet,
        userPermissionsList,
        getTenantId(selectedPartner),
        userSupportView,
      )
    );
  };

  if (!tabDisplay(tabRoutes[selectedTabId])) {
    return null;
  }

  return (
    <StyledGrid container spacing={0} className={classes.container}>
      <Grid item className={classes.tabsContainer}>
        <Tabs
          controlId="default"
          mode="contained"
          onChangeTab={handleChange}
          selectedTabId={selectedTabId}
          tabs={tabRoutes.map((route, i) => ({
            id: i,
            label: route.title,
            content: (
              <div
                key={i}
                style={{
                  display: tabDisplay(route) ? "inherit" : "none",
                }}
              />
            ),
          }))}
          type="online"
        />
      </Grid>
    </StyledGrid>
  );
}
