import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HTTP_METHODS } from "../../common/constants";
import {
  getIdentityId,
  getTenantId,
  getTwoDecimalNumber,
} from "../../common/utils";
import HTTPClient from "../../common/servicers/httpClient";
import _ from "lodash";
import { RESOURCE_HEALTH_STATUS } from "../dashboardConstants";

export const getSecurityOverview = createAsyncThunk(
  "getSecurityOverview",
  async (
    { useCache = true, tenant, organization },
    { getState, requestId, rejectWithValue },
  ) => {
    const { loadingOverviewRequestId, loadingOverview, securityOverview } =
      getState().dashboard.security;
    const tenantId = getTenantId(tenant);
    const orgId = getIdentityId(organization);

    if (!loadingOverview || loadingOverviewRequestId !== requestId) {
      return;
    }

    if (useCache && securityOverview[tenantId]) {
      return {
        data: securityOverview[tenantId],
        selectedTenant: tenantId,
        isCachedData: true,
      };
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/security/organizations/${orgId}/tenants/${tenantId}/overview`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant: tenantId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getRecommendations = createAsyncThunk(
  "getRecommendations",
  async (
    { useCache = true, tenant, organization },
    { getState, requestId, rejectWithValue },
  ) => {
    const {
      loadingRecommendationsRequestId,
      loadingRecommendations,
      recommendations,
    } = getState().dashboard.security;
    const tenantId = getTenantId(tenant);
    const orgId = getIdentityId(organization);

    if (
      !loadingRecommendations ||
      loadingRecommendationsRequestId !== requestId
    ) {
      return;
    }
    if (useCache && recommendations[tenantId]) {
      return null;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/security/organizations/${orgId}/tenants/${tenantId}/recommendations`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant: tenantId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getRecommendationDetails = createAsyncThunk(
  "getRecommendationDetails",
  async (
    { useCache = true, tenant, organization, assessmentKey },
    { getState, requestId, rejectWithValue },
  ) => {
    const {
      loadingDetailedRecommendationRequestId,
      loadingDetailedRecommendation,
      detailedRecommendations,
    } = getState().dashboard.security;
    const tenantId = getTenantId(tenant);
    const orgId = getIdentityId(organization);

    if (
      !loadingDetailedRecommendation ||
      loadingDetailedRecommendationRequestId !== requestId
    ) {
      return;
    }
    if (
      useCache &&
      detailedRecommendations[tenantId] &&
      detailedRecommendations[tenantId][assessmentKey]
    ) {
      return null;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/security/organizations/${orgId}/tenants/${tenantId}/recommendations/${assessmentKey}`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant: tenantId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getAlerts = createAsyncThunk(
  "getAlerts",
  async (
    { useCache = true, tenant, organization },
    { getState, requestId, rejectWithValue },
  ) => {
    const {
      loadingAlertsRequestId,
      loadingAlertsList,
      alerts,
      alertsAvailable,
    } = getState().dashboard.security;
    const tenantId = getTenantId(tenant);
    const orgId = getIdentityId(organization);

    if (!loadingAlertsList || loadingAlertsRequestId !== requestId) {
      return;
    }
    if (useCache && alertsAvailable[tenantId]) {
      return { data: { value: alerts[tenantId] }, selectedTenant: tenantId };
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/security/organizations/${orgId}/tenants/${tenantId}/alerts`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant: tenantId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const initialState = {
  alerts: {},
  alertsAvailable: {},
  loadingAlertsList: null,
  loadingAlertsError: null,
  loadingAlertsRequestId: null,
  securityOverview: {},
  loadingOverview: null,
  loadingOverviewError: null,
  loadingOverviewRequestId: null,
  recommendations: {},
  detailedRecommendations: {},
  loadingDetailedRecommendation: null,
  errorLoadingDetailedRecommendation: null,
  loadingDetailedRecommendationRequestId: null,
  loadingRecommendations: null,
  loadingRecommendationsError: null,
  loadingRecommendationsRequestId: null,
};

export const securitySlice = createSlice({
  name: "security",
  initialState,
  reducers: {},
  extraReducers: {
    [getAlerts.pending]: (state, action) => {
      state.loadingAlertsList = true;
      state.loadingAlertsRequestId = action.meta.requestId;
      state.loadingAlertsError = null;
    },
    [getAlerts.fulfilled]: (state, action) => {
      state.loadingAlertsRequestId = null;
      state.loadingAlertsList = false;
      state.loadingAlertsError = "";
      if (action.payload.selectedTenant) {
        _.set(
          state.alerts,
          `${action.payload.selectedTenant}`,
          action.payload.data ? action.payload.data.value : [],
        );
        _.set(state.alertsAvailable, `${action.payload.selectedTenant}`, true);
      }
    },
    [getAlerts.rejected]: (state, action) => {
      state.loadingAlertsRequestId = null;
      state.error = action.payload || true;
      state.loadingAlertsList = false;
    },
    [getSecurityOverview.pending]: (state, action) => {
      state.loadingOverview = true;
      state.loadingOverviewRequestId = action.meta.requestId;
      state.loadingOverviewError = null;
    },
    [getSecurityOverview.fulfilled]: (state, action) => {
      state.loadingOverviewRequestId = null;
      state.loadingOverview = false;
      state.loadingOverviewError = "";
      if (action.payload.selectedTenant && !action.payload.isCachedData) {
        // security score
        let overview = {};
        const columns = action.payload.data.secureScore.data.columns;
        const rows = action.payload.data.secureScore.data.rows[0];
        columns.forEach((column, index) => {
          overview[column.name] = rows[index][0];
        });
        // defender data
        let coveredResources = 0;
        let uncoveredResources = 0;
        const defenderRows = action.payload.data.defenderCoverage.data?.rows;
        defenderRows?.forEach((row) => {
          if (row[1] === "Standard") {
            coveredResources += row[2];
          } else {
            uncoveredResources += row[2];
          }
        });
        const defenderCoverage = getTwoDecimalNumber(
          (coveredResources / (coveredResources + uncoveredResources)) * 100,
        );
        overview.defenderCoverage = isNaN(defenderCoverage)
          ? 0
          : defenderCoverage;
        _.set(
          state.securityOverview,
          `${action.payload.selectedTenant}`,
          overview,
        );
      }
    },
    [getSecurityOverview.rejected]: (state, action) => {
      state.loadingOverviewRequestId = null;
      state.loadingOverviewError = action.payload || true;
      state.loadingOverview = false;
    },
    [getRecommendations.pending]: (state, action) => {
      state.loadingRecommendations = true;
      state.loadingRecommendationsRequestId = action.meta.requestId;
      state.loadingRecommendationsError = null;
    },
    [getRecommendations.fulfilled]: (state, action) => {
      if (action.payload && action.payload.data && action.payload.data.data) {
        const recommendations = action.payload.data.data;
        if (recommendations && recommendations.rows) {
          const values = recommendations.rows;
          const columns = recommendations.columns;
          const formattedRecommendations = [];
          values.forEach((row) => {
            const recommendation = {};
            row.forEach((rowElement, indexOfElement) => {
              recommendation[columns[indexOfElement].name] = rowElement;
            });
            formattedRecommendations.push(recommendation);
          });
          _.set(
            state.recommendations,
            `${action.payload.selectedTenant}`,
            formattedRecommendations,
          );
        }
      }
      state.loadingRecommendations = false;
      state.loadingRecommendationsRequestId = null;
      state.loadingRecommendationsError = "";
    },
    [getRecommendations.rejected]: (state, action) => {
      state.loadingRecommendationsRequestId = null;
      state.loadingRecommendationsError = action.payload || true;
      state.loadingRecommendations = false;
    },
    [getRecommendationDetails.pending]: (state, action) => {
      state.loadingDetailedRecommendation = true;
      state.loadingDetailedRecommendationRequestId = action.meta.requestId;
      state.errorLoadingDetailedRecommendation = null;
    },
    [getRecommendationDetails.fulfilled]: (state, action) => {
      if (action.payload && action.payload.data) {
        const recommendation = { ...action.payload.data };
        const resourceInfo = recommendation?.recommendationResourceInformation;
        if (resourceInfo && resourceInfo.rows) {
          const values = resourceInfo.rows;
          const columns = resourceInfo.columns;
          const resources = [];
          const unhealthyResources = [];
          const healthyResources = [];
          const notApplicableResources = [];
          values.forEach((row) => {
            let resource = {};
            row.forEach((rowItem, index) => {
              resource[columns[index].name] = rowItem;
            });
            resources.push(resource);
            switch (resource.statusCode) {
              case RESOURCE_HEALTH_STATUS.HEALTHY:
                healthyResources.push(resource);
                break;
              case RESOURCE_HEALTH_STATUS.UNHEALTHY:
                unhealthyResources.push(resource);
                break;
              case RESOURCE_HEALTH_STATUS.NOT_APPLICABLE:
                notApplicableResources.push(resource);
                break;
              default:
                break;
            }
          });
          recommendation.recommendationResourceInformation = resources;
          recommendation[RESOURCE_HEALTH_STATUS.HEALTHY] = healthyResources;
          recommendation[RESOURCE_HEALTH_STATUS.UNHEALTHY] = unhealthyResources;
          recommendation[RESOURCE_HEALTH_STATUS.NOT_APPLICABLE] =
            notApplicableResources;
          _.set(
            state.detailedRecommendations,
            `${action.payload.selectedTenant}.${recommendation.assessmentKey}`,
            recommendation,
          );
        }
      }
      state.loadingDetailedRecommendation = false;
      state.loadingDetailedRecommendationRequestId = null;
      state.errorLoadingDetailedRecommendation = "";
    },
    [getRecommendationDetails.rejected]: (state, action) => {
      state.loadingDetailedRecommendationRequestId = null;
      state.errorLoadingDetailedRecommendation = action.payload || true;
      state.loadingDetailedRecommendation = false;
    },
  },
});

export default securitySlice.reducer;
