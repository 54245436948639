import HTTPClient from "./httpClient";

const RTKbaseQuery = async ({
  isUnauthorizedCall,
  url,
  method,
  body,
  params,
  headers,
}) => {
  const config = {
    endpoint: url,
    method,
    data: body,
    headers,
    params,
  };
  const httpClient = new HTTPClient(config);
  try {
    if (isUnauthorizedCall) {
      const result = await httpClient.callUnauthorizedAPI();
      return result.data;
    }

    const result = await httpClient.callAuthorizedAPI();
    return result.data;
  } catch (axiosError) {
    let err = axiosError;
    return {
      error: { status: err.response?.status, data: err.response?.data },
    };
  }
};

export default RTKbaseQuery;
