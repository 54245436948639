import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HTTP_METHODS } from "../../common/constants";
import HTTPClient from "../../common/servicers/httpClient";
import { getTenantId } from "../../common/utils";
import {
  isStatusPending,
  shouldFulfill,
  shouldRequest,
} from "../../common/utils/slices";
import { filterActiveUserSessions } from "../dashboardUtils";

/**************************************
 *********** API ACTIONS ***************
 ***************************************/

export const getUserSessions = createAsyncThunk(
  "getUserSessions",
  async (
    { useCache = true, organizationId, tenantId },
    { getState, requestId, rejectWithValue },
  ) => {
    const { fetchRequestId, fetchStatus, userSessions } =
      getState().dashboard.userSessions;

    if (!shouldRequest(fetchStatus, fetchRequestId, requestId)) {
      return;
    }

    if (useCache && userSessions && userSessions[tenantId]) {
      return { data: userSessions[tenantId], tenantId };
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${tenantId}/users/sessions`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { data: response.data.data, tenantId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const logOffUserSession = createAsyncThunk(
  "logOffUserSession",
  async (
    { organizationId, tenantId, userSessions },
    { getState, requestId, rejectWithValue },
  ) => {
    const { loggingOffRequestId, loggingOff } =
      getState().dashboard.userSessions;
    if (!loggingOff || requestId !== loggingOffRequestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${tenantId}/spotGroups/userSessions`,
        method: HTTP_METHODS.DELETE,
        data: userSessions,
      }).callAuthorizedAPI();
      return {
        data: response.data,
        tenantId,
        ids: userSessions.map((s) => s.id),
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const initialState = {
  userSessions: {},
  activeUserSessions: {},
  loading: false,
  error: null,
  currentRequestId: null,

  loggingOff: null,
  loggingOffRequestId: null,
  loggingOffError: null,
  fetchStatus: "idle",
  fetchRequestId: null,
  silentRefresh: false,
  refreshTimeOutId: null,
};

export const userSessionsSlice = createSlice({
  name: "userSessions",
  initialState,
  reducers: {
    setUserSessions: (state, action) => {
      state.userSessions[action.payload?.tenantId] = action.payload.data;
    },
    setSilentRefresh: (state, action) => {
      state.silentRefresh = action.payload.data;
    },
    resetErrors: (state) => {
      state.error = null;
      state.loggingOffError = null;
    },
    setLoggingOffError: (state, action) => {
      state.loggingOffError = { message: action };
    },
    clearSessionsFromUserSessionList: (state, action) => {
      if (state.userSessions[action.payload?.tenantId]) {
        state.userSessions[action.payload?.tenantId] = state.userSessions[
          action.payload?.tenantId
        ].filter((s) => action.payload.ids.indexOf(s.id) === -1);
        state.activeUserSessions[action.payload?.tenantId] =
          state.activeUserSessions[action.payload?.tenantId].filter(
            (s) => action.payload.ids.indexOf(s.id) === -1,
          );
      }
    },
    updateUserSessionStatus: (state) => {
      state.silentRefresh = true;
    },
  },
  extraReducers: {
    [getUserSessions.pending]: (state, action) => {
      if (isStatusPending(state, "fetchStatus")) {
        return;
      }

      state.fetchStatus = "pending";
      state.loading = true;
      state.fetchRequestId = action.meta.requestId;
    },
    [getUserSessions.fulfilled]: (state, action) => {
      const { requestId } = action.meta;

      if (!shouldFulfill(state, "fetchStatus", "fetchRequestId", requestId)) {
        return;
      }

      state.fetchStatus = "idle";
      state.fetchRequestId = null;
      state.loading = false;
      state.error = null;
      state.userSessions[action.payload?.tenantId] = action.payload?.data || [];
      state.activeUserSessions[action.payload?.tenantId] =
        filterActiveUserSessions(action.payload?.data || []);
      state.silentRefresh = false;
    },
    [getUserSessions.rejected]: (state, action) => {
      state.fetchRequestId = null;
      state.error = action.payload;
      state.loading = false;
      state.fetchStatus = "idle";
    },

    [logOffUserSession.pending]: (state, action) => {
      state.loggingOff = true;
      state.loggingOffRequestId = action.meta.requestId;
      state.loggingOffError = null;
    },
    [logOffUserSession.fulfilled]: (state, action) => {
      state.loggingOffRequestId = null;
      state.loggingOff = false;
      state.loggingOffError = null;
      state.userSessions[action.payload?.tenantId] = state.userSessions[
        action.payload?.tenantId
      ].filter((s) => action.payload.ids.indexOf(s.id) === -1);
      state.activeUserSessions[action.payload?.tenantId] =
        state.activeUserSessions[action.payload?.tenantId].filter(
          (s) => action.payload.ids.indexOf(s.id) === -1,
        );
    },
    [logOffUserSession.rejected]: (state, action) => {
      state.loggingOffRequestId = null;
      state.loggingOffError = action.payload;
      state.loggingOff = false;
    },
  },
});

export const {
  setUserSessions,
  resetErrors,
  setLoggingOffError,
  clearSessionsFromUserSessionList,
  updateUserSessionStatus,
  setRefreshSessions,
} = userSessionsSlice.actions;

export const userSessionsSelector = (state) =>
  state.dashboard.userSessions?.userSessions[
    getTenantId(state.partners?.selectedPartner)
  ];

export default userSessionsSlice.reducer;
