import { useTheme } from "@mui/material/styles";
import React from "react";

export const ProgressIcon = () => {
  const theme = useTheme();
  const iconColor = theme.palette.help.main;
  const vectorColor = theme.palette.foreground.inverse;

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon">
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z"
          fill={vectorColor}
        />
        <path
          id="Icon_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.3787 5.92049C9.40648 4.80685 7.75208 4.49853 6.42346 5.26561C5.5148 5.79023 4.9653 6.69797 4.86198 7.66862C4.83738 7.89981 4.63001 8.06728 4.39882 8.04267C4.16763 8.01806 4.00016 7.8107 4.02477 7.5795C4.15534 6.35276 4.85091 5.20133 6.00248 4.53647C7.74062 3.53295 9.91893 3.98246 11.1309 5.5081L11.1766 5.33741C11.2368 5.11283 11.4676 4.97956 11.6922 5.03974C11.9168 5.09991 12.05 5.33075 11.9899 5.55532L11.6817 6.70544C11.6528 6.81329 11.5822 6.90524 11.4856 6.96106C11.3889 7.01689 11.274 7.03201 11.1661 7.00312L10.016 6.69494C9.79142 6.63477 9.65814 6.40393 9.71832 6.17936C9.77849 5.95478 10.0093 5.82151 10.2339 5.88168L10.3787 5.92049ZM11.6053 7.95733C11.8365 7.98194 12.0039 8.1893 11.9793 8.4205C11.8487 9.64724 11.1532 10.7987 10.0016 11.4635C8.26355 12.467 6.08537 12.0176 4.87339 10.4921L4.82771 10.6626C4.76753 10.8872 4.5367 11.0204 4.31212 10.9603C4.08755 10.9001 3.95427 10.6693 4.01445 10.4447L4.32262 9.29456C4.3828 9.06999 4.61363 8.93671 4.83821 8.99689L5.98833 9.30506C6.21291 9.36524 6.34618 9.59607 6.286 9.82065C6.22583 10.0452 5.99499 10.1785 5.77042 10.1183L5.62529 10.0794C6.59754 11.1931 8.25198 11.5015 9.58063 10.7344C10.4893 10.2098 11.0388 9.30203 11.1421 8.33138C11.1667 8.10019 11.3741 7.93272 11.6053 7.95733Z"
          fill={iconColor}
        />
      </g>
    </svg>
  );
};
