import React from "react";
import ParentRoute from "../common/components/ParentRoute";
import { LAYOUTS, SUPPORT_ROLES } from "../common/constants";
import {
  SuspenseLoader,
  componentLoader,
} from "./../common/components/SuspenseComponent";

const CostEstimator = React.lazy(() =>
  componentLoader(() => import("./components/CostEstimator")),
);

const CostEstimatorRoutes = [
  {
    path: "/cost-estimator",
    component: ParentRoute,
    nobreadcrumbs: true,
    layoutStyle: LAYOUTS.FULL_SCREEN,
    roles: [...SUPPORT_ROLES],
    routes: [
      {
        component: () => SuspenseLoader(CostEstimator),
        roles: [...SUPPORT_ROLES],
      },
    ],
  },
];

export default CostEstimatorRoutes;
