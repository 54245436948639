import { Field, Form } from "react-final-form";
import { Grid, useTheme } from "@mui/material";
import React from "react";
import { handleChange } from "../../admin-actions/pools/poolsUtils";
import PropTypes from "prop-types";
import { Select } from "@veneer/core";

export default function AssignmentUsersForm({
    onChange,
    initialValues,
    options,
    form = "assignmentUsersForm",
    loading = false
}) {
    const theme = useTheme();
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} color={theme.palette.foreground.light}>
                <p>
                    Select the users that need to be assigned to this pool.
                </p>
            </Grid>
            <Grid item xs={12}>
                <Form
                    onSubmit={() => { }}
                    validate={(e) => onChange(e, form)}
                    initialValues={initialValues}
                    render={(formMeta) => {
                        const form = formMeta.form;
                        const onChange = ({ value: selectedValue }) => {
                            const valueCopy = [...formMeta.values.assignmentUser];
                            const isOptionSelected = valueCopy.includes(selectedValue);
                            if (isOptionSelected) {
                                const index = valueCopy.indexOf(selectedValue);
                                valueCopy.splice(index, 1);
                            } else {
                                valueCopy.push(selectedValue);
                            }
                            handleChange("assignmentUser", valueCopy, form);
                        };
                        return (
                            <form onSubmit={formMeta.handleSubmit}>
                                <Grid container spacing={1}>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "24px",
                                        }}
                                    >
                                        <Field
                                            name="assignmentUser"
                                            render={() => (
                                                <Select
                                                    multiple
                                                    onChange={onChange}
                                                    onClear={() => handleChange("assignmentUser", [], form)}
                                                    value={formMeta.values.assignmentUser}
                                                    defaultValue={formMeta.initialValues.assignmentUser}
                                                    label="Target users"
                                                    options={options}
                                                    loading={loading}
                                                    isSearchable
                                                    separateLabel
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </form>
                        );
                    }}
                />
            </Grid>
        </Grid>
    );
}

AssignmentUsersForm.propTypes = {
    onChange: PropTypes.func,
    initialValues: PropTypes.object,
    options: PropTypes.array,
    form: PropTypes.string,
    loading: PropTypes.bool,
};

