import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { HTTP_METHODS } from "../../common/constants";
import HTTPClient from "../../common/servicers/httpClient";
import { getApiAccountId } from "../../common/utils";

export const getApiAccounts = createAsyncThunk(
  "getApiAccounts",
  async (
    { useCache = true, selectedOrganization, selectedTenant },
    { getState, requestId, rejectWithValue },
  ) => {
    const {
      getApiAccountsRequestId,
      loadingApiAccounts,
      apiAccounts,
      apiAccountsAvailable,
    } = getState().adminActions.apiAccounts;
    if (!loadingApiAccounts || requestId !== getApiAccountsRequestId) {
      return;
    }
    if (useCache && apiAccountsAvailable[selectedTenant] === true) {
      return { data: apiAccounts[selectedTenant], selectedTenant };
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${selectedOrganization}/tenants/${selectedTenant}/apiUsers`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const createApiAccount = createAsyncThunk(
  "createApiAccount",
  async (
    { selectedOrganization, selectedTenant, userName, expirationInDays },
    { getState, requestId, rejectWithValue },
  ) => {
    const { loadingCreateApiAccount, createApiAccountRequestId } =
      getState().adminActions.apiAccounts;
    if (!loadingCreateApiAccount || requestId !== createApiAccountRequestId) {
      return;
    }
    const payload = {
      userName,
      expirationInDays,
    };
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${selectedOrganization}/tenants/${selectedTenant}/apiUsers`,
        method: HTTP_METHODS.POST,
        data: payload,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const editApiAccount = createAsyncThunk(
  "editApiAccount",
  async (
    { selectedOrganization, selectedTenant, apiAccountId, expirationInDays },
    { getState, requestId, rejectWithValue },
  ) => {
    const { loadingEditApiAccount, editApiAccountRequestId } =
      getState().adminActions.apiAccounts;
    if (!loadingEditApiAccount || requestId !== editApiAccountRequestId) {
      return;
    }
    const payload = {
      expirationInDays: expirationInDays,
    };
    try {
      const apiAccount = getApiAccountId(apiAccountId);
      const response = await new HTTPClient({
        endpoint: `/organizations/${selectedOrganization}/tenants/${selectedTenant}/apiUsers/${apiAccount}`,
        method: HTTP_METHODS.PUT,
        data: payload,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant, apiAccountId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteApiAccount = createAsyncThunk(
  "deleteApiAccount",
  async (
    { selectedOrganization, selectedTenant, apiAccountId },
    { getState, requestId, rejectWithValue },
  ) => {
    const { deletingApiAccount, deleteApiAccountRequestId } =
      getState().adminActions.apiAccounts;
    if (!deletingApiAccount || requestId !== deleteApiAccountRequestId) {
      return;
    }
    try {
      const accountId = getApiAccountId(apiAccountId);
      const response = await new HTTPClient({
        endpoint: `/organizations/${selectedOrganization}/tenants/${selectedTenant}/apiUsers/${accountId}`,
        method: HTTP_METHODS.DELETE,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant, apiAccountId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const initialState = {
  apiAccounts: {},
  apiAccountsAvailable: {},
  loadingApiAccounts: null,
  getApiAccountsRequestId: null,
  getApiAccountsError: null,

  loadingCreateApiAccount: null,
  createApiAccountRequestId: null,
  createApiAccountError: null,
  createApiAccountSuccessResponse: null,

  loadingEditApiAccount: null,
  editApiAccountRequestId: null,
  editApiAccountError: null,
  editApiAccountSuccessResponse: null,

  deletingApiAccount: null,
  deleteApiAccountRequestId: null,
  deleteApiAccountError: null,
  deleteApiAccountSuccess: null,
};
export const apiAccountsSlice = createSlice({
  name: "apiAccounts",
  initialState,
  reducers: {
    resetErrors: (state) => {
      state.getApiAccountsError = null;
      state.editApiAccountError = null;
      state.deleteApiAccountError = null;
      state.createApiAccountSuccessResponse = null;
      state.editApiAccountSuccessResponse = null;
      state.deleteApiAccountSuccess = null;
      state.createApiAccountError = null;
    },
  },
  extraReducers: {
    [getApiAccounts.pending]: (state, action) => {
      state.loadingApiAccounts = true;
      state.getApiAccountsRequestId = action.meta.requestId;
      state.getApiAccountsError = null;
    },
    [getApiAccounts.fulfilled]: (state, action) => {
      state.loadingApiAccounts = false;
      state.getApiAccountsRequestId = null;
      state.getApiAccountsError = null;
      if (action.payload.selectedTenant) {
        _.set(
          state.apiAccounts,
          `${action.payload.selectedTenant}`,
          action.payload.data,
        );
        _.set(
          state.apiAccountsAvailable,
          `${action.payload.selectedTenant}`,
          true,
        );
      }
    },
    [getApiAccounts.rejected]: (state, action) => {
      state.loadingApiAccounts = false;
      state.getApiAccountsError = action.payload;
      state.getApiAccountsRequestId = null;
    },

    [createApiAccount.pending]: (state, action) => {
      state.loadingCreateApiAccount = true;
      state.createApiAccountRequestId = action.meta.requestId;
      state.createApiAccountError = null;
    },
    [createApiAccount.fulfilled]: (state, action) => {
      state.loadingCreateApiAccount = false;
      state.createApiAccountRequestId = null;
      state.createApiAccountError = null;
      state.createApiAccountSuccessResponse = action.payload?.data;
      if (action.payload.selectedTenant) {
        const tenantId = action.payload.selectedTenant;
        state.apiAccounts[tenantId] = [
          ...(state.apiAccounts[tenantId] || []),
          action.payload.data,
        ];
      }
    },
    [createApiAccount.rejected]: (state, action) => {
      state.loadingCreateApiAccount = false;
      state.createApiAccountError = action.payload;
      state.createApiAccountRequestId = null;
      state.createApiAccountSuccessResponse = null;
    },

    [editApiAccount.pending]: (state, action) => {
      state.loadingEditApiAccount = true;
      state.editApiAccountRequestId = action.meta.requestId;
      state.editApiAccountError = null;
    },
    [editApiAccount.fulfilled]: (state, action) => {
      state.loadingEditApiAccount = false;
      state.editApiAccountRequestId = null;
      state.editApiAccountError = false;
      state.editApiAccountSuccessResponse = action.payload?.data;
      if (action.payload.selectedTenant) {
        const tenantId = action.payload.selectedTenant;
        state.apiAccounts[tenantId] = state.apiAccounts[tenantId].map(
          (account) => {
            return account.id === action.payload.apiAccountId
              ? action.payload.data
              : account;
          },
        );
      }
    },
    [editApiAccount.rejected]: (state, action) => {
      state.loadingEditApiAccount = false;
      state.editApiAccountError = action.payload;
      state.editApiAccountRequestId = null;
      state.editApiAccountSuccessResponse = null;
    },

    [deleteApiAccount.pending]: (state, action) => {
      state.deletingApiAccount = true;
      state.deleteApiAccountError = null;
      state.deleteApiAccountRequestId = action.meta.requestId;
      state.deleteApiAccountSuccess = null;
    },
    [deleteApiAccount.fulfilled]: (state, action) => {
      state.deleteApiAccountSuccess = true;
      state.deletingApiAccount = false;
      state.deleteApiAccountError = null;
      state.deleteApiAccountRequestId = null;
      state.apiAccounts[action.payload.selectedTenant] = state.apiAccounts[
        action.payload.selectedTenant
      ].filter((u) => u.id !== action.payload.apiAccountId);
    },
    [deleteApiAccount.rejected]: (state, action) => {
      state.deletingApiAccount = null;
      state.deleteApiAccountError = action.payload;
      state.deleteApiAccountRequestId = null;
      state.deleteApiAccountSuccess = false;
    },
  },
});

export const { resetErrors } = apiAccountsSlice.actions;

export const apiAccountsSliceSelector = (state) =>
  state.adminActions.apiAccounts;
export default apiAccountsSlice.reducer;
