import React from "react";
import {
  FULL_ACCESS_PERMISSION,
  MODULES_LIST,
  PERMISSIONS_LIST,
  PERMISSIONS_TYPE,
  SEVERITY_LEVELS,
} from "../common/constants";
import { nth } from "lodash";
import {
  LOG_ANALYTICS_RESOURCE_TYPE,
  RESOURCE_DETAILS_HEADINGS,
} from "./dashboardConstants";
import { getTenantId } from "../common/utils";
import { Checkbox } from "@neos/core";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

export const getRamUsage = (row) => {
  return row.machineRAM ? parseInt(row.machineRAM.split("%")[0], 10) : null;
};

export const getCpuUsage = (row) => {
  return row.machineCPU ? parseInt(row.machineCPU.split("%")[0], 10) : null;
};

export const getUserInputDelay = (row) => {
  return row.userInputDelay ? row.userInputDelay : null;
};

export const getRoundTripTime = (row) => {
  return row.roundTrip ? parseInt(row.roundTrip.split(" ms")[0], 10) : null;
};

export const getSeverityPieChartData = (alertList) => {
  let pieChartData = [
    { name: "High Risk Alerts", value: 0 },
    { name: "Medium Risk Alerts", value: 0 },
    { name: "Low Risk Alerts", value: 0 },
  ];
  if (alertList.length > 0) {
    pieChartData = pieChartData.map((d) => ({ ...d, value: 0 }));
  }
  alertList.map((alert) => {
    if (alert.properties.severity === SEVERITY_LEVELS.High) {
      pieChartData[0].value++;
    }
    if (alert.properties.severity === SEVERITY_LEVELS.Medium) {
      pieChartData[1].value++;
    }
    if (alert.properties.severity === SEVERITY_LEVELS.Low) {
      pieChartData[2].value++;
    }
  });
  return pieChartData;
};

export const filterActiveMachines = (machines) =>
  machines?.filter((machine) => machine?.properties.online);

export const filterActiveUserSessions = (userSessions) =>
  userSessions?.filter(
    (userSession) => userSession?.properties.sessionState === "Active",
  );

export const getMachineName = (machine) =>
  machine.split(".")[0].toLowerCase() || machine.toLowerCase();

export const getSiteName = (machineId) => nth(machineId.split("/"), -3);

export const getSpotGroupName = (spotGroupId) =>
  nth(spotGroupId.split("/"), -1);

export const avoidNegativeColumns = (value) => (value < 0 ? 0 : value);

const getResourceDetailsHeading = (title) => {
  if (RESOURCE_DETAILS_HEADINGS[title.toLowerCase()]) {
    return RESOURCE_DETAILS_HEADINGS[title.toLowerCase()];
  } else {
    return title;
  }
};

export const getResourceDetailsFromResourceList = (list) => {
  try {
    const resourceList = list.filter(
      (resource) => resource.type !== LOG_ANALYTICS_RESOURCE_TYPE,
    );
    const affectedResource = resourceList[0];
    const resourceDetails = {};
    const resourceIdParts = affectedResource.azureResourceId.split("/");
    for (let i = 1; i < resourceIdParts.length; i = i + 2) {
      resourceDetails[getResourceDetailsHeading(resourceIdParts[i])] =
        resourceIdParts[i + 1];
    }
    return resourceDetails;
  } catch (e) {
    return {};
  }
};

export const getMachineDetailsRoute = (machine) => {
  if (
    machine?.helperData?.userPermissionsList &&
    !machine?.helperData?.userSupportView
  ) {
    const userPermissions = machine?.helperData?.userPermissionsList;
    if (
      userPermissions[
        PERMISSIONS_LIST[
          `${MODULES_LIST.VIRTUAL_MACHINES}.${MODULES_LIST.PERFORMANCE}`
        ][PERMISSIONS_TYPE.VIEW]
      ]
    ) {
      return `/dashboard/machines/${machine.value}/${machine.siteName}`;
    }
    if (
      userPermissions[
        PERMISSIONS_LIST[
          `${MODULES_LIST.VIRTUAL_MACHINES}.${MODULES_LIST.PROCESSES}`
        ][PERMISSIONS_TYPE.VIEW]
      ]
    ) {
      return `/dashboard/machines/${machine.value}/${machine.siteName}/processes`;
    }
    if (
      userPermissions[
        PERMISSIONS_LIST[
          `${MODULES_LIST.VIRTUAL_MACHINES}.${MODULES_LIST.ACTIVE_SESSIONS}`
        ][PERMISSIONS_TYPE.VIEW]
      ]
    ) {
      return `/dashboard/machines/${machine.value}/${machine.siteName}/activeSessions`;
    }
    if (
      userPermissions[
        PERMISSIONS_LIST[
          `${MODULES_LIST.VIRTUAL_MACHINES}.${MODULES_LIST.USER_LOGON_ACTIVITY}`
        ][PERMISSIONS_TYPE.VIEW]
      ]
    ) {
      return `/dashboard/machines/${machine.value}/${machine.siteName}/userLogonActivity`;
    }
    return null;
  }
  return `/dashboard/machines/${machine.value}/${machine.siteName}`;
};

export const getUserSessionDetailsRoute = (userSession) => {
  if (
    userSession?.helperData?.userPermissionsList &&
    !userSession?.helperData?.userSupportView
  ) {
    const userPermissions = userSession?.helperData?.userPermissionsList;
    if (
      userPermissions[
        PERMISSIONS_LIST[
          `${MODULES_LIST.USER_SESSIONS}.${MODULES_LIST.PERFORMANCE}`
        ][PERMISSIONS_TYPE.VIEW]
      ]
    ) {
      return `/dashboard/userSessions/${userSession.value}`;
    }
    if (
      userPermissions[
        PERMISSIONS_LIST[
          `${MODULES_LIST.USER_SESSIONS}.${MODULES_LIST.PROCESSES}`
        ][PERMISSIONS_TYPE.VIEW]
      ]
    ) {
      return `/dashboard/userSessions/${userSession.value}/processes`;
    }
    if (
      userPermissions[
        PERMISSIONS_LIST[
          `${MODULES_LIST.USER_SESSIONS}.${MODULES_LIST.USER_LOGON_ACTIVITY}`
        ][PERMISSIONS_TYPE.VIEW]
      ]
    ) {
      return `/dashboard/userSessions/${userSession.value}/userLogonActivity`;
    }
    return null;
  }
  return `/dashboard/userSessions/${userSession.value}`;
};

export const getSecurityRoute = (state) => {
  if (
    state.permissions?.userPermissionsList &&
    !state?.auth.userSupportView &&
    state.partners?.selectedPartner &&
    state.permissions?.userPermissionsList[
      getTenantId(state.partners?.selectedPartner)
    ]
  ) {
    const userPermissions =
      state.permissions?.userPermissionsList[
        getTenantId(state.partners?.selectedPartner)
      ];
    if (
      userPermissions[
        PERMISSIONS_LIST[
          `${MODULES_LIST.SECURITY}.${MODULES_LIST.SECURITY_ALERTS}`
        ][PERMISSIONS_TYPE.VIEW]
      ]
    ) {
      return "/dashboard/security/alerts";
    }
    if (
      userPermissions[
        PERMISSIONS_LIST[
          `${MODULES_LIST.SECURITY}.${MODULES_LIST.RECOMMENDATIONS}`
        ][PERMISSIONS_TYPE.VIEW]
      ]
    ) {
      return "/dashboard/security/recommendations";
    }
    return null;
  }
  return "/dashboard/security/alerts";
};

export const getLogsRoute = (state) => {
  if (
    state.permissions?.userPermissionsList &&
    !state?.auth.userSupportView &&
    state.partners?.selectedPartner &&
    state.permissions?.userPermissionsList[
      getTenantId(state.partners?.selectedPartner)
    ]
  ) {
    const userPermissions =
      state.permissions?.userPermissionsList[
        getTenantId(state.partners?.selectedPartner)
      ];
    if (
      userPermissions[
        PERMISSIONS_LIST[`${MODULES_LIST.LOGS}`][PERMISSIONS_TYPE.VIEW]
      ]
    ) {
      return "/dashboard/logs";
    }
    if (
      userPermissions[
        PERMISSIONS_LIST[`${MODULES_LIST.LOGS}.${MODULES_LIST.ADMIN_ACTIVITY}`][
          PERMISSIONS_TYPE.VIEW
        ]
      ]
    ) {
      return "/dashboard/logs/adminActivity";
    }
    if (
      userPermissions[
        PERMISSIONS_LIST[
          `${MODULES_LIST.LOGS}.${MODULES_LIST.USER_LOGON_ACTIVITY}`
        ][PERMISSIONS_TYPE.VIEW]
      ]
    ) {
      return "/dashboard/logs/userLogonTime";
    }
    return null;
  }
  return "/dashboard/logs";
};

export const getAdminColumns = (editing) => {
  const columns = [
    {
      header: "Module Name",
      accessor: "displayName",
      id: "name",
    },
  ];
  if (editing) {
    columns.push({
      header: "Full Access",
      accessor: "name",
      id: "fullAccess",
      cell: (row) => {
        return (
          <Checkbox
            color="info"
            style={{ padding: 0 }}
            onClick={(event) =>
              row.helperData.onChange(
                event.target.checked,
                row.name,
                FULL_ACCESS_PERMISSION,
                row.helperData,
              )
            }
            data-testid={"roles-checkbox"}
            checked={
              row.helperData?.modules?.find((m) => m.key === row.name)?.actions
                ?.length ===
              row.helperData.allPermissionModules[row.name]?.actions?.length
            }
          />
        );
      },
    });
  }
  columns.push(
    ...Object.keys(PERMISSIONS_TYPE).map((permissionType) => {
      return {
        header: permissionType,
        accessor: "actions",
        id: permissionType,
        cell: (row) => {
          if (row.actions.indexOf(PERMISSIONS_TYPE[permissionType]) === -1) {
            return <></>;
          }
          const permission = row.helperData.modules?.find(
            (m) => m.key === row.key,
          );
          if (row.helperData.editing) {
            return (
              <Checkbox
                color="info"
                style={{ padding: 0 }}
                onClick={(event) =>
                  row.helperData.onChange(
                    event.target.checked,
                    row.name,
                    permissionType,
                    row.helperData,
                  )
                }
                checked={
                  permission &&
                  permission.actions.length &&
                  permission.actions?.indexOf(
                    PERMISSIONS_TYPE[permissionType],
                  ) > -1
                    ? true
                    : false
                }
              />
            );
          }
          if (permission) {
            return permission.actions.indexOf(
              PERMISSIONS_TYPE[permissionType],
            ) > -1 ? (
              <DoneIcon style={{ color: "#4D52E6" }} />
            ) : (
              <CloseIcon style={{ color: "#FF0000" }} />
            );
          } else {
            return <CloseIcon style={{ color: "#FF0000" }} />;
          }
        },
      };
    }),
  );
  return columns;
};
