import { Box } from "@mui/material";
import { Breadcrumbs } from "@veneer/core";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import useCurrentTenant from "../hooks/useCurrentTenant";
import useCurrentMachine from "../hooks/useCurrentMachine";
import { generateBreadcrumb } from "../utils";
import useCurrentPool from "../hooks/useCurrentPool";
import useCurrentSite from "../hooks/useCurrentSite";

export default function BreadcrumbsWrapper(props) {
  const { route } = props;
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const location = useLocation();
  const currentTenant = useCurrentTenant();
  const currentMachine = useCurrentMachine();    
  const currentPool = useCurrentPool();
  const currentSite = useCurrentSite();

  useEffect(() => {
    const crumbs = generateBreadcrumb(location.pathname);
    setBreadcrumbs(crumbs);
  }, [location, route]);

  const crumbs = breadcrumbs && breadcrumbs.map((b, i) => {

    // replace the title with the actual names of elements: tenant, machine, site, etc...
    // Each element has a different location to get the name from.
    // This is the only place where it has to be done.

    let newText = b.text;
    switch (b.text) {
      case "<tenantName>":
        newText = currentTenant?.displayName || "Overview";
        break;
      case "<machineName>":
        newText = currentMachine?.name || null;
        break;
     case "<poolName>":
        newText = currentPool?.name || null;
        break;  
      case "<siteName>":
        newText = currentSite?.name || null;
        break;
      default:
        break      
    }

    const bc = {
      key: `bread-${i}`,
      text: newText,      
    }

    // breadcrumb to be only a text, not a link
    const breadcrumbAsText = ["Settings"]

    // Only add a link if the breadcrumb has a destination URL or a URL
    const linkUrl = b.destinationUrl || b.url || null;         
    if (linkUrl && !breadcrumbAsText.includes(b.text)) {
      bc.url = linkUrl;
      bc.customItem = <Link to={linkUrl}>{newText || ''}</Link>;
    } else {
      bc.customItem = <span>{newText || ''}</span>;
    }
    return bc;
  })
  // Remove empty title breadcrumbs
  .filter((b) => b.text !== '' && b.text !== null);

  return (
    <Box style={{ display: "flex", alignItems: 'center' }}>
      <Breadcrumbs 
        items={crumbs} 
      />
    </Box>
  );
}

BreadcrumbsWrapper.propTypes = {
  route: PropTypes.object,
};