import React from "react";

export const metricType = {
  SECONDS: "seconds",
  MINUTES: "minutes",
  HOURS: "hours",
};

const msLookup = {
  [metricType.SECONDS]: 1000,
  [metricType.MINUTES]: 1000 * 60,
  [metricType.HOURS]: 1000 * 60 * 60,
};

const suffixLookup = {
  [metricType.SECONDS]: "seconds",
  [metricType.MINUTES]: "minutes",
  [metricType.HOURS]: "hours",
};

export default function CountdownTimer(props) {
  const { hideText, prefixText, suffixText, metric, timer } = props;
  const suffix = suffixText || suffixLookup[metric];
  const text = `${prefixText} ${Math.ceil(parseFloat(timer))} ${suffix}`.trim();
  return (
    <React.Fragment>
      {!hideText && (
        <span className="countdown-timer">
          <strong>{text}</strong>
        </span>
      )}
    </React.Fragment>
  );
}
CountdownTimer.defaultProps = {
  timer: 0,
  repeat: false,
  hideText: false,
  metric: metricType.SECONDS,
  prefixText: "Trying in ",
};
