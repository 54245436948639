import React from "react";
import ParentRoute from "../common/components/ParentRoute";
import { ProtectedRouteSuspenseContainer } from "../common/components/ProtectedRoute";
import {
  LAYOUTS,
  MODULES_LIST,
  PERMISSIONS_LIST,
  PERMISSIONS_TYPE,
} from "../common/constants";
import DashBoardRouteTitle from "../common/components/DashboardRouteTitle";

import MonitorsWrapper from "./components/ci-alerts/MonitorsWrapper";
import { componentLoader } from "../common/components/SuspenseComponent";
const CIAlertsMonitorList = React.lazy(() =>
  componentLoader(() => import("./components/ci-alerts/CIAlertsMonitorList")),
);
const CreateCustomMonitor = React.lazy(() =>
  componentLoader(() => import("./components/ci-alerts/CreateCustomMonitor")),
);
const EditCustomMonitor = React.lazy(() =>
  componentLoader(() => import("./components/ci-alerts/EditCustomMonitor")),
);
const DeleteCustomMonitor = React.lazy(() =>
  componentLoader(
    () => import("./components/ci-alerts/DeleteCustomMonitor.jsx"),
  ),
);

const MonitorRoutes = [
  {
    path: "/monitors",
    authRequired: true,
    layoutStyle: LAYOUTS.MENU_SCREEN,
    title: "Monitors",
    component: ParentRoute,
    redirectTo: "/monitors/userDefined",
    routeTitleComponent: () => (
      <DashBoardRouteTitle title="Monitors" showQuickActions={true} />
    ),
    permissionSet: [
      PERMISSIONS_LIST[`${MODULES_LIST.MONITORS}`][PERMISSIONS_TYPE.VIEW],
    ],
    routes: [
      {
        path: "/monitors/default",
        title: "Monitors",
        exact: true,
        component: () => (
          <ProtectedRouteSuspenseContainer component={CIAlertsMonitorList} />
        ),
        permissionSet: [
          PERMISSIONS_LIST[`${MODULES_LIST.MONITORS}`][PERMISSIONS_TYPE.VIEW],
        ],
      },
      {
        path: "/monitors/userDefined",
        title: "Monitors",
        isSubComponent: true,
        component: MonitorsWrapper,
        permissionSet: [
          PERMISSIONS_LIST[`${MODULES_LIST.MONITORS}`][PERMISSIONS_TYPE.VIEW],
        ],
        routes: [
          {
            path: "/monitors/userDefined",
            title: "Monitors",
            exact: true,
            component: () => (
              <ProtectedRouteSuspenseContainer
                component={CIAlertsMonitorList}
              />
            ),
            permissionSet: [
              PERMISSIONS_LIST[`${MODULES_LIST.MONITORS}`][
                PERMISSIONS_TYPE.VIEW
              ],
            ],
          },
          {
            path: "/monitors/userDefined/action/create",
            title: "Create Monitor",
            exact: true,
            component: () => (
              <ProtectedRouteSuspenseContainer
                component={CreateCustomMonitor}
              />
            ),
            permissionSet: [
              PERMISSIONS_LIST[`${MODULES_LIST.MONITORS}`][
                PERMISSIONS_TYPE.CREATE
              ],
            ],
          },
          {
            path: "/monitors/userDefined/action/edit",
            title: "Edit Monitor",
            exact: true,
            component: () => (
              <ProtectedRouteSuspenseContainer component={EditCustomMonitor} />
            ),
            permissionSet: [
              PERMISSIONS_LIST[`${MODULES_LIST.MONITORS}`][
                PERMISSIONS_TYPE.EDIT
              ],
            ],
          },
          {
            path: "/monitors/userDefined/action/delete",
            title: "Delete Monitor",
            exact: true,
            component: () => (
              <ProtectedRouteSuspenseContainer
                component={DeleteCustomMonitor}
              />
            ),
            permissionSet: [
              PERMISSIONS_LIST[`${MODULES_LIST.MONITORS}`][
                PERMISSIONS_TYPE.DELETE
              ],
            ],
          },
          {
            path: "/monitors/userDefined/action/edit/:monitorId",
            title: "Edit Monitor",
            exact: true,
            component: () => (
              <ProtectedRouteSuspenseContainer component={EditCustomMonitor} />
            ),
            permissionSet: [
              PERMISSIONS_LIST[`${MODULES_LIST.MONITORS}`][
                PERMISSIONS_TYPE.EDIT
              ],
            ],
          },
        ],
      },
      // ],
      // },
    ],
  },
];

export default MonitorRoutes;
