import PropTypes from "prop-types";
import React from "react";
import { TENANT_PROGRESS } from "../../../common/constants";
import {
  mapProgressLogIcon,
  mapProgressLogLabel,
  mapProgressLogTagType,
} from "../../../onboarding/components/utils/progressLogStatusMap";
import { TagIndicator } from "./TagIndicator";

export const ProgressLogTag = ({ status, ...props }) => {
  const label = mapProgressLogLabel(status);
  const type = mapProgressLogTagType(status);
  const leadingIcon = mapProgressLogIcon(status);

  return (
    <TagIndicator
      label={label}
      type={type}
      leadingIcon={leadingIcon}
      {...props}
    />
  );
};

ProgressLogTag.propTypes = {
  status: PropTypes.oneOf([
    TENANT_PROGRESS.IN_PROGRESS,
    TENANT_PROGRESS.NOT_STARTED,
    TENANT_PROGRESS.COMPLETED,
    TENANT_PROGRESS.FAILED,
    TENANT_PROGRESS.PENDING,
    TENANT_PROGRESS.SKIPPED,
  ]),
};
