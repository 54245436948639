import React from "react";
import { useTheme } from "@mui/material/styles";

export const HPIconLarge = () => {
  const theme = useTheme();
  const logoColor = theme.palette.foreground.default;

  return (
    <svg
      width="175"
      height="32"
      viewBox="0 0 175 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5288_1145)">
        <path
          d="M16 32C15.8409 32 15.6825 31.9971 15.5245 31.9925L18.7943 23.006H23.2943C24.0842 23.006 24.9512 22.3988 25.2214 21.657L28.7689 11.9129C29.3493 10.3176 28.4355 9.01235 26.7379 9.01235H20.495L15.2569 23.4061H15.2549L12.2854 31.5656C5.24031 29.8906 0 23.5576 0 16C0 8.66039 4.94224 2.47565 11.6802 0.590636L8.61607 9.01235H8.6152L3.52141 23.006L6.9124 23.0063L11.2444 11.1152H13.7928L9.46083 23.0063L12.8505 23.0069L16.8874 11.9129C17.4682 10.3176 16.5543 9.01235 14.8584 9.01235H12.0079L15.2809 0.0168148C15.5194 0.00625924 15.759 0 16 0C24.8366 0 32 7.16343 32 16C32 24.8365 24.8366 32 16 32ZM25.6809 11.1066H23.1332L19.5653 20.8961H22.1131L25.6809 11.1066Z"
          fill={logoColor}
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.0978 23.2727V11.6364C64.0978 8.55133 62.8672 5.59255 60.6765 3.4105C58.4858 1.22844 55.5144 0.00171391 52.4154 0C49.3154 0 46.3422 1.22597 44.1502 3.40821C41.9581 5.59045 40.7266 8.55021 40.7266 11.6364V23.2727H46.7615V23.2663L52.4154 32L58.0629 23.2663V23.2727H64.0978ZM58.0629 23.2663V12.2828C58.0629 9.26599 55.5336 6.81805 52.4122 6.81805C49.2919 6.81805 46.7615 9.26492 46.7615 12.2828V23.2663H58.0629Z"
        fill={logoColor}
      />
      <path
        d="M167.573 23.2035C166.105 23.2035 164.87 22.9011 163.868 22.2965C162.866 21.6918 162.105 20.8712 161.587 19.8346C161.069 18.7808 160.81 17.5801 160.81 16.2325C160.81 14.8159 161.077 13.5806 161.613 12.5268C162.149 11.4729 162.909 10.6523 163.893 10.0649C164.895 9.47752 166.096 9.18383 167.496 9.18383C169.621 9.18383 171.253 9.84896 172.393 11.1792C173.551 12.4922 174.164 14.2889 174.233 16.5694C174.233 16.7767 174.13 16.8804 173.922 16.8804H163.557C163.367 16.8804 163.272 16.7767 163.272 16.5694V14.9109C163.272 14.7036 163.367 14.5999 163.557 14.5999H170.398C170.346 14.0644 170.208 13.572 169.983 13.1228C169.776 12.6563 169.465 12.2763 169.05 11.9826C168.636 11.6889 168.117 11.542 167.496 11.542C166.442 11.542 165.656 11.9394 165.137 12.7341C164.619 13.5288 164.36 14.6949 164.36 16.2325C164.36 17.8047 164.628 18.9622 165.163 19.705C165.716 20.4479 166.537 20.8194 167.625 20.8194C168.351 20.8194 168.947 20.6466 169.413 20.3011C169.897 19.9383 170.225 19.3682 170.398 18.5907C170.45 18.418 170.562 18.3316 170.735 18.3316H173.559C173.784 18.3316 173.879 18.4007 173.845 18.5389C173.741 19.3854 173.421 20.1629 172.886 20.8712C172.367 21.5795 171.659 22.1496 170.761 22.5815C169.862 22.9962 168.8 23.2035 167.573 23.2035Z"
        fill={logoColor}
      />
      <path
        d="M151.866 22.8666C151.676 22.8666 151.581 22.7629 151.581 22.5556L151.685 16.1548L151.581 9.83168C151.581 9.62437 151.676 9.52071 151.866 9.52071H154.846C155.036 9.52071 155.131 9.62437 155.131 9.83168L155.054 11.853C155.555 11.0237 156.22 10.4104 157.049 10.0131C157.896 9.61573 158.803 9.41705 159.77 9.41705C159.874 9.41705 159.977 9.42569 160.081 9.44297C160.185 9.44297 160.28 9.45161 160.366 9.46888C160.539 9.48616 160.625 9.59845 160.625 9.80577V11.8271C160.625 12.0344 160.522 12.1381 160.314 12.1381C160.245 12.1208 160.167 12.1121 160.081 12.1121C160.012 12.0949 159.934 12.0862 159.848 12.0862C158.967 12.0862 158.172 12.259 157.464 12.6045C156.755 12.9328 156.194 13.4338 155.779 14.1075C155.382 14.764 155.166 15.5933 155.131 16.5953L155.183 22.5556C155.183 22.7629 155.08 22.8666 154.872 22.8666H151.866Z"
        fill={logoColor}
      />
      <path
        d="M146.264 22.8666C146.074 22.8666 145.979 22.7629 145.979 22.5556L146.083 20.1197C146.083 19.9642 146.143 19.8519 146.264 19.7828L146.446 19.6532V12.5786L146.29 12.4749C146.135 12.4058 146.057 12.3022 146.057 12.164L145.979 9.83168C145.979 9.62437 146.066 9.52071 146.238 9.52071H149.27C149.46 9.52071 149.555 9.62437 149.555 9.83168L149.426 16.1807L149.555 22.5556C149.555 22.7629 149.46 22.8666 149.27 22.8666H146.264ZM142.014 23.1257C140.77 23.1257 139.725 22.8234 138.879 22.2187C138.032 21.5968 137.384 20.7675 136.935 19.731C136.503 18.6944 136.287 17.5282 136.287 16.2325C136.287 14.9368 136.52 13.762 136.987 12.7082C137.453 11.6543 138.118 10.8164 138.982 10.1945C139.846 9.57254 140.883 9.26157 142.092 9.26157C143.802 9.26157 145.115 9.86624 146.031 11.0756C146.964 12.2849 147.43 14.0125 147.43 16.2584C147.43 17.5887 147.214 18.7721 146.783 19.8087C146.368 20.8453 145.755 21.6572 144.943 22.2446C144.148 22.832 143.172 23.1257 142.014 23.1257ZM142.947 20.7416C143.966 20.7416 144.744 20.3702 145.279 19.6273C145.815 18.8671 146.091 17.7269 146.109 16.2066C146.109 14.7209 145.841 13.5893 145.305 12.8118C144.77 12.0344 144.001 11.6457 142.999 11.6457C142.325 11.6457 141.755 11.8184 141.289 12.164C140.822 12.5095 140.477 13.0105 140.252 13.667C140.028 14.3235 139.915 15.1096 139.915 16.0252C139.915 17.0272 140.028 17.8824 140.252 18.5907C140.477 19.299 140.814 19.8346 141.263 20.1974C141.729 20.5602 142.291 20.7416 142.947 20.7416Z"
        fill={logoColor}
      />
      <path
        d="M120.711 22.8666C120.538 22.8666 120.426 22.7802 120.374 22.6074L117.42 9.8576C117.385 9.63301 117.48 9.52071 117.705 9.52071H120.659C120.849 9.52071 120.961 9.61573 120.996 9.80577L122.706 20.016L124.727 9.80577C124.745 9.61573 124.848 9.52071 125.038 9.52071H128.77C128.943 9.52071 129.046 9.61573 129.081 9.80577L131.076 19.8087L132.813 9.80577C132.847 9.61573 132.951 9.52071 133.124 9.52071H135.819C136.043 9.52071 136.13 9.63301 136.078 9.8576L133.201 22.6074C133.15 22.7802 133.037 22.8666 132.865 22.8666H129.211C129.038 22.8666 128.934 22.7716 128.9 22.5815L126.619 12.449L124.65 22.5815C124.632 22.7716 124.529 22.8666 124.339 22.8666H120.711Z"
        fill={logoColor}
      />
      <path
        d="M108.101 27.272C107.998 27.272 107.92 27.2375 107.868 27.1684C107.816 27.1165 107.808 27.0388 107.842 26.9351L109.216 22.9702L103.929 9.8576C103.895 9.75394 103.903 9.6762 103.955 9.62437C103.99 9.55526 104.059 9.52071 104.162 9.52071H107.531C107.721 9.52071 107.842 9.60709 107.894 9.77985L110.978 18.4612L113.543 9.77985C113.595 9.60709 113.699 9.52071 113.854 9.52071H116.86C116.964 9.52071 117.033 9.55526 117.068 9.62437C117.12 9.6762 117.128 9.75394 117.094 9.8576L111.211 27.0129C111.159 27.1856 111.038 27.272 110.848 27.272H108.101Z"
        fill={logoColor}
      />
      <path
        d="M91.4344 22.8666C91.2271 22.8666 91.1234 22.7629 91.1234 22.5556L91.253 16.5435L91.1753 9.83168C91.1753 9.62437 91.2789 9.52071 91.4862 9.52071H94.3109C94.5009 9.52071 94.5959 9.62437 94.5959 9.83168L94.5441 11.3347C94.8378 10.9374 95.2006 10.5832 95.6325 10.2722C96.0644 9.94398 96.5482 9.68484 97.0837 9.4948C97.6366 9.30476 98.2326 9.20974 98.8718 9.20974C100.288 9.20974 101.403 9.66756 102.215 10.5832C103.044 11.4816 103.459 12.76 103.459 14.4185V17.1913L103.536 22.5556C103.536 22.7629 103.441 22.8666 103.251 22.8666H100.245C100.038 22.8666 99.9343 22.7629 99.9343 22.5556L100.038 17.1913V14.4703C100.038 13.6411 99.822 12.9932 99.3901 12.5268C98.9755 12.0603 98.4485 11.8271 97.8093 11.8271C97.3083 11.8271 96.8332 11.9739 96.384 12.2676C95.9348 12.5613 95.5548 13.0191 95.2438 13.6411C94.9328 14.2458 94.7601 15.0145 94.7255 15.9475V17.1913L94.7773 22.5556C94.7773 22.7629 94.6737 22.8666 94.4664 22.8666H91.4344Z"
        fill={logoColor}
      />
      <path
        d="M77.6223 18.0724C77.415 18.0724 77.3113 17.9688 77.3113 17.7615V15.481C77.3113 15.291 77.415 15.1959 77.6223 15.1959H85.578C85.7853 15.1959 85.8889 15.291 85.8889 15.481V17.7615C85.8889 17.9688 85.7853 18.0724 85.578 18.0724H77.6223ZM73.1132 22.8666C72.9922 22.8666 72.9059 22.8407 72.854 22.7888C72.8195 22.7197 72.8195 22.6334 72.854 22.5297L78.9957 4.98571C79.0303 4.81294 79.1426 4.72656 79.3326 4.72656H83.6603C83.8503 4.72656 83.9626 4.81294 83.9972 4.98571L90.0352 22.5297C90.0698 22.6334 90.0611 22.7197 90.0093 22.7888C89.9575 22.8407 89.8797 22.8666 89.7761 22.8666H86.6664C86.4936 22.8666 86.3899 22.7802 86.3554 22.6074L81.328 7.16251L76.482 22.6074C76.4302 22.7802 76.3179 22.8666 76.1452 22.8666H73.1132Z"
        fill={logoColor}
      />
      <defs>
        <clipPath id="clip0_5288_1145">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const HPIconSmall = () => {
  const theme = useTheme();
  const logoColor = theme.palette.foreground.default;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <g clipPath="url(#clip0_5288_1146)">
        <path
          d="M16 32C15.8409 32 15.6825 31.9971 15.5245 31.9925L18.7943 23.006H23.2943C24.0842 23.006 24.9512 22.3988 25.2214 21.657L28.7689 11.9129C29.3493 10.3176 28.4355 9.01235 26.7379 9.01235H20.495L15.2569 23.4061H15.2549L12.2854 31.5656C5.24031 29.8906 0 23.5576 0 16C0 8.66039 4.94224 2.47565 11.6802 0.590636L8.61607 9.01235H8.6152L3.52141 23.006L6.9124 23.0063L11.2444 11.1152H13.7928L9.46083 23.0063L12.8505 23.0069L16.8874 11.9129C17.4682 10.3176 16.5543 9.01235 14.8584 9.01235H12.0079L15.2809 0.0168148C15.5194 0.00625924 15.759 0 16 0C24.8366 0 32 7.16343 32 16C32 24.8365 24.8366 32 16 32ZM25.6809 11.1066H23.1332L19.5653 20.8961H22.1131L25.6809 11.1066Z"
          fill={logoColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_5288_1146">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
