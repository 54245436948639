export function generateRandomNumber(digitCount) {
  if (digitCount < 1) {
    return 0;
  }
  const min = Math.pow(10, digitCount - 1);
  const max = Math.pow(10, digitCount) - 1;
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const handleChange = (name, value, form) => {
  form.change(name, value);
};

export function findLastTrueWithAllPreviousTrue(arr) {
  let lastIndex = -1;
  arr.forEach((value, index) => {
    if (value && arr.slice(0, index).every((val) => val === true)) {
      lastIndex = index;
    }
  });
  return lastIndex !== -1 ? lastIndex : 0;
}
