import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HTTP_METHODS } from "../../common/constants";
import HTTPClient from "../../common/servicers/httpClient";
import { GALLERY_IMAGE_TYPE } from "./deschutesConstants";

export const getProvisioningPolicies = createAsyncThunk(
  "getProvisioningPolicies",
  async (
    { useCache = true, selectedTenant, organizationId },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loadingPolicies, provisioningPolicies } =
      getState().adminActions.provisioningPolicy;
    if (useCache && provisioningPolicies[selectedTenant]) {
      return { data: provisioningPolicies[selectedTenant], selectedTenant };
    }
    if (!loadingPolicies || requestId !== currentRequestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${selectedTenant}/provisioningPolicy`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);
export const deleteProvisioningPolicy = createAsyncThunk(
  "deleteProvisioningPolicy",
  async (
    { id, selectedTenant, organizationId },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loadingAction } =
      getState().adminActions.provisioningPolicy;
    if (!loadingAction || requestId !== currentRequestId) {
      return;
    }
    try {
      const policyId = id.split("/").pop();
      const siteName = id.split("/")[6];
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${selectedTenant}/sites/${siteName}/provisioningPolicy/${policyId}`,
        method: HTTP_METHODS.DELETE,
      }).callAuthorizedAPI();
      return { ...response.data, policyId: id, selectedTenant };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getProvisioningPolicy = createAsyncThunk(
  "getProvisioningPolicy",
  async (
    { payload, selectedTenant, organizationId },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loadingPolicy } =
      getState().adminActions.provisioningPolicy;
    if (!loadingPolicy || requestId !== currentRequestId) {
      return;
    }
    try {
      const policyId = payload.split("/").pop();
      const siteName = payload.split("/")[6];
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${selectedTenant}/sites/${siteName}/provisioningPolicy/${policyId}`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const updateProvisioningPolicy = createAsyncThunk(
  "updateProvisioningPolicy",
  async (
    { selectedTenant, region, data, organizationId },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loadingAction, selectedProvisioningPolicy } =
      getState().adminActions.provisioningPolicy;
    const { deviceImagesForSite, galleryImages } =
      getState().adminActions.deschutes;
    if (!loadingAction || requestId !== currentRequestId) {
      return;
    }
    const { id } = selectedProvisioningPolicy;
    const {
      imageType,
      deviceImage,
      policyName,
      policyDescription,
      connection,
      policyGroup,
    } = data;
    let imageDetails = {};
    if (imageType === GALLERY_IMAGE_TYPE) {
      const { imageId, imageDisplayName } = galleryImages.find(
        (image) => image.value === deviceImage,
      );
      imageDetails = {
        imageDisplayName,
        imageId,
        imageType,
      };
    } else {
      const { deviceImageId: imageId, displayName: imageDisplayName } =
        deviceImagesForSite[region].find((item) => item.value === deviceImage);
      imageDetails = { imageId, imageDisplayName, imageType };
    }
    const body = {
      displayName: policyName,
      description: policyDescription,
      onPremisesConnectionId: connection,
      groupIds: [...policyGroup.map((g) => g.groupId)],
      ...imageDetails,
    };
    const policyId = id.split("/").pop();
    const siteName = region;
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${selectedTenant}/sites/${siteName}/provisioningPolicy/${policyId}`,
        method: HTTP_METHODS.PUT,
        data: body,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant, connectionId: id };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const createProvisioningPolicy = createAsyncThunk(
  "createProvisioningPolicy",
  async (
    { selectedTenant, region, data, organizationId },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loadingAction } =
      getState().adminActions.provisioningPolicy;
    const { deviceImagesForSite, galleryImages } =
      getState().adminActions.deschutes;
    if (!loadingAction || requestId !== currentRequestId) {
      return;
    }
    const {
      imageType,
      deviceImage,
      policyName,
      policyDescription,
      selectedConnection,
      group,
    } = data;
    let imageDetails = {};
    if (imageType === GALLERY_IMAGE_TYPE) {
      const { imageId, imageDisplayName } = galleryImages.find(
        (image) => image.value === deviceImage,
      );
      imageDetails = {
        imageDisplayName,
        imageId,
        imageType,
      };
    } else {
      const { deviceImageId: imageId, displayName: imageDisplayName } =
        deviceImagesForSite[region].find((item) => item.value === deviceImage);
      imageDetails = { imageId, imageDisplayName, imageType };
    }
    const body = {
      displayName: policyName,
      description: policyDescription,
      onPremisesConnectionId: selectedConnection?.onPremisesConnectionId,
      groupIds: [...group.map((g) => g.groupId)],
      ...imageDetails,
    };
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${selectedTenant}/provisioningPolicy`,
        method: HTTP_METHODS.POST,
        data: body,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const initialState = {
  provisioningPolicies: {},
  loadingPolicy: false,
  loadingPolicies: false,
  loadingAction: false,
  deleteStatus: null,
  loaderText: null,
  selectedProvisioningPolicy: null,
  updateStatus: null,
  createStatus: null,
  selectedConnection: null,
};

export const provisioningPolicySlice = createSlice({
  name: "provisioningPolicy",
  initialState,
  reducers: {
    resetErrors: (state) => {
      state.error = null;
    },
    setProvsioningPolicyDeleteStatus: (state, action) => {
      state.deleteStatus = action.payload;
    },
    setProvsioningPolicyUpdateStatus: (state, action) => {
      state.updateStatus = action.payload;
    },
    setProvsioningPolicyCreateStatus: (state, action) => {
      state.createStatus = action.payload;
    },
    setSelectedProvisioningPolicy: (state, action) => {
      state.selectedProvisioningPolicy = action.payload;
    },
  },
  extraReducers: {
    [getProvisioningPolicies.pending]: (state, action) => {
      state.loadingPolicies = true;
      state.currentRequestId = action.meta.requestId;
    },
    [getProvisioningPolicies.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.loadingPolicies = false;
      state.provisioningPolicies[action.payload?.selectedTenant] = action
        .payload?.data
        ? action.payload.data.map((policy) => ({
            ...policy,
            name: policy.displayName,
          }))
        : null;
    },
    [getProvisioningPolicies.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.error = action.payload;
      state.loadingPolicies = false;
    },
    [deleteProvisioningPolicy.pending]: (state, action) => {
      state.loadingAction = true;
      state.currentRequestId = action.meta.requestId;
      state.deleteStatus = null;
      state.loaderText = "Deleting Policy...";
    },
    [deleteProvisioningPolicy.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.loadingAction = false;
      state.deleteStatus = true;
      state.loaderText = null;
      state.provisioningPolicies[action.payload?.selectedTenant] =
        state.provisioningPolicies[action.payload?.selectedTenant].filter(
          (i) => i.id !== action.payload.policyId,
        );
    },
    [deleteProvisioningPolicy.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.error = action.payload;
      state.loadingAction = false;
      state.deleteStatus = false;
      state.loaderText = null;
    },
    [getProvisioningPolicy.pending]: (state, action) => {
      state.loadingPolicy = true;
      state.currentRequestId = action.meta.requestId;
      state.loaderText = "Loading Policy";
    },
    [getProvisioningPolicy.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.loadingPolicy = false;
      state.loaderText = null;
      state.selectedProvisioningPolicy = {
        selectedRegion: action.payload?.data.id.split("/")[6],
        selectedDeviceImage: action.payload?.data.imageId,
        selectedImageType: action.payload?.data.imageType,
        policyName: action.payload?.data.displayName,
        policyDescription: action.payload?.data.description,
        selectedPolicyGroup: action.payload
          ? action.payload.data.assignments
            ? action.payload.data.assignments
            : []
          : [],
        selectedConnection: action.payload?.data.onPremisesConnectionId
          .split("/")
          .pop(),
        ...action.payload.data,
      };
    },
    [getProvisioningPolicy.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.error = action.payload;
      state.loadingPolicy = false;
      state.loaderText = null;
    },
    [updateProvisioningPolicy.pending]: (state, action) => {
      state.loadingAction = true;
      state.currentRequestId = action.meta.requestId;
      state.loaderText = "Updating Policy";
      state.updateStatus = null;
    },
    [updateProvisioningPolicy.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.loadingAction = false;
      state.loaderText = null;
      state.updateStatus = true;
      if (
        state.provisioningPolicies &&
        state.provisioningPolicies[action.payload.selectedTenant]
      ) {
        state.provisioningPolicies[action.payload.selectedTenant] =
          state.provisioningPolicies[action.payload.selectedTenant].map((i) => {
            if (i.id === action.payload.connectionId) {
              i = action.payload.data;
            }
            return i;
          });
      }
    },
    [updateProvisioningPolicy.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.error = action.payload;
      state.loadingAction = false;
      state.loaderText = null;
      state.updateStatus = false;
    },
    [createProvisioningPolicy.pending]: (state, action) => {
      state.loadingAction = true;
      state.currentRequestId = action.meta.requestId;
      state.loaderText = "Creating Policy";
      state.createStatus = null;
    },
    [createProvisioningPolicy.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.loadingAction = false;
      if (
        action.payload &&
        action.payload.data &&
        state.provisioningPolicies &&
        state.provisioningPolicies[action.payload.selectedTenant]
      ) {
        state.provisioningPolicies[action.payload.selectedTenant] = [
          ...state.provisioningPolicies[action.payload.selectedTenant],
          action.payload.data,
        ];
      }
      state.loaderText = null;
      state.createStatus = true;
    },
    [createProvisioningPolicy.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.error = action.payload;
      state.loadingAction = false;
      state.loaderText = null;
      state.createStatus = false;
    },
  },
});

export const {
  setProvsioningPolicyDeleteStatus,
  setSelectedProvisioningPolicy,
  setSelectedProvisioningPolicyGroup,
  setSelectedProvisioningPolicyDeviceImage,
  setSelectedProvisioningPolicyRegion,
  setProvsioningPolicyUpdateStatus,
  setSelectedImageType,
  setPolicyName,
  setPolicyDescription,
  setProvsioningPolicyCreateStatus,
  setSelectedProvisioningPolicyConnection,
  resetErrors,
} = provisioningPolicySlice.actions;

export default provisioningPolicySlice.reducer;
