import React from "react";
import ParentRoute from "../../common/components/ParentRoute";
import { ProtectedRouteSuspenseContainer } from "../../common/components/ProtectedRoute";
import {
  LAYOUTS,
  MODULES_LIST,
  PERMISSIONS_LIST,
  PERMISSIONS_TYPE,
} from "../../common/constants";
import DashBoardRouteTitle from "../../common/components/DashboardRouteTitle";
import { componentLoader } from "../../common/components/SuspenseComponent";

/*------Custom Actions Component------*/
const CustomActionsList = React.lazy(() =>
  componentLoader(() => import("./components/CustomActionsList")),
);

const DeleteCustomAction = React.lazy(() =>
  componentLoader(() => import("./components/DeleteCustomAction.jsx")),
);

const CustomActionsDetails = React.lazy(() =>
  componentLoader(() => import("./components/CustomActionsDetails")),
);

const CreateCustomActionsContainer = React.lazy(() =>
  componentLoader(() => import("./components/CreateCustomActionsContainer")),
);

const EditCustomActionsContainer = React.lazy(() =>
  componentLoader(() => import("./components/EditCustomActionsContainer")),
);

const CreateCustomActionsVersionContainer = React.lazy(() =>
  componentLoader(
    () => import("./components/CreateCustomActionsVersionContainer"),
  ),
);

const CustomActionsRoutes = [
  {
    path: "/custom-actions",
    authRequired: true,
    title: "Custom Actions",
    component: ParentRoute,
    routeTitleComponent: (props) => (
      <DashBoardRouteTitle
        title="Custom Actions"
        showAdminQuickActions={true}
        {...props}
      />
    ),
    permissionSet: [
      PERMISSIONS_LIST[`${MODULES_LIST.CUSTOM_ACTIONS}`][PERMISSIONS_TYPE.VIEW],
    ],
    layoutStyle: LAYOUTS.MENU_SCREEN,
    routes: [
      {
        path: "/custom-actions",
        exact: true,
        title: "Custom Actions",
        component: () => (
          <ProtectedRouteSuspenseContainer component={CustomActionsList} />
        ),
        permissionSet: [
          PERMISSIONS_LIST[`${MODULES_LIST.CUSTOM_ACTIONS}`][
            PERMISSIONS_TYPE.VIEW
          ],
        ],
      },
      {
        path: "/custom-actions/action/edit",
        exact: true,
        title: "Edit",
        component: () => (
          <ProtectedRouteSuspenseContainer
            component={EditCustomActionsContainer}
          />
        ),
        permissionSet: [
          PERMISSIONS_LIST[`${MODULES_LIST.CUSTOM_ACTIONS}`][
            PERMISSIONS_TYPE.EDIT
          ],
        ],
      },
      {
        path: "/custom-actions/action/edit/:customId",
        exact: true,
        title: "Edit",
        component: () => (
          <ProtectedRouteSuspenseContainer
            component={EditCustomActionsContainer}
          />
        ),
        permissionSet: [
          PERMISSIONS_LIST[`${MODULES_LIST.CUSTOM_ACTIONS}`][
            PERMISSIONS_TYPE.EDIT
          ],
        ],
      },
      {
        path: "/custom-actions/action/version/:customId/create",
        exact: true,
        title: "Create Version",
        component: () => (
          <ProtectedRouteSuspenseContainer
            component={CreateCustomActionsVersionContainer}
          />
        ),
        permissionSet: [
          PERMISSIONS_LIST[`${MODULES_LIST.CUSTOM_ACTIONS}`][
            PERMISSIONS_TYPE.EDIT
          ],
        ],
      },
      {
        path: "/custom-actions/action/detail/:customActionId",
        exact: true,
        title: "Custom Action",
        component: () => (
          <ProtectedRouteSuspenseContainer component={CustomActionsDetails} />
        ),
        permissionSet: [
          PERMISSIONS_LIST[`${MODULES_LIST.CUSTOM_ACTIONS}`][
            PERMISSIONS_TYPE.VIEW
          ],
        ],
      },
      {
        path: "/custom-actions/action/create",
        exact: true,
        title: "Create",
        component: () => (
          <ProtectedRouteSuspenseContainer
            component={CreateCustomActionsContainer}
          />
        ),
        permissionSet: [
          PERMISSIONS_LIST[`${MODULES_LIST.CUSTOM_ACTIONS}`][
            PERMISSIONS_TYPE.CREATE
          ],
        ],
      },
      {
        path: "/custom-actions/action/delete",
        exact: true,
        title: "Delete",
        component: () => (
          <ProtectedRouteSuspenseContainer component={DeleteCustomAction} />
        ),
        permissionSet: [
          PERMISSIONS_LIST[`${MODULES_LIST.CUSTOM_ACTIONS}`][
            PERMISSIONS_TYPE.DELETE
          ],
        ],
      },
    ],
  },
];

export default CustomActionsRoutes;
