import React from "react";
import ParentRoute from "../../common/components/ParentRoute";
import { ProtectedRouteSuspenseContainer } from "../../common/components/ProtectedRoute";
import {
  LAYOUTS,
  MODULES_LIST,
  PERMISSIONS_LIST,
  PERMISSIONS_TYPE,
} from "../../common/constants";
import DashBoardRouteTitle from "../../common/components/DashboardRouteTitle";
import { componentLoader } from "../../common/components/SuspenseComponent";
/*------Notification Profile Components------*/
const NotificationProfilesList = React.lazy(() =>
  componentLoader(() => import("./components/NotificationProfilesList")),
);

const CreateNotificationProfileContainer = React.lazy(() =>
  componentLoader(
    () => import("./components/CreateNotificationProfileContainer"),
  ),
);

const EditNotificationProfileContainer = React.lazy(() =>
  componentLoader(
    () => import("./components/EditNotificationProfileContainer"),
  ),
);

const DeleteNotificationProfile = React.lazy(() =>
  componentLoader(() => import("./components/DeleteNotificationProfile")),
);

const NotificationProfilesRoutes = [
  {
    path: "/notification-profiles",
    authRequired: true,
    component: ParentRoute,
    title: "Notification Profiles",
    routeTitleComponent: () => (
      <DashBoardRouteTitle
        title="Notification Profiles"
        showQuickActions={true}
      />
    ),
    permissionSet: [
      PERMISSIONS_LIST[MODULES_LIST.NOTIFICATION_PROFILES][
        PERMISSIONS_TYPE.VIEW
      ],
    ],
    layoutStyle: LAYOUTS.MENU_SCREEN,
    isTabs: true,
    onlyExactPathTabs: true,
    routes: [
      {
        path: "/notification-profiles",
        exact: true,
        title: "Notification Profiles",
        component: () => (
          <ProtectedRouteSuspenseContainer
            component={NotificationProfilesList}
          />
        ),
        permissionSet: [
          PERMISSIONS_LIST[MODULES_LIST.NOTIFICATION_PROFILES][
            PERMISSIONS_TYPE.VIEW
          ],
        ],
      },
      {
        path: "/notification-profiles/action/create",
        exact: true,
        title: "Create",
        component: () => (
          <ProtectedRouteSuspenseContainer
            component={CreateNotificationProfileContainer}
          />
        ),
        permissionSet: [
          PERMISSIONS_LIST[MODULES_LIST.NOTIFICATION_PROFILES][
            PERMISSIONS_TYPE.CREATE
          ],
        ],
      },
      {
        path: "/notification-profiles/action/edit",
        exact: true,
        title: "Edit",
        component: () => (
          <ProtectedRouteSuspenseContainer
            component={EditNotificationProfileContainer}
          />
        ),
        permissionSet: [
          PERMISSIONS_LIST[MODULES_LIST.NOTIFICATION_PROFILES][
            PERMISSIONS_TYPE.EDIT
          ],
        ],
      },
      {
        path: "/notification-profiles/action/edit/:profileId",
        exact: true,
        title: "Edit",
        component: () => (
          <ProtectedRouteSuspenseContainer
            component={EditNotificationProfileContainer}
          />
        ),
        permissionSet: [
          PERMISSIONS_LIST[MODULES_LIST.NOTIFICATION_PROFILES][
            PERMISSIONS_TYPE.EDIT
          ],
        ],
      },
      {
        path: "/notification-profiles/action/delete",
        exact: true,
        title: "Delete",
        component: () => (
          <ProtectedRouteSuspenseContainer
            component={DeleteNotificationProfile}
          />
        ),
        permissionSet: [
          PERMISSIONS_LIST[MODULES_LIST.NOTIFICATION_PROFILES][
            PERMISSIONS_TYPE.DELETE
          ],
        ],
      },
    ],
  },
];

export default NotificationProfilesRoutes;
