import React, { useEffect } from "react";
import { Box, Grid } from "@mui/material";
import ImageWithCallToAction from "./ImageWithCallToAction";
import { useHistory } from "react-router-dom";
import useCTA from "../hooks/useCTA";
import PropTypes from "prop-types";
import CurrentOnboardingSideProgress from "../../onboarding/components/CurrentOnboardingSideProgress";
import { useDispatch, useSelector } from "react-redux";
import { resetData } from "../../onboarding/onboardingSlice";
import { resetPool } from "../../admin-actions/pools/poolsSlice";
import { DASHBOARD_ROUTE } from "../../admin-actions/adminActionsConstants";
import { setPartner } from "../../common/partnersSlice";
import { getTenantPermissions } from "../../common/permissionsSlice";
import { getIdentityId } from "../../common/utils";
import { fetchPartners } from "../../common/partnersSlice";

const AVAILABLE_COMPONENTS = {
  "tenant-progress": CurrentOnboardingSideProgress,
};

const cleanup = (dispatch) => {
  dispatch(resetData());
  dispatch(resetPool());
};

const CTAPage = ({ children }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { data, shouldRender, clearCTA } = useCTA();
  const { selectedIdentity } = useSelector((state) => state.identities);

  const { tenant, tenantId } = useSelector((state) => state.onboarding);

  const hasSideContent = !!data.sideContent;
  const width = hasSideContent ? 8 : 12;
  const SideComponent = hasSideContent
    ? AVAILABLE_COMPONENTS[data.sideContent.component]
    : null;
  const sideProps =
    hasSideContent && !!data.sideContent.props ? data.sideContent.props : {};

  useEffect(() => {
    const onRouteChange = history.listen((next) => {
      if (shouldRender && next.pathname !== data.page) {
        clearCTA();
        cleanup(dispatch);
      }
    });

    return () => onRouteChange();
  }, [history, data.page, clearCTA, dispatch, shouldRender]);

  useEffect(() => {
    if (tenant && tenantId && selectedIdentity) {
      const allStepsDone = !tenant[tenantId].deploymentStatus.find(
        (x) => x.status !== "succeeded" && x.status !== "skipped",
      );

      if (allStepsDone) {
        clearCTA();
        cleanup(dispatch);
        dispatch(
          getTenantPermissions({
            useCache: true,
            selectedTenantId: tenantId,
            selectedOrganizationId: getIdentityId(selectedIdentity),
          }),
        );
        dispatch(
          fetchPartners({
            useCache: false,
            selectedOrganization: getIdentityId(selectedIdentity),
          }),
        );
        // setActiveTenant(tenantId);
        dispatch(setPartner(tenantId));
        history.push(DASHBOARD_ROUTE);
      }
    }
  }, [tenant, tenantId, selectedIdentity, clearCTA, dispatch, history]);

  if (!shouldRender) {
    return children;
  }

  return (
    <Grid container spacing={3} pb={3} sx={{ height: "80vh" }}>
      <Grid item xl={width} lg={width} md={width} sm={12} xs={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <ImageWithCallToAction {...data.content} />
        </Box>
      </Grid>

      {!!SideComponent && (
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <SideComponent {...sideProps} />
        </Grid>
      )}
    </Grid>
  );
};

CTAPage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.element,
    PropTypes.node,
    PropTypes.elementType,
  ]),
};

export default CTAPage;
