import { createApi } from "@reduxjs/toolkit/query/react";
import { HTTP_METHODS } from "../common/constants";
import RTKbaseQuery from "../common/servicers/RTKbaseQuery";

export const orderSummaryApi = createApi({
  reducerPath: "orderSummaryApi",
  baseQuery: RTKbaseQuery,
  endpoints: (builder) => ({
    getSubscriptionDetails: builder.query({
      query: (orderId) => {
        return {
          url: `/marketPlace/orderSummary?orderSummaryId=${orderId}`,
          method: HTTP_METHODS.GET,
          isUnauthorizedCall: true,
        };
      },
    }),
    resolvePurchaseRequest: builder.mutation({
      query: ({ orderId, payload }) => {
        return {
          url: `/marketPlace/orderSummary?orderSummaryId=${orderId}`,
          method: HTTP_METHODS.PUT,
          body: payload,
          isUnauthorizedCall: true,
        };
      },
    }),
    updateSourceReferrer: builder.mutation({
      query: ({ orderId, srcReferrer }) => {
        return {
          url: `/marketPlace/orderSummary?orderSummaryId=${orderId}`,
          method: HTTP_METHODS.PUT,
          body: {
            srcReferrer: srcReferrer,
          },
          isUnauthorizedCall: true,
        };
      },
    }),
  }),
});

export const {
  useGetSubscriptionDetailsQuery,
  useResolvePurchaseRequestMutation,
  useUpdateSourceReferrerMutation,
} = orderSummaryApi;
