import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import React from "react";
import Loader from "./Loader";

const PREFIX = "DialogWithLoader";

const classes = {
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
  closeButton: `${PREFIX}-closeButton`,
  title: `${PREFIX}-title`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.root}`]: {
    margin: 0,
    padding: theme.spacing(1, 2),
    boxShadow: "none",
  },

  [`& .${classes.paper}`]: {
    minWidth: "400px",
  },

  [`& .${classes.closeButton}`]: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(0.1),
    color: theme.palette.grey[500],
  },

  [`& .${classes.title}`]: {
    fontWeight: "500",
  },
  ["& .Loader-loaderText"]: {
    color: theme.palette.foreground.medium,
  },
}));

const DialogWithLoader = ({ open = false, ...props }) => {
  const { title, loaderText, onClose } = props;

  if (!open) {
    return null;
  }

  return (
    <StyledDialog
      classes={{ paper: classes.paper, root: classes.root }}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <MuiDialogTitle className={classes.root}>
        {title}

        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
      <MuiDialogContent dividers>
        <Loader loaderText={loaderText} />
      </MuiDialogContent>
    </StyledDialog>
  );
};

DialogWithLoader.propTypes = {
  title: PropTypes.string,
  loaderText: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

export default DialogWithLoader;
