import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HTTP_METHODS } from "../../common/constants";
import HTTPClient from "../../common/servicers/httpClient";

export const getConnections = createAsyncThunk(
  "getConnections",
  async (
    { useCache = true, selectedTenant, selectedConnectionId, organizationId },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loadingData, connections } =
      getState().adminActions.connections;
    if (useCache && connections[selectedTenant]) {
      return {
        data: connections[selectedTenant],
        selectedTenant,
        selectedConnectionId,
      };
    }
    if (!loadingData || requestId !== currentRequestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${selectedTenant}/connections`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant, selectedConnectionId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const createConnection = createAsyncThunk(
  "createConnection",
  async (
    { selectedPartner, organizationId },
    { getState, requestId, rejectWithValue },
  ) => {
    const {
      currentRequestId,
      selectedSite,
      connectionName,
      username,
      password,
      loadingAction,
    } = getState().adminActions.connections;
    if (!loadingAction || requestId !== currentRequestId) {
      return;
    }
    const body = {
      displayName: connectionName,
      adDomainUsername: username,
      adDomainPassword: password,
    };
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${selectedPartner}/sites/${selectedSite}/connections`,
        method: HTTP_METHODS.POST,
        data: body,
      }).callAuthorizedAPI();
      return { ...response.data, selectedPartner };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const updateConnection = createAsyncThunk(
  "updateConnection",
  async (
    { connectionSiteId, selectedTenant, organizationId },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, selectedConnection, loadingAction } =
      getState().adminActions.connections;
    if (!loadingAction || requestId !== currentRequestId) {
      return;
    }
    try {
      const connectionId = connectionSiteId.split("/").pop();
      const siteName = connectionSiteId.split("/")[6];
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${selectedTenant}/sites/${siteName}/connections/${connectionId}`,
        method: HTTP_METHODS.PUT,
        data: selectedConnection,
      }).callAuthorizedAPI();
      return {
        ...response.data,
        connectionId: connectionSiteId,
        connection: selectedConnection,
        selectedTenant,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteConnection = createAsyncThunk(
  "deleteConnection",
  async (
    { connectionSiteId, selectedTenant, organizationId },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loadingAction } =
      getState().adminActions.connections;
    if (!loadingAction || requestId !== currentRequestId) {
      return;
    }
    try {
      const connectionId = connectionSiteId.split("/").pop();
      const siteName = connectionSiteId.split("/")[6];
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${selectedTenant}/sites/${siteName}/connections/${connectionId}`,
        method: HTTP_METHODS.DELETE,
      }).callAuthorizedAPI();
      return {
        ...response.data,
        connectionId: connectionSiteId,
        selectedTenant,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const runHealthCheck = createAsyncThunk(
  "runHealthCheck",
  async (
    { payload, selectedTenant, organizationId },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loadingAction } =
      getState().adminActions.connections;
    if (!loadingAction || requestId !== currentRequestId) {
      return;
    }
    try {
      const connectionId = payload.split("/").pop();
      const siteName = payload.split("/")[6];
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${selectedTenant}/sites/${siteName}/connections/${connectionId}/runHealthCheck`,
        method: HTTP_METHODS.POST,
      }).callAuthorizedAPI();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchHealthCheckDetail = createAsyncThunk(
  "fetchHealthCheckDetail",
  async (
    { useCache = true, payload, selectedTenant, organizationId },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loadingData, healthCheckDetail } =
      getState().adminActions.connections;
    if (!loadingData || requestId !== currentRequestId) {
      return;
    }
    const connectionId = payload.split("/").pop();
    if (useCache && healthCheckDetail && healthCheckDetail[connectionId]) {
      return { data: healthCheckDetail[connectionId] };
    }
    try {
      const siteName = payload.split("/")[6];
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${selectedTenant}/sites/${siteName}/connections/${connectionId}/healthCheckStatusDetail`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const initialState = {
  loadingData: false,
  loadingAction: false,
  loadingHealthCheckDetailsAction: null,
  loaderText: null,
  selectedSite: null,
  credentialsType: "new",
  connectionName: null,
  username: null,
  password: null,
  createdConnection: null,
  connections: {},
  createStatus: null,
  selectedConnection: null,
  healthCheckDetail: {},
};

export const connectionsSlice = createSlice({
  name: "connections",
  initialState,
  reducers: {
    resetErrors: (state) => {
      state.error = null;
    },
    setSelectedSite: (state, action) => {
      state.selectedSite = action.payload;
    },
    setSelectedConnection: (state, action) => {
      state.selectedConnection = action.payload;
    },
    setUsername: (state, action) => {
      state.username = action.payload;
    },
    setPassword: (state, action) => {
      state.password = action.payload;
    },
    setCredentialsType: (state, action) => {
      state.credentialsType = action.payload;
    },
    setConnectionName: (state, action) => {
      state.connectionName = action.payload;
    },
  },
  extraReducers: {
    [getConnections.pending]: (state, action) => {
      state.loadingData = true;
      state.selectedConnection = null;
      state.currentRequestId = action.meta.requestId;
      state.deleteStatus = undefined;
      state.updateStatus = undefined;
      state.createStatus = undefined;
      state.selectedSite = null;
      state.credentialsType = "new";
      state.connectionName = null;
      state.username = null;
      state.password = null;
      state.createdConnection = null;
    },
    [getConnections.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.loadingData = false;
      state.connections[action.payload?.selectedTenant] = action.payload?.data
        ? action.payload.data.map((cn) => {
            const data = { ...cn, name: cn.displayName };
            if (
              action.payload?.selectedConnectionId === cn.onPremisesConnectionId
            ) {
              state.selectedConnection = data;
            }
            return data;
          })
        : null;
    },
    [getConnections.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.error = action.payload;
      state.loadingData = false;
    },
    [deleteConnection.pending]: (state, action) => {
      state.loadingAction = true;
      state.currentRequestId = action.meta.requestId;
      state.loaderText = "Deleting Connection";
      state.deleteStatus = null;
    },
    [deleteConnection.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.loadingAction = false;
      state.loaderText = null;
      state.deleteStatus = true;
      state.connections[action.payload?.selectedTenant] = state.connections[
        action.payload?.selectedTenant
      ].filter((i) => i.id !== action.payload.connectionId);
    },
    [deleteConnection.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.error = action.payload;
      state.loadingAction = false;
      state.loaderText = null;
      state.deleteStatus = false;
      state.deleteError = action.payload;
    },
    [updateConnection.pending]: (state, action) => {
      state.loadingAction = true;
      state.currentRequestId = action.meta.requestId;
      state.loaderText = "Updating Connection";
      state.updateStatus = null;
    },
    [updateConnection.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.loadingAction = false;
      state.loaderText = null;
      state.updateStatus = true;
      if (
        state.connections &&
        state.connections[action.payload.selectedTenant]
      ) {
        state.connections[action.payload.selectedTenant] = state.connections[
          action.payload.selectedTenant
        ].map((i) => {
          if (i.id === action.payload.connectionId) {
            i = action.payload.connection;
          }
          return i;
        });
      }
    },
    [updateConnection.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.error = action.payload;
      state.loadingAction = false;
      state.loaderText = null;
      state.updateStatus = false;
      state.updateError = action.payload;
    },
    [createConnection.pending]: (state, action) => {
      state.loadingAction = true;
      state.createdConnection = null;
      state.currentRequestId = action.meta.requestId;
      state.loaderText = "Creating Connection";
      state.createStatus = null;
    },
    [createConnection.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.loadingAction = false;
      if (
        action.payload &&
        action.payload.data &&
        state.connections &&
        state.connections[action.payload.selectedPartner]
      ) {
        action.payload.name = action.payload.displayName;
        state.connections[action.payload.selectedPartner] = [
          ...state.connections[action.payload.selectedPartner],
          action.payload.data,
        ];
      }
      state.createdConnection = action.payload?.data;
      state.loaderText = null;
      state.createStatus = true;
    },
    [createConnection.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.createdConnection = null;
      state.error = action.payload;
      state.loadingAction = false;
      state.loaderText = null;
      state.createStatus = false;
    },
    [fetchHealthCheckDetail.pending]: (state, action) => {
      state.loadingData = true;
      state.currentRequestId = action.meta.requestId;
      state.loadingHealthCheckDetailsAction = true;
      state.loaderText = "Fetching Health Check Detail";
    },
    [fetchHealthCheckDetail.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.loadingData = false;
      state.loadingHealthCheckDetailsAction = false;
      state.healthCheckDetail = action.payload?.data;
      if (
        state.connections &&
        state.connections[action.meta?.arg?.selectedTenant]
      ) {
        state.connections[action.meta?.arg?.selectedTenant] = state.connections[
          action.meta?.arg?.selectedTenant
        ].map((i) => {
          if (i.id === action.payload.data?.id) {
            i.healthCheckStatus = action.payload?.data?.healthCheckStatus;
          }
          return i;
        });
      }
      state.loaderText = null;
    },
    [fetchHealthCheckDetail.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.error = action.payload;
      state.loadingData = false;
      state.loadingHealthCheckDetailsAction = false;
      state.loaderText = null;
    },
    [runHealthCheck.pending]: (state, action) => {
      state.loadingAction = true;
      state.currentRequestId = action.meta.requestId;
      state.loaderText = "Running Health Check";
    },
    [runHealthCheck.fulfilled]: (state) => {
      state.currentRequestId = null;
      state.loadingAction = false;
      state.loaderText = null;
    },
    [runHealthCheck.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.error = action.payload;
      state.loadingAction = false;
      state.loaderText = null;
    },
  },
});

export const {
  resetErrors,
  setSelectedSite,
  setUsername,
  setPassword,
  setCredentialsType,
  setConnectionName,
  setSelectedConnection,
} = connectionsSlice.actions;

export default connectionsSlice.reducer;
