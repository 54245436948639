import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { HTTP_METHODS } from "../../common/constants";
import HTTPClient from "../../common/servicers/httpClient";

/**************************************
 *********** API ACTIONS ***************
 ***************************************/

export const getServerTemplates = createAsyncThunk(
  "getServerTemplates",
  async (
    { useCache, selectedTenant, organizationId, siteName },
    { getState, requestId, rejectWithValue },
  ) => {
    const { templatesRequestId, loadingTemplates, vmTemplates } =
      getState().adminActions.businessServer;
    if (!loadingTemplates || requestId !== templatesRequestId) {
      return;
    }
    if (
      useCache &&
      vmTemplates[selectedTenant] &&
      vmTemplates[selectedTenant][siteName]
    ) {
      return {
        data: vmTemplates[selectedTenant][siteName],
        selectedTenant,
        siteName,
      };
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${selectedTenant}/sites/${siteName}/virtualMachines/templates`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant, siteName };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const createVM = createAsyncThunk(
  "createVM",
  async (
    { selectedTenant, organizationId, siteName, virtualMachineName, payload },
    { getState, requestId, rejectWithValue },
  ) => {
    const { createVMRequestId, creatingVM } =
      getState().adminActions.businessServer;
    if (!creatingVM || requestId !== createVMRequestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${selectedTenant}/sites/${siteName}/virtualMachines/${virtualMachineName}`,
        method: HTTP_METHODS.POST,
        data: payload,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const updateVM = createAsyncThunk(
  "updateVM",
  async (
    { selectedTenant, organizationId, siteName, virtualMachineName, payload },
    { getState, requestId, rejectWithValue },
  ) => {
    const { updateVMRequestId, updatingVM } =
      getState().adminActions.businessServer;
    if (!updatingVM || requestId !== updateVMRequestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${selectedTenant}/sites/${siteName}/virtualMachines/${virtualMachineName}`,
        method: HTTP_METHODS.PUT,
        data: payload,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const initialState = {
  templatesRequestId: null,
  loadingTemplates: null,
  vmTemplates: {},
  errorFetchingTemplates: null,
  createVMRequestId: null,
  creatingVM: null,
  errorCreatingVM: null,
  createStatus: null,

  updateVMRequestId: null,
  updatingVM: null,
  errorUpdatingingVM: null,
  updateStatus: null,
};

export const businessServerSlice = createSlice({
  name: "businessServer",
  initialState,
  reducers: {
    resetErrors: (state) => {
      state.errorFetchingTemplates = null;
      state.loadingTemplates = null;
      state.errorCreatingVM = null;
      state.errorUpdatingingVM = null;
      state.createStatus = null;
      state.updateStatus = null;
    },
  },
  extraReducers: {
    [getServerTemplates.pending]: (state, action) => {
      state.loadingTemplates = true;
      state.templatesRequestId = action.meta.requestId;
    },
    [getServerTemplates.fulfilled]: (state, action) => {
      state.templatesRequestId = null;
      state.loadingTemplates = false;
      if (action.payload.selectedTenant && action.payload.siteName) {
        _.set(
          state.vmTemplates,
          `${action.payload?.selectedTenant}.${action.payload.siteName}`,
          action.payload.data,
        );
      }
    },
    [getServerTemplates.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.errorFetchingTemplates = action.payload || true;
      state.loading = false;
    },
    [createVM.pending]: (state, action) => {
      state.creatingVM = true;
      state.createVMRequestId = action.meta.requestId;
      state.createStatus = null;
    },
    [createVM.fulfilled]: (state) => {
      state.createStatus = true;
      state.creatingVM = false;
      state.createVMRequestId = null;
      state.errorCreatingVM = null;
    },
    [createVM.rejected]: (state, action) => {
      state.createVMRequestId = null;
      state.createStatus = false;
      state.creatingVM = false;
      state.errorCreatingVM = action.payload;
    },
    [updateVM.pending]: (state, action) => {
      state.updatingVM = true;
      state.updateVMRequestId = action.meta.requestId;
      state.updateStatus = null;
    },
    [updateVM.fulfilled]: (state) => {
      state.updateStatus = true;
      state.updatingVM = false;
      state.updateVMRequestId = null;
      state.errorUpdatingingVM = null;
    },
    [updateVM.rejected]: (state, action) => {
      state.updateVMRequestId = null;
      state.updateStatus = false;
      state.updatingVM = false;
      state.errorUpdatingingVM = action.payload;
    },
  },
});

export const templateSelector = (state) =>
  state.adminActions.businessServer.vmTemplates;

export const { resetErrors } = businessServerSlice.actions;

export default businessServerSlice.reducer;
