import { useTheme } from "@mui/material/styles";
import React from "react";

export const SkippedIcon = () => {
  const theme = useTheme();
  const subtractColor = theme.palette.foreground.default;

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon">
        <path
          id="Subtract"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.99992 14.6668C11.6818 14.6668 14.6666 11.682 14.6666 8.00016C14.6666 4.31826 11.6818 1.3335 7.99992 1.3335C4.31802 1.3335 1.33325 4.31826 1.33325 8.00016C1.33325 11.682 4.31802 14.6668 7.99992 14.6668ZM4.73483 4.73483C4.88128 4.58839 5.11872 4.58839 5.26517 4.73483L8.12374 7.59341C8.34829 7.81796 8.3483 8.18203 8.12374 8.40659L5.26517 11.2652C5.11872 11.4116 4.88128 11.4116 4.73483 11.2652C4.58839 11.1187 4.58839 10.8813 4.73483 10.7348L7.46967 8L4.73483 5.26517C4.58839 5.11872 4.58839 4.88128 4.73483 4.73483ZM8.76516 4.73483C8.61872 4.58839 8.38128 4.58839 8.23484 4.73483C8.08839 4.88128 8.08839 5.11872 8.23484 5.26517L10.9697 8L8.23484 10.7348C8.08839 10.8813 8.08839 11.1187 8.23484 11.2652C8.38128 11.4116 8.61872 11.4116 8.76516 11.2652L11.6237 8.40659C11.8483 8.18203 11.8483 7.81796 11.6237 7.59341L8.76516 4.73483Z"
          fill={subtractColor}
        />
      </g>
    </svg>
  );
};
