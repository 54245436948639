import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HTTP_METHODS } from "../common/constants";
import HTTPClient from "../common/servicers/httpClient";

/**************************************
 *********** API ACTIONS ***************
 ***************************************/

export const sampleGetCall = createAsyncThunk(
  "proxy-api/healthCheck",
  async (
    dataToBeUserdInAPIPayload,
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loading } = getState().azureB2C;
    if (!loading || requestId !== currentRequestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: "/healthcheck",
        method: HTTP_METHODS.GET,
      }).callUnauthorizedAPI({ sampleData: "sampleData" });
      return response.data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  },
);

/**************************************
 ************** Reducers ***************
 ***************************************/

export const azureB2CSlice = createSlice({
  name: "azureB2C",
  initialState: {
    token: "",
    requesting: false,
  },
  reducers: {
    setToken: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.token = action.payload;
      state.requesting = false;
    },
    setRequesting: (state, action) => {
      state.requesting = action.payload;
    },
  },
  extraReducers: {
    [sampleGetCall.pending]: (state, action) => {
      state.loading = true;
      state.currentRequestId = action.meta.requestId;
    },
    [sampleGetCall.fulfilled]: (state) => {
      state.loading = false;
      state.currentRequestId = null;
    },
    [sampleGetCall.rejected]: (state) => {
      state.loading = false;
      state.currentRequestId = null;
    },
  },
});

/**************************************
 ************** ACTIONS ****************
 ***************************************/

export const { setToken } = azureB2CSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
// export const ExampleOfAsyncAction = payload => dispatch => {
//   setTimeout(() => {
//     dispatch(actionDefinedAbove(payload));
//   }, 1000);
// };

/**************************************
 ************** Selectors **************
 ***************************************/

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectToken = (state) => state.azureB2C.token;

export default azureB2CSlice.reducer;
