import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import _ from "lodash";
import { HTTP_METHODS } from "../../common/constants";
import HTTPClient from "../../common/servicers/httpClient";

export const getSpotGroups = createAsyncThunk(
  "getSpotGroups",
  async (
    { useCache = true, selectedOrganization, selectedTenant },
    { getState, requestId, rejectWithValue },
  ) => {
    const { getSpotGroupsRequestId, loading, spotGroupsAvailable, spotGroups } =
      getState().adminActions.spotGroup;
    if (!loading || requestId !== getSpotGroupsRequestId) {
      return;
    }
    if (useCache && spotGroupsAvailable[selectedTenant]) {
      return {
        data: spotGroups[selectedTenant],
        selectedTenant,
        cachedData: true,
      };
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${selectedOrganization}/tenants/${selectedTenant}/spotGroups`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const editSpotGroup = createAsyncThunk(
  "editSpotGroup",
  async (
    { selectedOrganization, selectedTenant, siteName, spotGroupName, data },
    { getState, requestId, rejectWithValue },
  ) => {
    const { loadingAction, currentRequestId } =
      getState().adminActions.spotGroup;
    if (!loadingAction || requestId !== currentRequestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${selectedOrganization}/tenants/${selectedTenant}/sites/${siteName}/spotGroups/${spotGroupName}`,
        method: HTTP_METHODS.PUT,
        data: data,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant, spotGroupName };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getSpotGroupDetail = createAsyncThunk(
  "getSpotGroupDetail",
  async (
    {
      useCache = true,
      selectedOrganization,
      selectedTenant,
      siteName,
      spotGroupName,
    },
    { getState, requestId, rejectWithValue },
  ) => {
    const {
      loadingSpotGroupDetail,
      getSpotGroupDetailReuestId,
      spotGroupDetail,
    } = getState().adminActions.spotGroup;
    if (!loadingSpotGroupDetail || requestId !== getSpotGroupDetailReuestId) {
      return;
    } else if (
      useCache &&
      spotGroupDetail[selectedTenant] &&
      spotGroupDetail[selectedTenant][spotGroupName]
    ) {
      return {
        data: spotGroupDetail[selectedTenant][spotGroupName],
        selectedTenant,
        spotGroupName,
        cachedDetailData: true,
      };
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${selectedOrganization}/tenants/${selectedTenant}/sites/${siteName}/spotGroups/${spotGroupName}`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { data: response.data?.data, selectedTenant, spotGroupName };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const updateSpotGroup = createAsyncThunk(
  "updateSpotGroup",
  async (
    {
      selectedOrganization,
      selectedTenant,
      siteName,
      spotGroupName,
      imageSet,
      imageSetVersion,
      releaseMethod,
      scheduledTimeUTC,
      forceUsersOffInMinutes,
    },
    { getState, requestId, rejectWithValue },
  ) => {
    const { loadingAction, currentRequestId } =
      getState().adminActions.spotGroup;
    if (!loadingAction || requestId !== currentRequestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${selectedOrganization}/tenants/${selectedTenant}/sites/${siteName}/spotGroups/${spotGroupName}/imageSets/${imageSet}/imageSetVersions/${imageSetVersion}?releaseMethod=${releaseMethod}&scheduledTimeUTC=${scheduledTimeUTC}&forceUsersOffInMinutes=${forceUsersOffInMinutes}`,
        method: HTTP_METHODS.PUT,
      }).callAuthorizedAPI();
      return {
        ...response.data,
        selectedTenant,
        spotGroupName,
        imageSetVersion,
        imageSet,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getUserGroups = createAsyncThunk(
  "getUserGroups",
  async (
    { useCache = true, selectedOrganization, selectedTenant },
    { getState, requestId, rejectWithValue },
  ) => {
    const { userGroupsRequestId, loadingUserGroups, userGroups } =
      getState().adminActions.spotGroup;
    if (!loadingUserGroups || requestId !== userGroupsRequestId) {
      return;
    }
    if (useCache && userGroups[selectedTenant]) {
      return { data: userGroups[selectedTenant], selectedTenant };
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${selectedOrganization}/tenants/${selectedTenant}/groups`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getSpotGroupsTypes = createAsyncThunk(
  "getSpotGroupsTypes",
  async ({ useCache = true }, { getState, requestId, rejectWithValue }) => {
    const {
      getSpotGroupsTypesRequestId,
      loadingSpotGroupTypes,
      spotGroupsTypes,
    } = getState().adminActions.spotGroup;
    if (!loadingSpotGroupTypes || requestId !== getSpotGroupsTypesRequestId) {
      return;
    }
    if (useCache && spotGroupsTypes.length > 0) {
      return { data: spotGroupsTypes };
    }
    try {
      const response = await new HTTPClient({
        endpoint: "/spotGroups/types",
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);
export const getSessionHostsForSpotGroup = createAsyncThunk(
  "getSessionHostsForSpotGroup",
  async (
    {
      useCache = true,
      selectedOrganization,
      selectedTenant,
      siteName,
      spotGroupName,
    },
    { getState, requestId, rejectWithValue },
  ) => {
    const {
      getSessionHostsRequestId,
      gettingSessionHostsForSpotGroup,
      sessionHostsAvailable,
    } = getState().adminActions.spotGroup;
    if (
      !gettingSessionHostsForSpotGroup ||
      requestId !== getSessionHostsRequestId
    ) {
      return;
    }
    if (
      useCache &&
      sessionHostsAvailable[selectedTenant] &&
      sessionHostsAvailable[selectedTenant][spotGroupName]
    ) {
      return {
        data: sessionHostsAvailable[selectedTenant][spotGroupName],
        selectedTenant,
        spotGroupName,
        cachedData: true,
      };
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${selectedOrganization}/tenants/${selectedTenant}/sites/${siteName}/spotGroups/${spotGroupName}/sessionHosts`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant, spotGroupName };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getLicenses = createAsyncThunk(
  "getLicenses",
  async (
    { useCache = true, selectedTenant, organizationId },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loadingLicense, licenses } =
      getState().adminActions.spotGroup;
    if (!loadingLicense || requestId !== currentRequestId) {
      return;
    }
    if (useCache && licenses && licenses[selectedTenant]) {
      return { data: licenses[selectedTenant], selectedTenant };
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${selectedTenant}/licenses`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getOrgLicenses = createAsyncThunk(
  "getOrgLicenses",
  async (
    { useCache = true, organizationId },
    { getState, requestId, rejectWithValue },
  ) => {
    const {
      getOrgLicensesRequestId,
      loadingOrgLicense,
      licenses,
      licensesForOrgAvailable,
    } = getState().adminActions.spotGroup;
    if (!loadingOrgLicense || requestId !== getOrgLicensesRequestId) {
      return;
    }
    if (useCache && licenses && licensesForOrgAvailable[organizationId]) {
      return { data: licenses };
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/licenses`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, organizationId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getSpotGroupSiteLocations = createAsyncThunk(
  "getSpotGroupSiteLocations",
  async (
    { useCache = true, organizationId, selectedTenant, siteName },
    { getState, requestId, rejectWithValue },
  ) => {
    const {
      getSpotGroupsSiteLocationsRequestId,
      loadingSpotGroupSiteLocations,
      spotGroupsSiteLocations,
    } = getState().adminActions.spotGroup;
    if (
      !loadingSpotGroupSiteLocations ||
      requestId !== getSpotGroupsSiteLocationsRequestId
    ) {
      return;
    }
    if (
      useCache &&
      spotGroupsSiteLocations[selectedTenant] &&
      spotGroupsSiteLocations[selectedTenant][siteName]
    ) {
      return {
        data: spotGroupsSiteLocations[selectedTenant][siteName],
        selectedTenant,
        siteName,
      };
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${selectedTenant}/sites/${encodeURI(
          siteName,
        )}/spotGroups/locations`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant, siteName };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const createSpotGroup = createAsyncThunk(
  "createSpotGroup",
  async (
    { organizationId, selectedTenant, siteName, spotGroupName, payload },
    { getState, requestId, rejectWithValue },
  ) => {
    const { createSpotGroupRequestId, creatingSpotGroup } =
      getState().adminActions.spotGroup;
    if (!creatingSpotGroup || requestId !== createSpotGroupRequestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${selectedTenant}/sites/${siteName}/spotGroups/${spotGroupName}`,
        method: HTTP_METHODS.POST,
        data: payload,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant, siteName, spotGroupName };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteSpotGroup = createAsyncThunk(
  "deleteSpotGroup",
  async (
    { spotGroupName, siteName, selectedOrganization, selectedTenant },
    { getState, requestId, rejectWithValue },
  ) => {
    const { deleteSpotGroupRequestId, loadingAction } =
      getState().adminActions.spotGroup;
    if (!loadingAction || requestId !== deleteSpotGroupRequestId) {
      return;
    }
    try {
      await new HTTPClient({
        endpoint: `/organizations/${selectedOrganization}/tenants/${selectedTenant}/sites/${siteName}/spotGroups/${spotGroupName}`,
        method: HTTP_METHODS.DELETE,
      }).callAuthorizedAPI();
      return { spotGroupName, selectedTenant };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteImageUpdateScheduleForSpotGroup = createAsyncThunk(
  "deleteImageUpdateScheduleForSpotGroup",
  async (
    { spotGroupName, siteName, selectedOrganization, selectedTenant },
    { getState, requestId, rejectWithValue },
  ) => {
    const { deleteScheduleRequestId, deletingSchedule } =
      getState().adminActions.spotGroup;
    if (!deletingSchedule || requestId !== deleteScheduleRequestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${selectedOrganization}/tenants/${selectedTenant}/sites/${siteName}/spotGroups/${spotGroupName}/imageSets/scheduled`,
        method: HTTP_METHODS.DELETE,
      }).callAuthorizedAPI();
      return { ...response.data, spotGroupName, selectedTenant };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const assignUsersToSessionHost = createAsyncThunk(
  "assignUsersToSessionHost",
  async (
    {
      selectedTenant,
      organizationId,
      siteName,
      spotGroupName,
      sessionHostName,
      userId,
    },
    { getState, requestId, rejectWithValue },
  ) => {
    const { assignUserRequestId, assigningUserToSessionHost } =
      getState().adminActions.spotGroup;
    if (!assigningUserToSessionHost || requestId !== assignUserRequestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${selectedTenant}/sites/${siteName}/spotGroups/${spotGroupName}/sessionHosts/${sessionHostName}/assignedUser/${userId}`,
        method: HTTP_METHODS.PUT,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant, spotGroupName };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const initialState = {
  userGroups: {},
  loadingUserGroups: null,
  userGroupsRequestId: null,
  userGroupsError: null,
  refreshSpotGroups: false,
  silentRefresh: false,

  spotGroups: {},
  spotGroupsAvailable: {},
  spotGroupDetail: {},
  loadingSpotGroupDetail: null,
  getSpotGroupDetailReuestId: null,
  loading: null,
  loadingAction: null,
  getSpotGroupsRequestId: null,
  deleteSpotGroupRequestId: null,
  deleteSpotGroupError: null,
  error: null,
  editSpotGroupError: null,
  updateSpotGroupError: null,
  loadingSpotGroupTypes: null,
  spotGroupsTypes: [],
  getSpotGroupsTypesRequestId: null,
  getSpotGroupsTypesError: null,

  selectedSpotGroup: null,
  spotGroupsSiteLocations: {},
  loadingSpotGroupSiteLocations: null,
  getSpotGroupsSiteLocationsRequestId: null,
  getSpotGroupsSiteLocationsError: null,

  licenses: {},
  loadingLicense: false,
  licensesForOrgAvailable: {},
  loadingOrgLicense: null,
  getOrgLicensesRequestId: null,
  totalLicenseCount: {},

  creatingSpotGroup: null,
  createSpotGroupRequestId: null,
  createSpotGroupError: null,
  createStatus: null,

  getSessionHostsRequestId: null,
  gettingSessionHostsForSpotGroup: null,
  getSessionHostsError: null,
  spotGroupSessionHosts: {},
  sessionHostsAvailable: {},

  assignUserRequestId: null,
  assigningUserToSessionHost: null,
  assignUserError: null,
  assignUserSuccess: null,

  deleteScheduleRequestId: null,
  deletingSchedule: null,
  deleteScheduleSuccess: null,
  deleteScheduleError: null,
};

export const spotGroupSlice = createSlice({
  name: "spotGroup",
  initialState,
  reducers: {
    setSelectedSpotGroup: (state, action) => {
      state.selectedSpotGroup = action.payload.selectedSpotGroup;
    },
    resetSelectedSpotGroup: (state) => {
      state.selectedSpotGroup = null;
    },
    resetSpotGroupErrors: (state) => {
      state.deleteSpotGroupError = null;
      state.editSpotGroupError = null;
      state.updateSpotGroupError = null;
      state.createStatus = null;
      state.assignUserError = null;
      state.assignUserSuccess = null;
      state.deleteScheduleError = null;
      state.deleteScheduleSuccess = null;
    },
    clearSpotGroups: (state, action) => {
      if (action.payload !== undefined && action.payload.tenantId) {
        if (!action.payload.silentRefresh) {
          state.spotGroups[action.payload.tenantId] = [];
        } else {
          state.silentRefresh = true;
        }
        state.refreshSpotGroups = true;
        state.spotGroupsAvailable[action.payload.tenantId] = false;
      }
    },
  },
  extraReducers: {
    [getSpotGroups.pending]: (state, action) => {
      state.loading = true;
      state.refreshSpotGroups = false;
      state.getSpotGroupsRequestId = action.meta.requestId;
      state.error = null;
    },
    [getSpotGroups.fulfilled]: (state, action) => {
      state.getSpotGroupsRequestId = null;
      state.loading = false;
      state.silentRefresh = false;
      state.error = null;
      if (action.payload.selectedTenant && !action.payload.cachedData) {
        const data = action.payload?.data.map((group) => ({
          ...group,
          value: group.name,
          name: group.name + " - " + group.properties.friendlyName,
        }));
        _.set(state.spotGroups, `${action.payload.selectedTenant}`, data);
        _.set(
          state.spotGroupsAvailable,
          `${action.payload.selectedTenant}`,
          true,
        );
      }
    },
    [getSpotGroups.rejected]: (state, action) => {
      state.getSpotGroupsRequestId = null;
      state.silentRefresh = false;
      state.error = action.payload;
      state.loading = false;
    },
    [getSpotGroupDetail.pending]: (state, action) => {
      state.loadingSpotGroupDetail = true;
      state.refreshSpotGroups = false;
      state.getSpotGroupDetailReuestId = action.meta.requestId;
      state.error = null;
    },
    [getSpotGroupDetail.fulfilled]: (state, action) => {
      state.getSpotGroupDetailReuestId = null;
      state.loadingSpotGroupDetail = false;
      state.silentRefresh = false;
      state.error = null;
      if (action?.payload?.selectedTenant) {
        if (action.payload.cachedData) {
          action.payload?.data.forEach((spotGroup) => {
            if (spotGroup.value === action.payload.spotGroupName) {
              _.set(
                state.spotGroupDetail,
                `${action.payload.selectedTenant}.${action.payload.spotGroupName}`,
                spotGroup,
              );
            }
          });
        } else if (!action?.payload?.cachedDetailData) {
          _.set(
            state.spotGroupDetail,
            `${action.payload.selectedTenant}.${action.payload.spotGroupName}`,
            { ...action.payload?.data, value: action.payload?.data?.name },
          );
        }
      }
    },
    [getSpotGroupDetail.rejected]: (state, action) => {
      state.getSpotGroupDetailReuestId = null;
      state.silentRefresh = false;
      state.error = action.payload;
      state.loadingSpotGroupDetail = false;
    },
    [getSessionHostsForSpotGroup.pending]: (state, action) => {
      state.gettingSessionHostsForSpotGroup = true;
      state.getSessionHostsRequestId = action.meta.requestId;
      state.getSessionHostsError = null;
    },
    [getSessionHostsForSpotGroup.fulfilled]: (state, action) => {
      state.getSessionHostsRequestId = null;
      state.gettingSessionHostsForSpotGroup = false;
      state.getSessionHostsError = null;
      if (action.payload.selectedTenant && !action.payload.cachedData) {
        const sessionHosts = action.payload?.data;
        let assignedUserList = [];
        sessionHosts.forEach((s) => {
          if (s.properties?.assignedUser) {
            assignedUserList.push(s.properties?.assignedUser);
          }
        });
        _.set(
          state.spotGroupSessionHosts,
          `${action.payload.selectedTenant}.${action.payload.spotGroupName}`,
          { sessionHosts, assignedUserList },
        );
        _.set(
          state.sessionHostsAvailable,
          `${action.payload.selectedTenant}.${action.payload.spotGroupName}`,
          true,
        );
      }
    },
    [getSessionHostsForSpotGroup.rejected]: (state, action) => {
      state.getSessionHostsRequestId = null;
      state.getSessionHostsError = action.payload;
      state.gettingSessionHostsForSpotGroup = false;
    },
    [assignUsersToSessionHost.pending]: (state, action) => {
      state.assigningUserToSessionHost = true;
      state.assignUserRequestId = action.meta.requestId;
      state.assignUserError = null;
      state.assignUserSuccess = null;
    },
    [assignUsersToSessionHost.fulfilled]: (state, action) => {
      state.assignUserRequestId = null;
      state.assigningUserToSessionHost = false;
      state.assignUserError = null;
      if (action.payload.selectedTenant) {
        const response = action.payload?.data;
        const sessionHosts = current(
          _.get(
            state.spotGroupSessionHosts,
            `${action.payload.selectedTenant}.${action.payload.spotGroupName}.sessionHosts`,
          ),
        );
        const sessionHostsList = [];
        let assignedUserList = [];
        sessionHosts.forEach((s) => {
          if (s.id === response.id) {
            sessionHostsList.push(response);
          } else {
            sessionHostsList.push(s);
          }
          if (response.properties.assignedUser) {
            state.assignUserSuccess = `User assigned to ${response.name}`;
            assignedUserList.push(response.properties?.assignedUser);
          } else {
            state.assignUserSuccess = `User unassigned from ${response.name}`;
          }
        });
        _.set(
          state.spotGroupSessionHosts,
          `${action.payload.selectedTenant}.${action.payload.spotGroupName}`,
          { sessionHosts: sessionHostsList, assignedUserList },
        );
      }
    },
    [assignUsersToSessionHost.rejected]: (state, action) => {
      state.assignUserRequestId = null;
      state.assignUserError = action.payload;
      state.assigningUserToSessionHost = false;
      state.assignUserSuccess = null;
    },
    [deleteImageUpdateScheduleForSpotGroup.pending]: (state, action) => {
      state.deletingSchedule = true;
      state.deleteScheduleRequestId = action.meta.requestId;
      state.deleteScheduleError = null;
      state.deleteScheduleSuccess = null;
    },
    [deleteImageUpdateScheduleForSpotGroup.fulfilled]: (state, action) => {
      state.deleteScheduleRequestId = null;
      state.deletingSchedule = false;
      state.deleteScheduleError = null;
      state.deleteScheduleSuccess = true;
      state.spotGroups[action.payload?.selectedTenant] = state.spotGroups[
        action.payload.selectedTenant
      ].map((spotGroup) => {
        if (spotGroup.value === action.payload.spotGroupName) {
          if (action.payload.data?.imageSetProperties?.imageSetHistory) {
            spotGroup.imageSetProperties.imageSetHistory =
              action.payload.data?.imageSetProperties?.imageSetHistory;
          }
        }
        return spotGroup;
      });
      const spotGroupInDetails = _.get(
        state.spotGroupDetail,
        `${action.payload.selectedTenant}.${action.payload.spotGroupName}`,
      );
      if (spotGroupInDetails) {
        state.spotGroupDetail[action.payload.selectedTenant][
          action.payload.spotGroupName
        ].imageSetProperties.imageSetHistory =
          action.payload.data?.imageSetProperties?.imageSetHistory;
      }
    },
    [deleteImageUpdateScheduleForSpotGroup.rejected]: (state, action) => {
      state.deleteScheduleRequestId = null;
      state.deleteScheduleError = action.payload;
      state.deleteScheduleSuccess = false;
      state.deletingSchedule = null;
    },
    [deleteSpotGroup.pending]: (state, action) => {
      state.loadingAction = true;
      state.deleteSpotGroupRequestId = action.meta.requestId;
      state.deleteSpotGroupError = null;
    },
    [deleteSpotGroup.fulfilled]: (state, action) => {
      state.deleteSpotGroupRequestId = null;
      state.loadingAction = false;
      state.deleteSpotGroupError = "";
      state.spotGroups[action.payload?.selectedTenant] = state.spotGroups[
        action.payload?.selectedTenant
      ].filter((i) => i.value !== action.payload.spotGroupName);
      _.unset(
        state.spotGroupDetail,
        `${action.payload.selectedTenant}.${action.payload.spotGroupName}`,
      );
    },
    [deleteSpotGroup.rejected]: (state, action) => {
      state.deleteSpotGroupRequestId = null;
      state.deleteSpotGroupError = action.payload;
      state.loadingAction = false;
    },
    [getLicenses.pending]: (state, action) => {
      state.loadingLicense = true;
      state.currentRequestId = action.meta.requestId;
      state.error = null;
    },
    [getLicenses.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.loadingLicense = false;
      if (action.payload.selectedTenant && action.payload?.data?.licenses) {
        const data = action.payload?.data?.licenses.map((license) => ({
          ...license,
          value: license.subscriptionId,
          name: license.subscriptionName,
        }));
        _.set(state.licenses, `${action.payload.selectedTenant}`, data);
      }
    },
    [getLicenses.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.error = action.payload;
      state.loadingLicense = false;
    },
    [getOrgLicenses.pending]: (state, action) => {
      state.loadingOrgLicense = true;
      state.getOrgLicensesRequestId = action.meta.requestId;
      state.error = null;
    },
    [getOrgLicenses.fulfilled]: (state, action) => {
      state.getOrgLicensesRequestId = null;
      state.loadingOrgLicense = false;
      if (action.payload.organizationId && action.payload?.data) {
        const tenantListWithLicenses = action.payload.data;
        let totalLicenseQty = 0;
        tenantListWithLicenses.forEach((tenant) => {
          const tenantId = tenant.tenantId;
          const licenses = tenant.licenses.map((license) => ({
            ...license,
            value: license.subscriptionId,
            name: license.subscriptionName,
          }));
          state.licenses[tenantId] = licenses;
          totalLicenseQty += tenant.totalUserQTY;
        });
        state.totalLicenseCount[action.payload.organizationId] =
          totalLicenseQty;
        state.licensesForOrgAvailable[action.payload.organizationId] = true;
      }
    },
    [getOrgLicenses.rejected]: (state, action) => {
      state.getOrgLicensesRequestId = null;
      state.error = action.payload;
      state.loadingOrgLicense = false;
    },
    [editSpotGroup.pending]: (state, action) => {
      state.loadingAction = true;
      state.currentRequestId = action.meta.requestId;
      state.editSpotGroupError = null;
    },
    [editSpotGroup.fulfilled]: (state, action) => {
      state.loadingAction = false;
      state.currentRequestId = null;
      state.editSpotGroupError = "";
      state.spotGroups[action.payload?.selectedTenant] = state.spotGroups[
        action.payload.selectedTenant
      ].map((spotGroup) => {
        if (spotGroup.value === action.payload.spotGroupName) {
          spotGroup.properties = action.payload.data?.properties;
          spotGroup.license = action.payload.data?.license;
          spotGroup.profileProperties = action.payload.data?.profileProperties;
          spotGroup.groups = action.payload.data?.groups;
          spotGroup.value = action.payload.data?.name;
          spotGroup.name =
            action.payload.data?.name +
            " - " +
            action.payload.data?.properties?.friendlyName;
          spotGroup.tags = action.payload.data?.tags;
          _.set(
            state.spotGroupDetail,
            `${action.payload.selectedTenant}.${action.payload.spotGroupName}`,
            spotGroup,
          );
        }

        return spotGroup;
      });
    },
    [editSpotGroup.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.editSpotGroupError = action.payload;
      state.loadingAction = false;
    },
    [updateSpotGroup.pending]: (state, action) => {
      state.loadingAction = true;
      state.currentRequestId = action.meta.requestId;
      state.updateSpotGroupError = null;
    },
    [updateSpotGroup.fulfilled]: (state, action) => {
      state.loadingAction = false;
      state.currentRequestId = null;
      state.updateSpotGroupError = "";
      state.spotGroups[action.payload?.selectedTenant] = state.spotGroups[
        action.payload.selectedTenant
      ].map((spotGroup) => {
        if (spotGroup.value === action.payload.spotGroupName) {
          if (action.payload.data?.imageSetProperties?.imageSetHistory) {
            spotGroup.imageSetProperties.imageSetHistory =
              action.payload.data?.imageSetProperties?.imageSetHistory;
          }
          spotGroup.imageSetProperties.version =
            action.payload?.data?.imageSetProperties?.version;
          spotGroup.imageSetProperties.name =
            action.payload?.data?.imageSetProperties?.name;
          _.set(
            state.spotGroupDetail,
            `${action.payload.selectedTenant}.${action.payload.spotGroupName}`,
            spotGroup,
          );
        }
        return spotGroup;
      });
    },
    [updateSpotGroup.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.updateSpotGroupError = action.payload;
      state.loadingAction = false;
    },
    [getUserGroups.pending]: (state, action) => {
      state.loadingUserGroups = true;
      state.userGroupsRequestId = action.meta.requestId;
      state.userGroupsError = null;
    },
    [getUserGroups.fulfilled]: (state, action) => {
      state.userGroupsRequestId = null;
      state.loadingUserGroups = false;
      state.userGroupsError = null;
      if (action.payload.selectedTenant) {
        _.set(
          state.userGroups,
          `${action.payload.selectedTenant}`,
          action.payload.data,
        );
      }
    },
    [getUserGroups.rejected]: (state, action) => {
      state.userGroupsRequestId = null;
      state.userGroupsError = action.payload;
      state.loadingUserGroups = false;
    },
    [getSpotGroupsTypes.pending]: (state, action) => {
      state.loadingSpotGroupTypes = true;
      state.getSpotGroupsTypesRequestId = action.meta.requestId;
      state.getSpotGroupsTypesError = null;
    },
    [getSpotGroupsTypes.fulfilled]: (state, action) => {
      state.getSpotGroupsTypesRequestId = null;
      state.loadingSpotGroupTypes = false;
      state.getSpotGroupsTypesError = "";
      state.spotGroupsTypes = action.payload.data;
    },
    [getSpotGroupsTypes.rejected]: (state, action) => {
      state.getSpotGroupsRequestId = null;
      state.getSpotGroupsTypesError = action.payload;
      state.loadingSpotGroupTypes = false;
    },
    [getSpotGroupSiteLocations.pending]: (state, action) => {
      state.loadingSpotGroupSiteLocations = true;
      state.getSpotGroupsSiteLocationsRequestId = action.meta.requestId;
      state.getSpotGroupsSiteLocationsError = "";
    },
    [getSpotGroupSiteLocations.fulfilled]: (state, action) => {
      state.getSpotGroupsSiteLocationsRequestId = null;
      state.loadingSpotGroupSiteLocations = false;
      state.getSpotGroupsSiteLocationsError = "";
      if (action.payload.selectedTenant && action.payload.siteName) {
        _.set(
          state.spotGroupsSiteLocations,
          `${action.payload.selectedTenant}.${action.payload.siteName}`,
          action.payload.data,
        );
      }
    },
    [getSpotGroupSiteLocations.rejected]: (state, action) => {
      state.getSpotGroupsSiteLocationsRequestId = null;
      state.getSpotGroupsSiteLocationsError = action.payload;
      state.loadingSpotGroupSiteLocations = false;
    },
    [createSpotGroup.pending]: (state, action) => {
      state.creatingSpotGroup = true;
      state.createSpotGroupRequestId = action.meta.requestId;
      state.createSpotGroupError = "";
      state.createStatus = null;
    },
    [createSpotGroup.fulfilled]: (state, action) => {
      state.createSpotGroupRequestId = null;
      state.creatingSpotGroup = false;
      state.createStatus = true;
      state.createSpotGroupError = "";
      if (state.spotGroups[action.payload.selectedTenant]) {
        state.spotGroups[action.payload.selectedTenant].push({
          ...action.payload.data,
          value: action.payload.spotGroupName,
        });
      } else {
        state.spotGroups[action.payload.selectedTenant] = [action.payload.data];
      }
    },
    [createSpotGroup.rejected]: (state, action) => {
      state.createSpotGroupRequestId = null;
      state.createSpotGroupError = action.payload;
      state.creatingSpotGroup = false;
      state.createStatus = false;
    },
  },
});

export const {
  resetSpotGroupErrors,
  clearSpotGroups,
  setSelectedSpotGroup,
  resetSelectedSpotGroup,
} = spotGroupSlice.actions;

export default spotGroupSlice.reducer;
