import {
  IconCheckmarkCircle,
  IconClock,
  IconInfo,
  IconXCircle,
} from "@veneer/core";
import { formatLabelToCamelCase } from "../../../common/utils";

// Custom non-Veneer icons
import { ProgressIcon } from "../../../icons/ProgressIcon";
import { SkippedIcon } from "../../../icons/SkippedIcon";

// TODO: TSW-225873: Once the backend is adjusted to follow the naming convention for the status
// update the TENANT_PROGRESS constant to match the backend
const STATUS_MAP = {
  started: {
    type: "help",
    label: "In Progress",
    icon: <ProgressIcon />,
  },
  notStarted: {
    type: "disabled",
    label: "Not Started",
    icon: <IconClock filled />,
  },
  succeeded: {
    type: "positive",
    label: "Completed",
    icon: <IconCheckmarkCircle size={16} filled />,
  },
  failed: {
    type: "negative",
    label: "Failed",
    icon: <IconXCircle size={16} filled />,
  },
  pending: {
    type: "neutral",
    label: "Processing",
    icon: <IconInfo size={16} filled />,
  },
  skipped: {
    type: "warning",
    label: "Skipped",
    icon: <SkippedIcon />,
  },
};

const getStatusKey = (status) => formatLabelToCamelCase(status);

export const mapProgressLogTagType = (status) => {
  const key = getStatusKey(status);
  return STATUS_MAP[key]?.type || "disabled";
};

export const mapProgressLogLabel = (status) => {
  const key = getStatusKey(status);
  return STATUS_MAP[key]?.label || "Unknown";
};

export const mapProgressLogIcon = (status) => {
  const key = getStatusKey(status);
  return STATUS_MAP[key]?.icon || undefined;
};
