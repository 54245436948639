import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ParentRoute from "../../../common/components/ParentRoute";
import { resetErrors } from "../../slices/machineSlice";
import RequestRebootModal from "./RequestRebootModal";
import RequestLoginsModal from "./RequestLoginsModal";

export default function MachinesDetailsWrapper(props) {
  const openRequestRebootModal = useSelector(
    (state) => state.dashboard.machines.openRequestRebootModal
  );
  const openRequestLoginsModal = useSelector(
    (state) => state.dashboard.machines.openRequestLoginsModal
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resetErrors());
  }, []);

  return (
    <>
      <ParentRoute {...props} />
      <RequestRebootModal open={openRequestRebootModal} />
      <RequestLoginsModal
        open={openRequestLoginsModal.value ?? false}
        operation={openRequestLoginsModal.operation}
      />
    </>
  );
}
