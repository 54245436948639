import { useToast } from "@veneer/core";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import DialogWithLoader from "../../../common/components/DialogWithLoader";
import ParentRoute from "../../../common/components/ParentRoute";

export default function MachinesWrapper(props) {
  const { addToast } = useToast();
  const connectingToMachine = useSelector(
    (state) => state.dashboard.machines.connectingToMachine,
  );
  const loaderText = useSelector(
    (state) => state.dashboard.machines.loaderText,
  );
  const connectToMachineError = useSelector(
    (state) => state.dashboard.machines.connectoToMachineError,
  );

  useEffect(() => {
    if (connectToMachineError) {
      const message = connectToMachineError
        ? connectToMachineError
        : "Unknown Error.";
      addToast({
        text: message,
        type: "positive",
        timeout: 6,
        id: Date.now(),
      });
    }
  }, [addToast, connectToMachineError]);
  return (
    <>
      <DialogWithLoader
        open={connectingToMachine}
        title={"Please Wait"}
        loaderText={loaderText ? loaderText : "Loading..."}
      ></DialogWithLoader>
      <ParentRoute {...props} />
    </>
  );
}
