import * as React from "react";
import { getAzureB2C } from "../../azureB2C/factories/azure-ad-factory";
import { convertEpochTimeToLocalTime } from "../utils";

const AuthContext = React.createContext();

function authReducer(state, action) {
  switch (action.type) {
    case "authenticate": {
      return { isAuthenticated: true, account: action.account };
    }
    case "unAuthenticate": {
      return { isAuthenticated: false, account: null };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function useAuthContext() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}

function AuthProvider({ children }) {
  const authB2CService = getAzureB2C();
  const account = authB2CService.getAccount();
  const isAuthenticated =
    !!account &&
    convertEpochTimeToLocalTime(account.idTokenClaims.exp) > new Date();
  const [state, dispatch] = React.useReducer(authReducer, {
    isAuthenticated,
    account,
  });
  const value = { state, dispatch };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
export { AuthProvider, useAuthContext };
