import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export const OrganizationsSwitch = () => {
    const history = useHistory();
    useEffect(()=>{
        history.push("/admin");
    },[])
    return (
        <></>
    )
}