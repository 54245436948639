import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ParentRoute from "../../../../common/components/ParentRoute";
import { getIdentityId, getTenantId } from "../../../../common/utils";
import { getImageSetDetails } from "../../imageSetSlice";

export default function ImageSetDetailsWrapper(props) {
  const dispatch = useDispatch();
  const selectedPartner = useSelector((state) => state.partners.selectedPartner);
  const selectedIdentity = useSelector((state) => state.identities.selectedIdentity);
  const { refrehImageSets } = useSelector((state) => state.adminActions.imageSet);

  const { imageSetName } = useParams();

  const loadData = (useCache) => {
    if (selectedPartner && selectedIdentity && imageSetName) {
      dispatch(
        getImageSetDetails({
          useCache,
          selectedOrganization: getIdentityId(selectedIdentity),
          selectedTenant: getTenantId(selectedPartner),
          imageName: imageSetName,
        })
      );
    }
  };

  useEffect(() => {
    loadData(true);
  }, [selectedPartner, selectedIdentity, imageSetName]);

  useEffect(() => {
    if (refrehImageSets) {
      loadData(false);
    }
  }, [refrehImageSets]);

  return <ParentRoute {...props} nobreadcrumbs={true} />;
}
