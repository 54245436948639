import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HTTP_METHODS } from "../common/constants";
import HTTPClient from "../common/servicers/httpClient";

/**************************************
 *********** API ACTIONS ***************
 ***************************************/

export const getCountries = createAsyncThunk(
  "countries",
  async ({ useCache = true }, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, loading, countries } = getState().countries;
    if (!loading || requestId !== currentRequestId) {
      return;
    }
    if (useCache && countries && countries.length > 0) {
      return { data: countries };
    }
    try {
      const response = await new HTTPClient({
        endpoint: "/countries/",
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return response.data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  },
);

export const initialState = {
  countries: [],
  loading: false,
  requesting: false,
};

export const countries = createSlice({
  name: "countries",
  initialState,
  reducers: {
    setRequesting: (state, action) => {
      state.requesting = action.payload;
    },
  },
  extraReducers: {
    [getCountries.pending]: (state, action) => {
      state.loading = true;
      state.currentRequestId = action.meta.requestId;
    },
    [getCountries.fulfilled]: (state, action) => {
      state.loading = false;
      state.currentRequestId = null;
      state.countries = action.payload
        ? action.payload.data
          ? action.payload.data
          : []
        : [];
    },
    [getCountries.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.currentRequestId = null;
    },
  },
});

export default countries.reducer;
