import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HTTP_METHODS } from "../../common/constants";
import HTTPClient from "../../common/servicers/httpClient";
import _ from "lodash";

export const getDataVolumes = createAsyncThunk(
  "getDataVolumes",
  async (
    { useCache = true, tenantId, organizationId },
    { getState, requestId, rejectWithValue },
  ) => {
    const {
      loadingDataVolumesRequestId,
      loadingDataVolumes,
      dataVolumesAvailable,
      dataVolumes,
    } = getState().adminActions.dataVolumes;

    if (!loadingDataVolumes || loadingDataVolumesRequestId !== requestId) {
      return;
    }
    if (useCache && dataVolumesAvailable[tenantId]) {
      return { data: dataVolumes[tenantId], tenantId, cachedData: true };
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${tenantId}/dataVolumes`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, tenantId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getDataVolume = createAsyncThunk(
  "getDataVolume",
  async (
    { useCache = true, tenantId, organizationId, dataVolumeName, siteName },
    { getState, requestId, rejectWithValue },
  ) => {
    const { loadingDataVolumeRequestId, loadingDataVolume, dataVolume } =
      getState().adminActions.dataVolumes;
    if (!loadingDataVolume || requestId !== loadingDataVolumeRequestId) {
      return;
    }
    if (useCache && dataVolume && dataVolume.name === dataVolumeName) {
      return { data: dataVolume };
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${tenantId}/sites/${siteName}/dataVolumes/${dataVolumeName}`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getDirectoryInfoForDataVolumes = createAsyncThunk(
  "getDirectoryInfoForDataVolume",
  async (
    { useCache = true, tenantId, organizationId },
    { getState, requestId, rejectWithValue },
  ) => {
    const { getDirectoryInfoRequestId, loadingDirectoryInfo, directoryInfo } =
      getState().adminActions.dataVolumes;
    if (!loadingDirectoryInfo || requestId !== getDirectoryInfoRequestId) {
      return;
    }
    if (useCache && directoryInfo && Object.keys(directoryInfo).length > 0) {
      return { data: directoryInfo };
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${tenantId}/dataVolumes/AzureADDirectJoin`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const updateDirectoryInfoForDataVolumes = createAsyncThunk(
  "updateDirectoryInfoForDataVolume",
  async (
    { tenantId, organizationId, siteName, dataVolumeName, payload },
    { getState, requestId, rejectWithValue },
  ) => {
    const { updatingDirectoryInfoRequestId, updatingDirectoryInfo } =
      getState().adminActions.dataVolumes;
    if (
      !updatingDirectoryInfo ||
      requestId !== updatingDirectoryInfoRequestId
    ) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${tenantId}/sites/${siteName}/dataVolumes/${dataVolumeName}/AzureADDirectJoin`,
        method: HTTP_METHODS.PUT,
        data: payload,
      }).callAuthorizedAPI();
      return { ...response.data, data: payload };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const initialState = {
  dataVolumes: {},
  dataVolume: {},
  dataVolumesAvailable: {},
  loadingDataVolumes: null,
  loadingDataVolume: null,
  loadingDataVolumesRequestId: null,
  loadingDataVolumeRequestId: null,
  getDataVolumesError: null,
  getDataVolumeError: null,
  createDataVolumeError: null,
  creatingDataVolume: null,
  restoreDataVolumeError: null,
  restoringDataVolume: null,
  editingDataVolume: null,
  editDataVolumeError: null,
  loadingDirectoryInfo: null,
  directoryInfo: null,
  errorLoadingDirectoryInfo: null,
  getDirectoryInfoRequestId: null,
  updatingDirectoryInfo: null,
  updatingDirectoryInfoRequestId: null,
  errorUpdatingDirectoryInfo: null,
  successUpdatingDirectoryInfo: null,
};

export const dataVolumesSlice = createSlice({
  name: "dataVolumes",
  initialState,
  reducers: {
    setDirectoryInfo: (state, action) => {
      state.directoryInfo = action.payload;
    },
    resetStateIndicators: (state) => {
      state.successUpdatingDirectoryInfo = null;
      state.errorLoadingDirectoryInfo = null;
      state.errorUpdatingDirectoryInfo = null;
    },
  },
  extraReducers: {
    [getDataVolumes.pending]: (state, action) => {
      state.loadingDataVolumes = true;
      state.loadingDataVolumesRequestId = action.meta.requestId;
      state.error = null;
    },
    [getDataVolumes.fulfilled]: (state, action) => {
      state.loadingDataVolumesRequestId = null;
      state.loadingDataVolumes = false;
      if (action.payload?.tenantId && action.payload?.data) {
        let dataVolumes = action.payload.data.map((dataVolume) => {
          let propertiesCopy = { ...dataVolume?.properties };
          propertiesCopy.usedGib = propertiesCopy?.usedGib
            ? Number(propertiesCopy?.usedGib?.toFixed(2))
            : null;
          let dataVolumeCopy = propertiesCopy.usedGib
            ? { ...dataVolume, properties: propertiesCopy }
            : { ...dataVolume };
          return dataVolumeCopy;
        });
        _.set(state.dataVolumes, `${action.payload.tenantId}`, dataVolumes);
        _.set(state.dataVolumesAvailable, `${action.payload.tenantId}`, true);
      }
    },
    [getDataVolumes.rejected]: (state, action) => {
      state.loadingDataVolumesRequestId = null;
      state.getDataVolumesError = action.payload;
      state.loadingDataVolumes = false;
    },
    [getDataVolume.pending]: (state, action) => {
      state.loadingDataVolume = true;
      state.loadingDataVolumeRequestId = action.meta.requestId;
      state.getDataVolumeError = null;
      state.error = null;
    },
    [getDataVolume.fulfilled]: (state, action) => {
      if (action.payload?.data && action.payload?.data?.properties) {
        let usedGib = Number(
          action.payload.data.properties.usedGib?.toFixed(2),
        );
        let payload = _.cloneDeep(action.payload);
        payload.data.properties.usedGib = usedGib;
        action.payload = payload;
      }
      state.loadingDataVolumeRequestId = null;
      state.loadingDataVolume = false;
      state.dataVolume = action.payload?.data;
    },
    [getDataVolume.rejected]: (state, action) => {
      state.loadingDataVolumeRequestId = null;
      state.getDataVolumeError = action.payload || true;
      state.loadingDataVolume = false;
    },
    [getDirectoryInfoForDataVolumes.pending]: (state, action) => {
      state.loadingDirectoryInfo = true;
      state.getDirectoryInfoRequestId = action.meta.requestId;
      state.errorLoadingDirectoryInfo = null;
    },
    [getDirectoryInfoForDataVolumes.fulfilled]: (state, action) => {
      if (action.payload?.data) {
        state.directoryInfo = action.payload.data;
      } else {
        state.directoryInfo = {};
      }
      state.loadingDirectoryInfo = null;
      state.getDirectoryInfoRequestId = null;
      state.errorLoadingDirectoryInfo = null;
    },
    [getDirectoryInfoForDataVolumes.rejected]: (state, action) => {
      state.loadingDirectoryInfo = null;
      state.errorLoadingDirectoryInfo = action.payload || true;
      state.getDirectoryInfoRequestId = null;
    },
    [updateDirectoryInfoForDataVolumes.pending]: (state, action) => {
      state.updatingDirectoryInfo = true;
      state.updatingDirectoryInfoRequestId = action.meta.requestId;
      state.errorUpdatingDirectoryInfo = null;
      state.successUpdatingDirectoryInfo = null;
    },
    [updateDirectoryInfoForDataVolumes.fulfilled]: (state, action) => {
      if (action.payload?.data) {
        state.directoryInfo = action.payload.data;
      } else {
        state.directoryInfo = null;
      }
      state.updatingDirectoryInfo = null;
      state.updatingDirectoryInfoRequestId = null;
      state.errorUpdatingDirectoryInfo = null;
      state.successUpdatingDirectoryInfo = true;
    },
    [updateDirectoryInfoForDataVolumes.rejected]: (state, action) => {
      state.successUpdatingDirectoryInfo = null;
      state.updatingDirectoryInfo = null;
      state.errorUpdatingDirectoryInfo = action.payload || true;
      state.updatingDirectoryInfoRequestId = null;
    },
  },
});

export const { setDirectoryInfo, resetStateIndicators } =
  dataVolumesSlice.actions;

export const azureAdDirectJoinNotAvailableForDataVolume = (state) =>
  !state?.adminActions?.dataVolumes?.dataVolume?.properties
    ?.azureADDirectJoinEnabled;

export default dataVolumesSlice.reducer;
