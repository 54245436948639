import React from "react";
import { styled } from '@mui/material/styles';
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import { CircularProgress } from "@mui/material";
import { RadioButton, RadioButtons as RadioGroups } from '@veneer/core';

const PREFIX = 'RadioGroups';

const classes = {
  radio: `${PREFIX}-radio`,
  checked: `${PREFIX}-checked`,
  formLabelRoot: `${PREFIX}-formLabelRoot`,
  formLabelFocused: `${PREFIX}-formLabelFocused`,
  progress: `${PREFIX}-progress`,
  flexDirection: `${PREFIX}-flexDirection`
};

const StyledFormControl = styled(FormControl)((
  {
    theme
  }
) => ({
  [`& .${classes.radio}`]: {
    color: theme.palette.grey,
    // TODO: fox MUI commented by Alex. ->> https://mui.com/material-ui/migration/v5-style-changes/#migrate-theme-styleoverrides-to-emotion
    // "&$checked": {
    //   color: theme.palette.primary.main,
    // },
  },

  [`& .${classes.checked}`]: {},

  [`& .${classes.formLabelRoot}`]: {
    color: '#7D7D7D',
    fontWeight: 400,
    marginBottom: '15px'
    // TODO: fox MUI commented by Alex. ->> https://mui.com/material-ui/migration/v5-style-changes/#migrate-theme-styleoverrides-to-emotion
    // "&$formLabelFocused": { color: theme.palette.formLabel.main },
  },

  [`& .${classes.formLabelFocused}`]: {},

  [`& .${classes.progress}`]: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2),
  },

  [`& .${classes.flexDirection}`]: {
    flexDirection: "row",
  }
}));

const RadioButtons = (props) => {

  const {
    options = [],
    meta,
    label,
    loading,
    ...rest
  } = props;
  const renderCircularProgress = () => {
    return (
      <div className={classes.progress}>
        <CircularProgress thickness={4} size={20} />
      </div>
    );
  };
  return (
    <StyledFormControl component="fieldset" error={!!(meta && meta.touched && meta.error)}>
      {label && (
        <FormLabel
          component="legend"
          classes={{
            root: classes.formLabelRoot,
            focused: classes.formLabelFocused,
          }}
          error={meta && meta.touched && meta.error ? true : false}
        >
          {label}
        </FormLabel>
      )}
      <FormHelperText
        data-testid="input-error"
        error={meta && meta.touched && meta.error ? true : false}
        style={{ display: "inline-block", margin: "-5px 5px" }}
      >
        {meta && meta.touched && meta.error ? meta.error : ""}
      </FormHelperText>
      <RadioGroups {...rest} style={{ marginBottom: 0 }}>
        {options.map(i => (<RadioButton {...i} key={i} />))}
      </RadioGroups>
      {loading && renderCircularProgress()}

    </StyledFormControl>
  );
};

export default RadioButtons;
