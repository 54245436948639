import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import _ from "lodash";
import { HTTP_METHODS } from "../../common/constants";
import HTTPClient from "../../common/servicers/httpClient";

export const getImageSets = createAsyncThunk(
  "getImageSets",
  async (
    { useCache = true, selectedOrganization, selectedTenant },
    { getState, requestId, rejectWithValue },
  ) => {
    const {
      getImageSetsRequestId,
      loadingImageSets,
      imageSets,
      imageSetsAvailable,
    } = getState().adminActions.imageSet;
    if (!loadingImageSets || requestId !== getImageSetsRequestId) {
      return;
    }
    if (useCache && imageSetsAvailable[selectedTenant] === true) {
      return { data: imageSets[selectedTenant], selectedTenant };
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${selectedOrganization}/tenants/${selectedTenant}/imageSets`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getImageSetDetails = createAsyncThunk(
  "getImageSetDetails",
  async (
    { useCache = true, selectedOrganization, selectedTenant, imageName },
    { getState, requestId, rejectWithValue },
  ) => {
    const { getImageSetDetailsRequestId, loadingImageSetDetails, imageSets } =
      getState().adminActions.imageSet;
    if (!loadingImageSetDetails || requestId !== getImageSetDetailsRequestId) {
      return;
    }
    if (
      useCache &&
      imageSets[selectedTenant] &&
      imageSets[selectedTenant].find((i) => i.name === imageName)
    ) {
      return {
        data: imageSets[selectedTenant].find((i) => i.name === imageName),
        selectedTenant,
        cachedVersion: true,
      };
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${selectedOrganization}/tenants/${selectedTenant}/imageSet/${imageName}`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getSourceImages = createAsyncThunk(
  "getSourceImages",
  async (
    { useCache = true, selectedTenant, organizationId, site },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loadingSourceImages, sourceImages } =
      getState().adminActions.imageSet;
    if (
      useCache &&
      sourceImages[selectedTenant] &&
      sourceImages[selectedTenant][site]
    ) {
      return { data: sourceImages[selectedTenant][site], selectedTenant, site };
    }
    if (!loadingSourceImages || requestId !== currentRequestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${selectedTenant}/sites/${encodeURI(
          site,
        )}/imageSets/sourceImages`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant, site };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getSourceImagesForVersion = createAsyncThunk(
  "getSourceImagesForVersion",
  async (
    { useCache = true, selectedTenant, organizationId, imageSetName },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loadingSourceImages, versionSourceImages } =
      getState().adminActions.imageSet;
    if (
      useCache &&
      versionSourceImages[selectedTenant] &&
      versionSourceImages[selectedTenant][imageSetName]
    ) {
      return {
        data: versionSourceImages[selectedTenant][imageSetName],
        selectedTenant,
        imageSetName,
      };
    }
    if (!loadingSourceImages || requestId !== currentRequestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${selectedTenant}/imageSets/${encodeURI(
          imageSetName,
        )}/versions/sourceImages`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant, imageSetName };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getImageSetSites = createAsyncThunk(
  "getImageSetSites",
  async (
    { useCache = true, selectedTenant, organizationId },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loadingSites, imageSetSites } =
      getState().adminActions.imageSet;
    if (useCache && imageSetSites[selectedTenant]) {
      return { data: imageSetSites[selectedTenant], selectedTenant };
    }
    if (!loadingSites || requestId !== currentRequestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${selectedTenant}/imageSets/sites`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getImageTypes = createAsyncThunk(
  "getImageTypes",
  async ({ useCache = true }, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, loadingTypes, imageTypes } =
      getState().adminActions.imageSet;
    if (useCache && imageTypes) {
      return { data: imageTypes, useCache };
    }
    if (!loadingTypes || requestId !== currentRequestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: "/imageSets/types",
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const postNotesForImageSet = createAsyncThunk(
  "postImageSetNotes",
  async (
    { notes, selectedOrganization, selectedTenant, imageName },
    { getState, requestId, rejectWithValue },
  ) => {
    const { postImageNotesRequestId, postingImageNotes } =
      getState().adminActions.imageSet;
    if (!postingImageNotes || requestId !== postImageNotesRequestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${selectedOrganization}/tenants/${selectedTenant}/imageSet/${imageName}/notes`,
        method: HTTP_METHODS.POST,
        data: { notes: notes },
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant, imageName };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const postNotesForImageSetVersion = createAsyncThunk(
  "postNotesForImageSetVersion",
  async (
    { notes, selectedOrganization, selectedTenant, imageName, version },
    { getState, requestId, rejectWithValue },
  ) => {
    const { postImageNotesRequestId, postingImageNotes } =
      getState().adminActions.imageSet;
    if (!postingImageNotes || requestId !== postImageNotesRequestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${selectedOrganization}/tenants/${selectedTenant}/imageSet/${imageName}/versions/${version}/notes`,
        method: HTTP_METHODS.POST,
        data: { notes: notes },
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant, imageName };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const editImageSet = createAsyncThunk(
  "editImageSet",
  async (
    { selectedOrganization, selectedTenant, imageName, data },
    { getState, requestId, rejectWithValue },
  ) => {
    const { loadingAction, currentRequestId } =
      getState().adminActions.imageSet;
    if (!loadingAction || requestId !== currentRequestId) {
      return;
    }
    const payload = {
      type: data.imageType,
      properties: {
        description: data.description,
      },
    };
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${selectedOrganization}/tenants/${selectedTenant}/imageSet/${imageName}`,
        method: HTTP_METHODS.PUT,
        data: payload,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant, imageName };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const restartImageSet = createAsyncThunk(
  "restartImageSet",
  async (
    { selectedOrganization, selectedTenant, imageName, version },
    { getState, requestId, rejectWithValue },
  ) => {
    const { loadingAction, restartImageSetsRequestId } =
      getState().adminActions.imageSet;
    if (!loadingAction || requestId !== restartImageSetsRequestId) {
      return;
    }

    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${selectedOrganization}/tenants/${selectedTenant}/imageSet/${imageName}/versions/${version}/restart`,
        method: HTTP_METHODS.PUT,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant, imageName };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const packageImageSet = createAsyncThunk(
  "packageImageSet",
  async (
    { selectedOrganization, selectedTenant, imageName, version },
    { getState, requestId, rejectWithValue },
  ) => {
    const { loadingAction, packageImageSetsRequestId } =
      getState().adminActions.imageSet;
    if (!loadingAction || requestId !== packageImageSetsRequestId) {
      return;
    }

    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${selectedOrganization}/tenants/${selectedTenant}/imageSet/${imageName}/versions/${version}/package`,
        method: HTTP_METHODS.PUT,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant, imageName };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const saveImageSet = createAsyncThunk(
  "saveImageSet",
  async (
    { organizationId, tenantId, imageSetData, notes },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loadingAction } =
      getState().adminActions.imageSet;
    if (!loadingAction || requestId !== currentRequestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${tenantId}/imageSets/${imageSetData.name}`,
        method: HTTP_METHODS.POST,
        data: {
          type: imageSetData.imageType,
          site: imageSetData.site,
          properties: {
            description: imageSetData.description,
            sourceImage: imageSetData.imageSource.name,
            sourceImageSite: imageSetData.imageSource.site,
            sourceImageType: imageSetData.imageSource.type,
            note: {
              notes: notes,
            },
          },
        },
      }).callAuthorizedAPI();
      return { ...response.data, selectedPartner: tenantId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const saveImageSetVersion = createAsyncThunk(
  "saveImageSetVersion",
  async (
    {
      organizationId,
      tenantId,
      imageSetData,
      notes,
      imageSetName,
      customActions,
    },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loadingAction } =
      getState().adminActions.imageSet;
    if (!loadingAction || requestId !== currentRequestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${tenantId}/imageSets/${imageSetName}/versions/${imageSetData.imageVersion}`,
        method: HTTP_METHODS.POST,
        data: {
          properties: {
            sourceImage: imageSetData.imageSource.name,
            userStatus: imageSetData.userStatus,
            sourceImageType: imageSetData.imageSource.type,
            note: {
              notes: notes,
            },
          },
          customActions: customActions,
        },
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant: tenantId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteImageSet = createAsyncThunk(
  "deleteImageSet",
  async (
    { selectedOrganization, selectedTenant, imageName },
    { getState, requestId, rejectWithValue },
  ) => {
    const { deletingImageSet, deleteImageSetRequestId } =
      getState().adminActions.imageSet;
    if (!deletingImageSet || requestId !== deleteImageSetRequestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${selectedOrganization}/tenants/${selectedTenant}/imageSet/${imageName}`,
        method: HTTP_METHODS.DELETE,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant, imageName };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteImageSetVersion = createAsyncThunk(
  "deleteImageSetVersion",
  async (
    { selectedOrganization, selectedTenant, imageName, version },
    { getState, requestId, rejectWithValue },
  ) => {
    const { deletingImageSetVersion, deleteImageSetVersionRequestId } =
      getState().adminActions.imageSet;
    if (
      !deletingImageSetVersion ||
      requestId !== deleteImageSetVersionRequestId
    ) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${selectedOrganization}/tenants/${selectedTenant}/imageSets/${imageName}/versions/${version}`,
        method: HTTP_METHODS.DELETE,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant, imageName, version };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getUserStatuses = createAsyncThunk(
  "getUserStatuses",
  async ({ useCache = true }, { getState, requestId, rejectWithValue }) => {
    const { getStatusesRequestId, loadingStatuses, userStatuses } =
      getState().adminActions.imageSet;
    if (useCache && userStatuses && userStatuses.length > 0) {
      return { data: userStatuses, useCache };
    }
    if (!loadingStatuses || requestId !== getStatusesRequestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: "/imageSets/versions/userStatuses",
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const initialState = {
  loadingImageSets: null,
  loadingSourceImages: null,
  getImageSetsRequestId: null,
  restartImageSetsRequestId: null,
  packageImageSetsRequestId: null,
  silentRefresh: false,
  refrehImageSets: false,
  getImageSetsError: null,
  imageTypes: null,
  imageSetSites: {},
  loadingSites: null,
  imageSets: {},
  imageSetsAvailable: {},
  loading: false,
  deleteStatus: null,
  deletingImageSet: null,
  deleteImageSetError: null,
  deleteImageSetRequestId: null,
  deleteVersionStatus: null,
  deletingImageSetVersion: null,
  deleteImageSetVersionError: null,
  deleteImageSetVersionRequestId: null,
  loaderText: null,
  loadingImageSetDetails: null,
  getImageSetDetailsRequestId: null,
  getImageSetDetailsError: null,
  sourceImages: {},
  versionSourceImages: {},
  fetchSouceImagesFailed: null,
  error: null,
  loadingTypes: false,
  createdImageSet: null,
  createdImageSetVersion: null,
  createStatus: null,
  loadingAction: false,
  postingImageNotes: null,
  postImageNotesError: null,
  postImageNotesRequestId: null,
  currentRequestId: null,
  editImageSetError: null,
  restartImageSetError: null,
  packageImageSetError: null,
  loadingRestartImageSets: null,
  loadingPackageImageSets: null,
  getStatusesRequestId: null,
  loadingStatuses: null,
  userStatuses: [],
  getUserStatusError: null,
  refreshImageSites: null,
};

export const imageSetSlice = createSlice({
  name: "imageSet",
  initialState,
  reducers: {
    setImageSetDeleteStatus: (state, action) => {
      state.deleteStatus = action.payload;
    },
    resetErrors: (state) => {
      state.createStatus = null;
      state.deleteImageSetError = null;
      state.postImageNotesError = null;
      state.getImageSetsError = null;
      state.editImageSetError = null;
      state.restartImageSetError = null;
      state.packageImageSetError = null;
      state.createdImageSetVersion = null;
      state.deleteImageSetVersionError = null;
      state.fetchSouceImagesFailed = null;
    },
    clearImageSets: (state, action) => {
      if (action.payload !== undefined && action.payload.tenantId) {
        if (!action.payload.silentRefresh) {
          state.imageSets[action.payload.tenantId] = [];
          state.versionSourceImages[action.payload.tenantId] = [];
        } else {
          state.silentRefresh = true;
        }
        state.refrehImageSets = true;
        state.imageSetsAvailable[action.payload.tenantId] = false;
      }
    },
    clearImageSetsSites: (state, action) => {
      if (action.payload !== undefined && action.payload.tenantId) {
        state.refreshImageSites = true;
      }
    },
  },
  extraReducers: {
    [getImageSets.pending]: (state, action) => {
      state.loadingImageSets = true;
      state.getImageSetsRequestId = action.meta.requestId;
      state.getImageSetsError = null;
      state.refrehImageSets = false;
    },
    [getImageSets.fulfilled]: (state, action) => {
      state.getImageSetsRequestId = null;
      state.loadingImageSets = false;
      state.getImageSetsError = null;
      state.silentRefresh = false;
      if (action.payload.selectedTenant) {
        _.set(
          state.imageSets,
          `${action.payload.selectedTenant}`,
          action.payload.data,
        );
        _.set(
          state.imageSetsAvailable,
          `${action.payload.selectedTenant}`,
          true,
        );
      }
    },
    [getImageSets.rejected]: (state, action) => {
      state.getImageSetsRequestId = null;
      state.getImageSetsError = action.payload;
      state.loadingImageSets = false;
      state.silentRefresh = false;
    },
    [restartImageSet.pending]: (state, action) => {
      state.loadingImageSets = true;
      state.restartImageSetsRequestId = action.meta.requestId;
      state.restartImageSetError = null;
      state.loadingAction = true;
    },
    [restartImageSet.fulfilled]: (state, action) => {
      state.loadingAction = false;
      state.restartImageSetsRequestId = null;
      state.loadingRestartImageSets = false;
      state.getRestartImageSetsError = null;
      if (
        action.payload.selectedTenant &&
        state.imageSets[action.payload.selectedTenant]
      ) {
        let currentImageSets = current(
          state.imageSets[action.payload.selectedTenant],
        );
        state.imageSets[action.payload.selectedTenant] = [
          ...(currentImageSets ?? []).filter(
            (d) => d.id !== action.payload.data.id,
          ),
          action.payload.data,
        ];
        _.set(
          state.imageSetsAvailable,
          `${action.payload.selectedTenant}`,
          true,
        );
      }
    },
    [restartImageSet.rejected]: (state, action) => {
      state.restartImageSetsRequestId = null;
      state.restartImageSetError = action.payload;
      state.loadingRestartImageSets = false;
    },
    [packageImageSet.pending]: (state, action) => {
      state.packageImageSetsRequestId = action.meta.requestId;
      state.loadingAction = true;
      state.loadingPackageImageSets = true;
      state.packageImageSetError = null;
    },
    [packageImageSet.fulfilled]: (state, action) => {
      state.loadingAction = false;
      state.packageImageSetsRequestId = null;
      state.loadingPackageImageSets = false;
      state.packageImageSetError = null;
      if (action.payload.selectedTenant) {
        const imageSets = _.get(
          state.imageSets,
          `${action.payload.selectedTenant}`,
        );
        if (imageSets) {
          const filteredImageSets = imageSets.filter(
            (i) => i.id !== action.payload.data.id,
          );
          _.set(state.imageSets, `${action.payload.selectedTenant}`, [
            ...filteredImageSets,
            action.payload.data,
          ]);
        } else {
          _.set(state.imageSets, `${action.payload.selectedTenant}`, [
            action.payload.data,
          ]);
        }
      }
    },
    [packageImageSet.rejected]: (state, action) => {
      state.packageImageSetsRequestId = null;
      state.packageImageSetError = action.payload?.message;
      state.loadingPackageImageSets = false;
      state.loadingAction = false;
    },
    [getImageSetDetails.pending]: (state, action) => {
      state.loadingImageSetDetails = true;
      state.refrehImageSets = false;
      state.getImageSetDetailsRequestId = action.meta.requestId;
      state.getImageSetDetailsError = null;
    },
    [getImageSetDetails.fulfilled]: (state, action) => {
      state.loadingImageSetDetails = false;
      state.getImageSetDetailsRequestId = null;
      state.getImageSetDetailsError = null;
      state.silentRefresh = false;
      if (!action.payload.cachedVersion) {
        if (state.imageSets[action.payload.selectedTenant]) {
          let currentImageSets = current(
            state.imageSets[action.payload.selectedTenant],
          );
          state.imageSets[action.payload.selectedTenant] = [
            ...currentImageSets.filter((d) => d.id !== action.payload.data.id),
            action.payload.data,
          ];
        } else {
          _.set(state.imageSets, `${action.payload.selectedTenant}`, [
            action.payload.data,
          ]);
        }
      }
    },
    [getImageSetDetails.rejected]: (state, action) => {
      state.getImageSetDetailsRequestId = null;
      state.silentRefresh = false;
      state.getImageSetDetailsError = action.payload;
      state.loadingImageSetDetails = false;
    },
    [getImageSetSites.pending]: (state, action) => {
      state.loadingSites = true;
      state.createStatus = null;
      state.currentRequestId = action.meta.requestId;
      state.loaderText = "Loading Sites";
      state.error = null;
    },
    [getImageSetSites.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.loadingSites = false;
      state.imageSetSites[action.payload?.selectedTenant] =
        action.payload?.data;
      state.refreshImageSites = null;
    },
    [getImageSetSites.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.error = action.payload;
      state.loadingSites = false;
      state.refreshImageSites = null;
    },
    [getSourceImages.pending]: (state, action) => {
      state.loadingSourceImages = true;
      state.createStatus = null;
      state.currentRequestId = action.meta.requestId;
      state.loaderText = "Loading Source Images";
      state.error = null;
      state.fetchSouceImagesFailed = null;
    },
    [getSourceImages.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.loadingSourceImages = false;
      state.fetchSouceImagesFailed = false;
      if (state.sourceImages[action.payload?.selectedTenant]) {
        state.sourceImages[action.payload?.selectedTenant][
          action.payload?.site
        ] = action.payload?.data;
      } else {
        state.sourceImages[action.payload?.selectedTenant] = {
          [action.payload?.site]: action.payload?.data,
        };
      }
    },
    [getSourceImages.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.error = action.payload;
      state.loadingSourceImages = false;
      state.fetchSouceImagesFailed = true;
    },
    [getSourceImagesForVersion.pending]: (state, action) => {
      state.loadingSourceImages = true;
      state.createStatus = null;
      state.currentRequestId = action.meta.requestId;
      state.loaderText = "Loading Source Images";
      state.error = null;
      state.refrehImageSets = false;
    },
    [getSourceImagesForVersion.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.loadingSourceImages = false;
      state.silentRefresh = false;
      if (state.versionSourceImages[action.payload?.selectedTenant]) {
        state.versionSourceImages[action.payload?.selectedTenant][
          action.payload?.imageSetName
        ] = action.payload?.data;
      } else {
        state.versionSourceImages[action.payload?.selectedTenant] = {
          [action.payload?.imageSetName]: action.payload?.data,
        };
      }
    },
    [getSourceImagesForVersion.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.silentRefresh = false;
      state.error = action.payload;
      state.loadingSourceImages = false;
    },

    [getImageTypes.pending]: (state, action) => {
      state.loadingTypes = true;
      state.currentRequestId = action.meta.requestId;
      state.loaderText = "Loading Image Types";
      state.error = null;
    },
    [getImageTypes.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.loadingTypes = false;
      state.imageTypes = action.payload?.data
        ? action.payload.useCache
          ? action.payload.data
          : action.payload?.data.map((str, index) => ({
              name: str,
              id: index + 1,
            }))
        : null;
    },
    [getImageTypes.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.error = action.payload;
      state.loadingTypes = false;
    },
    [getUserStatuses.pending]: (state, action) => {
      state.loadingStatuses = true;
      state.getStatusesRequestId = action.meta.requestId;
      state.getUserStatusError = null;
    },
    [getUserStatuses.fulfilled]: (state, action) => {
      state.getStatusesRequestId = null;
      state.loadingStatuses = false;
      state.userStatuses = action.payload?.data
        ? action.payload.useCache
          ? action.payload.data
          : action.payload?.data.map((str, index) => ({
              name: str,
              id: index + 1,
            }))
        : null;
      state.getUserStatusError = null;
    },
    [getUserStatuses.rejected]: (state, action) => {
      state.getStatusesRequestId = null;
      state.getUserStatusError = action.payload;
      state.loadingStatuses = false;
    },
    [saveImageSet.pending]: (state, action) => {
      state.loadingAction = true;
      state.createdImageSet = null;
      state.currentRequestId = action.meta.requestId;
      state.loaderText = "Creating Image Set";
      state.createStatus = null;
    },
    [saveImageSet.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.loadingAction = false;
      if (
        action.payload &&
        action.payload.data &&
        state.imageSets &&
        state.imageSets[action.payload.selectedPartner]
      ) {
        state.imageSets[action.payload.selectedPartner] = [
          ...state.imageSets[action.payload.selectedPartner],
          action.payload.data,
        ];
      }
      state.createdImageSet = action.payload?.data;
      state.loaderText = null;
      state.createStatus = true;
    },
    [saveImageSet.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.loadingAction = false;
      state.createdImageSet = null;
      state.error = action.payload;
      state.loaderText = null;
      state.createStatus = false;
    },
    [saveImageSetVersion.pending]: (state, action) => {
      state.loadingAction = true;
      state.createdImageSetVersion = null;
      state.currentRequestId = action.meta.requestId;
      state.loaderText = "Creating Image Set Version";
      state.createStatus = null;
    },
    [saveImageSetVersion.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.loadingAction = false;
      if (state.imageSets[action.payload.selectedTenant]) {
        let currentImageSets = current(
          state.imageSets[action.payload.selectedTenant],
        );
        state.imageSets[action.payload.selectedTenant] = [
          ...currentImageSets.filter((d) => d?.id !== action.payload.data.id),
          action.payload.data,
        ];
      } else {
        _.set(state.imageSets, `${action.payload.selectedTenant}`, [
          action.payload.data,
        ]);
      }
      state.createdImageSetVersion = action.payload?.data;
      state.loaderText = null;
      state.createStatus = true;
    },
    [saveImageSetVersion.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.loadingAction = false;
      state.createdImageSetVersion = null;
      state.error = action.payload;
      state.loaderText = null;
      state.createStatus = false;
    },
    [postNotesForImageSet.pending]: (state, action) => {
      state.postImageNotesRequestId = action.meta.requestId;
      state.postingImageNotes = true;
      state.postImageNotesError = null;
    },
    [postNotesForImageSet.fulfilled]: (state, action) => {
      state.postImageNotesRequestId = null;
      state.postingImageNotes = false;
      state.postImageNotesError = "";
      if (state.imageSets[action.payload.selectedTenant]) {
        state.imageSets[action.payload.selectedTenant] = state.imageSets[
          action.payload.selectedTenant
        ].map((image) => {
          if (image.name === action.payload.imageName) {
            return action.payload.data;
          } else {
            return image;
          }
        });
      }
    },
    [postNotesForImageSet.rejected]: (state, action) => {
      state.postImageNotesRequestId = null;
      state.postingImageNotes = false;
      state.postImageNotesError = action.payload;
    },
    [postNotesForImageSetVersion.pending]: (state, action) => {
      state.postImageNotesRequestId = action.meta.requestId;
      state.postingImageNotes = true;
      state.postImageNotesError = null;
    },
    [postNotesForImageSetVersion.fulfilled]: (state, action) => {
      state.postImageNotesRequestId = null;
      state.postingImageNotes = false;
      state.postImageNotesError = "";
      if (state.imageSets[action.payload.selectedTenant]) {
        state.imageSets[action.payload.selectedTenant] = state.imageSets[
          action.payload.selectedTenant
        ].map((image) => {
          if (image.name === action.payload.imageName) {
            return action.payload.data;
          } else {
            return image;
          }
        });
      }
    },
    [postNotesForImageSetVersion.rejected]: (state, action) => {
      state.postImageNotesRequestId = null;
      state.postingImageNotes = false;
      state.postImageNotesError = action.payload;
    },
    [deleteImageSet.pending]: (state, action) => {
      state.deletingImageSet = true;
      state.deleteImageSetRequestId = action.meta.requestId;
      state.deleteImageSetError = null;
    },
    [deleteImageSet.fulfilled]: (state, action) => {
      state.deletingImageSet = false;
      state.deleteImageSetRequestId = null;
      state.deleteImageSetError = "";
      state.deleteStatus = true;
      if (state.imageSets[action.payload.selectedTenant]) {
        _.set(
          state.imageSets,
          `${action.payload.selectedTenant}`,
          state.imageSets[action.payload.selectedTenant].filter(
            (i) => i?.name !== action.payload.imageName,
          ),
        );
      }
    },
    [deleteImageSet.rejected]: (state, action) => {
      state.deletingImageSet = false;
      state.deleteImageSetRequestId = null;
      state.deleteImageSetError = action.payload;
      state.deleteStatus = false;
    },
    [deleteImageSetVersion.pending]: (state, action) => {
      state.deletingImageSetVersion = true;
      state.deleteImageSetVersionRequestId = action.meta.requestId;
      state.deleteImageSetVersionError = null;
    },
    [deleteImageSetVersion.fulfilled]: (state, action) => {
      state.deletingImageSetVersion = false;
      state.deleteImageSetVersionRequestId = null;
      state.deleteImageSetVersionError = "";
      state.deleteVersionStatus = true;
      if (state.imageSets[action.payload.selectedTenant]) {
        _.set(state.imageSets, `${action.payload.selectedTenant}`, [
          ...state.imageSets[action.payload.selectedTenant].filter(
            (i) => i?.name !== action.payload.imageName,
          ),
          action.payload.data,
        ]);
      }
    },
    [deleteImageSetVersion.rejected]: (state, action) => {
      state.deletingImageSetVersion = false;
      state.deleteImageSetVersionRequestId = null;
      state.deleteImageSetVersionError = action.payload;
      state.deleteVersionStatus = false;
    },

    [editImageSet.pending]: (state, action) => {
      state.loadingAction = true;
      state.currentRequestId = action.meta.requestId;
      state.editImageSetError = null;
    },
    [editImageSet.fulfilled]: (state, action) => {
      state.loadingAction = false;
      state.currentRequestId = null;
      state.editImageSetError = "";
      if (state.imageSets[action.payload.selectedTenant]) {
        state.imageSets[action.payload?.selectedTenant] = state.imageSets[
          action.payload.selectedTenant
        ].map((imageSet) =>
          imageSet.name === action.payload.imageName
            ? action.payload.data
            : imageSet,
        );
      }
    },
    [editImageSet.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.editImageSetError = action.payload;
      state.loadingAction = false;
    },
  },
});

export const {
  setImageSetDeleteStatus,
  resetErrors,
  clearImageSets,
  clearImageSetsSites,
} = imageSetSlice.actions;

export default imageSetSlice.reducer;
