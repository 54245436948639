import { InteractionRequiredAuthError } from "@azure/msal-browser";
import Tooltip from "@mui/material/Tooltip";
import {
  IconApplicationCode,
  IconArrowBoxRight,
  IconCheckboxSelectAll,
  IconDownload,
  IconDuplicateJob,
  IconPaperAirplane,
  IconPauseCircle,
  IconPencil,
  IconPersonPlus,
  IconPlayCircle,
  IconPlus,
  IconPlusOpenAll,
  IconReload,
  IconRestore,
  IconSave,
  IconSizingContract,
  IconSizingFullScreen,
  IconStopCircle,
  IconTrash,
  IconUpdate,
  IconX,
} from "@veneer/core";
import { saveAs } from "file-saver";
import _ from "lodash";
import { DateTime } from "luxon";
import moment from "moment";
import PapaParse from "papaparse";
import React from "react";
import { renderToString } from "react-dom/server";
import { Field } from "react-final-form";
import { UPDATE_METHODS } from "../admin-actions/adminActionsConstants";
import { getAzureB2C } from "../azureB2C/factories/azure-ad-factory";
import VeneerButton from "../common/components/VeneerButton";
import { LOG_ANALYTICS_RESOURCE_TYPE } from "../dashboard/dashboardConstants";
import {
  getMachineName,
  getResourceDetailsFromResourceList,
} from "../dashboard/dashboardUtils";
import AddProfileIcon from "../icons/addprofileIcon.svg";
import DeleteIcon from "../icons/deleteIcon.svg";
import EditIcon from "../icons/edit.svg";
import UpdateIcon from "../icons/updateIcon.svg";
import wexTheme from "../wexTheme";
import ButtonMenu from "./components/ButtonMenu";
import {
  API_ACCOUNT_ID_PREFIX,
  CUSTOM_ACTION_ID_PREFIX,
  DEFAULT_PERMISSIONS,
  DEVELOPMENT_ENVIRONMENT,
  IDENTITY_ID_PREFIX,
  INVITATION_ID_PREFIX,
  LOADING_TEXT,
  NOTIFICATION_PROFILE_ID_PREFIX,
  ORGANIZATION_ID_PREFIX,
  RESOURCE_ID_PREFIX,
  SUPPORT_ROLES,
  TENANT_ID_PREFIX,
  USER_TYPES,
  ZENDESK_CHAT_BOT_URL,
  ZENDESK_ELIGIBLE_ROLES,
} from "./constants";

export const shouldLimitMaxTenants =
  import.meta.env.REACT_APP_SHOULD_LIMIT_MAX_TENANTS || "false";
export const maxLimitOfTenants =
  import.meta.env.REACT_APP_MAX_NUMBER_TENANTS || 5;

export const B2C_REDIRECT_URL = import.meta.env.REACT_APP_B2C_REDIRECT_URL;
export const B2C_API_SCOPES = strToList(
  import.meta.env.REACT_APP_B2C_API_SCOPES,
  ",",
  true,
);
const BASE_URL = import.meta.env.REACT_APP_PROXY_API_URL;
export const ACCESS_TOKEN = "accessToken";
export const REQ = { redirectUri: B2C_REDIRECT_URL, scopes: B2C_API_SCOPES };
export const SESSION = "session";

export function strToList(str, delimiter = ",", noSpaces = false) {
  const newStr = noSpaces ? (str || "").replace(/\s/g, "") : str || "";
  return newStr.split(delimiter);
}

export function storeSession(session) {
  const cur = JSON.parse(localStorage.getItem(SESSION)) || {};
  const merged = { ...cur, ...session };
  localStorage.setItem(SESSION, JSON.stringify(merged));

  return merged;
}

export function getSession() {
  const session = localStorage.getItem(SESSION);

  if (session) {
    return JSON.parse(localStorage.getItem(SESSION));
  } else {
    return null;
  }
}

export const isAuthenticated = () => {
  return true;
};

export const shouldBlockTenantCreation = (tenantsLength) => {
  return shouldLimitMaxTenants === "true" && tenantsLength >= maxLimitOfTenants;
};

export const secondsToHms = (d) => {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var hDisplay =
    h > 0 ? h + (h === 1 ? " hr, " : ` hrs${m > 0 || s > 0 ? "," : ""} `) : "";
  var mDisplay =
    m > 0 ? m + (m === 1 ? " min, " : ` mins${s > 0 ? "," : ""} `) : "";
  var sDisplay = s > 0 ? s + (s === 1 ? " sec" : " secs") : "";
  return hDisplay + mDisplay + sDisplay;
};

export const shouldUseSentry = () => {
  return !!import.meta.env.REACT_APP_SENTRY_DSN;
};

export const PROFILE_PATH_TYPES = {
  CUSTOM: "custom",
  DATA_VOLUME: "dataVolume",
};

export const getMillisecondsForSelection = (selection) => {
  var currentTime = DateTime.local();
  switch (selection) {
    case "15_MIN":
      return {
        toDate: currentTime.ts,
        fromDate: currentTime.minus({ minutes: 15 }).ts,
      };
    case "1_HR":
      return {
        toDate: currentTime.ts,
        fromDate: currentTime.minus({ hours: 1 }).ts,
      };
    case "3_HR":
      return {
        toDate: currentTime.ts,
        fromDate: currentTime.minus({ hours: 3 }).ts,
      };
    case "1_DAY":
      return {
        toDate: currentTime.ts,
        fromDate: currentTime.minus({ days: 1 }).ts,
      };
    case "3_DAY":
      return {
        toDate: currentTime.ts,
        fromDate: currentTime.minus({ days: 3 }).ts,
      };
    case "7_DAY":
      return {
        toDate: currentTime.ts,
        fromDate: currentTime.minus({ days: 7 }).ts,
      };
    case "30_DAY":
      return {
        toDate: currentTime.ts,
        fromDate: currentTime.minus({ days: 30 }).ts,
      };
    default:
      return {};
  }
};

export function transformTimeseriesDataToChartData(data, objectData) {
  let dataEntity = [];
  let chartDataMap = {};
  if (data && Array.isArray(data)) {
    data.forEach((timeseriesObj, index) => {
      const key =
        objectData && objectData.length > 0 ? index : timeseriesObj.id;
      if (
        timeseriesObj &&
        timeseriesObj.timeseries &&
        Array.isArray(timeseriesObj.timeseries)
      ) {
        timeseriesObj.timeseries.forEach((timeseries) => {
          const dt = DateTime.fromMillis(timeseries.time);
          const name = timeseries.time;
          if (chartDataMap[name]) {
            chartDataMap[name][key] = timeseries.value;
          } else {
            var idField = timeseriesObj.id;

            if (objectData && objectData.length > 0) {
              const filteredResult = objectData.filter((result) => {
                if (result.id === timeseriesObj.id + "") {
                  return true;
                }
                return false;
              });
              if (filteredResult && filteredResult.length > 0) {
                idField = filteredResult[0].simpleName;
              }
            }
            chartDataMap[name] = {
              id: idField,
              name: name,
              [key]: timeseries.value,
              label: timeseries.time,
              toolTipLabel:
                dt.toLocaleString(DateTime.DATE_HUGE) +
                " " +
                dt.toLocaleString(DateTime.TIME_SIMPLE),
            };
          }
        });
      }
      dataEntity.push({ key: key });
    });
  }
  var chartData = Object.values(chartDataMap);
  chartData.sort((a, b) => a.name - b.name);
  return { chartData: chartData, dataEntity };
}

export function formatDt(time, selectedTimeInterval) {
  const dt = DateTime.fromMillis(time);
  switch (selectedTimeInterval) {
    case "15_MIN":
      return dt.toFormat("hh:mm a");
    case "1_HR":
      return dt.toFormat("hh:mm a");
    case "3_HR":
      return dt.toFormat("hh:mm a");
    case "1_DAY":
      return dt.toFormat("hh:mm a(dd. LLL)");
    case "3_DAY":
      return dt.toFormat("dd. LLL");
    case "7_DAY":
      return dt.toFormat("dd. LLL");
    case "30_DAY":
      return dt.toFormat("dd. LLL");
    default:
      return {};
  }
}

export const getAccessToken = async () => {
  const azureClient = getAzureB2C();
  const azureAccount = azureClient.getAccount();
  azureClient.setActiveAccount(azureAccount);
  try {
    const azureData = await azureClient.acquireTokenSilent({
      azureAccount,
      ...REQ,
      forceRefresh: false,
    });
    return azureData.accessToken;
  } catch (e) {
    if (e instanceof InteractionRequiredAuthError) {
      window.location.reload();
    }
  }
};

export const prettyPrice = (price = 0, numDigits = 2) => {
  if (isNaN(price) || price === null) {
    return "";
  }

  return (
    "$" +
    price.toLocaleString("en-us", {
      minimumFractionDigits: numDigits,
      maximumFractionDigits: numDigits,
    })
  );
};

export const convertEpochTimeToLocalTime = (epoch) => {
  const d = new Date(0); // The 0 there is the key, which sets the date to the epoch
  d.setUTCSeconds(epoch);
  return d;
};

export function queryString(params) {
  return Object.keys(params)
    .map(
      (key) => encodeURIComponent(key) + "=" + encodeURIComponent(params[key]),
    )
    .join("&");
}

export function loginRedirect(apiPath, query) {
  const params = queryString(query);
  const baseRedirectURL = `${BASE_URL}/login-redirects`;
  const url = `${baseRedirectURL}/${apiPath}?${params}`;
  window.location.href = url;
}

export const getTenantId = (tenantId) =>
  tenantId ? tenantId.split(TENANT_ID_PREFIX)[1] : "";

export const getCustomActionId = (customActionId) =>
  customActionId
    ? customActionId.split(CUSTOM_ACTION_ID_PREFIX)[1]?.split("/")[0]
    : "";

export const getApiAccountId = (id) => id.split(API_ACCOUNT_ID_PREFIX)[1];

export const getNotificationProfileId = (id) =>
  id.split(NOTIFICATION_PROFILE_ID_PREFIX)[1];

export const getConnectionId = (connectionId) =>
  connectionId ? connectionId.split("/").pop() : "";

export const getResourceId = (resourceId) =>
  resourceId.split(RESOURCE_ID_PREFIX).pop();

export function mapSocketData(data, socketData) {
  return data?.map((d) => {
    const mappedData = socketData.find(
      (m) =>
        getMachineName(d.name?.toLowerCase()) ===
        getMachineName(m.id?.toLowerCase()),
    );
    if (mappedData) {
      return { ...d, properties: { ...d.properties, ...mappedData } };
    }
    return d;
  });
}

export const checkLoading = (loading, value) => {
  return loading ? LOADING_TEXT : value;
};

export const getIdentityId = (identity = "") => {
  if (identity) {
    var identityPart = identity.split(ORGANIZATION_ID_PREFIX);
    if (identityPart && identityPart.length > 0) {
      return identityPart[1].split("/")[0];
    } else {
      return "";
    }
  }
  return "";
};

export const convertTenantDataForDropdown = (tenants) => {
  let dropdownData = [{ name: "All Tenant", value: "-1" }];
  if (tenants && tenants.length > 0) {
    tenants.forEach((tenant) => {
      dropdownData.push({
        name: tenant.displayName,
        value: getTenantId(tenant.id),
      });
    });
  }
  return dropdownData;
};

export const getIdentityIdFromId = (identity = "") =>
  identity.split(IDENTITY_ID_PREFIX)[1];

export const getSelectedIdentityDetails = (
  identities = [],
  selectedIdentity = "",
) => identities?.find((identity) => identity.id === selectedIdentity);

export const getInvitationId = (invitation) =>
  invitation.split(INVITATION_ID_PREFIX)[1].split("/")[0];

export function mapSocketDataFoUserSessions(data, socketData) {
  return data && data.length > 0
    ? data.map((d) => {
        const mappedData = socketData.find(
          (m) =>
            m.id_complete
              .toLowerCase()
              .includes(d.sessionHostName.toLowerCase()) &&
            m.id_complete
              ?.toLowerCase()
              .includes(d.properties?.userPrincipalName?.toLowerCase()),
        );
        if (mappedData) {
          return { ...d, properties: { ...d.properties, ...mappedData } };
        }
        return d;
      })
    : [];
}

export const formDropdownData = (
  data,
  nameKey,
  valueKey,
  excludeOtherFields = false,
) =>
  data.reduce((acc, m) => {
    if (m[nameKey]) {
      let t = excludeOtherFields ? {} : { ...m };
      t["name"] = m[nameKey];
      t["value"] = m[valueKey];
      acc.push(t);
    }
    return acc;
  }, []);

export const utcTimeToSpecificFormat = (utcTime, format = "DD MMM YYYY") => {
  return utcTime ? moment.utc(utcTime).local(format).format(format) : "";
};

export const timeToSpecificFormat = (timeStr, format = "DD MMM YYYY") => {
  return timeStr ? moment(timeStr).format(format) : "";
};

export const getTwoDecimalNumber = (num) => {
  return parseFloat(num.toFixed(2));
};

export const caseInsensitiveStringComparator = (a, b) => {
  a = a.toLowerCase();
  b = b.toLowerCase();
  if (a === b) {
    return 0;
  }
  if (a > b) {
    return 1;
  }
  return -1;
};

/**
 * Converts a label to camel case format.
 *
 * This function handles the following scenarios:
 * - Converts space-separated words to camel case (e.g., "create failed" to "createFailed").
 * - Converts concatenated words with uppercase letters to camel case (e.g., "CreateFailed" to "createFailed").
 * - Handles mixed cases where words are separated by spaces and uppercase letters (e.g., "Create Failed" to "createFailed").
 *
 * @param {string} label - The label to be converted.
 * @returns {string} The converted label in camel case format.
 */
export const formatLabelToCamelCase = (label) => {
  if (typeof label !== "string") {
    return label;
  }
  return _.camelCase(label);
};

export const getRefreshConfig = (
  handler,
  style = {},
  label = "",
  testId = "table-data-refresh-action",
) => ({
  title: (
    <VeneerButton
      style={style}
      appearance="secondary"
      leadingIcon={<IconReload />}
      small
    >
      {label}
    </VeneerButton>
  ),
  handler: (params) => handler(params),
  "data-testid": testId,
});

export const getArrowBoxConfig = (
  handler,
  style = {},
  label = "Cancel",
  testId = "cancel-permissions-update-for-role",
) => ({
  title: (
    <VeneerButton
      style={style}
      appearance="secondary"
      leadingIcon={<IconArrowBoxRight />}
      small
    >
      {label}
    </VeneerButton>
  ),
  handler: (e) => handler(e),
  "data-testid": testId,
});

export const getSaveConfig = (
  handler,
  style = {},
  label = "Save Permissions",
  testId = "save-permissions-for-role",
) => ({
  title: (
    <VeneerButton
      style={style}
      appearance="secondary"
      leadingIcon={<IconSave />}
      small
    >
      {label}
    </VeneerButton>
  ),
  handler: (e) => handler(e),
  "data-testid": testId,
});

export const getCloseConfig = (
  handler,
  style = {},
  label = "",
  testId = "close-test-for-role",
) => ({
  title: (
    <VeneerButton
      style={style}
      appearance="secondary"
      leadingIcon={<IconX />}
      small
    >
      {label}
    </VeneerButton>
  ),
  handler: (params) => handler(params),
  "data-testid": testId,
  hide: (params) => !params.selected.length > 0,
});

export const getEditButtonConfig = (
  handler,
  style = {},
  label = "Edit Permissions",
  testId = "edit-permissions-for-role",
) => ({
  title: (
    <VeneerButton
      style={style}
      appearance="secondary"
      leadingIcon={<IconPencil />}
      small
    >
      {label}
    </VeneerButton>
  ),
  handler: (e) => handler(e),
  "data-testid": testId,
});

export const getDownloadConfig = (
  handler,
  style = {},
  label = "Download",
  testId = "table-data-Download-action",
) => ({
  title: (
    <VeneerButton
      style={style}
      appearance="secondary"
      leadingIcon={<IconDownload />}
      small
    >
      {label}
    </VeneerButton>
  ),
  handler: (params) => handler(params),
  "data-testid": testId,
});

export const getPauseConfig = (
  handler,
  style = {},
  label = "Pause",
  testId = "table-data-pause-action",
) => ({
  title: (
    <VeneerButton
      style={style}
      appearance="secondary"
      leadingIcon={<IconPauseCircle />}
      small
    >
      {label}
    </VeneerButton>
  ),
  handler: (e) => handler(e),
  "data-testid": testId,
});

export const getButtonConfig = (
  handler,
  style = {},
  label = "Pause",
  testId = "veneer-button-table-data-action",
) => ({
  title: (
    <VeneerButton style={style} appearance="secondary" small>
      {label}
    </VeneerButton>
  ),
  handler: (params) => handler(params),
  "data-testid": testId,
});

export const isWithinLastOneDay = (date) =>
  moment(date).isBetween(moment().subtract(1, "days"), moment().format());

export const getAddNewConfig = (
  handler,
  style = {},
  label = "",
  testId = "table-data-add-action",
  blockCreation = false,
) => ({
  title: (
    <VeneerButton
      style={style}
      disabled={blockCreation}
      appearance="secondary"
      leadingIcon={<IconPlus />}
      small
    >
      {label}
    </VeneerButton>
  ),
  handler: (e) => handler(e),
  "data-testid": testId,
});

export const getEditConfig = (
  handler,
  title = "",
  datatestId = "table-data-edit-action",
  tooltip = "Edit",
  style = {},
) => ({
  title: title,
  icon: (
    <Tooltip title={tooltip}>
      <img src={EditIcon} alt="emptyList" height={"17"} style={style} />
    </Tooltip>
  ),
  "data-testid": datatestId,
  handler: (params) => handler(params),
});

export const getUpdateConfig = (
  handler,
  title = "",
  datatestId = "table-data-update-action",
  tooltip = "Update",
) => ({
  title: title,
  icon: (
    <Tooltip title={tooltip}>
      <img src={UpdateIcon} alt="emptyList" height={"22"} />
    </Tooltip>
  ),
  "data-testid": datatestId,
  handler: (params) => handler(params),
});

export const getDeleteConfig = (
  handler,
  title = "",
  datatestId = "table-data-delete-action",
  tooltip = "Delete",
) => ({
  title: title,
  icon: (
    <Tooltip title={tooltip}>
      <img
        src={DeleteIcon}
        alt="emptyList"
        height={"23"}
        style={{ marginTop: "3px" }}
      />
    </Tooltip>
  ),
  "data-testid": datatestId,
  handler: (params) => handler(params),
});

export const getAssignUserConfig = (
  handler,
  title = "",
  datatestId = "table-data-add-profile-action",
  tooltip = "Add Profile",
) => ({
  title: title,
  icon: (
    <Tooltip title={tooltip}>
      <img
        src={AddProfileIcon}
        alt="emptyList"
        height={"23"}
        style={{ marginTop: "3px" }}
      />
    </Tooltip>
  ),
  "data-testid": datatestId,
  handler: (params) => handler(params),
});

export const componentIconToURL = (icon, color) => {
  const svgIconString = renderToString(icon);
  const svgIconStringWithColor = svgIconString.replace(
    /currentColor/g,
    color || "currentColor",
  );
  const blob = new Blob([svgIconStringWithColor], { type: "image/svg+xml" });
  return URL.createObjectURL(blob);
};

export const getTimezoneOffSet = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const haveElibileRole = (session, roles) => {
  if (session.roles) {
    const assignedRoles = Object.values(session.roles);
    const isEligible = roles.reduce((eligible, role) => {
      if (!eligible && assignedRoles.indexOf(role) >= 0) {
        eligible = true;
      }
      return eligible;
    }, false);
    return isEligible;
  }
  return false;
};

export const addZenDeskChatBot = (userSession) => {
  // If not ZENDESK_CHAT_BOT_URL is not defined, return
  if (!ZENDESK_CHAT_BOT_URL) {
    /* console.debug(
      "ZENDESK_CHAT_BOT_URL is not defined, so zendesk chat bot will not be added.",
    ); */
    return;
  }

  window.zESettings = {
    webWidget: {
      offset: { horizontal: "left" },
      color: {
        theme: "#006FE6",
      },
      chat: {
        departments: {
          enabled: ["Spot PC"],
          select: "Spot PC",
        },
      },
    },
  };
  const zendeskScript = document.getElementById("ze-snippet");
  const supportPermissions = haveElibileRole(
    userSession,
    ZENDESK_ELIGIBLE_ROLES,
  );
  if (!zendeskScript && userSession && supportPermissions) {
    const script = document.createElement("script");
    script.id = "ze-snippet";
    script.src = ZENDESK_CHAT_BOT_URL;
    script.async = true;
    document.body.appendChild(script);
  } else if (
    userSession &&
    zendeskScript &&
    window.zE &&
    typeof window.zE.show === "function" &&
    supportPermissions
  ) {
    window.zE.show();
  } else if (
    userSession &&
    zendeskScript &&
    window.zE &&
    typeof window.zE.hide === "function" &&
    !supportPermissions
  ) {
    window.zE.hide();
  }
};
export const Condition = ({ when, is, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) =>
      (typeof is === "string" ? value === is : is.indexOf(value) > -1)
        ? children
        : null
    }
  </Field>
);

export const ConditionValueObject = ({ when, is, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value: selectedOption } }) =>
      selectedOption?.value === is?.value ? children : null
    }
  </Field>
);

export const getFormattedDate = (
  date,
  dateOffset = 0,
  monthOffset = 0,
  yearOffSet = 0,
  hourOffset = 0,
  minutesOffSet = 0,
  dateOnlyFormat = false,
) => {
  var currentdate = new Date(date);
  var dd = currentdate.getDate() + dateOffset;
  var mm = currentdate.getMonth() + 1 + monthOffset; //January is 0 so need to add 1 to make it 1!
  var yyyy = currentdate.getFullYear() + yearOffSet;
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  const hh = currentdate.getHours() + hourOffset;
  const MM = currentdate.getMinutes() + minutesOffSet;
  if (dateOnlyFormat) {
    return `${yyyy}-${mm}-${dd}`;
  } else {
    return `${yyyy}-${mm}-${dd}T${hh}:${MM}:00`;
  }
};

export const exportSecurityAlertLogsData = async (dataSet) => {
  const customData = [];
  dataSet.map((a) => {
    const resourceList = a.properties?.resourceIdentifiers?.filter(
      (resource) => resource.type !== LOG_ANALYTICS_RESOURCE_TYPE,
    );
    if (resourceList && resourceList.length > 0) {
      const resourceDetails = getResourceDetailsFromResourceList(resourceList);
      customData.push({
        ...a,
        properties: {
          ...a.properties,
          resourceIdentifiers: resourceDetails["Virtual Machine"]
            ? resourceDetails["Virtual Machine"]
            : "N/A",
        },
      });
    } else {
      customData.push({
        ...a,
        properties: { ...a.properties, resourceIdentifiers: "N/A" },
      });
    }
  });
  return customData;
};

export const exportSecurityRecommendationLogsData = async (dataSet) => {
  const customData = [];
  dataSet.map((a) => {
    const total = a?.resourceCount;
    const unhealthy =
      a?.statusAndCount?.find((s) => s.statusCode === "Unhealthy")
        ?.resourceCount || 0;
    const healthy = total - unhealthy;
    customData.push({
      ...a,
      exposedMachinesCount: `${healthy} | ${unhealthy}`,
    });
  });
  return customData;
};

export const convertToCsv = (data, columns) => {
  const headerLookup = columns.reduce((acc, c) => {
    if (c.accessor && c.header) {
      acc[c.accessor] = c.header;
    }
    return acc;
  }, {});
  let csvData = data.reduce((acc1, d) => {
    const row = Object.keys(headerLookup).reduce((acc2, h) => {
      acc2[headerLookup[h]] = _.get(d, h);
      return acc2;
    }, {});

    if (Object.keys(row).length > 0) {
      acc1.push(row);
    }
    return acc1;
  }, []);

  const str = PapaParse.unparse(csvData, { header: true });
  return new Blob([str], { type: "text/csv" });
};

export function exportToCsv(data, columns, filename) {
  const blob = convertToCsv(data, columns);
  const date = DateTime.local()
    .toLocaleString(DateTime.DATE_SHORT)
    .replace(/\//g, "-");
  return saveAs(blob, `${filename} (${date}).csv`);
}

export const hasAccessToComponent = (componentName, userOrgRole) => {
  if (DEFAULT_PERMISSIONS[userOrgRole]) {
    if (
      DEFAULT_PERMISSIONS[userOrgRole] &&
      DEFAULT_PERMISSIONS[userOrgRole].includes(componentName)
    ) {
      return true;
    } else {
      return false;
    }
  }
  return true;
};
export const checkAdminPermissions = (
  routePermissionsSet = [],
  userPermissionsList,
  userSupportView,
) => {
  if (!userSupportView && routePermissionsSet.length > 0) {
    if (!userPermissionsList) {
      return false;
    }
    let permitted = false;
    routePermissionsSet.forEach((permission) => {
      if (!permitted && userPermissionsList[permission]) {
        permitted = true;
      }
    });
    return permitted;
  }
  return true;
};
export const checkTenantPermissions = (
  routePermissionsSet = [],
  userPermissionsList,
  selectedTenant,
  userSupportView,
) => {
  if (!userSupportView && selectedTenant && routePermissionsSet.length > 0) {
    if (!userPermissionsList || !userPermissionsList[selectedTenant]) {
      return false;
    }
    let permitted = false;
    routePermissionsSet.forEach((permission) => {
      if (!permitted && userPermissionsList[selectedTenant][permission]) {
        permitted = true;
      }
    });
    return permitted;
  }
  return true;
};
export const hasAccessToRoute = (
  routeRoles = [],
  userRoles = [],
  userSupportView,
  isPrivate = false,
  adminView = false,
  tenant = {},
  routeOrgRoles = null,
  userOrgRole = null,
) => {
  if (routeOrgRoles && !userSupportView && userOrgRole) {
    if (userOrgRole && routeOrgRoles.includes(userOrgRole)) {
      return true;
    } else {
      return false;
    }
  } else if (routeOrgRoles && !userOrgRole) {
    return false;
  }
  if (!adminView && tenant?.supportAccessEnabled && userSupportView) {
    return true;
  } else if (
    _.intersection(userRoles, SUPPORT_ROLES).length > 0 ||
    SUPPORT_ROLES.includes(userRoles)
  ) {
    if (
      (userSupportView &&
        _.intersection(routeRoles, SUPPORT_ROLES)?.length > 0) ||
      (!userSupportView && !isPrivate)
    ) {
      return true;
    } else {
      return false;
    }
  } else if (isPrivate) {
    return false;
  }
  return true;
};

export const isUserSupport = (userRoles = []) => {
  return (
    false &&
    (_.intersection(userRoles, SUPPORT_ROLES).length > 0 ||
      SUPPORT_ROLES.includes(userRoles))
  );
};
export const needToFetchIdentities = (
  currentIdentitiesUser,
  userSupportView,
) => {
  if (currentIdentitiesUser === USER_TYPES.NORMAL_USER && userSupportView) {
    return true;
  }
  if (currentIdentitiesUser === USER_TYPES.SUPPORT_USER && !userSupportView) {
    return true;
  }
  return false;
};

export const calculateTotalTenantsForAllOrgs = (identities) => {
  if (identities) {
    return identities.reduce((acc, i) => {
      acc += i.tenants ? i.tenants.length : 0;
      return acc;
    }, 0);
  }
};

const comparator = (a, b) => {
  if (a > b) {
    return -1;
  }
  if (a < b) {
    return 1;
  }
  return 0;
};

/**
 * Retries a given function with exponential backoff.
 *
 * @param {Function} fn - The function to be retried.
 * @param {number} [maxRetries=3] - The maximum number of retries.
 * @param {number} [delay=500] - The delay between retries in milliseconds.
 * @returns {Promise<any>} - A promise that resolves with the result of the function.
 * @throws {Error} - Throws an error if the maximum number of retries is exceeded.
 */
export const exponentialRetry = async (fn, maxRetries = 3, delay = 30000) => {
  const retry = async (retriesLeft) => {
    try {
      return await fn();
    } catch (error) {
      if (retriesLeft < 1) {
        throw error;
      }
      await new Promise((resolve) =>
        setTimeout(resolve, delay * (maxRetries - retriesLeft + 1)),
      );
      return retry(retriesLeft - 1);
    }
  };
  return retry(maxRetries);
};

const customizedSort = (accesor, customArray) => (a, b) => {
  const first = customArray.indexOf(a[accesor]);
  const second = customArray.indexOf(b[accesor]);
  return comparator(first, second);
};

const transformLogsRowValue = (column, value) => {
  switch (column) {
    case "SessionTarget":
      if (value === "Disconnected") {
        return "Reconnect";
      }
      break;
    case "GatewayRegion":
      if (value === "<>") {
        return null;
      }
      break;
    case "UdpUse":
      if (value === "<>") {
        return null;
      }
      break;
    case "SessionHostName":
      return value ? value.split(".")[0] : value;
    default:
      return value;
  }
};

export const transformLogsData = (
  data,
  columnsOrder = [],
  textToJsonColumn,
  rowDataTransformation,
) => {
  const table = data?.tables?.[0] || {};
  const rows = table?.rows?.map((row) =>
    row.reduce(
      (prev, curr, index) => ({
        ...prev,
        [table.columns[index].name]:
          textToJsonColumn && textToJsonColumn === table.columns[index].name
            ? JSON.parse(curr)
            : rowDataTransformation
              ? transformLogsRowValue(table.columns[index].name, curr)
              : curr,
      }),
      {},
    ),
  );
  const columns = [...(table?.columns || [])].sort(
    customizedSort("name", [...columnsOrder].reverse()),
  );
  return { rows, columns };
};

export const filterCheckpointsData = (data) => {
  let rows = data.rows;
  rows.map((d) => {
    let filteredCheckpoints = [];
    let durationList = d.Duration?.split(".");
    let noOfDays;
    let durationSplit = [];
    if (durationList?.length === 3) {
      noOfDays = durationList[0] + "d";
      durationSplit = durationList[1]?.split(":");
    } else if (durationList?.length === 2) {
      durationSplit = durationList[0]?.split(":");
    } else {
      durationSplit = d.Duration?.split(":");
    }
    if (durationSplit) {
      d.Duration = noOfDays
        ? noOfDays +
          (durationSplit[0] !== "00" ? durationSplit[0] + "h" : "") +
          (durationSplit[1] !== "00" ? durationSplit[1] + "m" : "") +
          (durationSplit[2] !== "00" ? durationSplit[2] + "s" : "")
        : (durationSplit[0] !== "00" ? durationSplit[0] + "h" : "") +
          (durationSplit[1] !== "00" ? durationSplit[1] + "m" : "") +
          (durationSplit[2] !== "00" ? durationSplit[2] + "s" : "");
    }
    if (d.Checkpoints) {
      d.Checkpoints.some((checkpoint) => {
        filteredCheckpoints.push(checkpoint);
      });
      d.Checkpoints = filteredCheckpoints;
    }
    return d;
  });
  data.rows = rows;
  return data;
};

export const checkIfOrgIdIsAvailableInIdentities = (identities, targetId) => {
  return !!identities?.find((i) => i.id === targetId);
};

export const calculateDaysDifference = (date) => {
  const a = moment().startOf("day");
  const b = moment(date);
  return Math.abs(a.diff(b, "days"));
};

export const generateBreadcrumb = (pathname) => {
  const currentPath = pathname.split("?")[0];

  // Hardcoded object where each breadcrumb path is mapped to its parent
  // If an element need to be replaced by the actual value, it should be enclosed in <>.
  // If the destinationUrl is different from the url, it should be specified in the destinationUrl property.
  // Ex,: when clicking the tenants, the page to be redirect to is /dashboard/userSessions, but the /dashboard
  // is the actual URL to detect the tenant page.

  const breadcrumbMap = [
    { text: "Tenants", url: "/admin", parent: null },
    { text: "Settings", url: "/settings", parent: null },
    { text: "General", url: "/settings/general", parent: "Settings" },
    { text: "Licenses", url: "/settings/licenses", parent: "Settings" },

    // TODO: discuss if it is correct that the page where the userSessions is shown
    // is the same page shown when you go to /dashboard.
    {
      text: "<tenantName>",
      url: "/dashboard",
      parent: "Tenants",
      destinationUrl: "/dashboard/userSessions",
    },

    { text: "Accounts", url: "/admin/manage/accounts", parent: "Tenants" },
    { text: "Roles", url: "/admin/manage/roles", parent: "Tenants" },
    {
      text: "Activity logs",
      url: "/admin/manage/activityLogs",
      parent: "Tenants",
    },

    { text: "Add tenant", url: "/selectOnboardingWizard", parent: "Tenants" },
    {
      text: "Creating Windows 365 Cloud PC tenant",
      url: "/onboardingAzureTenant",
      parent: "Tenants",
    },
    {
      text: "Creating VMware tenant",
      url: "/onboardingVSphere",
      parent: "Tenants",
    },

    {
      text: "Edit tenant details",
      url: "/tenants/edit",
      parent: "<tenantName>",
    },

    {
      text: "User sessions",
      url: "/dashboard/userSessions",
      parent: "<tenantName>",
    },
    { text: "Machines", url: "/dashboard/machines", parent: "<tenantName>" },

    { text: "Logs", url: "/dashboard/logs", parent: "<tenantName>" },
    {
      text: "Admin activity",
      url: "/dashboard/logs/adminActivity",
      parent: "Logs",
    },
    {
      text: "User logon Activity",
      url: "/dashboard/logs/userLogonTime",
      parent: "Logs",
    },

    { text: "Sites", url: "/sites", parent: "<tenantName>" },
    {
      text: "API accounts",
      url: "/extensibility/accounts",
      parent: "<tenantName>",
    },
    { text: "Create site", url: "/sites/action/create", parent: "Sites" },
    {
      text: "Creating Windows 365 Cloud PC Site",
      url: "/sites/action/create/win365",
      parent: "Sites",
    },
    {
      text: "Creating VMware Site",
      url: "/sites/action/create/vmware",
      parent: "Sites",
    },
    { text: "<siteName>", url: "/sites/action/edit", parent: "Sites" },

    { text: "Pools", url: "/dashboard/pools", parent: "<tenantName>" },
    { text: "<poolName>", url: "dashboard/pools/progress", parent: "Pools" },
    {
      text: "<poolName>",
      url: "/dashboard/pools/.*/",
      parent: "Pools",
      regex: true,
    },

    // /dashboard/machines/<machineName>/<siteName>
    {
      text: "<machineName>",
      url: "/dashboard/machines/.*/",
      parent: "Machines",
      regex: true,
    },
    // http://localhost:3000/dashboard/machines/FSVM3/FS-site1/processes
    {
      text: "Processes",
      url: "/dashboard/machines/.*/.*/processes",
      parent: "<machineName>",
      regex: true,
    },
    // http://localhost:3000/dashboard/machines/FSVM3/FS-site1/activeSessions
    {
      text: "Active Sessions",
      url: "/dashboard/machines/.*/.*/activeSessions",
      parent: "<machineName>",
      regex: true,
    },
    // http://localhost:3000/dashboard/machines/FSVM3/FS-site1/userLogonActivity
    {
      text: "User Logon Activity",
      url: "/dashboard/machines/.*/.*/userLogonActivity",
      parent: "<machineName>",
      regex: true,
    },
    // http://localhost:3000/dashboard/machines/FSVM3/FS-site1/customActions
    {
      text: "Custom Actions",
      url: "/dashboard/machines/.*/.*/customActions",
      parent: "<machineName>",
      regex: true,
    },

    {
      text: "Add Pool",
      url: "/dashboard/pools/addPool/.*",
      parent: "Pools",
      regex: true,
    },

    // { text: "Create site", url: "/sites/action/create", parent: "Sites" },
    // { text: "<siteName>", url: "/sites/detail", parent: "Sites" },
  ];

  // sort by regex first, sorted by length of url, then the non-regex also sorted by length of url
  breadcrumbMap.sort((a, b) => {
    if (a.regex && !b.regex) {
      return -1;
    }
    if (!a.regex && b.regex) {
      return 1;
    }
    if (a.url.length > b.url.length) {
      return -1;
    }
    if (a.url.length < b.url.length) {
      return 1;
    }
    return 0;
  });

  // Find a path in the breadcrumbMap that matches the current pathname and follow the breadcrumb parents sequence

  let matchingBreadCrumb;
  // If the breadcrumb is regex, try to match the regex
  matchingBreadCrumb = breadcrumbMap.find(
    (b) => b.regex && new RegExp(b.url).test(currentPath),
  );

  if (!matchingBreadCrumb) {
    matchingBreadCrumb = breadcrumbMap.find(
      (b) => b.url === currentPath || currentPath.includes(b.url),
    );
  }

  const breadcrumbList = [];
  if (matchingBreadCrumb) {
    breadcrumbList.unshift(matchingBreadCrumb);
    while (matchingBreadCrumb.parent) {
      matchingBreadCrumb = breadcrumbMap.find(
        (b) => b.text === matchingBreadCrumb.parent,
      );
      breadcrumbList.unshift(matchingBreadCrumb);
    }
  }
  // Remove the url of the last breadcrumb, as it should not be clickable
  if (breadcrumbList.length > 0) {
    breadcrumbList[breadcrumbList.length - 1].url = null;
    breadcrumbList[breadcrumbList.length - 1].destinationUrl = null;
  }

  // If a single breadcrumb is found, return an empty array, as the root breadcrumb is not needed
  return breadcrumbList.length > 1 ? breadcrumbList : [];
};

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const saveSelectedOrganizationId = (completeOrgId) => {
  sessionStorage.setItem("selectedOrgId", completeOrgId);
  localStorage.setItem("selectedOrgId", completeOrgId);
};

export const getSelectedOrganizationId = () => {
  return (
    sessionStorage.getItem("selectedOrgId") ||
    localStorage.getItem("selectedOrgId")
  );
};

export const saveSelectedTenantId = (completeTenantId) => {
  sessionStorage.setItem("selectedTenantId", completeTenantId);
  localStorage.setItem("selectedTenantId", completeTenantId);
};

export const getSelectedTenantId = () => {
  return (
    sessionStorage.getItem("selectedTenantId") ||
    localStorage.getItem("selectedTenantId")
  );
};

export const checkIfFutureUpdateIsPending = (row) => {
  let isPending = false;
  if (row?.imageSetProperties?.imageSetHistory?.length > 0) {
    row.imageSetProperties?.imageSetHistory.forEach((i) => {
      if (i.releaseMethod === UPDATE_METHODS.SCHEDULED && !isPending) {
        const now = moment(new Date()); //todays date
        const end = moment(i.releaseScheduledTimeUTC);
        const duration = moment.duration(now.diff(end));
        if (duration < 0) {
          isPending = true;
        }
      }
    });
  }
  return isPending;
};

export const reactHookFormErrorToMeta = (errors, key) => {
  if (!errors || !errors[key]) {
    return;
  }

  return { touched: true, error: errors[key].message || true };
};

export const cleanRoutePathName = (pathname) => {
  const actualPath = pathname.endsWith("/") ? pathname.slice(0, -1) : pathname;

  return actualPath.toLowerCase();
};

export const getFormFieldError = (errors, fieldName) => {
  return (
    !!fieldName && !!errors && !!errors[fieldName] && errors[fieldName].message
  );
};

export const checkStringIncludes = (a, b, caseSensitive = false) => {
  if (caseSensitive) {
    return a.includes(b);
  }

  return a.toLocaleLowerCase().includes(b.toLocaleLowerCase());
};

export const generateRandomId = (prefix) =>
  `${prefix || "randomId"}-${Date.now() + Math.random()}`;

export const timeToMilliseconds = (hr, min, sec) =>
  (hr * 60 * 60 + min * 60 + sec) * 1000;

export const MillisecondsToHMS = (ms) => {
  let seconds = ms / 1000;
  const hours = parseInt(seconds / 3600);
  seconds = seconds % 3600;
  const minutes = parseInt(seconds / 60);
  seconds = seconds % 60;

  return `${hours ? `${hours} hour(s)` : ""} ${minutes ? `${minutes} minute(s)` : ""} ${seconds ? `${seconds} second(s)` : ""}`;
};

const getPlural = (word, value) => `${word}${!value ? "" : "s"}`;

const getDurationStr = (word, value) =>
  value ? `${value} ${getPlural(word, value)}` : "";

export const formatDuration = (duration, unit = "seconds") => {
  const whole = moment.duration(duration, unit);
  const daysStr = getDurationStr("day", whole.days());
  const hoursStr = getDurationStr("hour", whole.hours());
  const minutesStr = getDurationStr("minute", whole.minutes());
  const secondsStr = getDurationStr("second", whole.seconds());
  let result = "";

  if (daysStr) {
    result += `${daysStr} `;
  }

  if (hoursStr) {
    result += `${hoursStr} `;
  }

  if (minutesStr) {
    result += `${minutesStr} `;
  }

  if (secondsStr) {
    result += `${secondsStr} `;
  }

  return result;
};

const customStyle = {
  color: wexTheme.color.light.foreground.default,
  width: "100%",
  justifyContent: "flex-start",
};

export const tableActions = {
  CheckboxSelectAll: (
    handler,
    label = "Complete onboarding",
    testId = "table-data-complete-onboarding",
    style = {},
  ) => ({
    "data-testid": testId,
    button: true,
    title: (
      <ButtonMenu
        style={{ ...customStyle, ...style }}
        leadingIcon={<IconCheckboxSelectAll />}
      >
        {label}
      </ButtonMenu>
    ),
    handler: (params) => handler(params),
  }),
  Refresh: (
    handler,
    label = "",
    testId = "table-data-refresh-action",
    style = {},
  ) => ({
    "data-testid": testId,
    button: true,
    title: (
      <ButtonMenu
        style={{ ...customStyle, ...style }}
        leadingIcon={<IconReload />}
      >
        {label}
      </ButtonMenu>
    ),
    handler: (params) => handler(params),
  }),
  Add: (
    handler,
    label = "",
    testId = "table-data-add-table-action",
    style = {},
  ) => ({
    "data-testid": testId,
    button: true,
    title: (
      <ButtonMenu
        style={{ ...customStyle, ...style }}
        leadingIcon={<IconPlus />}
      >
        {label}
      </ButtonMenu>
    ),
    handler: (params) => handler(params),
  }),
  Edit: (
    handler,
    label = "Edit",
    testId = "table-data-edit-table-action",
    style = {},
  ) => ({
    "data-testid": testId,
    button: true,
    title: (
      <ButtonMenu
        style={{ ...customStyle, ...style }}
        leadingIcon={<IconPencil />}
      >
        {label}
      </ButtonMenu>
    ),
    handler: (params) => handler(params),
  }),
  Delete: (
    handler,
    label = "Delete",
    testId = "table-data-delete-table-action",
    style = {},
  ) => ({
    "data-testid": testId,
    button: true,
    title: (
      <ButtonMenu
        style={{ ...customStyle, ...style }}
        leadingIcon={<IconTrash />}
      >
        {label}
      </ButtonMenu>
    ),
    handler: (params) => handler(params),
  }),
  Code: (
    handler,
    label = "View Code",
    testId = "view-code-custom-action",
    style = {},
  ) => ({
    "data-testid": testId,
    button: true,
    title: (
      <ButtonMenu
        style={{ ...customStyle, ...style }}
        leadingIcon={<IconApplicationCode />}
      >
        {label}
      </ButtonMenu>
    ),
    handler: (params) => handler(params),
  }),
  Note: (
    handler,
    label = "Add Note",
    testId = "note-custom-action",
    style = {},
  ) => ({
    "data-testid": testId,
    button: true,
    title: (
      <ButtonMenu
        style={{ ...customStyle, ...style }}
        leadingIcon={<IconApplicationCode />}
      >
        {label}
      </ButtonMenu>
    ),
    handler: (params) => handler(params),
  }),
  Close: (
    handler,
    label = "Close",
    testId = "close-custom-action",
    style = {},
  ) => ({
    "data-testid": testId,
    button: true,
    title: (
      <ButtonMenu style={{ ...customStyle, ...style }} leadingIcon={<IconX />}>
        {label}
      </ButtonMenu>
    ),
    handler: (params) => handler(params),
  }),
  Update: (
    handler,
    label = "Update",
    testId = "close-custom-action",
    style = {},
  ) => ({
    "data-testid": testId,
    button: true,
    title: (
      <ButtonMenu
        style={{ ...customStyle, ...style }}
        leadingIcon={<IconUpdate />}
      >
        {label}
      </ButtonMenu>
    ),
    handler: (params) => handler(params),
  }),
  AddUser: (
    handler,
    label = "",
    testId = "add-user-custom-action",
    style = {},
  ) => ({
    "data-testid": testId,
    button: true,
    title: (
      <ButtonMenu
        style={{ ...customStyle, ...style }}
        leadingIcon={<IconPersonPlus />}
      >
        {label}
      </ButtonMenu>
    ),
    handler: (params) => handler(params),
  }),
  FullScreen: (
    handler,
    label = "",
    testId = "full-screen-custom-action",
    style = {},
  ) => ({
    "data-testid": testId,
    button: true,
    title: (
      <ButtonMenu
        style={{ ...customStyle, ...style }}
        leadingIcon={<IconSizingFullScreen />}
      >
        {label}
      </ButtonMenu>
    ),
    handler: (params) => handler(params),
  }),
  ExitFullScreen: (
    handler,
    label = "",
    testId = "full-screen-custom-action",
    style = {},
  ) => ({
    "data-testid": testId,
    button: true,
    title: (
      <ButtonMenu
        style={{ ...customStyle, ...style }}
        leadingIcon={<IconSizingContract />}
      >
        {label}
      </ButtonMenu>
    ),
    handler: (params) => handler(params),
  }),
  Download: (
    handler,
    label = "Download",
    testId = "Download-custom-action",
    style = {},
  ) => ({
    "data-testid": testId,
    button: true,
    title: (
      <ButtonMenu
        style={{ ...customStyle, ...style }}
        leadingIcon={<IconDownload />}
      >
        {label}
      </ButtonMenu>
    ),
    handler: (params) => handler(params),
  }),
  Export: (
    handler,
    label = "",
    testId = "export-custom-action",
    style = {},
  ) => ({
    "data-testid": testId,
    button: true,
    title: (
      <ButtonMenu
        style={{ ...customStyle, ...style }}
        leadingIcon={<IconArrowBoxRight />}
      >
        {label}
      </ButtonMenu>
    ),
    handler: (params) => handler(params),
  }),
  Stop: (
    handler,
    label = "Stop",
    testId = "stop-custom-action",
    style = {},
  ) => ({
    "data-testid": testId,
    button: true,
    title: (
      <ButtonMenu
        style={{ ...customStyle, ...style }}
        leadingIcon={<IconStopCircle />}
      >
        {label}
      </ButtonMenu>
    ),
    handler: (params) => handler(params),
  }),
  ForceStop: (
    handler,
    label = "Force Stop",
    testId = "force-stop-custom-action",
    style = {},
  ) => ({
    "data-testid": testId,
    button: true,
    title: (
      <ButtonMenu
        style={{ ...customStyle, ...style }}
        leadingIcon={<IconStopCircle />}
      >
        {label}
      </ButtonMenu>
    ),
    handler: (params) => handler(params),
  }),
  Restart: (
    handler,
    label = "Restart",
    testId = "restart-custom-action",
    style = {},
  ) => ({
    "data-testid": testId,
    button: true,
    title: (
      <ButtonMenu
        style={{ ...customStyle, ...style }}
        leadingIcon={<IconRestore />}
      >
        {label}
      </ButtonMenu>
    ),
    handler: (params) => handler(params),
  }),
  Clone: (
    handler,
    label = "Clone",
    testId = "clone-custom-action",
    style = {},
  ) => ({
    "data-testid": testId,
    button: true,
    title: (
      <ButtonMenu
        style={{ ...customStyle, ...style }}
        leadingIcon={<IconDuplicateJob />}
      >
        {label}
      </ButtonMenu>
    ),
    handler: (params) => handler(params),
  }),
  Start: (
    handler,
    label = "Start",
    testId = "start-custom-action",
    style = {},
  ) => ({
    "data-testid": testId,
    button: true,
    title: (
      <ButtonMenu
        style={{ ...customStyle, ...style }}
        leadingIcon={<IconPlayCircle />}
      >
        {label}
      </ButtonMenu>
    ),
    handler: (params) => handler(params),
  }),
  AddResumeOnboarding: (
    handler,
    label = "Complete onboarding",
    testId = "table-data-add-onboarding",
    style = {},
  ) => ({
    "data-testid": testId,
    button: true,
    title: (
      <ButtonMenu
        style={{ ...customStyle, ...style }}
        leadingIcon={<IconPlusOpenAll />}
      >
        {label}
      </ButtonMenu>
    ),
    handler: (params) => handler(params),
  }),
  Invite: (
    handler,
    label = "Invite",
    testId = "table-data-invite",
    style = {},
  ) => ({
    "data-testid": testId,
    button: true,
    title: (
      <ButtonMenu
        style={{ ...customStyle, ...style }}
        leadingIcon={<IconPaperAirplane />}
      >
        {label}
      </ButtonMenu>
    ),
    handler: (params) => handler(params),
  }),
};

export const sanitizeSpaces = (string) => {
  return string.replace(/\s\s+/g, " ").trim();
};

export const statusMessages = {
  400: "Bad Request",
  401: "Unauthorized",
  403: "Forbidden",
  404: "Not Found",
  405: "Method Not Allowed",
  500: "Internal Server Error",
  502: "Bad Gateway",
  503: "Service Unavailable",
  504: "Gateway Timeout",
};

export const getMessageForCode = (code) => {
  return (
    statusMessages[code] || code || "An error occurred. Please try again later."
  );
};

export const isDevelopment = () =>
  import.meta.env.REACT_APP_ENVIRONMENT === DEVELOPMENT_ENVIRONMENT;
