import Drawer, { drawerClasses } from "@mui/material/Drawer";
import { paperClasses } from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { SideMenu } from "@veneer/core";
import clsx from "clsx";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { toggleSidebar } from "../../../sidebarSlice";
import { HPIconLarge, HPIconSmall } from "./logos";
import { SidebarContent } from "./SidebarContent";

const PREFIX = "styled-sidebar-menu-admin";

const classes = {
  drawer: `${PREFIX}-drawer`,
  drawerOpen: `${PREFIX}-drawerOpen`,
  drawerClose: `${PREFIX}-drawerClose`,
  sideBar: `${PREFIX}-sideBar`,
};

const StyledSidebarMenuAdmin = styled("div")(() => ({
  [`& .${classes.drawer}`]: {
    width: "304px",
  },

  [`& .${classes.drawerOpen}`]: {
    width: "304px",
  },

  [`& .${classes.drawerClose}`]: {
    width: 80,
  },
}));

const CustomDrawer = styled(Drawer)(({ theme }) => ({
  [`&.${drawerClasses.root}`]: {
    border: "none",
    [`& .${classes.drawer}`]: {
      width: "304px",
      flexShrink: 0,
      transition: theme.transitions.create(["width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    [`& .${classes.drawerOpen}`]: {
      color: theme.palette.background.stroke,
      display: "flex",
      width: "fit-content",
      flexDirection: "column",
      justifyContent: "spaceBetween",
      alignItems: "flexStart",
      flexShrink: "0",
      [`& .${classes.sideBar}`]: {
        width: "304px",
      },
      [`& .${classes.sideBar} > *`]: {
        width: "100%",
      },
    },
    [`& .${classes.drawerClose}`]: {
      color: theme.palette.background.stroke,
      display: "flex",
      width: "80px",
      flexDirection: "column",
      justifyContent: "spaceBetween",
      alignItems: "flexStart",
      flexShrink: 0,
      overflowX: "hidden",
    },
    [`& .${paperClasses.root}`]: {
      border: "none",
    },
    [`& .${classes.sideBar} > :first-of-type`]: {
      border: "none",
    },
    [`& .${classes.sideBar} > :first-of-type button`]: {
      display: "none",
    },
    "& ul": {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    "& a": {
      textDecoration: "none",
    },
  },
}));

export const SidebarMenuAdmin = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.sidebar.isOpen);
  const handleToggle = () => {
    dispatch(toggleSidebar());
  };
  return (
    <StyledSidebarMenuAdmin>
      <CustomDrawer
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: isOpen,
          [classes.drawerClose]: !isOpen,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: isOpen,
            [classes.drawerClose]: !isOpen,
          }),
        }}
        open={isOpen}
        onClose={handleToggle}
        transitionDuration={0}
        variant="permanent"
        PaperProps={{
          elevation: 0,
        }}
      >
        {/* Disabled the button to open/close the sidebar
        while UX team defines what to show when sidebar is closed.*/}
        {/*
        <ButtonOpenClose onClick={handleToggle} open={isOpen}>
          <IconChevronLeft
            color={wexTheme.palette.light.background.container}
          />
        </ButtonOpenClose> */}

        <SideMenu
          appearance="outlined"
          logo={
            <NavLink to={"/admin"} exact>
              {isOpen ? <HPIconLarge /> : <HPIconSmall />}
            </NavLink>
          }
          position="relative"
          expanded={isOpen}
          size="small"
          onToggle={handleToggle}
          showIcons
          className={classes.sideBar}
        >
          <SidebarContent />
        </SideMenu>
      </CustomDrawer>
    </StyledSidebarMenuAdmin>
  );
};
