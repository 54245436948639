import PropTypes from "prop-types";
import React from "react";
import {
  mapPoolsTagIcon,
  mapPoolsTagLabel,
  mapPoolsTagType,
} from "../pools/utils/poolStatusMap";
import { TagIndicator } from "./TagIndicator";

export const PoolTag = ({ status }) => {
  const leadingIcon = mapPoolsTagIcon(status);
  const label = mapPoolsTagLabel(status);
  const type = mapPoolsTagType(status);

  return <TagIndicator label={label} type={type} leadingIcon={leadingIcon} />;
};

const STATUS_MAP = {
  available: {
    statuses: ["available"],
  },
  preparingPool: {
    statuses: ["creating", "needMoreServers"],
  },
  pending: {
    statuses: ["unknown"],
  },
  actionRequired: {
    statuses: [
      "serverCreationFailed",
      "tooManyServers",
      "mustUpdateImage",
      "createFailed",
    ],
  },
  preparingServer: {
    statuses: ["creatingServers"],
  },
  deletingServer: {
    statuses: ["deletingServers"],
  },
};

const allStatuses = Object.values(STATUS_MAP).flatMap(
  (statusObj) => statusObj.statuses,
);

PoolTag.propTypes = {
  status: PropTypes.oneOf(allStatuses),
};
