import { styled } from "@mui/material/styles";
import { IconCheckmarkCircle, IconWarning, TextBox } from "@veneer/core";
import primitives from "@veneer/primitives";
import PropTypes from "prop-types";
import React from "react";
import { getFormFieldError } from "../../utils";
import InputTooltip from "./InputTooltip";

const StyledTextBox = styled(TextBox)(({ theme }) => ({
  "& label": {
    color: theme.palette.foreground.light,
    fontWeight: 400,
    whiteSpace: "normal",
  },
  "&.variant--success .vn-input": {
    borderColor: primitives.color.green7,
    "& .vn-input__icon svg": {
      fill: primitives.color.green7,
    },
  },
  "&.variant--error .vn-input": {
    borderColor: primitives.color.red7,
    "& .vn-input__icon svg": {
      fill: primitives.color.red7,
    },
  },
}));

const TextInput = React.forwardRef((props, ref) => {
  const {
    errors,
    name,
    onChange = () => {},
    helperText,
    tooltip,
    label,
    className = "",
    variant,
    trailingIcon = null,
    autoComplete = "new-password",
    ...rest
  } = props;
  const fieldError = getFormFieldError(errors, name);

  const variantType = {
    success: {
      className: "variant--success",
      icon: <IconCheckmarkCircle />,
    },
    error: {
      className: "variant--error",
      icon: <IconWarning />,
    },
  };

  const customClassName =
    `${className} ${variantType[variant]?.className || ""}`.trim();

  return (
    <StyledTextBox
      ref={ref}
      error={!!fieldError}
      helperText={fieldError || helperText || ""}
      name={name}
      label={label}
      separateLabel
      className={customClassName}
      autoComplete={autoComplete}
      trailingButton={<InputTooltip tooltip={tooltip} />}
      onChange={onChange}
      trailingIcon={variantType[variant]?.icon || trailingIcon}
      {...rest}
    />
  );
});

TextInput.displayName = "TextInput";

TextInput.propTypes = {
  errors: PropTypes.object,
  name: PropTypes.string,
  helperText: PropTypes.string,
  tooltip: PropTypes.node,
  label: PropTypes.string,
  trailingIcon: PropTypes.node,
  variant: PropTypes.oneOf(["success", "error", "default"]),
  className: PropTypes.string,
  onChange: PropTypes.func,
};

export default TextInput;
