import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HTTP_METHODS } from "./constants";
import HTTPClient from "./servicers/httpClient";

/**************************************
 *********** API ACTIONS ***************
 ***************************************/

export const getRoles = createAsyncThunk(
  "getRoles",
  async ({ useCache = true }, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, loading, roles } = getState().orgRoles;
    if (!loading || requestId !== currentRequestId) {
      return;
    }
    if (useCache && roles && roles.length > 0) {
      return { data: roles };
    }
    try {
      const response = await new HTTPClient({
        endpoint: "/organizations/roles",
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const createRole = createAsyncThunk(
  "createRole",
  async (
    { selectedOrganization, rolesPayload, roleName },
    { getState, requestId, rejectWithValue },
  ) => {
    const { creatingRole, createRoleRequestId } = getState().orgRoles;
    if (!creatingRole || requestId !== createRoleRequestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${selectedOrganization}/tenants/roles/${roleName}`,
        method: HTTP_METHODS.POST,
        data: rolesPayload,
      }).callAuthorizedAPI();
      return { ...response.data, selectedOrgId: selectedOrganization };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getSupportRoles = createAsyncThunk(
  "getSupportRoles",
  async ({ useCache = true }, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, loading, roles } = getState().orgRoles;
    if (!loading || requestId !== currentRequestId) {
      return;
    }
    if (useCache && roles && roles.length > 0) {
      return { data: roles };
    }
    try {
      const response = await new HTTPClient({
        endpoint: "/internal-support/roles",
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

// Get Tenant Roles
export const getTenantRoles = createAsyncThunk(
  "getTenantRoles",
  async (
    { useCache = true, organizationId },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loadingTenantRoles, tenantRoles } =
      getState().orgRoles;
    if (!loadingTenantRoles || requestId !== currentRequestId) {
      return;
    }
    if (
      useCache &&
      tenantRoles[organizationId] &&
      tenantRoles[organizationId].length > 0
    ) {
      return {
        data: tenantRoles[organizationId],
        selectedOrgId: organizationId,
      };
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/roles`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, selectedOrgId: organizationId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const updateRolePermissions = createAsyncThunk(
  "updateRolePermissions",
  async (
    { organizationId, roleId, permissions },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, updatingTenantRole } = getState().orgRoles;
    if (!updatingTenantRole || requestId !== currentRequestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/roles/${roleId}`,
        method: HTTP_METHODS.PUT,
        data: permissions,
      }).callAuthorizedAPI();
      return { ...response.data, roleId, selectedOrgId: organizationId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteRole = createAsyncThunk(
  "deleteRole",
  async (
    { organizationId, roleId },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, deletingRole } = getState().orgRoles;
    if (!deletingRole || requestId !== currentRequestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/roles/${roleId}`,
        method: HTTP_METHODS.DELETE,
      }).callAuthorizedAPI();
      return { ...response.data, roleId, selectedOrgId: organizationId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const initialState = {
  roles: [],
  tenantRoles: {},
  loadingTenantRoles: null,
  supportRoles: [],
  requesting: false,
  loading: true,
  error: "",
  errorDeletingRole: null,
  deletingRole: null,
  updatingTenantRole: null,
  errorUpdatingRole: null,
  creatingRole: null,
  createRoleRequestId: null,
  errorCreatingRole: null,
  loaderText: null,
  createStatus: null,
};

export const rolesSlice = createSlice({
  name: "orgRoles",
  initialState,
  reducers: {
    reset: (state) => {
      state.loading = true;
      state.error = "";
      state.errorDeletingRole = null;
      state.deletingRole = null;
      state.updatingTenantRole = null;
      state.errorUpdatingRole = null;
      state.creatingRole = null;
      state.createRoleRequestId = null;
      state.errorCreatingRole = null;
      state.loaderText = null;
      state.createStatus = null;
    },
  },
  extraReducers: {
    [getRoles.pending]: (state, action) => {
      state.loading = true;
      state.currentRequestId = action.meta.requestId;
    },
    [getRoles.fulfilled]: (state, action) => {
      state.loading = false;
      state.currentRequestId = null;
      state.roles = action.payload
        ? action.payload.data
          ? action.payload.data
          : []
        : [];
    },
    [getRoles.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.currentRequestId = null;
    },
    [getSupportRoles.pending]: (state, action) => {
      state.loading = true;
      state.currentRequestId = action.meta.requestId;
    },
    [getSupportRoles.fulfilled]: (state, action) => {
      state.loading = false;
      state.currentRequestId = null;
      state.supportRoles = action.payload
        ? action.payload.data
          ? action.payload.data
          : []
        : [];
    },
    [getSupportRoles.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.currentRequestId = null;
    },
    [getTenantRoles.pending]: (state, action) => {
      state.loadingTenantRoles = true;
      state.currentRequestId = action.meta.requestId;
    },
    [getTenantRoles.fulfilled]: (state, action) => {
      state.loadingTenantRoles = false;
      state.currentRequestId = null;
      state.tenantRoles[action.payload.selectedOrgId] = action.payload
        ? action.payload.data
          ? action.payload.data
          : []
        : [];
    },
    [getTenantRoles.rejected]: (state, action) => {
      state.error = action.payload;
      state.loadingTenantRoles = false;
      state.currentRequestId = null;
    },
    [createRole.pending]: (state, action) => {
      state.creatingRole = true;
      state.loaderText = "Creating Role";
      state.createRoleRequestId = action.meta.requestId;
      state.createStatus = null;
    },
    [createRole.fulfilled]: (state, action) => {
      state.creatingRole = false;
      state.createRoleRequestId = null;
      if (action.payload.data) {
        state.tenantRoles[action.payload.selectedOrgId].push(
          action.payload.data,
        );
      }
      state.createStatus = true;
    },
    [createRole.rejected]: (state, action) => {
      state.errorCreatingRole = action.payload;
      state.creatingRole = false;
      state.createRoleRequestId = null;
      state.createStatus = false;
    },
    [updateRolePermissions.pending]: (state, action) => {
      state.updatingTenantRole = true;
      state.currentRequestId = action.meta.requestId;
    },
    [updateRolePermissions.fulfilled]: (state, action) => {
      state.updatingTenantRole = false;
      state.currentRequestId = null;
      if (action.payload.data) {
        state.tenantRoles[action.payload.selectedOrgId] = state.tenantRoles[
          action.payload.selectedOrgId
        ].map((t) => {
          if (t.id !== action.payload.roleId) {
            return t;
          } else {
            return action.payload.data;
          }
        });
      }
    },
    [updateRolePermissions.rejected]: (state, action) => {
      state.errorUpdatingRole = action.payload;
      state.updatingTenantRole = false;
      state.currentRequestId = null;
    },
    [deleteRole.pending]: (state, action) => {
      state.deletingRole = true;
      state.currentRequestId = action.meta.requestId;
    },
    [deleteRole.fulfilled]: (state, action) => {
      state.deletingRole = false;
      state.currentRequestId = null;
      if (action.payload.roleId) {
        state.tenantRoles[action.payload.selectedOrgId] = state.tenantRoles[
          action.payload.selectedOrgId
        ].filter((t) => t.id !== action.payload.roleId);
      }
    },
    [deleteRole.rejected]: (state, action) => {
      state.errorDeletingRole = action.payload;
      state.deletingRole = false;
      state.currentRequestId = null;
    },
  },
});

export const { reset } = rolesSlice.actions;

export default rolesSlice.reducer;
