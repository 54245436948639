import React from "react";
import ConfirmModal from "./Modal";
import CountdownTimer from "./CountdownTimer";
import { secondsToHms } from "../utils";

export default function InactivityModal(props) {
  const { open, onConfirm, onClose, inactivityTimeOut } = props;

  return (
    <ConfirmModal
      open={open}
      header="Are you still active?"
      showHTMLContent={true}
      htmlContent={() => (
        <div>
          {"Hey there! It looks like you've been inactive for more than " +
            secondsToHms(inactivityTimeOut) +
            ", so we'll log you out automatically in "}
          <CountdownTimer prefixText="" timer={props.timeLeft} />.
        </div>
      )}
      primaryText="I'm still here"
      primaryNegative={false}
      secondaryText="Log out now"
      onConfirm={onConfirm}
      onClose={onClose}
    />
  );
}
