import { useSelector } from "react-redux";

const useCurrentPool = () => {
  const currentPool =
    useSelector((state) => state.adminActions?.pools?.pool) || {};

  return currentPool;
};

export default useCurrentPool;
