import React from "react";
import ParentRoute from "../../common/components/ParentRoute";
import { ProtectedRouteSuspenseContainer } from "../../common/components/ProtectedRoute";
import {
  LAYOUTS,
  MODULES_LIST,
  PERMISSIONS_LIST,
  PERMISSIONS_TYPE,
} from "../../common/constants";
import DashBoardRouteTitle from "../../common/components/DashboardRouteTitle";
import Back from "../../common/components/Back";
import CheckW365Enabled from "./components/common/CheckW365Enabled";
import { componentLoader } from "../../common/components/SuspenseComponent";
/*------Connections Components------*/
const ConnectionsContainer = React.lazy(() =>
  componentLoader(
    () => import("./components/connections/ConnectionsContainer"),
  ),
);
const DeleteConnection = React.lazy(() =>
  componentLoader(() => import("./components/connections/DeleteConnections")),
);
const CreateConnectionContainer = React.lazy(() =>
  componentLoader(
    () => import("./components/connections/CreateConnectionContainer"),
  ),
);
const EditConnectionContainer = React.lazy(() =>
  componentLoader(
    () => import("./components/connections/EditConnectionContainer"),
  ),
);
/*------DeviceImage Components------*/
const DeviceImageContainer = React.lazy(() =>
  componentLoader(
    () => import("./components/device-images/DeviceImageContainer"),
  ),
);
const CreateDeviceImageContainer = React.lazy(() =>
  componentLoader(
    () => import("./components/device-images/CreateDeviceImageContainer"),
  ),
);
const DeleteDeviceImages = React.lazy(() =>
  componentLoader(
    () => import("./components/device-images/DeleteDeviceImages"),
  ),
);
/*------Provisioning Policies Components------*/
const ProvisioningPoliciesList = React.lazy(() =>
  componentLoader(
    () => import("./components/provisioning-policies/ProvisioningPoliciesList"),
  ),
);
const CreateProvisioningPolicyContainer = React.lazy(() =>
  componentLoader(
    () =>
      import(
        "./components/provisioning-policies/CreateProvisioningPolicyContainer"
      ),
  ),
);
const EditProvisioningPolicyContainer = React.lazy(() =>
  componentLoader(
    () =>
      import(
        "./components/provisioning-policies/EditProvisioningPolicyContainer"
      ),
  ),
);
const DeleteProvisioningPolicy = React.lazy(() =>
  componentLoader(
    () => import("./components/provisioning-policies/DeleteProvisioningPolicy"),
  ),
);

const backComponent = () => <Back label="Back" />;

const DeschutesRoutes = [
  {
    path: "/windows365",
    authRequired: true,
    component: ParentRoute,
    redirectTo: "/windows365/connections",
    routeTitleComponent: () => (
      <DashBoardRouteTitle title="Windows 365" showQuickActions={true} />
    ),
    layoutStyle: LAYOUTS.MENU_SCREEN,
    isTabs: true,
    onlyExactPathTabs: true,
    permissionSet: [
      PERMISSIONS_LIST[MODULES_LIST.CONNECTIONS][PERMISSIONS_TYPE.VIEW],
      PERMISSIONS_LIST[MODULES_LIST.DEVICE_IMAGES][PERMISSIONS_TYPE.VIEW],
      PERMISSIONS_LIST[MODULES_LIST.PROVISIONING_POLICY][PERMISSIONS_TYPE.VIEW],
    ],
    routes: [
      {
        path: "/windows365/connections",
        component: ParentRoute,
        title: "Connections",
        isSubComponent: true,
        isTab: true,
        permissionSet: [
          PERMISSIONS_LIST[MODULES_LIST.CONNECTIONS][PERMISSIONS_TYPE.VIEW],
        ],
        routes: [
          {
            path: "/windows365/connections",
            exact: true,
            title: "Connections",
            backComponent,
            component: () => (
              <ProtectedRouteSuspenseContainer
                component={CheckW365Enabled}
                childComponent={ConnectionsContainer}
              />
            ),
            permissionSet: [
              PERMISSIONS_LIST[MODULES_LIST.CONNECTIONS][PERMISSIONS_TYPE.VIEW],
            ],
          },
          {
            path: "/windows365/connections/delete",
            exact: true,
            title: "Connections",
            backComponent,
            component: () => (
              <ProtectedRouteSuspenseContainer
                component={CheckW365Enabled}
                childComponent={DeleteConnection}
              />
            ),
            permissionSet: [
              PERMISSIONS_LIST[MODULES_LIST.CONNECTIONS][
                PERMISSIONS_TYPE.DELETE
              ],
            ],
          },
          {
            path: "/windows365/connections/edit",
            exact: true,
            title: "Edit",
            backComponent,
            component: () => (
              <ProtectedRouteSuspenseContainer
                component={CheckW365Enabled}
                childComponent={EditConnectionContainer}
              />
            ),
            permissionSet: [
              PERMISSIONS_LIST[MODULES_LIST.CONNECTIONS][PERMISSIONS_TYPE.EDIT],
            ],
          },
          {
            path: "/windows365/connections/edit/:connectionId",
            hideTabs: true,
            exact: true,
            title: "Connections",
            backComponent,
            component: () => (
              <ProtectedRouteSuspenseContainer
                component={CheckW365Enabled}
                childComponent={EditConnectionContainer}
              />
            ),
            permissionSet: [
              PERMISSIONS_LIST[MODULES_LIST.CONNECTIONS][PERMISSIONS_TYPE.EDIT],
            ],
          },
          {
            path: "/windows365/connections/create-connection",
            exact: true,
            title: "Connections",
            backComponent,
            component: () => (
              <ProtectedRouteSuspenseContainer
                component={CheckW365Enabled}
                childComponent={CreateConnectionContainer}
              />
            ),
            permissionSet: [
              PERMISSIONS_LIST[MODULES_LIST.CONNECTIONS][
                PERMISSIONS_TYPE.CREATE
              ],
            ],
          },
        ],
      },
      {
        path: "/windows365/device-images",
        component: ParentRoute,
        title: "Device Images",
        isSubComponent: true,
        isTab: true,
        permissionSet: [
          PERMISSIONS_LIST[MODULES_LIST.DEVICE_IMAGES][PERMISSIONS_TYPE.VIEW],
        ],
        routes: [
          {
            path: "/windows365/device-images",
            exact: true,
            isTab: true,
            title: "Device Images",
            backComponent,
            component: () => (
              <ProtectedRouteSuspenseContainer
                component={CheckW365Enabled}
                childComponent={DeviceImageContainer}
              />
            ),
            permissionSet: [
              PERMISSIONS_LIST[MODULES_LIST.DEVICE_IMAGES][
                PERMISSIONS_TYPE.VIEW
              ],
            ],
          },
          {
            path: "/windows365/device-images/create",
            exact: true,
            title: "Device Image",
            backComponent,
            component: () => (
              <ProtectedRouteSuspenseContainer
                component={CheckW365Enabled}
                childComponent={CreateDeviceImageContainer}
              />
            ),
            permissionSet: [
              PERMISSIONS_LIST[MODULES_LIST.DEVICE_IMAGES][
                PERMISSIONS_TYPE.CREATE
              ],
            ],
          },
          {
            path: "/windows365/device-images/delete",
            exact: true,
            title: "Device Image",
            backComponent,
            component: () => (
              <ProtectedRouteSuspenseContainer
                component={CheckW365Enabled}
                childComponent={DeleteDeviceImages}
              />
            ),
            permissionSet: [
              PERMISSIONS_LIST[MODULES_LIST.DEVICE_IMAGES][
                PERMISSIONS_TYPE.DELETE
              ],
            ],
          },
        ],
      },
      {
        path: "/windows365/provisioning-policies",
        component: ParentRoute,
        title: "Provisioning Policies",
        isSubComponent: true,
        isTab: true,
        permissionSet: [
          PERMISSIONS_LIST[MODULES_LIST.PROVISIONING_POLICY][
            PERMISSIONS_TYPE.VIEW
          ],
        ],
        routes: [
          {
            path: "/windows365/provisioning-policies",
            exact: true,
            isTab: true,
            title: "Provisioning Policies",
            backComponent,
            component: () => (
              <ProtectedRouteSuspenseContainer
                component={CheckW365Enabled}
                childComponent={ProvisioningPoliciesList}
              />
            ),
            permissionSet: [
              PERMISSIONS_LIST[MODULES_LIST.PROVISIONING_POLICY][
                PERMISSIONS_TYPE.VIEW
              ],
            ],
          },
          {
            path: "/windows365/provisioning-policies/create",
            exact: true,
            title: "Create Provisioning Policy",
            backComponent,
            component: () => (
              <ProtectedRouteSuspenseContainer
                component={CheckW365Enabled}
                childComponent={CreateProvisioningPolicyContainer}
              />
            ),
            permissionSet: [
              PERMISSIONS_LIST[MODULES_LIST.PROVISIONING_POLICY][
                PERMISSIONS_TYPE.CREATE
              ],
            ],
          },
          {
            path: "/windows365/provisioning-policies/edit/:policyId?",
            exact: true,
            title: "Edit Provisioning Policy",
            backComponent,
            component: () => (
              <ProtectedRouteSuspenseContainer
                component={CheckW365Enabled}
                childComponent={EditProvisioningPolicyContainer}
              />
            ),
            permissionSet: [
              PERMISSIONS_LIST[MODULES_LIST.PROVISIONING_POLICY][
                PERMISSIONS_TYPE.EDIT
              ],
            ],
          },
          {
            path: "/windows365/provisioning-policies/delete",
            exact: true,
            title: "Delete Provisioning Policy",
            backComponent,
            component: () => (
              <ProtectedRouteSuspenseContainer
                component={CheckW365Enabled}
                childComponent={DeleteProvisioningPolicy}
              />
            ),
            permissionSet: [
              PERMISSIONS_LIST[MODULES_LIST.PROVISIONING_POLICY][
                PERMISSIONS_TYPE.DELETE
              ],
            ],
          },
        ],
      },
    ],
  },
];

export default DeschutesRoutes;
