import {
  IconCheckmarkCircle,
  IconClock,
  IconInfo,
  IconWarning,
} from "@veneer/core";

const STATUS_MAP = {
  available: {
    statuses: ["available"],
    type: "positive",
    label: "Available",
    icon: <IconCheckmarkCircle size={16} filled />,
  },
  preparingPool: {
    statuses: ["creating", "needmoreservers"],
    type: "neutral",
    label: "Preparing pool",
    icon: <IconInfo size={16} filled />,
  },
  pending: {
    statuses: ["unknown"],
    type: "disabled",
    label: "Pending",
    icon: <IconClock filled />,
  },
  actionRequired: {
    statuses: [
      "servercreationfailed",
      "toomanyservers",
      "mustupdateimage",
      "createfailed",
    ],
    type: "warning",
    label: "Action required",
    icon: <IconWarning size={16} filled />,
  },
  preparingServer: {
    statuses: ["creatingservers"],
    type: "neutral",
    label: "Preparing server",
    icon: <IconInfo size={16} filled />,
  },
  deletingServer: {
    statuses: ["deletingservers"],
    type: "neutral",
    label: "Deleting server",
    icon: <IconWarning size={16} filled />,
  },
};

export const ACTION_REQUIRED_TOOLTIP_TEXT = {
  servercreationfailed: "Server creation failed",
  toomanyservers: "Too many servers",
  mustupdateimage: "Image needs to be updated",
};

const findStatusKey = (status) => {
  const statusKeys = Object.keys(STATUS_MAP);
  return statusKeys.find((key) =>
    STATUS_MAP[key].statuses.includes(String(status).toLowerCase()),
  );
};

export const mapPoolsTagType = (status) => {
  const statusKey = findStatusKey(status);
  return STATUS_MAP[statusKey]?.type || "disabled";
};

export const mapPoolsTagLabel = (status) => {
  const statusKey = findStatusKey(status);
  return STATUS_MAP[statusKey]?.label || "Unknown";
};

export const mapPoolsTagIcon = (status) => {
  const statusKey = findStatusKey(status);
  return STATUS_MAP[statusKey]?.icon || undefined;
};
