import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { Box } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import { alpha, styled } from "@mui/material/styles";
import {
  Avatar,
  ContextualMenu,
  IconLogOut,
  IconPerson,
  MenuItem,
  MenuList,
} from "@veneer/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useMode } from "../../ModeProvider";
import anywareIcon from "../../icons/anyware-icon.svg";
import SwitchTheme from "../../onboarding/components/SwitchTheme";
import { getOrganizationIdentity } from "../../registration/organizationIdentitySlice";
import { fetchItem } from "../../storage";
import { setUserSupportView } from "../authSlice";
import { ALL_CUSTOMERS } from "../constants";
import { getIdentities, setIdentity } from "../identitiesSlice";
import { fetchPartners, setPartner } from "../partnersSlice";
import {
  getAllPermissionModules,
  getTenantPermissions,
} from "../permissionsSlice";
import {
  checkIfOrgIdIsAvailableInIdentities,
  getIdentityId,
  getSelectedOrganizationId,
  getSelectedTenantId,
  getTenantId,
  isUserSupport,
} from "../utils";
import AlertNotification from "./AlertNotification";
import BreadcrumbsWrapper from "./BreadcrumbsWrapper";
import DialogWithLoader from "./DialogWithLoader";
import VeneerButton from "./VeneerButton";

const PREFIX = "Menu";
const drawerWidth = 150;

const classes = {
  root: `${PREFIX}-root`,
  appLogo: `${PREFIX}-appLogo`,
  hideWhenScreenIsSmaller: `${PREFIX}-hideWhenScreenIsSmaller`,
  hideWhenBiggerThanSM: `${PREFIX}-hideWhenBiggerThanSM`,
  appBar: `${PREFIX}-appBar`,
  menuButton: `${PREFIX}-menuButton`,
  drawer: `${PREFIX}-drawer`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  drawerOpen: `${PREFIX}-drawerOpen`,
  drawerClose: `${PREFIX}-drawerClose`,
  toolbar: `${PREFIX}-toolbar`,
  formControl: `${PREFIX}-formControl`,
  menuItem: `${PREFIX}-menuItem`,
  menuLabel: `${PREFIX}-menuLabel`,
  menuActive: `${PREFIX}-menuActive`,
  menuIcon: `${PREFIX}-menuIcon`,
  rightBorder: `${PREFIX}-rightBorder`,
  dropDownenu: `${PREFIX}-dropDownenu`,
  dropDownContainer: `${PREFIX}-dropDownContainer`,
  dropDownTitle: `${PREFIX}-dropDownTitle`,
  expandMoreIcon: `${PREFIX}-expandMoreIcon`,
  dropDownOptionActive: `${PREFIX}-dropDownOptionActive`,
  dropDownPopover: `${PREFIX}-dropDownPopover`,
  avatar: `${PREFIX}-avatar`,
};

const Root = styled("div")(({ theme, isSidebarOpen }) => ({
  [`& .${classes.root}`]: {
    display: "flex",
  },

  [`& .${classes.appLogo}`]: {
    margin: "10px 0px 0px 10px",
    cursor: "pointer",
  },

  [`& .${classes.hideWhenScreenIsSmaller}`]: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  [`& .${classes.hideWhenBiggerThanSM}`]: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },

  [`& .${classes.appBar}`]: {
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "white",
    [theme.breakpoints.up("lg")]: {},
    marginLeft: isSidebarOpen ? 304 : 80,
  },

  [`& .${classes.menuButton}`]: {
    marginRight: 36,
  },

  [`& .${classes.drawer}`]: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },

  [`& .${classes.drawerPaper}`]: {
    width: drawerWidth,
  },

  [`& .${classes.drawerOpen}`]: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  [`& .${classes.drawerClose}`]: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("lg")]: {
      width: theme.spacing(9) + 1,
    },
  },

  [`& .${classes.toolbar}`]: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    color: "inherit",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingRight: theme.spacing(4),
    marginLeft: isSidebarOpen ? "304px" : "64px",
    ...theme.mixins.toolbar,
    transition: theme.transitions.create(["margin-left"], {
      delay: 50,
      easing: theme.transitions.easing.ease,
      duration: 200,
    }),
    "& a": {
      borderRadius: "360px",
    },
  },

  [`& .${classes.formControl}`]: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.1),
    },
  },

  [`& .${classes.menuItem}`]: {
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "20px",
  },

  [`& .${classes.menuLabel}`]: {
    height: "55px",
  },

  [`& .${classes.menuActive}`]: {
    backgroundColor: "#DAEDFF",
    borderRight: "4px solid #4D52E6",
  },

  [`& .${classes.menuIcon}`]: {
    minWidth: "28px",
    marginRight: "5px",
  },

  [`& .${classes.rightBorder}`]: {
    borderRight: "1px solid #C4C4C4",
    height: "100%",
    display: "flex",
    position: "relative",
    alignItems: "center",
  },

  [`& .${classes.dropDownenu}`]: {
    display: "flex",
    justifyContent: "space-between",
  },

  [`& .${classes.dropDownContainer}`]: {
    display: "flex",
    width: "120%",
  },

  [`& .${classes.dropDownTitle}`]: {
    minWidth: "90px",
    fontSize: "1rem",
    lineHeight: "1.5",
    fontWeight: 400,
    color: "#4A4A4A",
    height: "auto",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },

  [`& .${classes.expandMoreIcon}`]: {
    color: "#4A4A4A",
  },

  [`& .${classes.dropDownOptionActive}`]: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
  },

  [`& .${classes.dropDownPopover}`]: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  [`& .${classes.avatar}`]: {
    width: "40px",
    height: "40px",
    objectFit: "cover",
  },
}));

function AccountSettings(props) {
  const { organizationIdentity } = useSelector(
    (state) => state.organizationIdentity,
  );

  const name = organizationIdentity?.username?.split("@")[0];
  const initials = name
    ?.split(".")
    .map((part) => part[0].toUpperCase())
    .join("");

  const menuOptions = [
    {
      label: organizationIdentity?.username,
      disabled: true,
      leadingArea: <IconPerson fontSize="small" color="primary" />,
    },
    {
      label: "Logout",
      onClick: props.logout,
      leadingArea: <IconLogOut fontSize="small" color="primary" />,
    },
  ];

  return (
    <>
      <ContextualMenu
        anchorNode={
          <VeneerButton iconButton>
            <Avatar label={initials} size={36}></Avatar>
          </VeneerButton>
        }
        placement="bottom-end"
      >
        <MenuList>
          {menuOptions.map((option, index) => (
            <MenuItem key={index} value={index} {...option} />
          ))}
        </MenuList>
      </ContextualMenu>
    </>
  );
}

function HeaderToolBar(props) {
  const { mode, changeTheme } = useMode();
  const userRoles = useSelector((state) => state.auth?.auth?.userRoles);
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state.auth);
  const identities = useSelector((state) => state.identities.identities);
  const selectedIdentity = useSelector(
    (state) => state.identities.selectedIdentity,
  );
  const userSupportView = useSelector((state) => state.auth.userSupportView);
  const selectedPartner = useSelector(
    (state) => state.partners.selectedPartner,
  );

  useEffect(() => {
    const selectedOrgIdFromLocalStorage = getSelectedOrganizationId();
    if (identities && identities.length) {
      if (
        selectedOrgIdFromLocalStorage &&
        checkIfOrgIdIsAvailableInIdentities(
          identities,
          selectedOrgIdFromLocalStorage,
        )
      ) {
        if (!selectedIdentity) {
          dispatch(setIdentity(selectedOrgIdFromLocalStorage));
        }
        if (userSupportView === false) {
          dispatch(
            getOrganizationIdentity({
              organizationId: selectedOrgIdFromLocalStorage,
            }),
          );
        }
      } else if (identities.length > 0) {
        if (!selectedIdentity) {
          dispatch(setIdentity(identities[0].id));
        }
        if (userSupportView === false) {
          dispatch(
            getOrganizationIdentity({ organizationId: identities[0].id }),
          );
        }
      }
    }
  }, [identities, auth]);

  useEffect(() => {
    const selectedTenantFromLocalStorage = getSelectedTenantId();
    const selectedOrganizationIdFromLocalStorage = getSelectedOrganizationId();
    if (selectedTenantFromLocalStorage && !selectedPartner) {
      if (
        selectedOrganizationIdFromLocalStorage &&
        selectedTenantFromLocalStorage &&
        selectedTenantFromLocalStorage !== ALL_CUSTOMERS
      ) {
        dispatch(
          getTenantPermissions({
            useCache: true,
            selectedTenantId: getTenantId(selectedTenantFromLocalStorage),
            selectedOrganizationId: getIdentityId(
              selectedOrganizationIdFromLocalStorage,
            ),
          }),
        );
      }
      dispatch(setPartner(selectedTenantFromLocalStorage));
    } else if (!selectedPartner && !selectedTenantFromLocalStorage) {
      dispatch(setPartner(ALL_CUSTOMERS));
    }
  }, [selectedPartner]);
  useEffect(() => {
    if (selectedIdentity) {
      dispatch(
        fetchPartners({
          useCache: true,
          selectedOrganization: getIdentityId(selectedIdentity),
        }),
      );
    }
  }, [selectedIdentity]);

  useEffect(() => {
    if (
      fetchItem("userSupportRole") === "true" &&
      userRoles &&
      isUserSupport(userRoles)
    ) {
      dispatch(setUserSupportView(true));
    }
  }, [userRoles]);

  const onChangeTheme = (e) => {
    const newTheme = e.target.checked ? "dark" : "light";
    changeTheme(newTheme);
  };

  return (
    <Grid
      item
      lg={7}
      md={7}
      sm={7}
      xs={0}
      className={classes.hideWhenScreenIsSmaller}
      display="flex"
      justifyContent={"flex-end"}
      alignItems="center"
      gap={2}
    >
      <Grid item lg={1} md={1} sm={1}>
        <SwitchTheme
          onChange={onChangeTheme}
          defaultChecked={mode === "dark"}
        />
      </Grid>
      <Grid item lg={1} md={1} sm={1}>
        <AlertNotification {...props} />
      </Grid>
      <Grid item lg={1} md={1} sm={1}>
        <AccountSettings {...props} />
      </Grid>
    </Grid>
  );
}

export default function Menu(props) {
  const history = useHistory();

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth.auth);
  const userSupportView = useSelector((state) => state.auth.userSupportView);
  const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);

  useEffect(() => {
    if (auth && userSupportView != null) {
      dispatch(getIdentities({ useCache: true }));
      dispatch(getAllPermissionModules({}));
    }
  }, [dispatch, auth, userSupportView]);
  const identities = useSelector((state) => state.identities.identities);
  const loading = useSelector((state) => state.identities.loading);

  const { saving: savingPartner } = useSelector((state) => state.partners);

  useEffect(() => {
    if (
      !loading &&
      !userSupportView &&
      (!identities || identities.length === 0) &&
      window.location.pathname !== "/create-organization"
    ) {
      history.push("/create-organization");
    }
  }, [auth, identities, loading, userSupportView]);

  const [openCollapse, setOpenCollapse] = React.useState({});
  const MENU_CONFIG = [
    // {
    //   label: "Back to Spot Console",
    //   icon: <ArrowBackIcon />,
    //   external: true,
    //   tooltip:
    //     "In the future, this button will be a link to the login page for the rest of the services Spot by NetApp provides.",
    //   onClick: () => {},
    // },
    {
      label: "Anyware PC",
      icon: <img src={anywareIcon} alt=""></img>,
      href: "/admin",
      match: ["/dashboard", "/admin"],
      exact: true,
    },
    {
      label: "logout",
      icon: <ExitToAppIcon />,
      onClick: props?.logout,
      mobile: true,
    },
  ];

  const checkMenuActive = (match, exact) => {
    if (Array.isArray(match)) {
      var active = false;
      match.forEach((h) => {
        if (
          (exact && window.location.pathname === h) ||
          (!exact && window.location.pathname.indexOf(h) !== -1)
        ) {
          active = true;
        }
      });
      return active;
    } else if (
      (exact && window.location.pathname === match) ||
      (!exact && window.location.pathname.indexOf(match) !== -1)
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    MENU_CONFIG.forEach((menu, index) => {
      if (menu.subMenu) {
        menu.subMenu.forEach((menuItem) => {
          if (checkMenuActive(menuItem.match, menu.exact)) {
            handleOpenSettings(index);
          }
        });
      }
    });
  }, []);

  const handleOpenSettings = (index) => {
    setOpenCollapse({
      ...openCollapse,
      [index]: openCollapse[index] ? false : true,
    });
  };

  return (
    <Root className={classes.root} isSidebarOpen={isSidebarOpen}>
      <CssBaseline />
      <DialogWithLoader
        open={savingPartner}
        loaderText="Please wait..."
        title="Please Wait"
      />
      <AppBar
        elevation={0}
        position="fixed"
        className={classes.appBar}
        color="transparent"
      >
        <Toolbar className={classes.toolbar}>
          <BreadcrumbsWrapper route={props.route} />
          <Box sx={{ flexGrow: 1 }} />
          <HeaderToolBar {...props} identities={identities} />
        </Toolbar>
      </AppBar>
      <Divider />
    </Root>
  );
}
