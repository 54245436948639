import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { checkShouldRenderCTA, clearCTA, setCTA } from "../slices/ctaSlice";
import { useCallback, useMemo } from "react";

const useCTA = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { data } = useSelector((state) => state.cta);
  const _clearCTA = useCallback(() => {
    dispatch(clearCTA());
  }, [dispatch]);
  const _setCTA = useCallback(
    (state) => {
      dispatch(setCTA(state));
    },
    [dispatch],
  );

  const location = history.location;
  const result = useMemo(() => {
    const shouldRender = !!checkShouldRenderCTA(data, location);

    return { shouldRender, clearCTA: _clearCTA, setCTA: _setCTA, data };
  }, [_clearCTA, _setCTA, data, location]);

  return { ...result };
};

export default useCTA;
