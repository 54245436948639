import { useSelector } from "react-redux";

const useCurrentMachine = () => {
  // This is how the machine is stored in the state
  // {
  //   "dashboard": {
  //       "machines": {
  //           ...
  //           "machine": {
  //               "fc01eee2-6981-48e1-901b-c192b69218ec": {
  //                   "fsvm3": {
  //                       "id": "/organizations/48236a29-e78b-47e1-ab99-a83ed41e2e38/tenants/fc01eee2-6981-48e1-901b-c192b69218ec/sites/FS-site1/virtualMachines/FSVM3",
  //                       "name": "FSVM3",
  //                       "siteName": "FS-site1",
  //                       "properties": {
  //                           "resourcePoolName": "FSVM3",
  //                           "resourcePoolId": "/organizations/48236a29-e78b-47e1-ab99-a83ed41e2e38/tenants/fc01eee2-6981-48e1-901b-c192b69218ec/sites/FS-site1/resourcePools/FSVM3",
  //                           "heartBeatTime": "2024-05-23T14:09:08.5897787+00:00",
  //                           "status": "Available",
  //                           "ramGB": 4,
  //                           "cpu": 0,
  //                           "online": false,
  //                           "type": "VDI"
  //                       },
  //                       "tags": {}
  //                   }
  //               }
  //           },
  //           "activeMachines": {
  //               "fc01eee2-6981-48e1-901b-c192b69218ec": []
  //           },
  //           ...

  try {
    const activeMachine = useSelector(
      (state) => state?.dashboard?.machines?.machine || {},
    );
    // This is the machine id
    if (!Object.keys(activeMachine).length) {
      return {};
    }
    const machineId = Object.keys(activeMachine)[0];
    // This is the machine object
    const machineNameIndex = Object.keys(activeMachine[machineId])[0];
    const machine = activeMachine[machineId][machineNameIndex];

    return machine;
  } catch (error) {
    // When the machine is not available, return an empty object
    return {};
  }
};

export default useCurrentMachine;
