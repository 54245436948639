import { useMemo } from "react";
import { useSelector } from "react-redux";
import { getIdentityId } from "../utils";

const useCurrentTenant = () => {
  const selectedPartner = useSelector(
    (state) => state.partners.selectedPartner,
  );
  const selectedIdentity = useSelector(
    (state) => state.identities.selectedIdentity,
  );
  const tenants = useSelector((state) => state.partners.tenants);
  const current = useMemo(() => {
    if (tenants && selectedPartner && selectedIdentity) {
      const filtered = tenants[getIdentityId(selectedIdentity)]?.filter(
        (el) => el.id === selectedPartner,
      );

      if (filtered?.length) {
        return filtered[0];
      }
    }
  }, [selectedIdentity, selectedPartner, tenants]);

  return current;
};

export default useCurrentTenant;
