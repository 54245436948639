import { Grid, styled } from "@mui/material";
import { IconClock } from "@veneer/core";
import primitives from "@veneer/primitives";
import React from "react";

const PREFIX = "PoolCreationProgress";

const classes = {
  caption: `${PREFIX}-caption`,
  title: `${PREFIX}-title`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  width: "400px",
  backgroundColor: primitives.color.darkOrange1,
  borderRadius: theme.spacing(2),
  margin: "1.5rem 0",
  "& > .MuiGrid-item": {
    ":first-of-type": {
      padding: 0,
    },
    paddingTop: 0,
  },
  [`& .${classes.title}`]: {
    color: primitives.color.gray10,
    textTransform: "uppercase",
  },
  [`& .${classes.caption}`]: {
    fontWeight: 600,
  },
}));

const PoolCreationProgress = () => {
  const content = [
    { title: "time remaining", caption: "60 minutes" },
    { title: "vms created", caption: "7/10" },
  ];

  return (
    <StyledGrid
      container
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={3}
      padding={3}
    >
      <Grid item>
        <IconClock size={48} />
      </Grid>

      {content.map((c, i) => (
        <Grid item key={i}>
          <Grid container flexDirection="column">
            <Grid className={classes.title}>
              <p>{c.title}</p>
            </Grid>

            <Grid className={classes.caption}>
              <p>{c.caption}</p>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </StyledGrid>
  );
};

export default PoolCreationProgress;
