import { useToast } from "@veneer/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DialogWithLoader from "../../../common/components/DialogWithLoader";
import ParentRoute from "../../../common/components/ParentRoute";
import { resetErrors } from "../../slices/userSessionsSlice";

export default function UserSessionDetailsWrapper(props) {
  const dispatch = useDispatch();
  const { addToast } = useToast();

  const { loggingOffError, loggingOff } = useSelector(
    (state) => state.dashboard.userSessions,
  );
  useEffect(() => {
    dispatch(resetErrors());
  }, []);

  useEffect(() => {
    if (loggingOffError) {
      const message =
        loggingOffError && loggingOffError.message
          ? loggingOffError.message
          : "Unable to logoff user";
      addToast({
        text: message,
        type: "negative",
        timeout: 6,
        id: Date.now(),
      });
    }
  }, [addToast, loggingOffError]);

  return (
    <>
      <ParentRoute {...props} />
      <DialogWithLoader
        open={loggingOff}
        title={"Please Wait"}
        loaderText={"Logging Off User..."}
      ></DialogWithLoader>
    </>
  );
}
