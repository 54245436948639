import { LogLevel, PublicClientApplication } from "@azure/msal-browser";

export default class MSALService {
  constructor(config) {
    const c = config || {};

    this.scopes = [];
    this.forgotPasswordConfig = {
      auth: {
        ...(c.forgotPassword || {}),
      },
    };
    this.config = {
      auth: {
        ...(c.auth || {}),
        prompt: "select_account",
      },
      cache: {
        cacheLocation: "sessionStorage",
        ...(c.cache || {}),
      },
      system: {
        windowHashTimeout: 60000,
        iframeHashTimeout: 6000,
        loadFrameTimeout: 0,
        loggerOptions: {
          loggerCallback: (level, message, containsPii) => {
            if (containsPii) {
              return;
            }
            switch (level) {
              case LogLevel.Error:
                console.error(message);
                return;
              case LogLevel.Info:
                console.info(message);
                return;
              case LogLevel.Verbose:
                console.debug(message);
                return;
              case LogLevel.Warning:
                console.warn(message);
                return;
            }
          },
          piiLoggingEnabled: false,
        },
        ...(c.system || {}),
      },
      framework: { ...(c.framework || {}) },
    };
    this.client = new PublicClientApplication(this.config);
    this.forgotPasswordClient = new PublicClientApplication(
      this.forgotPasswordConfig,
    );
    this.getAccount = this.getAccount.bind(this);
    this.getAllAccounts = this.getAllAccounts.bind(this);
    this.setRedirectCallback = this.setRedirectCallback.bind(this);
    this.setActiveAccount = this.setActiveAccount.bind(this);
    // Array to store interval IDs
    this.intervalIds = [];
  }
  setRedirectCallback(successCallback, errorCallback) {
    this.client
      .handleRedirectPromise()
      .then(successCallback)
      .catch(errorCallback);
  }
  setScopes(s) {
    this.scopes = s || [];
  }
  getScopes() {
    return this.scopes;
  }
  getRedirectUri() {
    return this.client.redirectUri;
  }
  isLoginInProgress() {
    return this.client.getLoginInProgress();
  }
  getAccount() {
    const accounts = this.client.getAllAccounts() || [];
    return accounts.length > 0 ? accounts[0] : null;
  }
  getAllAccounts() {
    const accounts = this.client.getAllAccounts() || [];
    return accounts;
  }
  setActiveAccount(account) {
    return this.client.setActiveAccount(account);
  }
  async loginPopup(config) {
    return this.client.loginPopup(config);
  }
  // Method to wait for the msal.interaction.status to be not present
  waitForInteractionToComplete() {
    return new Promise((resolve) => {
      const intervalId = setInterval(() => {
        const interactionStatus = window.sessionStorage.getItem(
          "msal.interaction.status",
        );
        if (!interactionStatus) {
          clearInterval(intervalId);
          this.intervalIds = this.intervalIds.filter((id) => id !== intervalId);
          resolve();
        }
      }, 100);
      this.intervalIds.push(intervalId);
    });
  }
  async loginRedirect(config) {
    // Wait for the current interaction to complete
    // This is a workaround to prevent the MSAL library from throwing an error
    // when an interaction is already in progress
    await this.waitForInteractionToComplete();

    this.client
      .handleRedirectPromise()
      .then((authResult) => {
        if (!authResult) {
          const accounts = this.client.getAllAccounts();
          if (accounts.length === 0) {
            // No user signed in
            this.client.loginRedirect(config);
          }
        }
      })
      .catch((err) => {
        if (err.errorMessage && err.errorMessage.indexOf("AADB2C90118") >= 0) {
          this.forgotPasswordClient.loginRedirect(this.forgotPasswordConfig);
        } else {
          this.client.loginRedirect(config);
        }
      });
  }
  async acquireTokenRedirect(config) {
    return this.client.acquireTokenRedirect(config);
  }
  async acquireTokenPopup(config) {
    return this.client.acquiteTokenPopup(config);
  }
  async acquireTokenSilent(config) {
    return this.client.acquireTokenSilent(config);
  }
  getAuthority() {
    return this.client.authority();
  }
  async logout() {
    const accounts = this.client.getAllAccounts();
    if (accounts.length !== 0) {
      await this.waitForInteractionToComplete();
      accounts.forEach((account) => {
        this.client.logoutRedirect({
          account: account,
          postLogoutRedirectUri: "/",
        });
      });
      this.setActiveAccount(null);
    }
  }
}
