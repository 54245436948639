import React, { useEffect } from "react";
import { styled } from '@mui/material/styles';
import { withStyles } from "@mui/material/styles";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import MenuItem from "@mui/material/MenuItem";
import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { checkTenantPermissions, getTenantId } from "../utils";

const PREFIX = 'QuickActionDropdown';

const classes = {
  root: `${PREFIX}-root`,
  borderred: `${PREFIX}-borderred`,
  dropdownContainer: `${PREFIX}-dropdownContainer`,
  expandMoreIcon: `${PREFIX}-expandMoreIcon`,
  optionSubLabel: `${PREFIX}-optionSubLabel`,
  optionSubLabelSpan: `${PREFIX}-optionSubLabelSpan`,
  optionSubLabelWarningSpan: `${PREFIX}-optionSubLabelWarningSpan`,
  optionSubLabelInactiveSpan: `${PREFIX}-optionSubLabelInactiveSpan`,
  warningIcon: `${PREFIX}-warningIcon`,
  dropdownItem: `${PREFIX}-dropdownItem`,
  dropdownLabel: `${PREFIX}-dropdownLabel`,
  dropdownTitle: `${PREFIX}-dropdownTitle`,
  menuItem: `${PREFIX}-menuItem`
};

const Root = styled(Grid)((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    borderRadius: "2px",
    border: "1px solid #4D52E6",
  },

  [`& .${classes.borderred}`]: {
    border: "1px solid #C4C4C4",
  },

  [`& .${classes.dropdownContainer}`]: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },

  [`& .${classes.expandMoreIcon}`]: {
    textAlign: "center",
    height: "25px",
  },

  [`& .${classes.optionSubLabel}`]: {
    width: "100%",
    textAlign: "right",
  },

  [`& .${classes.optionSubLabelSpan}`]: {
    marginRight: "2px",
    border: "1px solid #28C78A",
    padding: "2px",
    color: "#28C78A",
    borderRadius: "5px",
    fontWeight: "bold",
  },

  [`& .${classes.optionSubLabelWarningSpan}`]: {
    marginRight: "2px",
    border: "1px solid #FFBB57",
    padding: "2px",
    color: "#FFBB57",
    borderRadius: "5px",
    fontWeight: "bold",
  },

  [`& .${classes.optionSubLabelInactiveSpan}`]: {
    marginRight: "2px",
    border: "1px solid #E2E2E2",
    padding: "2px",
    color: "#FFFFFF",
    background: "#E2E2E2",
    borderRadius: "5px",
    fontWeight: "bold",
  },

  [`& .${classes.warningIcon}`]: {
    color: theme.palette.warning.main,
  },

  [`& .${classes.dropdownTitle}`]: {
    flexGrow: 1,
    fontWeight: "700",
    color: "#4D52E6",
  },

  [`& .${classes.menuItem}`]: {
    width: "230px",
  }
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  [`& .${classes.dropdownItem}`]: {
    height: "33px",
    marginLeft: "-10px",
  },

  [`& .${classes.dropdownLabel}`]: {
    color: "#4D52E6",
    marginLeft: "3px",
  },
}));

const Accordion = styled(MuiAccordion)(({ theme }) => ({
  [`&.${classes.root}`]: {
    border: "none",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&::before": {
      display: "none",
    },
    "& .Mui-expanded": {
      margin: "0px",
    },
  },
}));

const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: "5px",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&:hover": {
      backgroundColor: "#daeeff",
    },
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: "5px",
    display: "flex",
    flexDirection: "column",
  },
}));

export function ConfigActionMenuItem(props) {
  const { onClick, selectedOption, option } = props;
  return (
    <StyledMenuItem
      onClick={onClick}
      className={classes.dropdownItem}
      style={
        selectedOption && selectedOption.value === option.value
          ? { background: "rgba(0, 0, 0, 0.04)" }
          : {}
      }
    >
      {option.icon && option.icon()}
      <Typography variant="body2" component="span" className={classes.dropdownLabel}>
        {option.label}
      </Typography>
    </StyledMenuItem>
  );
}

export default function QuickActionDropdown(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedOption, setSelectedOption] = React.useState(null);
  const [width, setWidth] = React.useState(0);
  const groupDropdown = React.createRef();
  const [expanded, setExpanded] = React.useState(0);
  const [subExpanded, setsubExpanded] = React.useState(null);

  const selectedPartner = useSelector((state) => state.partners.selectedPartner);
  const userPermissionsList = useSelector((state) => state.permissions.userPermissionsList);
  const { userSupportView } = useSelector((state) => state.auth);

  const {
    label,
    placeholder,
    dropdownData,
    onChange,
    defaultOpen,
    defaultValue,
    dontShowBorder,
    configActionHeight,
  } = props;

  const handleClick = (event) => {
    setWidth(groupDropdown.current.offsetWidth);
    setAnchorEl(event.currentTarget);
  };

  const handleOptionCLick = (option) => {
    setSelectedOption(option);
    setAnchorEl(null);
    if (onChange) {
      onChange(option);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const handleSubChange = (panel) => (event, newExpanded) => {
    setsubExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    setExpanded(defaultOpen);
  }, [defaultOpen]);

  useEffect(() => {
    if (defaultValue) setSelectedOption(defaultValue);
  }, [defaultValue]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Root container>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{ display: "flex", flexDirection: "row-reverse" }}
      >
        {label && (
          <Typography variant="body2" component="div">
            {label}
          </Typography>
        )}
        <MenuItem
          ref={groupDropdown}
          onClick={handleClick}
          key={label + "dropdown"}
          className={clsx(classes.root, classes.menuItem, {
            [classes.borderred]: !dontShowBorder,
          })}
        >
          <div className={classes.dropdownContainer}>
            {/* {selectedOption ? (
              <Typography variant="subtitle1" component="span" className={classes.dropdownTitle}>
                {selectedOption.label}
              </Typography>
            ) : ( */}
            <Typography
              variant="subtitle2"
              component="span"
              className={classes.dropdownTitle}
              align="center"
            >
              {placeholder}
              <span className={classes.expandMoreIcon}>
                {" "}
                <ArrowDropDownIcon />
              </span>
            </Typography>
            {/* )} */}
          </div>
        </MenuItem>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          PaperProps={{
            style: {
              maxHeight: configActionHeight + "px",
              marginTop: "2px",
              width: width + "px",
            },
          }}
        >
          {dropdownData
            .filter((item) =>
              checkTenantPermissions(
                item?.permissionSet,
                userPermissionsList,
                getTenantId(selectedPartner),
                userSupportView
              )
            )
            .map((dropdown_data, index) => {

              const menuKey = `menu-${index}`;

              return (
                <Accordion
                  className={classes.root}
                  key={menuKey}
                  expanded={props.expandAll ? true : expanded === menuKey}
                  square
                  onChange={handleChange(menuKey)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandLessIcon />}
                    aria-controls="panel1a-content"
                    className={classes.root}
                    id="panel1a-header"
                  >
                    <Typography
                      variant="body2"
                      component="p"
                      style={{ fontWeight: "700", marginLeft: "10px" }}
                    >
                      {dropdown_data.group}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    className={classes.root}
                    data-testid={"quick-action-menu-detail-" + dropdown_data.group}
                  >
                    {dropdown_data.options
                      .filter((item) =>
                        checkTenantPermissions(
                          item?.permissionSet,
                          userPermissionsList,
                          getTenantId(selectedPartner),
                          userSupportView
                        )
                      )
                      .map((option, subIndex) => {
                        const subMenuKey = `accordion-${index}-${subIndex}`;
                        return option.group ? (
                          <Accordion
                            className={classes.root}
                            key={subMenuKey}
                            expanded={subExpanded === subMenuKey}
                            square
                            style={{ border: "none" }}
                            onChange={handleSubChange(subMenuKey)}
                          >
                            <AccordionSummary
                              className={classes.root}
                              expandIcon={<ExpandLessIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography
                                variant="body2"
                                component="p"
                                style={{ fontWeight: "500", marginLeft: "10px" }}
                              >
                                {option.group}
                              </Typography>
                            </AccordionSummary>

                            <AccordionDetails
                              className={classes.root}
                              data-testid={"quick-action-menu-detail-" + option.group}
                            >
                              {option.options
                                .filter((a) =>
                                  checkTenantPermissions(
                                    a?.permissionSet,
                                    userPermissionsList,
                                    getTenantId(selectedPartner),
                                    userSupportView
                                  )
                                )
                                .map((subOption, subMenuIndex) => {
                                  const subOptionKey = `submenu-${index}-${subIndex}-${subMenuIndex}`;
                                  return (
                                    <ConfigActionMenuItem
                                      key={subOptionKey}
                                      onClick={() => handleOptionCLick(subOption)}
                                      selectedOption={selectedOption}
                                      option={subOption}
                                    />
                                  );
                                })}
                            </AccordionDetails>
                          </Accordion>
                        ) : (
                          <ConfigActionMenuItem
                            key={subMenuKey}
                            onClick={() => handleOptionCLick(option)}
                            selectedOption={selectedOption}
                            option={option}
                          />
                        );
                      })}
                  </AccordionDetails>
                </Accordion>
              );
            })}
        </Popover>
      </Grid>
    </Root>
  );
}

QuickActionDropdown.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  dropdownData: PropTypes.array,
  onChange: PropTypes.func,
  defaultOpen: PropTypes.number,
  dontShowBorder: PropTypes.bool,
};
QuickActionDropdown.defaultProps = {
  placeholder: "Text",
  dropdownData: [],
  dontShowBorder: false,
};
