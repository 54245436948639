import { Field, Form } from "react-final-form";
import { Grid } from "@mui/material";
import React from "react";
import SelectInput from "../../common/components/form/SelectInput";
import { handleChange } from "../../admin-actions/pools/poolsUtils";
import TextInput from "../../common/components/form/TextInput";
import TextPasswordInput from "../../common/components/form/Password";
import { required } from "../../common/validators";
import PropTypes from "prop-types";

export default function SelectTemplateForm({
  onChange,
  initialValues,
  formName = "selectTemplateForm",
  options,
  loading,
}) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Form
          onSubmit={() => {}}
          validate={(e) => onChange(e, formName)}
          initialValues={initialValues}
          render={(formMeta) => {
            const form = formMeta.form;
            return (
              <form onSubmit={formMeta.handleSubmit}>
                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "24px",
                    }}
                  >
                    <Field
                      name="template"
                      render={() => (
                        <SelectInput
                          key="selected-device-image" 
                          onChange={(e) => {
                            handleChange("template", e, form);
                          }}
                          onClear={() => {
                            handleChange("template", null, form);
                          }}
                          value={formMeta.values.template}
                          defaultValue={formMeta.initialValues.template}
                          label="Template"
                          options={options}
                          loading={loading}
                          isSearchable
                        />
                      )}
                    />
                    <Field
                      name="username"
                      label="Template admin username"
                      validate={required}
                      render={({ input, meta }) => (
                        <TextInput
                          {...input}
                          label="Template admin username"
                          sx={{
                            width: "100%",
                          }}
                          error={meta.touched && meta.error}
                          helperText={meta.touched && meta.error}
                        />
                      )}
                    />
                    <Field
                      name="password"
                      validate={required}
                      render={({ input, meta }) => (
                        <TextPasswordInput
                          {...input}
                          hideRequirements
                          type="password"
                          label="Template admin password"
                          sx={{
                            width: "100%",
                          }}
                          error={meta.touched && meta.error}
                          helperText={meta.touched && meta.error}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </form>
            );
          }}
        />
      </Grid>
    </Grid>
  );
}

SelectTemplateForm.propTypes = {
  onChange: PropTypes.func,
  initialValues: PropTypes.object,
  formName: PropTypes.string,
  options: PropTypes.array,
  loading: PropTypes.bool,
};
