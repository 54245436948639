import React from "react";
import ParentRoute from "../../common/components/ParentRoute";
import { ProtectedRouteSuspenseContainer } from "../../common/components/ProtectedRoute";
import {
  LAYOUTS,
  MODULES_LIST,
  PERMISSIONS_LIST,
  PERMISSIONS_TYPE,
} from "../../common/constants";
import DashBoardRouteTitle from "../../common/components/DashboardRouteTitle";
import { componentLoader } from "../../common/components/SuspenseComponent";
/*------Business Server Components------*/
const ListBusinessServerContainer = React.lazy(() =>
  componentLoader(() => import("./components/BusinessServersList")),
);

const CreateBusinessServerContainer = React.lazy(() =>
  componentLoader(() => import("./components/CreateBusinessServerContainer")),
);

const EditBusinessServerContainer = React.lazy(() =>
  componentLoader(() => import("./components/EditBusinessServerContainer")),
);

const DeleteBusinessServerContainer = React.lazy(() =>
  componentLoader(() => import("./components/DeleteBusinessServerContainer")),
);

const BusinessServersRoutes = [
  {
    path: "/business-servers",
    authRequired: true,
    component: ParentRoute,
    title: "Business servers",
    routeTitleComponent: () => (
      <DashBoardRouteTitle title="Business Servers" showQuickActions={true} />
    ),
    permissionSet: [
      PERMISSIONS_LIST[MODULES_LIST.BUSINESS_SERVERS][PERMISSIONS_TYPE.VIEW],
    ],
    layoutStyle: LAYOUTS.MENU_SCREEN,
    isTabs: true,
    onlyExactPathTabs: true,
    routes: [
      {
        path: "/business-servers",
        exact: true,
        title: "List",
        component: () => (
          <ProtectedRouteSuspenseContainer
            component={ListBusinessServerContainer}
          />
        ),
        permissionSet: [
          PERMISSIONS_LIST[MODULES_LIST.BUSINESS_SERVERS][
            PERMISSIONS_TYPE.VIEW
          ],
        ],
      },
      {
        path: "/business-servers/action/create",
        exact: true,
        authRequired: true,
        title: "Create",
        component: () => (
          <ProtectedRouteSuspenseContainer
            component={CreateBusinessServerContainer}
          />
        ),
        permissionSet: [
          PERMISSIONS_LIST[MODULES_LIST.BUSINESS_SERVERS][
            PERMISSIONS_TYPE.CREATE
          ],
        ],
      },
      {
        path: "/business-servers/action/edit",
        exact: true,
        title: "Edit",
        component: () => (
          <ProtectedRouteSuspenseContainer
            component={EditBusinessServerContainer}
          />
        ),
        permissionSet: [
          PERMISSIONS_LIST[MODULES_LIST.BUSINESS_SERVERS][
            PERMISSIONS_TYPE.EDIT
          ],
        ],
      },
      {
        path: "/business-servers/action/edit/:serverName",
        exact: true,
        title: "Edit",
        component: () => (
          <ProtectedRouteSuspenseContainer
            component={EditBusinessServerContainer}
          />
        ),
        permissionSet: [
          PERMISSIONS_LIST[MODULES_LIST.BUSINESS_SERVERS][
            PERMISSIONS_TYPE.EDIT
          ],
        ],
      },
      {
        path: "/business-servers/action/delete",
        exact: true,
        title: "Delete",
        component: () => (
          <ProtectedRouteSuspenseContainer
            component={DeleteBusinessServerContainer}
          />
        ),
        permissionSet: [
          PERMISSIONS_LIST[MODULES_LIST.BUSINESS_SERVERS][
            PERMISSIONS_TYPE.DELETE
          ],
        ],
      },
    ],
  },
];

export default BusinessServersRoutes;
