import { Grid, styled } from "@mui/material";
import PropTypes from "prop-types";
import { Button } from "@veneer/core";
import primitives from "@veneer/primitives";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import PoolCreationProgress from "../../dashboard/components/pools/PoolCreationProgress";

const AVAILABLE_COMPONENTS = {
  "pool-progress": PoolCreationProgress,
};

const PREFIX = "ImageWithCallToAction";

const classes = {
  caption: `${PREFIX}-caption`,
  title: `${PREFIX}-title`,
};

const StyledGrid = styled(Grid)(({ variant }) => ({
  [`& .${classes.caption}`]: {
    maxWidth: "400px",
    margin: "1rem 0 1.5rem 0",
    color: primitives.color.gray6,
  },
  ...(variant === "modal" && {
    [`& .${classes.title}`]: {
      marginTop: "15px",
      fontSize: "16px",
      lineHeight: "20px",
      fontWeight: 400,
    },
    [`& .${classes.caption}`]: {
      margin: "0 0 1.5rem 0",
      fontSize: "14px",
      lineHeight: "20px",
    },
  }),
  ...(variant === "table" && {
    padding: "40px 0",
    "& button": {
      marginTop: "32px",
    },
    [`& .${classes.caption}`]: {
      maxWidth: "400px",
      margin: 0,
      padding: 0,
      fontSize: "0.875rem",
      color: primitives.color.gray6,
    },
  }),
}));

const ImageWithCallToAction = ({
  image,
  title,
  caption,
  action = { color: "secondary" },
  variant,
  customContent,
}) => {
  const history = useHistory();

  const hasCustomContent = !!customContent && !!customContent.component;
  const CustomComponent = hasCustomContent
    ? AVAILABLE_COMPONENTS[customContent.component]
    : null;
  const customProps =
    hasCustomContent && !!customContent.props ? customContent.props : {};

  const handleClick = (e) => {
    if (action.navigateTo) {
      history.replace(action.navigateTo);

      return;
    }

    action.onClick(e);
  };

  return (
    <StyledGrid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      variant={variant}
    >
      <img src={image.src} alt={image.alt} />

      <h5 className={classes.title}>{title}</h5>

      {!!caption && <span className={classes.caption}>{caption}</span>}

      {!!CustomComponent && (
        <Grid>
          <CustomComponent {...customProps} />
        </Grid>
      )}

      {!!action && !!action.title && (action.onClick || action.navigateTo) && (
        <Button
          onClick={handleClick}
          appearance={action.color || "primary"}
          leadingIcon={action.icon || null}
          small
          customStyle={{
            borderRadius: "360px",
          }}
        >
          {action.title}
        </Button>
      )}
    </StyledGrid>
  );
};

ImageWithCallToAction.propTypes = {
  image: PropTypes.shape({
    src: PropTypes.any.isRequired,
    alt: PropTypes.string,
  }),
  variant: PropTypes.oneOf(["table", "modal"]),
  title: PropTypes.string.isRequired,
  caption: PropTypes.string,
  action: PropTypes.shape({
    title: PropTypes.string,
    onClick: PropTypes.func,
    color: PropTypes.oneOf(["primary", "secondary"]),
    icon: PropTypes.element,
    navigateTo: PropTypes.string,
  }),
  customContent: PropTypes.shape({
    component: PropTypes.elementType,
    props: PropTypes.object,
  }),
};

export default ImageWithCallToAction;
