import { styled } from "@mui/material";
import {
  ContextualMenu,
  IconChevronDown,
  MenuList,
  SideMenuItem,
} from "@veneer/core";
import propTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { checkTenantPermissions, getTenantId } from "../utils";
import VeneerButton from "./VeneerButton";

const CustomSideMenuItem = styled(SideMenuItem)(() => ({
  marginTop: 0,
}));

const OptionsMenuDropdownButton = ({ options, title }) => {
  const userPermissionsList = useSelector(
    (state) => state.permissions.userPermissionsList,
  );
  const { userSupportView } = useSelector((state) => state.auth);
  const selectedPartner = useSelector(
    (state) => state.partners.selectedPartner,
  );

  const menuElements = options
    .filter((el) => !!el.href)
    .filter((el) =>
      checkTenantPermissions(
        el.permissionSet,
        userPermissionsList,
        getTenantId(selectedPartner),
        userSupportView,
      ),
    );

  return (
    <ContextualMenu
      anchorNode={
        <VeneerButton
          appearance="primary"
          id="dropdown-menu-button"
          trailingIcon={<IconChevronDown />}
          disabled={!menuElements.length}
        >
          {title}
        </VeneerButton>
      }
      placement="bottom-end"
    >
      <MenuList>
        {menuElements.map((el, index) => (
          <CustomSideMenuItem
            key={index}
            label={el.title}
            url={el.href}
            link={<NavLink to={el.href} />}
          />
        ))}
      </MenuList>
    </ContextualMenu>
  );
};

OptionsMenuDropdownButton.propTypes = {
  title: propTypes.string.isRequired,
  options: propTypes.arrayOf(
    propTypes.shape({
      title: propTypes.string.isRequired,
      permissionSet: propTypes.arrayOf(propTypes.string),
      href: propTypes.string,
    }),
  ),
};

export default OptionsMenuDropdownButton;
