import { styled } from "@mui/material";
import { ProgressIndicator, Select } from "@veneer/core";
import { isEqual } from "lodash";
import debounce from "lodash/debounce";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { checkStringIncludes, getFormFieldError } from "../../utils";

const getCurrentValue = (value) => {
  if (!value) {
    return;
  }
  return typeof value === "object" ? value.value : value;
};

const StyledSelect = styled(Select)(({ theme, disabled }) => ({
  "& label": {
    color: theme.palette.foreground.light,
    fontWeight: 400,
  },
  "& span": {
    color: disabled ? `${theme.palette.foreground.disabled}` : "inherit",
  },
}));

const SelectContainer = styled("div")({
  display: "flex",
  alignItems: "center",
});

const SelectInput = React.forwardRef((props, ref) => {
  const {
    errors,
    name,
    helperText,
    label,
    value,
    isSearchable = false,
    itemsPerLoad = 50,
    isSliceDisplay = false,
    options,
    multiple,
    onClear = () => {},
    loading = false,
    ...rest
  } = props;

  const [displayOptions, setDisplayOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const fieldError = getFormFieldError(errors, name);
  const currentValue = multiple ? value : getCurrentValue(value);
  const hasValue = !!currentValue;
  const isValuePresent = !!displayOptions.find((el) =>
    isEqual(el, currentValue),
  );

  const isGroupedOptions =
    options.length > 0 && options[0].group && options[0].options;

  // The getOptions function is used to prepare the options for the Select component.
  // It takes three parameters: opts (the options), start (the start index for slicing), and end (the end index for slicing).
  const getOptions = (opts, start = 0, end = itemsPerLoad) => {
    if (!isGroupedOptions) {
      return isSliceDisplay ? opts.slice(start, end) : opts;
    } else {
      // If the options are grouped, it reduces the options array into a new array.
      // For each group in the options array, it checks if group.options is an array.
      // If it is, it checks if isSliceDisplay is true. If it is, it slices the group.options array from the start index to the end index.
      // If it's not, it simply uses the group.options array as is.
      // If group.options is not an array, it wraps it in an array.
      // It then returns a new array that starts with an object representing the group label
      // (which is disabled - since it will serve as the group label separator) and followed by the group options.
      return opts.reduce((acc, group) => {
        const groupOptions = Array.isArray(group.options)
          ? isSliceDisplay
            ? group.options.slice(start, end)
            : group.options
          : [group.options];
        return [
          ...acc,
          { label: group.group, value: group.group, disabled: true },
          ...groupOptions,
        ];
      }, []);
    }
  };

  useEffect(() => {
    setDisplayOptions(getOptions(options));
  }, [options, itemsPerLoad, isSliceDisplay]);

  const handleScrollEnd = () => {
    if (searchTerm || displayOptions.length >= options.length) {
      return;
    }

    const moreOptions = getOptions(
      options,
      displayOptions.length,
      displayOptions.length + itemsPerLoad,
    );
    setDisplayOptions((prevOptions) => [...prevOptions, ...moreOptions]);
  };

  const handleSearch = debounce((term) => {
    setSearchTerm(term);
    // Filter options based on search term
    const filteredOptions = getOptions(
      options.filter((option) => checkStringIncludes(option.label, term)),
    );
    setDisplayOptions(filteredOptions);
  }, 300);

  const handleFocus = () => {
    setDisplayOptions(getOptions(options));
  };

  return (
    <SelectContainer>
      <StyledSelect
        ref={ref}
        onScrollEnd={handleScrollEnd}
        onSearch={isSearchable ? handleSearch : undefined}
        onFocus={isSearchable ? handleFocus : undefined}
        showSearch={isSearchable}
        error={!!fieldError}
        helperText={fieldError || helperText}
        value={hasValue ? (multiple ? currentValue : [currentValue]) : []}
        preselectedOptions={isValuePresent || !hasValue ? [] : [value]}
        name={name}
        label={label}
        separateLabel
        onChange={props.onChange}
        onClear={onClear}
        multiple={multiple}
        {...rest}
        options={displayOptions}
      />
      {loading && (
        <ProgressIndicator
          appearance="circular"
          behavior="indeterminate"
          style={{ marginLeft: "10px", marginTop: "24px" }}
        />
      )}
    </SelectContainer>
  );
});

SelectInput.displayName = "SelectInput";

SelectInput.propTypes = {
  errors: PropTypes.object,
  name: PropTypes.string,
  helperText: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      value: PropTypes.any,
    }),
    PropTypes.array,
  ]),
  isSearchable: PropTypes.bool,
  itemsPerLoad: PropTypes.number,
  isSliceDisplay: PropTypes.bool,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.any,
        label: PropTypes.string,
      }),
    ),
    PropTypes.arrayOf(
      PropTypes.shape({
        group: PropTypes.string,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            value: PropTypes.any,
            label: PropTypes.string,
          }),
        ),
      }),
    ),
  ]).isRequired,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  multiple: PropTypes.bool,
  loading: PropTypes.bool,
};

export default SelectInput;
