import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { HTTP_METHODS } from "../../common/constants";
import HTTPClient from "../../common/servicers/httpClient";
import { getNotificationProfileId } from "../../common/utils";

export const getNotificationProfiles = createAsyncThunk(
  "getNotificationProfiles",
  async (
    { useCache, selectedTenant, organizationId },
    { getState, requestId, rejectWithValue },
  ) => {
    const {
      getNotificationProfilesRequestId,
      loadingNotificationProfiles,
      notificationProfiles,
    } = getState().adminActions.notificationProfiles;
    if (
      !loadingNotificationProfiles ||
      requestId !== getNotificationProfilesRequestId
    ) {
      return;
    }
    if (useCache && notificationProfiles[selectedTenant]) {
      return { data: notificationProfiles[selectedTenant], selectedTenant };
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${selectedTenant}/notificationProfiles`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const createNotificationProfile = createAsyncThunk(
  "createNotificationProfile",
  async (
    { selectedOrganization, selectedTenant, name, recipients },
    { getState, requestId, rejectWithValue },
  ) => {
    const {
      loadingCreateNotificationProfile,
      createNotificationProfileRequestId,
    } = getState().adminActions.notificationProfiles;
    if (
      !loadingCreateNotificationProfile ||
      requestId !== createNotificationProfileRequestId
    ) {
      return;
    }
    const payload = {
      type: "email",
      recipients,
    };
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${selectedOrganization}/tenants/${selectedTenant}/notificationProfiles/${name}`,
        method: HTTP_METHODS.POST,
        data: payload,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const editNotificationProfile = createAsyncThunk(
  "editNotificationProfile",
  async (
    { selectedOrganization, selectedTenant, recipients, profileId },
    { getState, requestId, rejectWithValue },
  ) => {
    const { loadingEditNotificationProfile, editNotificationProfileRequestId } =
      getState().adminActions.notificationProfiles;
    if (
      !loadingEditNotificationProfile ||
      requestId !== editNotificationProfileRequestId
    ) {
      return;
    }
    const payload = {
      type: "email",
      recipients,
    };
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${selectedOrganization}/tenants/${selectedTenant}/notificationProfiles/${profileId}`,
        method: HTTP_METHODS.PUT,
        data: payload,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant, profileId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteNotificationProfile = createAsyncThunk(
  "deleteNotificationProfile",
  async (
    { selectedOrganization, selectedTenant, notificationProfileId },
    { getState, requestId, rejectWithValue },
  ) => {
    const { deletingNotificationProfile, deleteNotificationProfileRequestId } =
      getState().adminActions.notificationProfiles;
    if (
      !deletingNotificationProfile ||
      requestId !== deleteNotificationProfileRequestId
    ) {
      return;
    }
    try {
      const profileId = getNotificationProfileId(notificationProfileId);
      const response = await new HTTPClient({
        endpoint: `/organizations/${selectedOrganization}/tenants/${selectedTenant}/notificationProfiles/${profileId}`,
        method: HTTP_METHODS.DELETE,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant, notificationProfileId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const initialState = {
  notificationProfiles: {},
  loadingNotificationProfiles: null,
  getNotificationProfilesRequestId: null,
  getNotificationProfilesError: null,

  loadingCreateNotificationProfile: null,
  createNotificationProfileRequestId: null,
  createNotificationProfileError: null,
  createNotificationProfileSuccessResponse: null,

  loadingEditNotificationProfile: null,
  editNotificationProfileRequestId: null,
  editNotificationProfileError: null,
  editNotificationProfileSuccessResponse: null,

  deletingNotificationProfile: null,
  deleteNotificationProfileRequestId: null,
  deleteNotificationProfileError: null,
  deleteNotificationProfileSuccess: null,
};

export const notificationProfilesSlice = createSlice({
  name: "notificationProfiles",
  initialState,
  reducers: {
    resetErrors: (state) => {
      state.getNotificationProfilesError = null;
      state.deletingNotificationProfile = null;
      state.deleteNotificationProfileError = null;
      state.deleteNotificationProfileSuccess = null;
      state.createNotificationProfileSuccessResponse = null;
      state.createNotificationProfileError = null;
      state.loadingCreateNotificationProfile = null;
      state.editNotificationProfileSuccessResponse = null;
      state.loadingEditNotificationProfile = null;
    },
  },
  extraReducers: {
    [getNotificationProfiles.pending]: (state, action) => {
      state.loadingNotificationProfiles = true;
      state.getNotificationProfilesRequestId = action.meta.requestId;
    },
    [getNotificationProfiles.fulfilled]: (state, action) => {
      state.getNotificationProfilesRequestId = null;
      state.loadingNotificationProfiles = false;
      if (action.payload.selectedTenant) {
        _.set(
          state.notificationProfiles,
          action.payload?.selectedTenant,
          action.payload.data,
        );
      }
    },
    [getNotificationProfiles.rejected]: (state, action) => {
      state.getNotificationProfilesRequestId = null;
      state.getNotificationProfilesError = action.payload || true;
      state.loadingNotificationProfiles = false;
    },

    [createNotificationProfile.pending]: (state, action) => {
      state.loadingCreateNotificationProfile = true;
      state.createNotificationProfileRequestId = action.meta.requestId;
      state.createNotificationProfileError = null;
    },
    [createNotificationProfile.fulfilled]: (state, action) => {
      state.loadingCreateNotificationProfile = false;
      state.createNotificationProfileRequestId = null;
      state.createNotificationProfileError = null;
      state.createNotificationProfileSuccessResponse = action.payload?.data;
      if (action.payload.selectedTenant) {
        const tenantId = action.payload.selectedTenant;
        state.notificationProfiles[tenantId] = [
          ...(state.notificationProfiles[tenantId] || []),
          action.payload.data,
        ];
      }
    },
    [createNotificationProfile.rejected]: (state, action) => {
      state.loadingCreateNotificationProfile = false;
      state.createNotificationProfileError = action.payload;
      state.createNotificationProfileRequestId = null;
      state.createNotificationProfileSuccessResponse = null;
    },

    [editNotificationProfile.pending]: (state, action) => {
      state.loadingEditNotificationProfile = true;
      state.editNotificationProfileSuccessResponse = null;
      state.editNotificationProfileRequestId = action.meta.requestId;
      state.editNotificationProfileError = null;
    },
    [editNotificationProfile.fulfilled]: (state, action) => {
      state.loadingEditNotificationProfile = false;
      state.editNotificationProfileRequestId = null;
      state.editNotificationProfileError = null;
      state.editNotificationProfileSuccessResponse = true;
      if (action.payload.selectedTenant) {
        const tenantId = action.payload.selectedTenant;
        state.notificationProfiles[tenantId] = state.notificationProfiles[
          tenantId
        ].map((p) => {
          if (p.notificationProfileId === action.payload.profileId) {
            return action.payload?.data;
          }
          return p;
        });
      }
    },
    [editNotificationProfile.rejected]: (state, action) => {
      state.loadingEditNotificationProfile = false;
      state.editNotificationProfileError = action.payload;
      state.editNotificationProfileRequestId = null;
      state.editNotificationProfileSuccessResponse = false;
    },

    [deleteNotificationProfile.pending]: (state, action) => {
      state.deletingNotificationProfile = true;
      state.deleteNotificationProfileError = null;
      state.deleteNotificationProfileRequestId = action.meta.requestId;
      state.deleteNotificationProfileSuccess = null;
    },
    [deleteNotificationProfile.fulfilled]: (state, action) => {
      state.deleteNotificationProfileSuccess = true;
      state.deletingNotificationProfile = false;
      state.deleteNotificationProfileError = null;
      state.deleteNotificationProfileRequestId = null;
      state.notificationProfiles[action.payload.selectedTenant] =
        state.notificationProfiles[action.payload.selectedTenant].filter(
          (u) => u.id !== action.payload.notificationProfileId,
        );
    },
    [deleteNotificationProfile.rejected]: (state, action) => {
      state.deletingNotificationProfile = null;
      state.deleteNotificationProfileError = action.payload;
      state.deleteNotificationProfileRequestId = null;
      state.deleteNotificationProfileSuccess = false;
    },
  },
});

export const { resetErrors } = notificationProfilesSlice.actions;

export const notificationProfilesSliceSelector = (state) =>
  state.adminActions.notificationProfiles;

export default notificationProfilesSlice.reducer;
