import { createTheme } from "@mui/material/styles";
import { primitives } from "@veneer/primitives";

// Wex tokens collected from figma:
// https://www.figma.com/file/T5EndUmpj0fj7NmZrPOIVn/WEX-Tokens?type=design&node-id=0%3A1&mode=design&t=9vmbogzFvAUopOnS-1

/**
 * Applies opacity to a given hex color and returns the corresponding rgba color.
 *
 * @param {string} hexColor - The hex color value.
 * @param {number} opacity - The opacity value as a percentage (0 to 100).
 * @returns {string} The rgba color value.
 */
export const applyOpacity = (hexColor, opacity) => {
  // Convert hex to RGB
  const bigint = parseInt(hexColor.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  // Convert opacity from percentage to a value between 0 and 1
  const normalizedOpacity = opacity / 100;

  // Return rgba color
  return `rgba(${r},${g},${b},${normalizedOpacity})`;
};

// Light theme

const lightForeground = {
  default: primitives.color.gray12,
  medium: primitives.color.gray9,
  light: primitives.color.gray7,
  disabled: primitives.color.gray5,
  inverse: primitives.color.white,
};

const lightBackground = {
  default: applyOpacity(primitives.color.darkNavy5, primitives.color.opacity1),
  disabled: primitives.color.gray3,
  container: primitives.color.white,
  shell: primitives.color.white,
  hover: primitives.color.gray1,
  stroke: primitives.color.darkNavy0,
  boxShadow: applyOpacity(primitives.color.gray12, primitives.color.opacity2),
  foreground: primitives.color.gray9,
};

const lightPrimary = {
  default: primitives.color.darkNavy7,
  hover: primitives.color.darkNavy8,
  active: primitives.color.darkNavy9,
  backgroundDefault: primitives.color.darkNavy0,
  backgroundHover: applyOpacity(
    primitives.color.darkNavy7,
    primitives.color.opacity1,
  ),
  backgroundActive: applyOpacity(
    primitives.color.darkNavy7,
    primitives.color.opacity2,
  ),
  foreground: primitives.color.white,
};

const lightNeutral = {
  default: primitives.color.gray7,
  hover: primitives.color.gray8,
  active: primitives.color.gray9,
  backgroundDefault: primitives.color.gray1,
  backgroundHover: applyOpacity(
    primitives.color.gray5,
    primitives.color.opacity1,
  ),
  backgroundActive: applyOpacity(
    primitives.color.gray5,
    primitives.color.opacity2,
  ),
  foreground: primitives.color.white,
};

const lightPositive = {
  default: primitives.color.green7,
  hover: primitives.color.green8,
  active: primitives.color.green9,
  backgroundDefault: primitives.color.green0,
  backgroundHover: primitives.color.green1,
  backgroundActive: primitives.color.green2,
  foreground: primitives.color.white,
};

const lightWarning = {
  default: primitives.color.darkOrange5,
  hover: primitives.color.darkOrange6,
  active: primitives.color.darkOrange7,
  backgroundDefault: primitives.color.darkOrange0,
  backgroundHover: primitives.color.darkOrange1,
  backgroundActive: primitives.color.darkOrange2,
  foreground: primitives.color.gray12,
};

const lightNegative = {
  default: primitives.color.red7,
  hover: primitives.color.red8,
  active: primitives.color.red9,
  backgroundDefault: primitives.color.red0,
  backgroundHover: primitives.color.red1,
  backgroundActive: primitives.color.red2,
  foreground: primitives.color.white,
};

const lightHelp = {
  default: primitives.color.vividSkyBlue7,
  hover: primitives.color.vividSkyBlue8,
  active: primitives.color.vividSkyBlue9,
  backgroundDefault: primitives.color.vividSkyBlue0,
  backgroundHover: primitives.color.vividSkyBlue1,
  backgroundActive: primitives.color.vividSkyBlue2,
  foreground: primitives.color.white,
};

// Dark theme

const darkForeground = {
  default: primitives.color.white,
  medium: primitives.color.gray3,
  light: primitives.color.gray5,
  disabled: primitives.color.gray7,
  inverse: primitives.color.gray12,
};

const darkBackground = {
  default: primitives.color.gray12,
  medium: primitives.color.gray10,
  disabled: primitives.color.gray9,
  container: primitives.color.gray11,
  hover: primitives.color.gray10,
  stroke: primitives.color.gray9,
  boxShadow: applyOpacity(primitives.color.gray12, primitives.color.opacity2),
  foreground: primitives.color.white,
};

const darkPrimary = {
  default: primitives.color.darkNavy5,
  hover: primitives.color.darkNavy4,
  active: primitives.color.darkNavy3,
  backgroundDefault: primitives.color.darkNavy12,
  backgroundHover: applyOpacity(
    primitives.color.darkNavy5,
    primitives.color.opacity1,
  ),
  backgroundActive: applyOpacity(
    primitives.color.darkNavy5,
    primitives.color.opacity2,
  ),
  foreground: primitives.color.white,
};

const darkNeutral = {
  default: primitives.color.gray5,
  hover: primitives.color.gray4,
  active: primitives.color.gray3,
  backgroundDefault: primitives.color.gray10,
  backgroundHover: applyOpacity(
    primitives.color.gray6,
    primitives.color.opacity1,
  ),
  backgroundActive: applyOpacity(
    primitives.color.gray6,
    primitives.color.opacity2,
  ),
  foreground: primitives.color.gray12,
};

const darkPositive = {
  default: primitives.color.green5,
  hover: primitives.color.green4,
  active: primitives.color.green3,
  backgroundDefault: primitives.color.green12,
  backgroundHover: primitives.color.green11,
  backgroundActive: primitives.color.green10,
  foreground: primitives.color.gray12,
};

const darkWarning = {
  default: primitives.color.darkOrange5,
  hover: primitives.color.darkOrange4,
  active: primitives.color.darkOrange3,
  backgroundDefault: primitives.color.darkOrange12,
  backgroundHover: primitives.color.darkOrange11,
  backgroundActive: primitives.color.darkOrange10,
  foreground: primitives.color.gray12,
};

const darkNegative = {
  default: primitives.color.red5,
  hover: primitives.color.red4,
  active: primitives.color.red3,
  backgroundDefault: primitives.color.red12,
  backgroundHover: primitives.color.red11,
  backgroundActive: primitives.color.red10,
  foreground: primitives.color.gray12,
};

const darkHelp = {
  default: primitives.color.vividSkyBlue5,
  hover: primitives.color.vividSkyBlue4,
  active: primitives.color.vividSkyBlue3,
  backgroundDefault: primitives.color.vividSkyBlue12,
  backgroundHover: primitives.color.vividSkyBlue11,
  backgroundActive: primitives.color.vividSkyBlue10,
  foreground: primitives.color.gray12,
};

const colorSchemes = {
  light: {
    primary: {
      ...lightPrimary,
      default: lightPrimary.default,
      hover: lightPrimary.hover,
      active: lightPrimary.active,
      backgroundLight: lightPrimary.backgroundHover,
      background: lightPrimary.backgroundDefault,
      backgroundDefault: lightPrimary.backgroundActive,
      foreground: lightPrimary.foreground,
    },
    neutral: {
      ...lightNeutral,
      default: lightNeutral.default,
      hover: lightNeutral.hover,
      active: lightNeutral.active,
      backgroundLight: lightNeutral.backgroundActive,
      background: lightNeutral.backgroundDefault,
      foreground: lightNeutral.foreground,
    },
    positive: {
      ...lightPositive,
      default: lightPositive.default,
      hover: lightPositive.hover,
      active: lightPositive.active,
      backgroundLight: lightPositive.backgroundActive,
      background: lightPositive.backgroundDefault,
      foreground: lightPositive.foreground,
    },
    warning: {
      ...lightWarning,
      default: lightWarning.default,
      hover: lightWarning.hover,
      active: lightWarning.active,
      backgroundLight: lightWarning.backgroundActive,
      background: lightWarning.backgroundDefault,
      foreground: lightWarning.foreground,
    },
    negative: {
      ...lightNegative,
      default: lightNegative.default,
      hover: lightNegative.hover,
      active: lightNegative.active,
      backgroundLight: lightNegative.backgroundActive,
      background: lightNegative.backgroundDefault,
      foreground: lightNegative.foreground,
    },
    foreground: {
      ...lightForeground,
      default: lightForeground.default,
      medium: lightForeground.medium,
      light: lightForeground.light,
      inverse: lightForeground.inverse,
      disabled: lightForeground.disabled,
    },
    background: {
      ...lightBackground,
      default: lightBackground.default,
      container: lightBackground.container,
      disabled: lightBackground.disabled,
      shell: lightBackground.shell,
      boxShadow: lightBackground.boxShadow,
      stroke: lightBackground.stroke,
      foreground: lightBackground.foreground,
    },
    help: {
      ...lightHelp,
      default: lightHelp.default,
      hover: lightHelp.hover,
      active: lightHelp.active,
      backgroundLight: lightHelp.backgroundActive,
      background: lightHelp.backgroundDefault,
      foreground: lightHelp.foreground,
    },
  },
  dark: {
    primary: {
      ...darkPrimary,
      default: darkPrimary.default,
      hover: darkPrimary.hover,
      active: darkPrimary.active,
      backgroundLight: darkPrimary.backgroundHover,
      background: darkPrimary.backgroundDefault,
      backgroundDefault: darkPrimary.backgroundActive,
      foreground: darkPrimary.foreground,
    },
    neutral: {
      ...darkNeutral,
      default: darkNeutral.default,
      hover: darkNeutral.hover,
      active: darkNeutral.active,
      backgroundLight: darkNeutral.backgroundHover,
      background: darkNeutral.backgroundDefault,
      foreground: darkNeutral.foreground,
    },
    positive: {
      ...darkPositive,
      default: darkPositive.default,
      hover: darkPositive.hover,
      active: darkPositive.active,
      backgroundLight: darkPositive.backgroundHover,
      background: darkPositive.backgroundDefault,
      foreground: darkPositive.foreground,
    },
    warning: {
      ...darkWarning,
      default: darkWarning.default,
      hover: darkWarning.hover,
      active: darkWarning.active,
      backgroundLight: darkWarning.backgroundHover,
      background: darkWarning.backgroundDefault,
      foreground: darkWarning.foreground,
    },
    negative: {
      ...darkNegative,
      default: darkNegative.default,
      hover: darkNegative.hover,
      active: darkNegative.active,
      backgroundLight: darkNegative.backgroundHover,
      background: darkNegative.backgroundDefault,
      foreground: darkNegative.foreground,
    },
    foreground: {
      ...darkForeground,
      default: darkForeground.default,
      medium: darkForeground.medium,
      light: darkForeground.light,
      inverse: darkForeground.inverse,
      disabled: darkForeground.disabled,
    },
    background: {
      ...darkBackground,
      default: darkBackground.default,
      container: darkBackground.container,
      disabled: darkBackground.disabled,
      shell: darkBackground.container,
      boxShadow: lightBackground.boxShadow,
      stroke: darkBackground.stroke,
      foreground: darkBackground.foreground,
    },
    help: {
      ...darkHelp,
      default: darkHelp.default,
      hover: darkHelp.hover,
      active: darkHelp.active,
      backgroundLight: darkHelp.backgroundHover,
      background: darkHelp.backgroundDefault,
    },
  },
};

export function getColorScheme(mode) {
  return colorSchemes[mode];
}

function getComponentStyleOverrides(mode) {
  const colors = getColorScheme(mode);
  return {
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow:
            mode === "dark"
              ? "0px 5px 5px rgba(0, 0, 0, 0.2)"
              : "0px 0px 4px rgba(0, 0, 0, 0.25)",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        outlined: {
          border: `1px solid ${colors.primary.background}`,
          color: colors.primary.default,
        },
        root: {
          "&.Mui-disabled": {
            color: colors.foreground.disabled,
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        button: {
          textDecoration: "none",
          color: colors.primary.default,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        body1: {
          color: colors.foreground.default,
          fontSize: "16px",
          lineHeight: "22px",
        },
        body2: {
          fontWeight: 400,
          color: colors.foreground.default,
          fontSize: "12px",
          lineHeight: "22px",
          marginTop: "7px",
          marginBottom: "7px",
          marginRight: "7px",
        },
        body3: {
          fontWeight: 400,
          color: colors.foreground.default,
          fontSize: "12px",
          lineHeight: "12px",
          marginTop: "7px",
          marginBottom: "7px",
          marginRight: "7px",
        },
        h4: {
          fontSize: "18px",
          lineHeight: "24px",
          fontWeight: 400,
          color: colors.foreground.default,
        },
        h3: {
          fontSize: "24px",
          fontWeight: 400,
          lineHeight: "30px",
          color: colors.foreground.default,
        },
        subtitle1: {
          color: colors.foreground.default,
          fontSize: "14px",
        },
        subtitle2: {
          color: colors.foreground.disabled,
          fontSize: "14px",
        },
        // Removed duplicate body3 for consistency
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: colors.foreground.disabled,
          height: "2px",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        scrollable: {
          borderBottom: `1px solid ${colors.primary.background}`,
          marginBottom: "0px !important",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          fontWeight: "600",
          lineHeight: "20px",
        },
        textColorPrimary: {
          color: colors.foreground.medium,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "14px",
          backgroundColor: colors.background.container,
          color: colors.foreground.default,
        },
      },
    },
  };
}

function createDynamicTheme(mode) {
  const colors = getColorScheme(mode);
  const componentStyleOverrides = getComponentStyleOverrides(mode);

  return createTheme({
    typography: {
      fontFamily: "'Forma DJR UI',Arial,sans-serif",
    },
    palette: {
      primary: {
        main: colors.primary.default,
        light: colors.primary.backgroundLight,
        dark: colors.primary.background,
        hover: colors.primary.hover,
        active: colors.primary.active,
        background: colors.primary.backgroundDefault,
        inverse: colors.primary.backgroundHover,
        inverseActive: colors.primary.backgroundActive,
        foreground: colors.primary.foreground,
      },
      neutral: {
        default: colors.neutral.default,
        hover: colors.neutral.hover,
        active: colors.neutral.active,
        background: colors.neutral.background,
        backgroundLight: colors.neutral.backgroundLight,
        inverse: colors.neutral.inverse,
        foreground: colors.neutral.foreground,
      },
      secondary: {
        main: colors.neutral.default,
        light: colors.neutral.backgroundLight,
        dark: colors.neutral.background,
        hover: colors.neutral.hover,
        active: colors.neutral.active,
        foreground: colors.neutral.foreground,
      },
      error: {
        main: colors.negative.default,
        light: colors.negative.backgroundLight,
        dark: colors.negative.background,
        hover: colors.negative.hover,
        active: colors.negative.active,
        foreground: colors.negative.foreground,
      },
      warning: {
        main: colors.warning.default,
        light: colors.warning.backgroundLight,
        dark: colors.warning.background,
        hover: colors.warning.hover,
        active: colors.warning.active,
        foreground: colors.warning.foreground,
      },
      info: {
        main: colors.neutral.default,
        light: colors.neutral.backgroundLight,
        dark: colors.neutral.background,
        hover: colors.neutral.hover,
        active: colors.neutral.active,
        foreground: colors.neutral.foreground,
      },
      success: {
        main: colors.positive.default,
        light: colors.positive.backgroundLight,
        dark: colors.positive.background,
        hover: colors.positive.hover,
        active: colors.positive.active,
        foreground: colors.positive.foreground,
      },
      background: {
        default: colors.background.default,
        paper: colors.background.container,
        stroke: colors.background.stroke,
        container: colors.background.container,
        boxShadow: colors.background.boxShadow,
        disabled: colors.background.disabled,
        foreground: colors.background.foreground,
        shell: colors.background.shell,
      },
      foreground: {
        default: colors.foreground.default,
        medium: colors.foreground.medium,
        light: colors.foreground.light,
        inverse: colors.foreground.inverse,
        disabled: colors.foreground.disabled,
        hint: colors.foreground.light,
      },
      text: {
        primary: colors.foreground.default,
        secondary: colors.foreground.medium,
        disabled: colors.foreground.disabled,
        hint: colors.foreground.light,
      },
      help: {
        main: colors.help.default,
        light: colors.help.backgroundLight,
        dark: colors.help.background,
        hover: colors.help.hover,
        active: colors.help.active,
        foreground: colors.help.foreground,
      },
    },
    components: componentStyleOverrides,
  });
}

export { createDynamicTheme };
