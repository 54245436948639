import React from 'react';
import PropTypes from "prop-types";
import styled from "@mui/system/styled";
import { IconPlus, Modal } from "@veneer/core";

import VeneerButton from './VeneerButton';

const PREFIX = "CommonModal";

const classes = {
  modalFooter: `${PREFIX}-modalFooter`,
  modalButton: `${PREFIX}-modalButton`,
  modalButtonExpanded: `${PREFIX}-modalButtonExpanded`,
};

const BaseModal = styled(Modal)(({ theme }) => ({
  [`& .${classes.modalFooter}`]: {
    display: "flex",
    width: "100%",
  },

  [`& .${classes.modalButton}`]: {
    marginRight: "16px",
    borderRadius: "360px",
    minWidth: "112px",
    height: "36px",
  },

  [`& .${classes.modalButtonExpanded}`]: {
    flex: "1",
  },

  [`& .vn-modal--footer`]: {
    paddingTop: "16px",
  },
}));

const CommonModal = ({
  id = 'modal',
  title,
  open,
  actions = [],
  expanded = false,
  onClose,
  children,
  className,
  maxWidth,
}) => {
  return (
    <BaseModal
      id={`${PREFIX}-${id}`}
      align="start"
      closeButton
      expanded={expanded}
      maxWidth={maxWidth}
      show={open}
      onClose={onClose}
      title={title}
      className={className}
      footer={actions && actions.length > 0 &&
        <div className={classes.modalFooter}>
          {actions.map((item, index) => (
            <VeneerButton
              key={index}
              className={`${classes.modalButton} ${item.expanded ? classes.modalButtonExpanded : ""}`}
              disabled={item.disabled}
              appearance={item.main ? "primary" : "secondary"}
              leadingIcon={item.leadingIcon ? <IconPlus /> : undefined}
              onClick={item.onClick}
              colorScheme={item.context === 'error' ? 'negative' : undefined}
            >
              {item.label}
            </VeneerButton>
          ))}
        </div>
      }
    >
      {children}
    </BaseModal>
  )
}

CommonModal.displayName = "CommonModal";

CommonModal.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  open: PropTypes.bool.isRequired,
  actions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    context: PropTypes.oneOf([
      "inherit",
      "primary",
      "secondary",
      "error",
      "info",
      "success",
      "warning",
      "neutral",
      "blackWhite"
    ]),
    main: PropTypes.bool,
    expanded: PropTypes.bool,
    disabled: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
  })),
  onClose: PropTypes.func.isRequired,
  expanded: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  maxWidth: PropTypes.string,
};

export default CommonModal;
