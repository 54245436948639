import React from "react";
import { styled } from '@mui/material/styles';
import MuiTooltip from "@mui/material/Tooltip";
import PropTypes from "prop-types";
import InfoIcon from "@mui/icons-material/Info";

const PREFIX = 'Tooltip';

const classes = {
  arrow: `${PREFIX}-arrow`,
  tooltip: `${PREFIX}-tooltip`,
  tooltip2: `${PREFIX}-tooltip2`
};

const StyledInfoIcon = styled(InfoIcon)((
  {
    theme
  }
) => ({
  [`& .${classes.tooltip2}`]: {
    marginLeft: theme.spacing(1),
    color: "#4A4A4A",
    fontSize: "inherit",
  }
}));

export const HtmlTooltip = () => ({
  [`& .${classes.arrow}`]: {
    color: "#f5f5f9",
  },

  [`& .${classes.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    border: "1px solid #dadde9",
  }
})(MuiTooltip);

const Tooltip = (props) => {

  const { title, placement, icon, ...rest } = props;
  return (
    <MuiTooltip className={classes.tooltip} title={title} placement={placement} {...rest}>
      {icon}
    </MuiTooltip>
  );
};

Tooltip.propTypes = {
  title: PropTypes.string,
  placement: PropTypes.string,
  icon: PropTypes.element,
};

Tooltip.defaultProps = {
  placement: "right-end",
  title: "default",
  icon: <StyledInfoIcon />,
};

export default Tooltip;
