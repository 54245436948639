/* eslint-disable react-hooks/rules-of-hooks */
import { useParams } from "react-router";
const TYPE_ANF = "ANF";

export const getDataVolumneTitle = (params) => {
  const { dataVolumeName } = params ? params : useParams();
  return dataVolumeName;
};

export const checkDataVolumePerformanceDisabled = (params) => {
  const { type } = params ? params : useParams();
  return type !== TYPE_ANF;
};
/* eslint-enable react-hooks/rules-of-hooks */

export const hideDataVolumeDirectoryValues = (state) => {
  if (
    state?.adminActions?.dataVolumes?.dataVolume?.properties
      ?.azureADDirectJoinEnabled === undefined
  ) {
    return true;
  }
  return false;
};
