import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import { Paper, Typography, Link, Button, Grid } from "@mui/material";

import Carousel from "react-material-ui-carousel";
import {
  getReleaseAnnouncements,
  setHiddenReleases,
} from "../../dashboard/slices/releaseAnnouncementsSlice";
import { useDispatch, useSelector } from "react-redux";
import VeneerButton from "./VeneerButton";

const PREFIX = 'InfoBanner';

const classes = {
  announcementLink: `${PREFIX}-announcementLink`,
  paper: `${PREFIX}-paper`,
  CheckButton: `${PREFIX}-CheckButton`,
  announcement: `${PREFIX}-announcement`,
  carousel: `${PREFIX}-carousel`
};

const StyledPaper = styled(Paper)((
  {
    theme
  }
) => ({
  [`& .${classes.announcementLink}`]: {
    float: "right",
    fontSize: "16px",
  },

  [`& .${classes.paper}`]: {
    background: "#fcf2d9",
    height: "15%",
    padding: "1%",
  },

  [`& .${classes.CheckButton}`]: {
    float: "right",
    padding: "0% 1%",
    color: "#4D52E6",
  },

  [`& .${classes.announcement}`]: {
    textAlign: "center",
  },

  [`& .${classes.carousel}`]: {
    display: "block",
    height: "130px",
  }
}));

const InfoBanner = (props) => {
  const dispatch = useDispatch();

  const [unhiddenReleases, setUnhiddenReleases] = useState("");
  const latestReleaseAnnouncements = useSelector(
    (state) => state.dashboard.releaseAnnouncements.latestReleaseAnnouncements
  );
  const hiddenReleases = useSelector(
    (state) => state.dashboard.releaseAnnouncements.hiddenReleases
  );
  useEffect(() => {
    dispatch(getReleaseAnnouncements({ useCache: false, filter: "latest" }));
  }, []);

  useEffect(() => {
    setUnhiddenReleases(
      latestReleaseAnnouncements.filter((release) => !hiddenReleases.includes(release.id))
    );
  }, [hiddenReleases, latestReleaseAnnouncements]);

  return (
    <>
      {unhiddenReleases?.length > 1 && (
        <Carousel
          className={classes.carousel}
          fullHeightHover={false}
          navButtonsAlwaysInvisible={true}
          activeIndicatorIconButtonProps={{
            style: {
              backgroundColor: "#b0aeae",
            },
          }}
          indicatorIconButtonProps={{
            style: {
              color: "#b0aeae",
              padding: "0.25px",
            },
          }}
          interval={7000}
          duration={1000}
        >
          {unhiddenReleases?.map((release, index) => (
            <Item
              key={index}
              item={release}
              style={classes}
              setHiddenReleases={props.setHiddenReleases}
              dispatch={dispatch}
            />
          ))}
        </Carousel>
      )}
      {unhiddenReleases?.length === 1 && (
        <Item
          item={unhiddenReleases[0]}
          style={classes}
          setHiddenReleases={props.setHiddenReleases}
          dispatch={dispatch}
          marginBottom={"2%"}
        />
      )}
    </>
  );
};

function Item(props) {
  return (
    <StyledPaper className={props.style.paper} style={{ marginBottom: props.marginBottom }}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="body1" className={props.style.announcement}>
            {props.item.releaseAnnouncement}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <VeneerButton
            className={props.style.CheckButton}
            onClick={() => props.dispatch(setHiddenReleases({ id: props.item.id }))}
          >
            Dismiss
          </VeneerButton>
          {props.item.releaseNotesLink && (
            <VeneerButton
              className={props.style.CheckButton}
              onClick={() => {
                window.open(props.item.releaseNotesLink, "_blank");
              }}
            >
              Learn More
            </VeneerButton>
          )}
        </Grid>
      </Grid>
    </StyledPaper>
  );
}

export default InfoBanner;
