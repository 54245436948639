import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import React from "react";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

const PREFIX = "Loader";

const classes = {
  bottom: `${PREFIX}-bottom`,
  top: `${PREFIX}-top`,
  circle: `${PREFIX}-circle`,
  loaderText: `${PREFIX}-loaderText`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.bottom}`]: {
    color: "rgba(26, 138, 255, 0.2)",
  },

  [`& .${classes.top}`]: {
    color: "#1a90ff",
    animationDuration: "1s",
    position: "absolute",
  },

  [`& .${classes.circle}`]: {
    strokeLinecap: "round",
  },

  [`& .${classes.loaderText}`]: {
    display: "flex",
    justifyContent: "center",
    fontSize: "16px",
    color: theme.palette.foreground.medium,
    margin: "16px 0px",
  },
}));

const Loader = (props) => {
  const { loaderText } = props;

  return (
    <StyledBox display="flex" alignItems="center" justifyContent="center">
      <div>
        <Box
          position="relative"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress
            variant="determinate"
            className={classes.bottom}
            size={50}
            thickness={5}
            value={100}
          />
          <CircularProgress
            variant="indeterminate"
            disableShrink
            className={classes.top}
            size={50}
            thickness={5}
          />
        </Box>
        {loaderText ? (
          <Typography
            className={classes.loaderText}
            variant="caption"
            component="div"
          >
            {loaderText}
          </Typography>
        ) : null}
      </div>
    </StyledBox>
  );
};

Loader.propTypes = {
  loaderText: PropTypes.string,
};

export default Loader;
