import React, { Suspense } from "react";
import { RETRY_TIME_FOR_LOADING_CHUNK } from "../constants";
import { sleep } from "../utils";

export const SuspenseLoader = (Component, props) => (
  <Suspense fallback={"Loading..."}>
    <Component {...props} />
  </Suspense>
);

export const componentLoader = (lazyComponent, attemptsLeft = 2) => {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error) => {
        sleep(RETRY_TIME_FOR_LOADING_CHUNK).then(() => {
          if (attemptsLeft === 0) {
            reject(error);
            return;
          }
          componentLoader(lazyComponent, attemptsLeft - 1).then(resolve, reject);
        });
      });
  });
};
