import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';

import CheckboxField from "./CheckboxField";
import { Autocomplete, CircularProgress, TextField, FormLabel } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { sortBy } from "lodash";
import MouseOverPopover from "../MouseOverPopover";

const PREFIX = 'MultiSelectAutocompleteField';

const classes = {
  formLabelRoot: `${PREFIX}-formLabelRoot`,
  formLabelFocused: `${PREFIX}-formLabelFocused`,
  formLabelError: `${PREFIX}-formLabelError`,
  root: `${PREFIX}-root`,
  rootWithoutMargin: `${PREFIX}-rootWithoutMargin`,
  textRoot: `${PREFIX}-textRoot`,
  progress: `${PREFIX}-progress`,
  label: `${PREFIX}-label`,
  labelDisabled: `${PREFIX}-labelDisabled`,
  checkboxWrapper: `${PREFIX}-checkboxWrapper`,
};

const StyledFormControl = styled(FormControl)((
  {
    theme
  }
) => ({
  [`& .${classes.formLabelRoot}`]: {
    color: theme.palette.foreground.light,
    // TODO: fox MUI commented by Alex. ->> https://mui.com/material-ui/migration/v5-style-changes/#migrate-theme-styleoverrides-to-emotion
    "&.Mui-focused": {
      color: theme.palette.foreground.light,
    },
    "&.Mui-error": { color: theme.palette.error.main },
  },

  [`& .${classes.formLabelFocused}`]: {},
  [`& .${classes.formLabelError}`]: {},

  [`& .${classes.root}`]: {
    marginTop: "5px",
    "&:hover .MuiOutlinedInput-root fieldset": {
      border: "1px solid #C4C4C4",
    },
    "& .MuiOutlinedInput-root fieldset": {
      border: "1px solid #C4C4C4",
    },
    "& .MuiInputBase-root": {
      height: "2.25rem",
    },
    "& .MuiInputBase-root.Mui-disabled fieldset": {
      border: "1px solid #C4C4C4",
    },
    "& .MuiInputBase-root.Mui-disabled": {
      cursor: "not-allowed",
    },
    "& label": {
      color: theme.palette.grey[400],
    },
  },

  [`& .${classes.rootWithoutMargin}`]: {
    "&:hover .MuiOutlinedInput-root fieldset": {
      border: "1px solid #C4C4C4",
    },
    "& .MuiOutlinedInput-root fieldset": {
      border: "1px solid #C4C4C4",
    },
    "& .MuiInputBase-root": {
      height: "2.25rem",
    },
    "& .MuiInputBase-root.Mui-disabled fieldset": {
      border: "1px solid #C4C4C4",
    },
    "& .MuiInputBase-root.Mui-disabled": {
      cursor: "not-allowed",
    },
    "& label": {
      color: theme.palette.grey[400],
    },
  },

  [`& .${classes.textRoot}`]: {
    paddingTop: "5px",
    "&:hover .MuiOutlinedInput-root fieldset": {
      border: "1px solid #C4C4C4",
    },
  },

  [`& .${classes.progress}`]: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },

  [`& .${classes.label}`]: {
    backgroundColor: "white",
  },

  [`& .${classes.labelDisabled}`]: {
    backgroundColor: "inherit",
    color: "rgba(0, 0, 0, 0.2)!important",
  },
}));

const CheckboxWrapper = styled('div')({
  [`&.${classes.checkboxWrapper}`]: {
    display: "flex",
    padding: "2px 16px",
  },
});

const MultiSelectAutocompleteField = ({
  onChange,
  input,
  meta,
  options,
  label,
  formLabel,
  toolTip,
  labelStyle,
  loading,
  disabled,
  InputProps,
  valueField,
  disabledNewSelection,
  disabledNewSelectionTooltip,
  selectedSelectionTooltip,
  scrollReachedBottom,
  sorted = true,
  ...rest
}) => {
  const [selectedNames, setSelectedNames] = useState([]);
  const [displayOptions, setDisplayOptions] = useState([]);
  const setRenderNames = (values) => {
    setSelectedNames(
      displayOptions
        .filter((option) => values?.indexOf(valueField ? option[valueField] : option.value) > -1)
        .map((item) => item.name)
    );
  };
  useEffect(() => {
    if (sorted) {
      setDisplayOptions(sortBy(options, ["name"]));
    } else {
      setDisplayOptions(options);
    }
  }, [options]);

  useEffect(() => {
    setRenderNames(input.value);
  }, [input.value, displayOptions]);

  const renderCircularProgress = () => {
    return (
      <div className={classes.progress}>
        <CircularProgress thickness={4} size={20} />
      </div>
    );
  };

  const onChangeOfField = (e, value) => {
    let values = [];
    let targetValue = value
    if (e.keyCode === 8) {
      values = [...input.value.slice(0, -1)];
    } else if (targetValue) {
      if (input.value.indexOf(targetValue) > -1) {
        if (
          !(
            InputProps?.selectedReadonlyData &&
            InputProps?.selectedReadonlyData.indexOf(targetValue) > -1
          )
        ) {
          values = input.value.filter((value) => value !== targetValue);
        } else {
          values = input.value;
        }
      } else {
        if (disabledNewSelection) {
          values = [...input.value];
        } else {
          values = [...input.value, targetValue];
        }
      }
    }

    if (input && input.onChange) {
      input.onChange(values);
    }
    if (onChange) {
      onChange(values);
    }
    setRenderNames(values);
  };

  return (
    <StyledFormControl
      className={classes.formControl}
      fullWidth={true}
      error={meta && meta.touched && meta.error ? true : false}
    >
      {formLabel && (
        <FormLabel
          component="legend"
          className={classes.formLabelRoot}
          style={labelStyle ? labelStyle : {}}
          error={meta && meta.touched && meta.error ? true : false}
        >
          {formLabel}
        </FormLabel>
      )}
      <Autocomplete
        multiple
        className={formLabel ? classes.rootWithoutMargin : classes.root}
        id="autocomplete-multiselect"
        options={displayOptions || []}
        disableCloseOnSelect
        getOptionLabel={(option) => option.name}
        IconComponent={loading ? renderCircularProgress : KeyboardArrowDownIcon}
        ListboxProps={{
          onScroll: (event) => {
            const listboxNode = event.currentTarget;
            if (
              Math.ceil(listboxNode.scrollTop + listboxNode.clientHeight) >=
              listboxNode.scrollHeight
            ) {
              if (scrollReachedBottom) {
                scrollReachedBottom();
              }
            }
          },
        }}
        renderTags={() => {
          return (
            <>
              {selectedNames && selectedNames.length > 0 && selectedNames[0]}
              {selectedNames && selectedNames.length > 1 ? (
                <MouseOverPopover
                  label={`+${selectedNames.length - 1}`}
                  data={selectedNames.slice(1)}
                />
              ) : (
                ""
              )}{" "}
            </>
          );
        }}
        isOptionEqualToValue={(e) => input?.value?.indexOf(e.value) > -1}
        disableClearable={true}
        renderOption={(props, options, state) => {
          return (
            <CheckboxWrapper key={options.value} className={classes.checkboxWrapper}>
              <CheckboxField
                key={options.value}
                toolTip={
                  disabledNewSelection && !state.selected
                    ? {
                      title: disabledNewSelectionTooltip,
                    }
                    : InputProps?.selectedReadonlyData &&
                      InputProps?.selectedReadonlyData.indexOf(option.value) > -1 &&
                      selectedSelectionTooltip
                      ? {
                        title: selectedSelectionTooltip,
                      }
                      : undefined
                }
                value={options.value}
                onChange={(e) => {
                  onChangeOfField(e, options.value);
                }}
                checked={input.value?.indexOf(options.value) > -1 && state.selected}
                disabled={
                  (!(input.value?.indexOf(options.value) > -1 && state.selected) && disabledNewSelection) ||
                  (InputProps?.selectedReadonlyData &&
                    InputProps?.selectedReadonlyData.indexOf(options.value) > -1)
                }
                color={"info"}
              />
              {options.name}
            </CheckboxWrapper>
          )
        }}
        style={{ width: "100%", backgroundColor: disabled && "rgba(0, 0, 0, 0.1)" }}
        renderInput={(params) => (
          <TextField
            {...params}
            className={classes.textRoot}
            variant="outlined"
            label={label}
            size="small"
            InputLabelProps={{
              classes: {
                root: classes.label,
                disabled: classes.labelDisabled,
              },
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? renderCircularProgress() : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
        disabled={disabled}
        {...input}
        {...rest}
      />
    </StyledFormControl>
  );
};

export default MultiSelectAutocompleteField;
