import styled from "@emotion/styled";
import { ProgressIndicator } from "@veneer/core";
import PropTypes from "prop-types";
import React from "react";
import { PROGRESS_STATUS } from "../../common/constants";

const PREFIX = "ProgressBarLog";
const classes = {
  infoWrapper: `${PREFIX}-infoWrapper`,
  completedText: `${PREFIX}-completedText`,
};

const ProgressBarLog = styled("div")(({ theme }) => ({
  [`.${classes.infoWrapper}`]: {
    marginTop: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  [`.${classes.completedText}`]: {
    color: theme.palette.foreground.light,
  },
  '[role="progressbar"]': {
    borderRadius: "10px",
    div: {
      borderRadius: "10px",
    },
  },
  "& > div > span": {
    marginLeft: "auto",
  },
}));

const CustomProgressIndicator = styled(ProgressIndicator)(() => ({
  display: "grid",
}));

export const ProgressLogBar = ({ steps, loading }) => {
  const completedSteps = steps?.filter(
    (step) => step.status === PROGRESS_STATUS.COMPLETED,
  ).length;
  const totalSteps = steps?.length || 1;
  const [internalProgress, setInternalProgress] = React.useState(0);

  React.useEffect(() => {
    setInternalProgress(Math.round((completedSteps / totalSteps) * 100));
  }, [completedSteps, totalSteps]);

  if (loading) {
    return (
      <ProgressBarLog>
        <CustomProgressIndicator behavior="indeterminate" appearance="linear" />
      </ProgressBarLog>
    );
  }

  return (
    <ProgressBarLog progress={internalProgress}>
      <CustomProgressIndicator
        appearance="linear"
        behavior="determinate"
        showLabel
        value={internalProgress}
      />
    </ProgressBarLog>
  );
};

ProgressLogBar.propTypes = {
  completedSteps: PropTypes.number,
  totalSteps: PropTypes.number,
  loading: PropTypes.bool.isRequired,
};
