import { styled } from "@mui/material";
import { Card } from "@veneer/core";
import PropTypes from "prop-types";
import React from "react";

const PREFIX = "CardExample";

const classes = {
  title: `${PREFIX}-title`,
  example: `${PREFIX}-example`,
};

const CardExampleRoot = styled("div")(({ theme }) => ({
  maxWidth: "555px",
  borderRadius: "12px",
  border: `1px solid ${theme.palette.background.stroke}`,
  backgroundColor: `${theme.palette.primary.main}0D`,

  [`& .${classes.title}`]: {
    overflow: "hidden",
    color: theme.palette.text.primary,
    textOverflow: "ellipsis",
    fontFamily: "Forma DJR UI",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20px",
  },
  [`& .${classes.example}`]: {
    overflow: "hidden",
    color: theme.palette.text.primary,
    textOverflow: "ellipsis",
    fontFamily: "Forma DJR UI",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "24px",
  },
}));

// need to add the styles to the card component via the customStyle prop
// since veneer does not support className prop
const cardStyles = {
  border: "0px solid transparent",
  backgroundColor: "transparent",
  display: "flex",
  gap: "24px",
  padding: "12px 32px",
  alignItems: "center",
};

const CardExample = ({ title, examples }) => {
  return (
    <CardExampleRoot>
      <Card
        customStyle={cardStyles}
        background="filled"
        border="outlined"
        content={
          <>
            <nobr>
              <span className={classes.title}>{title}</span>
            </nobr>
            <span className={classes.example}>{examples}</span>
          </>
        }
      />
    </CardExampleRoot>
  );
};

CardExample.propTypes = {
  title: PropTypes.string.isRequired,
  examples: PropTypes.string.isRequired,
};

export default CardExample;
