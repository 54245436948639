import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setItem } from "../storage";
import { HTTP_METHODS } from "./constants";
import HTTPClient from "./servicers/httpClient";
import { isUserSupport } from "./utils";

/**************************************
 *********** API ACTIONS ***************
 ***************************************/

export const authApi = createAsyncThunk(
  "auth",
  async ({ useCache = true }, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, loading, auth } = getState().auth;
    if (!loading || requestId !== currentRequestId) {
      return;
    }
    if (useCache && auth) {
      return { data: auth };
    }
    try {
      const response = await new HTTPClient({
        endpoint: "/auth/",
        method: HTTP_METHODS.POST,
      }).callAuthorizedAPI();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);
export const initialState = {
  auth: null,
  requesting: false,
  loading: true,
  userSupportView: null,
};

export const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserSupportView: (state, action) => {
      setItem("userSupportRole", action.payload);
      state.userSupportView = action.payload;
    },
  },
  extraReducers: {
    [authApi.pending]: (state, action) => {
      state.loading = true;
      state.currentRequestId = action.meta.requestId;
    },
    [authApi.fulfilled]: (state, action) => {
      state.loading = false;
      state.currentRequestId = null;
      state.auth = action.payload
        ? action.payload.data
          ? action.payload.data
          : null
        : null;
      if (
        action?.payload?.data &&
        isUserSupport(action?.payload?.data?.userRoles) &&
        !action?.payload?.data?.roles
      ) {
        state.userSupportView = true;
      } else {
        state.userSupportView = false;
      }
    },
    [authApi.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.currentRequestId = null;
    },
  },
});

export const { setUserSupportView } = auth.actions;

export default auth.reducer;
