import React from "react";
import styled from "@emotion/styled";
import { Button } from '@veneer/core';

const PREFIX = "ButtonMenu";

const classes = {
  button: `${PREFIX}-button`,
};

const StyledButtonMenu = styled(Button)(({ theme }) => ({
  [`&.${classes.button}`]: {
    borderRadius: '360px', // from figma
    color: `${theme.palette.foreground.medium} !important`,
    width: '100%',
    justifyContent: 'flex-start',
    padding: '0 16px 0 0',
  },
}));

const ButtonMenu = ({ ...other }) => {
  return (
    <StyledButtonMenu
      className={classes.button}
      small
      appearance="ghost"
      expanded
      {...other}
    />
  );
};

export default ButtonMenu;