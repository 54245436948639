import React from "react";
import { styled } from '@mui/material/styles';

import { useAuthContext } from "../contexts/AuthContext";
import AzureADAuthWrapper from "../../azureB2C/components/login";
import { Paper, Typography } from "@mui/material";

const PREFIX = 'MyProfile';

const classes = {
  root: `${PREFIX}-root`,
  myProfile: `${PREFIX}-myProfile`
};

const StyledAzureADAuthWrapper = styled(AzureADAuthWrapper)((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    textAlign: "center",
  },

  [`& .${classes.myProfile}`]: {
    width: "50%",
    padding: "3%",
    marginTop: "3%",
    marginLeft: "25%",
    minHeight: "500px",
  }
}));

export default function MyProfile() {

  const { state: authData } = useAuthContext();
  const { account } = authData;

  if (authData && authData.isAuthenticated) {
    return (
      <div className={classes.root}>
        <Paper className={classes.myProfile}>
          <Typography variant="h4" component="h4">
            My Profile
          </Typography>
          <br></br>
          <Typography variant="h5" component="h5">
            Email : {account.idTokenClaims.email}
          </Typography>
          <Typography variant="h5" component="h5">
            Name : {account.name}
          </Typography>
          <Typography variant="h5" component="h5">
            Environment : {account.environment}
          </Typography>
        </Paper>
      </div>
    );
  } else {
    return <StyledAzureADAuthWrapper noDisplay={true} />;
  }
}
