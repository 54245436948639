import MSALService from "../services/msal-service";
import { b2cSignInAuth } from "../utils/azure-b2c";

let azureB2C = null;

export function getAzureB2C() {
  if (!azureB2C) {
    azureB2C = new MSALService(b2cSignInAuth);
  }
  return azureB2C;
}
