import PropTypes from "prop-types";
import React from "react";
import VeneerModal from "./VeneerModal";

const ConfirmModal = ({
  open = false,
  showPrimary = true,
  showSecondary = true,
  primaryLoading = false,
  primaryType = "button",
  size = "medium",
  manualReverify = false,
  descriptionList = [],
  ...props
}) => {
  const allProps = {
    open,
    showPrimary,
    showSecondary,
    primaryLoading,
    primaryType,
    size,
    manualReverify,
    descriptionList,
    ...props,
  };
  return <VeneerModal {...allProps} />;
};

ConfirmModal.propTypes = {
  title: PropTypes.string,
  description1: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  open: PropTypes.bool,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  showPrimary: PropTypes.bool,
  primaryLoading: PropTypes.bool,
  primaryText: PropTypes.string,
  showSecondary: PropTypes.bool,
  secondaryText: PropTypes.string,
  size: PropTypes.string,
  manualReverify: PropTypes.bool,
  verificationString: PropTypes.string,
  showHTMLContent: PropTypes.bool,
  htmlContent: PropTypes.func,
  descriptionList: PropTypes.array,
};

export default ConfirmModal;
