import IconButton from "@mui/material/IconButton";
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from "react";

import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import ReportProblemRoundedIcon from "@mui/icons-material/ReportProblemRounded";
import { Menu as MuiMenu } from "@mui/material";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { IconBell, NotificationBadge } from '@veneer/core';
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getTenantDetail } from "../../dashboard/slices";
import { resetRefreshTenants } from "../../dashboard/slices/ciAlerts";
import GroupIcon from "../../icons/group.png";
import PartnerIcon from "../../icons/partner.svg";
import { CI_ALERTS_SEVERITY_TYPES, TENANT_ID_PREFIX } from "../constants";
import { fetchPartners, setPartner } from "../partnersSlice";
import { getTenantPermissions } from "../permissionsSlice";
import { getIdentityId, getTenantId } from "../utils";
import { DASHBOARD_ROUTE } from "../../admin-actions/adminActionsConstants";

const PREFIX = 'AlertNotification';

const classes = {
  title: `${PREFIX}-title`,
  verticalAlignCenter: `${PREFIX}-verticalAlignCenter`,
  menuItem: `${PREFIX}-menuItem`,
  viewAll: `${PREFIX}-viewAll`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const StyledMenu = styled(MuiMenu)((
  {
    theme
  }
) => ({
  [`& .${classes.title}`]: {
    width: "400px",
    height: "38px",
    padding: "8px 8px 8px 16px",
    borderBottom: "1px solid #E2E2E2",
  },

  [`& .${classes.verticalAlignCenter}`]: { display: "flex", alignItems: "center" },
  [`& .${classes.menuItem}`]: { width: "400px", height: "92px", background: "#F6F5FF", border: "1px solid #E2E2E2" },

  [`& .${classes.viewAll}`]: {
    display: "flex",
    alignItems: "center",
    width: "400px",
    height: "37px",
    justifyContent: "center",
    color: "#006FE6",
  }
}));

export default function AlertNotification(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);
  const [showAll, setShowAll] = useState(false);
  const [formatedAlert, setFormatedAlert] = useState([]);
  const [formatedDisabledEmailAlert, setFormatedDisabledEmailAlert] = useState([]);
  const [formatedVisibleAlert, setFormatedVisibleAlert] = useState([]);
  const tenants = useSelector((state) => state.partners.tenants);
  const selectedIdentity = useSelector((state) => state.identities.selectedIdentity);
  const { organizationIdentity } = useSelector((state) => state.organizationIdentity);
  const { unreadAlerts, refreshTenants } = useSelector((state) => state.dashboard.ciAlerts);

  useEffect(() => {
    if (refreshTenants && refreshTenants[getIdentityId(selectedIdentity)]) {
      dispatch(
        fetchPartners({ useCache: false, selectedOrganization: getIdentityId(selectedIdentity) })
      );
      dispatch(
        getTenantDetail({
          organizationId: getIdentityId(selectedIdentity),
          tenantId: refreshTenants[getIdentityId(selectedIdentity)],
          useCache: false,
        })
      );
      dispatch(resetRefreshTenants());
    }
  }, [refreshTenants]);

  useEffect(() => {
    if (tenants && tenants[getIdentityId(selectedIdentity)]) {
      let disabledEmailTeanantList = [];
      tenants[getIdentityId(selectedIdentity)].forEach((t) => {
        if (
          t?.notificationSettings?.emailDisabled &&
          t?.notificationSettings?.emailDisabledByReceiver
        ) {
          disabledEmailTeanantList.push({
            tenantId: t.tenantId,
            tenantName: t?.displayName,
            updatedAt: t?.notificationSettings?.emailDisabledDateTime
              ? moment
                  .utc(t?.notificationSettings?.emailDisabledDateTime)
                  .local()
                  .format("DD MMM, hh:mm A")
              : null,
          });
        }
      });
      setFormatedDisabledEmailAlert(disabledEmailTeanantList);
    } else {
      setFormatedDisabledEmailAlert([]);
    }
  }, [tenants]);

  useEffect(() => {
    if (unreadAlerts && unreadAlerts[getIdentityId(selectedIdentity)]) {
      let tenantsMap = {};
      unreadAlerts[getIdentityId(selectedIdentity)].forEach((ua) => {
        if (tenantsMap[ua.TenantID]) {
          tenantsMap[ua.TenantID] = updateMapdata(tenantsMap[ua.TenantID], ua);
        } else {
          tenantsMap[ua.TenantID] = {
            tenantId: ua.TenantID,
            tenantName: tenants[getIdentityId(selectedIdentity)].find(
              (t) => getTenantId(t.id) === ua.TenantID
            )?.displayName,
            warning: 0,
            critical: 0,
          };
          tenantsMap[ua.TenantID] = updateMapdata(tenantsMap[ua.TenantID], ua);
        }
      });
      let alertArr = [];
      Object.keys(tenantsMap).forEach((k) => {
        alertArr.push(tenantsMap[k]);
      });
      alertArr = alertArr.sort((a, b) => b.updatedAt.getTime() - a.updatedAt.getTime());
      setFormatedAlert(alertArr);
      if (showAll || alertArr.length <= 3) {
        setFormatedVisibleAlert(alertArr);
      } else {
        setFormatedVisibleAlert(alertArr.slice(0, 3));
      }
    } else {
      setFormatedAlert([]);
    }
  }, [unreadAlerts]);

  useEffect(() => {
    if (showAll) {
      setFormatedVisibleAlert(formatedAlert);
    }
  }, [showAll]);

  const updateMapdata = (tenantsMapObj, unreadAlert) => {
    if (unreadAlert.Severity.toLowerCase() === CI_ALERTS_SEVERITY_TYPES.WARNING.toLowerCase()) {
      tenantsMapObj = { ...tenantsMapObj, warning: tenantsMapObj.warning + 1 };
    } else if (
      unreadAlert.Severity.toLowerCase() === CI_ALERTS_SEVERITY_TYPES.CRITICAL.toLowerCase()
    ) {
      tenantsMapObj = { ...tenantsMapObj, critical: tenantsMapObj.critical + 1 };
    }
    if (
      !tenantsMapObj.updatedAt ||
      tenantsMapObj.updatedAt.getTime() < unreadAlert.updatedAt.getTime()
    ) {
      tenantsMapObj.updatedAt = unreadAlert.updatedAt;
    }
    return tenantsMapObj;
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const redirectToAlerts = (id) => {
    dispatch(setPartner(TENANT_ID_PREFIX + id));
    dispatch(
      getTenantPermissions({
        useCache: true,
        selectedTenantId: id,
        selectedOrganizationId: getIdentityId(selectedIdentity),
      })
    );
    handleClose();
    history.push("/dashboard/alerts");
  };
  const redirectToTenant = (id) => {
    dispatch(setPartner(TENANT_ID_PREFIX + id));
    dispatch(
      getTenantPermissions({
        useCache: true,
        selectedTenantId: id,
        selectedOrganizationId: getIdentityId(selectedIdentity),
      })
    );
    handleClose();
    history.push(DASHBOARD_ROUTE);
  };

  const getWarningLabel = (warning, critical) => {
    let totalCount = warning + critical;
    let label = totalCount + " Monitor";
    if (critical && !warning) {
      label += " Critical";
    }
    label += " Warning";
    if (totalCount > 1) {
      label += "s";
    }
    return label;
  };

  const hasUnreadAlerts = unreadAlerts && unreadAlerts[getIdentityId(selectedIdentity)];

  const badgeContent = (
    hasUnreadAlerts ? unreadAlerts[getIdentityId(selectedIdentity)].length : 0) + 
    (formatedDisabledEmailAlert?.length || 0);

  return (
    <>
      {badgeContent > 0 ? (
        <NotificationBadge position="top-end">
          <IconButton
            edge="end"
            aria-label="alerts"
            aria-haspopup="true"
            color="primary"
            onClick={handleClick}
            size="small"
          >
            <IconBell size={24} />
          </IconButton>
        </NotificationBadge>
      ) : (
        <IconButton
          edge="end"
          aria-label="alerts"
          aria-haspopup="true"
          color="primary"
          onClick={handleClick}
          size="small"
        >
          <IconBell size={24} />
        </IconButton>
      )}

      <StyledMenu
        id="alerts-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        // TODO: fix MUI commented by Alex
        // getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <div className={classes.title}>
          <Typography component="span" variant="body1">
            {"Alerts"}
          </Typography>
        </div>
        {formatedDisabledEmailAlert &&
          formatedDisabledEmailAlert.map((alertObj, index) => {
            return (
              <MenuItem
                key={index}
                className={classes.menuItem}
                onClick={() => redirectToTenant(alertObj?.tenantId)}
              >
                <Grid container spacing={2}>
                  <Grid item lg={1} md={1} sm={1} xs={1} className={classes.verticalAlignCenter}>
                    <ReportProblemRoundedIcon style={{ color: "#FFA726" }} />
                  </Grid>
                  <Grid
                    item
                    lg={11}
                    md={11}
                    sm={11}
                    xs={11}
                    className={classes.verticalAlignCenter}
                  >
                    <Typography component="span" variant="body1" style={{ fontWeight: 600 }}>
                      {"Email Alerts Disabled"}
                    </Typography>
                  </Grid>
                  <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
                  <Grid item lg={2} md={2} sm={2} xs={2} className={classes.verticalAlignCenter}>
                    <Typography
                      component="span"
                      variant="body1"
                      style={{ fontWeight: 400, fontSize: "12px" }}
                    >
                      {alertObj?.updatedAt}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={8}
                    md={3}
                    sm={8}
                    xs={8}
                    className={classes.verticalAlignCenter}
                    style={{
                      marginLeft: "14px",
                    }}
                  >
                    <img src={PartnerIcon} alt="partner" />
                    &nbsp;
                    <Typography
                      component="span"
                      variant="body1"
                      style={{ fontSize: "14px", color: "#006FE6" }}
                    >
                      {alertObj?.tenantName}
                    </Typography>
                  </Grid>
                </Grid>
              </MenuItem>
            );
          })}
        {formatedVisibleAlert &&
          formatedVisibleAlert.map((alertObj, index) => {
            return (
              <MenuItem
                key={index}
                onClick={() => redirectToAlerts(alertObj?.tenantId)}
                className={classes.menuItem}
              >
                <Grid container spacing={2}>
                  <Grid item lg={1} md={1} sm={1} xs={1} className={classes.verticalAlignCenter}>
                    {alertObj?.warning && alertObj?.critical ? (
                      <img src={GroupIcon} alt="Group Icon" />
                    ) : alertObj?.critical ? (
                      <ErrorRoundedIcon style={{ color: "#FF0000" }} />
                    ) : alertObj?.warning ? (
                      <ReportProblemRoundedIcon style={{ color: "#FFA726" }} />
                    ) : (
                      <></>
                    )}
                  </Grid>
                  <Grid
                    item
                    lg={11}
                    md={11}
                    sm={11}
                    xs={11}
                    className={classes.verticalAlignCenter}
                  >
                    <Typography component="span" variant="body1" style={{ fontWeight: 600 }}>
                      {getWarningLabel(alertObj?.warning, alertObj?.critical)}
                    </Typography>
                  </Grid>
                  <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
                  <Grid item lg={2} md={2} sm={2} xs={2} className={classes.verticalAlignCenter}>
                    <Typography
                      component="span"
                      variant="body1"
                      style={{ fontWeight: 400, fontSize: "12px" }}
                    >
                      {moment(alertObj?.updatedAt).format("DD MMM, hh:mm A")}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={8}
                    md={3}
                    sm={8}
                    xs={8}
                    className={classes.verticalAlignCenter}
                    style={{
                      marginLeft: "12px",
                    }}
                  >
                    <img src={PartnerIcon} alt="partner" />
                    &nbsp;
                    <Typography
                      component="span"
                      variant="body1"
                      style={{ fontSize: "14px", color: "#006FE6" }}
                    >
                      {alertObj?.tenantName}
                    </Typography>
                  </Grid>
                </Grid>
              </MenuItem>
            );
          })}
        {formatedAlert && formatedAlert.length > 3 && !showAll && (
          <MenuItem onClick={() => setShowAll(true)} className={classes.verticalAlignCenter}>
            {"View all"}
          </MenuItem>
        )}
        {(!formatedAlert || formatedAlert.length === 0) &&
          (!formatedDisabledEmailAlert || formatedDisabledEmailAlert.length === 0) && (
            <MenuItem style={{ width: "400px", height: "40px" }}>{"No new Alert"}</MenuItem>
          )}
      </StyledMenu>
    </>
  );
}
