import { LAYOUTS } from "../common/constants";
import Logout from "../Logout/logout";

const LogoutRoute = [
  {
    path: "/logout",
    component: Logout,
    layoutStyle: LAYOUTS.MENU_SCREEN,
  },
];

export default LogoutRoute;
