import React, { Suspense, useCallback, useEffect } from "react";
import { Route } from "react-router-dom";
import AzureADAuthWrapper from "../../azureB2C/components/login";
import { getAzureB2C } from "../../azureB2C/factories/azure-ad-factory";
import { useAuthContext } from "../contexts/AuthContext";
import { REQ } from "../utils";

function ProtectedRoute({ component: Component, ...rest }) {
  const {
    state: { isAuthenticated },
  } = useAuthContext();
  const client = getAzureB2C();

  const handleSignIn = useCallback(() => {
    client
      .loginRedirect({
        ...REQ,
        scopes: ["openid", ...REQ.scopes],
        prompt: "select_account",
      })
      .catch((e) => {
        console.error(e);
      });
  }, [client]);

  useEffect(() => {
    if (!isAuthenticated) {
      handleSignIn();
    }
  }, [isAuthenticated, handleSignIn]);

  return (
    <Route
      {...rest}
      render={() => {
        if (isAuthenticated) {
          return Component;
        } else {
          return <AzureADAuthWrapper noDisplay={true} />;
        }
      }}
    />
  );
}

export const ProtectedRouteSuspenseContainer = ({
  component: Component,
  ...rest
}) => {
  return (
    <ProtectedRoute
      component={
        <Suspense>
          <Component {...rest} />
        </Suspense>
      }
    />
  );
};
