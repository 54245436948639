import { styled } from "@mui/system";

const PREFIX = "ProgressStyles";

const classes = {
  root: `${PREFIX}-root`,
  header: `${PREFIX}-header`,
  label: `${PREFIX}-label`,
  footer: `${PREFIX}-footer`,
  footerMessage: `${PREFIX}-footerMessage`,
  inactiveLabel: `${PREFIX}-inactiveLabel`,
  completeIcon: `${PREFIX}-completeIcon`,
  startedIcon: `${PREFIX}-startedIcon`,
  progressIcon: `${PREFIX}-progressIcon`,
};

const SideProgressStyles = styled("div")(() => ({
  [`.${classes.root}`]: {
    padding: "19px",
    paddingRight: "28px",
    paddingLeft: "28px",
  },
  [`.${classes.header}`]: {
    marginBottom: "4%",
    fontWeight: 600,
  },
  [`.${classes.label}`]: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  [`.${classes.footer}`]: {
    marginTop: "16px",
    display: "flex",
    flexDirection: "row",
  },
  [`.${classes.footerMessage}`]: {
    marginTop: "0px",
    display: "flex",
    alignItems: "center",
  },
  [`.${classes.inactiveLabel}`]: {
    color: "#939393",
  },
  [`.${classes.completeIcon}`]: {
    color: "#28C78A",
    fontSize: "16px",
    marginRight: "5px",
  },
  [`.${classes.startedIcon}`]: {
    color: "#99a29f",
    fontSize: "16px",
    marginRight: "5px",
  },
  [`.${classes.progressIcon}`]: {
    marginRight: "7px",
    marginLeft: "2px",
  },
}));

export default SideProgressStyles;
export { classes };
