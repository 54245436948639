import React from 'react';
import { TextBox } from '@veneer/core';
import PropTypes from "prop-types";
import { getFormFieldError } from '../../utils';
import InputTooltip from './InputTooltip';
import { styled } from '@mui/material/styles';
import { Password } from '@veneer/core';
import primitives from "@veneer/primitives";
import {
  IconCheckmarkCircle,
  IconWarning,
} from "@veneer/core";

const StyledPassword = styled(Password)({
  '& label': {
    color: '#737373',
    fontWeight: 400
  },
  "&.variant--success .vn-input": {
    borderColor: primitives.color.green7,
    "& .vn-input__icon svg": {
      fill: primitives.color.green7,
    },
    "& .vn-trailing_button": {
      color: primitives.color.green7,
    },
  },
  "&.variant--error .vn-input": {
    borderColor: primitives.color.red7,
    "& .vn-input__icon svg": {
      fill: primitives.color.red7,
    },
    "& .vn-trailing_button": {
      color: primitives.color.red7,
    },
  },
});

const TextPasswordInput = React.forwardRef((props, ref) => {
  const { errors, name, helperText, tooltip, label, trailingIcon = null, className = '', variant, ...rest } = props
  const fieldError = getFormFieldError(errors, name);

  const variantType = {
    success: {
      className: 'variant--success',
      icon: <IconCheckmarkCircle />
    },
    error: {
      className: 'variant--error',
      icon: <IconWarning />
    },
  }

  const customClassName = `${className} ${variantType[variant]?.className || ''}`.trim()

  return (
    <StyledPassword
      ref={ref}
      error={!!fieldError}
      helperText={fieldError || helperText}
      name={name}
      label={label}
      separateLabel
      className={customClassName}
      trailingButton={<InputTooltip tooltip={tooltip} />}
      trailingIcon={variantType[variant]?.icon || trailingIcon}
      {...rest}
    />
  )
})

TextPasswordInput.displayName = "TextPasswordInput"

TextPasswordInput.propTypes = {
  errors: PropTypes.object,
  name: PropTypes.string,
  helperText: PropTypes.string,
  tooltip: PropTypes.string,
  label: PropTypes.string,
  trailingIcon: PropTypes.node,
  variant: PropTypes.oneOf(["success", "error", "default"]),
  className: PropTypes.string,
}

export default TextPasswordInput
