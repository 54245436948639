import { CREDENTIAL_TYPES } from "./deschutesConstants";

export const validateChooseConnectionsStep = (values) => {
  if (!values.credentialsType) {
    return false;
  }
  if (
    values.credentialsType === CREDENTIAL_TYPES.NEW &&
    (!values.username || !values.password)
  ) {
    return false;
  }
  return true;
};

export const checkW365NotEnabled = (tenantId, cloudPcEnabled) => {
  return (
    cloudPcEnabled &&
    tenantId &&
    typeof cloudPcEnabled[tenantId] !== "undefined" &&
    !cloudPcEnabled[tenantId]
  );
};

export const checkW365Enabled = (tenantId, cloudPcEnabled) => {
  return (
    cloudPcEnabled &&
    tenantId &&
    typeof cloudPcEnabled[tenantId] !== "undefined" &&
    cloudPcEnabled[tenantId]
  );
};

export const HEALTH_CHECK_STATUS = {
  PASSED: "passed",
  FAILED: "failed",
  PENDING: "pending",
  RUNNING: "running",
  WARNING: "warning",
};

export const createAdditionalParams = (list = []) => {
  const props = {};
  list.forEach((tag) => {
    const entries = Object.entries(tag);
    const [key, value] = entries[0];
    props[key] = value;
  });
  return props;
};

export const convertAdditionalParamsToTags = (params = {}) => {
  return Object.entries(params).map(([key, value]) => {
    return { [key]: value };
  });
};
