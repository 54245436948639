import { CssBaseline } from "@mui/material";
import { styled } from "@mui/material/styles";
import { unwrapResult } from "@reduxjs/toolkit";
import clsx from "clsx";
import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { getAzureB2C } from "../../azureB2C/factories/azure-ad-factory";
import { getOrganizationIdentity } from "../../registration/organizationIdentitySlice";
import { authApi } from "../authSlice";
import { ALL_CUSTOMERS, APPLICATION_CONTEXTS, LAYOUTS } from "../constants";
import { useAuthContext } from "../contexts/AuthContext";
import { setPartner } from "../partnersSlice";
import {
  checkTenantPermissions,
  getSelectedOrganizationId,
  getTenantId,
  hasAccessToRoute,
} from "../utils";
import ErrorPage from "./ErrorPage";
import InactivityTimer from "./InactivityTimer";
import InfoBanner from "./InfoBanner";
import Menu from "./Menu";
import Tabs from "./PageTabs";
import SubTabs from "./SubTabs";
import { SidebarMenuAdmin } from "./ame-components/sidebar/Sidebar";
import ShimmerLoader from "../shimmerLoader/ShimmerLoader";
import Header from "./Header";
import CTAPage from "./CTAPage";
import useCTA from "../hooks/useCTA";

const PREFIX = "ParentRoute";

const classes = {
  root: `${PREFIX}-root`,
  toolbar: `${PREFIX}-toolbar`,
  content: `${PREFIX}-content`,
  routeTitle: `${PREFIX}-routeTitle`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme, isSidebarOpen }) => ({
  [`& .${classes.root}`]: {
    marginLeft: isSidebarOpen ? 304 : 80,
    transition: theme.transitions.create(["margin-left"], {
      delay: 50,
      easing: theme.transitions.easing.ease,
      duration: 200,
    }),
  },

  [`& .${classes.toolbar}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    // ...theme.mixins.toolbar,
    minHeight: "52px",
  },

  [`& .${classes.content}`]: {
    flexGrow: 1,
    paddingLeft: "32px",
    paddingRight: "32px",
    paddingTop: "12px",
  },
}));

function ParentRoute(props) {
  const { state: authData } = useAuthContext();
  const savedIdentity = getSelectedOrganizationId();
  const client = getAzureB2C();
  const dispatch = useDispatch();
  const { auth, userSupportView } = useSelector((state) => state.auth);
  const showFullScreen = useSelector(
    (state) => state.userPreferences.showFullScreen
  );
  const { userPermissionsList, loadingTenantPermissions } = useSelector(
    (state) => state.permissions
  );
  const { organizationIdentity } = useSelector(
    (state) => state.organizationIdentity
  );
  const authLoading = useSelector((state) => state.auth.loading);
  const { isSubComponent, authRequired, hideAnnouncements, context } = props;
  const selectedPartner = useSelector(
    (state) => state.partners.selectedPartner
  );
  const isLoading =
    authLoading === null ||
    loadingTenantPermissions === null ||
    authLoading ||
    loadingTenantPermissions;
  const tenant = useSelector((state) => state.dashboard.tenant.tenant);
  const identities = useSelector((state) => state.identities.identities);
  const selectedIdentity = useSelector(
    (state) => state.identities.selectedIdentity
  );
  const hideRelease = useSelector(
    (state) => state.dashboard.releaseAnnouncements.hideRelease
  );
  const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);
  const { shouldRender: shouldRenderCTA } = useCTA();

  useEffect(() => {
    if (authData.isAuthenticated && !auth && !isSubComponent && authRequired) {
      dispatch(authApi({ useCache: true }))
        .then(unwrapResult)
        .then(() => {
          if (savedIdentity && userSupportView === false)
            dispatch(
              getOrganizationIdentity({ organizationId: savedIdentity })
            );
        })
        .catch(() => {
          client.logout();
        });
    }
  }, [authData, auth]);

  useEffect(() => {
    if (
      context === APPLICATION_CONTEXTS.ORGANIZATION &&
      selectedPartner !== ALL_CUSTOMERS
    ) {
      dispatch(setPartner(ALL_CUSTOMERS));
    }
  }, []);

  const logout = () => {
    client.logout();
  };

  return (
    <Root isSidebarOpen={isSidebarOpen}>
      <div
        className={
          props.layoutStyle === LAYOUTS.MENU_SCREEN && !showFullScreen
            ? classes.root
            : {}
        }
      >
        {authData.isAuthenticated && (
          <InactivityTimer
            tenant={tenant}
            identities={identities}
            selectedIdentity={selectedIdentity}
            selectedPartner={selectedPartner}
          />
        )}
        {authData.isAuthenticated &&
          props.layoutStyle === LAYOUTS.MENU_SCREEN && (
            <SidebarMenuAdmin />
          )}

        {authData.isAuthenticated &&
          props.layoutStyle === LAYOUTS.MENU_SCREEN && (
            <>
              <Menu logout={logout} route={props} />
              <Header hideActions={shouldRenderCTA} />
            </>
          )}

        {!showFullScreen && <CssBaseline />}

        <main className={clsx({ [classes.content]: !props.isSubComponent && props.layoutStyle === LAYOUTS.MENU_SCREEN })}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
            {!shouldRenderCTA && !showFullScreen && (!authRequired || authData.isAuthenticated) && (
              <>
                {authData.isAuthenticated &&
                  props.layoutStyle === LAYOUTS.MENU_SCREEN &&
                  !props.isSubComponent &&
                  !hideAnnouncements &&
                  !hideRelease && (
                    <InfoBanner />
                  )}

                {props.isTabs && !loadingTenantPermissions && (
                  <Tabs {...props} />
                )}

                {props.isSubTabs && !loadingTenantPermissions && (
                  <SubTabs {...props} />
                )}
              </>
            )}

            <Switch>
              {props.routes.map((route, i) => (
                <Route
                  key={i}
                  exact={route.exact}
                  path={route.path}
                  render={(routeprops) =>
                    hasAccessToRoute(
                      route?.roles,
                      auth?.userRoles,
                      userSupportView,
                      route?.isPrivate,
                      route?.adminView,
                      tenant && selectedPartner
                        ? tenant[getTenantId(selectedPartner)]
                        : null,
                      route.orgRoles,
                      organizationIdentity.role
                    ) &&
                      checkTenantPermissions(
                        route?.permissionSet,
                        userPermissionsList,
                        getTenantId(selectedPartner),
                        userSupportView
                      ) ? (
                      <>
                        {(!authRequired || authData.isAuthenticated) &&
                          route.backComponent &&
                          props.nobreadcrumbs && (
                            <div>
                              <route.backComponent />
                            </div>
                          )}

                        {!!route.renderCTA && (
                          <CTAPage>
                            <route.component
                              isSubComponent={!!props.isSubComponent}
                              {...routeprops}
                              {...route}
                              routes={route.routes}
                            />
                          </CTAPage>
                        )}

                        {!route.renderCTA && (
                          <route.component
                            isSubComponent={!!props.isSubComponent}
                            {...routeprops}
                            {...route}
                            routes={route.routes}
                          />
                        )}
                      </>
                    ) : isLoading ? (
                      <ShimmerLoader type='table' />
                    ) : (
                      <ErrorPage
                        description={`Sorry, you don't have permission to view this page`}
                      />
                    )
                  }
                />
              ))}
            </Switch>
          </div>
        </main>
      </div>
    </Root>
  );
}

export default memo(ParentRoute);
