import { combineReducers } from "@reduxjs/toolkit";
import {
  deschutesSlice,
  getDeviceImages,
  getDeviceImagesForSite,
  getGalleryImages,
  getGroups,
} from "./deschutes/deschutesSlice";
import {
  createProvisioningPolicy,
  deleteProvisioningPolicy,
  getProvisioningPolicies,
  getProvisioningPolicy,
  provisioningPolicySlice,
  updateProvisioningPolicy,
} from "./deschutes/provisioningPolicySlice";
import { imageSetSlice } from "./images/imageSetSlice";
import { connectionsSlice } from "./deschutes/connectionsSlice";
import { getSpotGroups, spotGroupSlice } from "./spotGroups/spotGroupsSlice";
import { poolsSlice } from "./pools/poolsSlice";
import { apiAccountsSlice } from "./apiUsers/apiAccountsSlice";
import { adminAccountsSlice } from "./apiUsers/adminAccountsSlice";
import { businessServerSlice } from "./business-servers/businessServersSlice";
import { sitesSlice } from "./sites/sitesSlice";
import {
  dataVolumesSlice,
  getDataVolume,
  getDataVolumes,
} from "./data-volumes/dataVolumesSlice";
import { notificationProfilesSlice } from "./notificationProfiles/notificationProfilesSlice";
import { customActionsSlice } from "./custom-actions/customActionsSlice";

/*------Slices------*/
export const adminActionsSlices = {
  actions: {
    ...provisioningPolicySlice.actions,
    ...deschutesSlice.actions,
    ...connectionsSlice.actions,
    ...imageSetSlice.actions,
    ...spotGroupSlice.actions,
    ...poolsSlice.actions,
    ...apiAccountsSlice.actions,
    ...adminAccountsSlice.actions,
    ...businessServerSlice.actions,
    ...sitesSlice.actions,
    ...dataVolumesSlice.actions,
    ...customActionsSlice.actions,
  },
  reducer: combineReducers({
    deschutes: deschutesSlice.reducer,
    sites: sitesSlice.reducer,
    provisioningPolicy: provisioningPolicySlice.reducer,
    imageSet: imageSetSlice.reducer,
    connections: connectionsSlice.reducer,
    spotGroup: spotGroupSlice.reducer,
    pools: poolsSlice.reducer,
    apiAccounts: apiAccountsSlice.reducer,
    adminAccounts: adminAccountsSlice.reducer,
    businessServer: businessServerSlice.reducer,
    dataVolumes: dataVolumesSlice.reducer,
    notificationProfiles: notificationProfilesSlice.reducer,
    customActions: customActionsSlice.reducer,
  }),
};

/*------Actions------*/
export const {
  setCredentialsType,
  setHealthCheckStatus,
  setPassword,
  setUsername,
  setProvsioningPolicyDeleteStatus,
  setSelectedProvisioningPolicyDeviceImage,
  setSelectedProvisioningPolicy,
  setSelectedProvisioningPolicyGroup,
  setSelectedProvisioningPolicyRegion,
  setProvsioningPolicyUpdateStatus,
  setSelectedImageType,
  setPolicyName,
  setPolicyDescription,
  setProvsioningPolicyCreateStatus,
  setSelectedProvisioningPolicyConnection,
  setImageSetDeleteStatus,
} = adminActionsSlices.actions;

/*------API Actions------*/

/*-----Deschutes Actions------*/
export { getDeviceImages, getDeviceImagesForSite, getGalleryImages, getGroups };

/*-----Provisioning Policy Actions------*/
export {
  getProvisioningPolicies,
  deleteProvisioningPolicy,
  getProvisioningPolicy,
  updateProvisioningPolicy,
  createProvisioningPolicy,
};

export { getDataVolume, getDataVolumes };

export { getSpotGroups };

/*------Reducer------*/
export default adminActionsSlices.reducer;
