import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  page: null,
  content: {
    title: null,
    caption: null,
    image: {
      src: null,
      alt: null,
    },
    action: {
      title: null,
      onClick: null,
      color: null,
      icon: null,
    },
    customContent: null,
  },
  headerProps: null,
  sideContent: {
    component: null,
    props: null,
  },
};

export const checkShouldRenderCTA = (state, location) => {
  const {
    content: {
      title,
      image: { src, alt },
    },
    page,
  } = state;

  return !!title && !!src && !!alt && location.pathname.match(page);
};

const ctaSlice = createSlice({
  name: "cta",
  initialState: {
    data: initialState,
  },
  reducers: {
    setCTA: (state, action) => {
      state.data = action.payload;
    },
    clearCTA: (state) => {
      state.data = initialState;
    },
  },
});

export const { setCTA, clearCTA } = ctaSlice.actions;

export default ctaSlice.reducer;
