import { useCallback, useState } from "react";

export function useErrorHandling() {
  const [errorMessages, setErrorMessages] = useState([]);

  const handleError = useCallback((error, source) => {
    setErrorMessages((prevErrors) => {
      const isDuplicate = prevErrors.some((msg) =>
        msg.includes(`Unable to fetch ${source}`),
      );
      if (isDuplicate) {
        return prevErrors;
      }
      return [
        ...prevErrors,
        `Unable to fetch ${source}: ${error?.message || error?.detail}`,
      ];
    });
  }, []);

  const resetErrors = () => setErrorMessages([]);

  return { errorMessages, handleError, resetErrors };
}
