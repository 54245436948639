import React, { useEffect, useRef, useState } from "react";
import { Button, IconInfo, Tooltip } from "@veneer/core";
import PropTypes from "prop-types";
import { Grid, styled } from "@mui/material";

const PREFIX = "InputTooltip";

const classes = {
  button: `${PREFIX}-button`,
};

const StyledGrid = styled(Grid)(({ theme, color }) => ({
  [`& .${classes.button}`]: {
    marginRight: "8px",
    color: color || theme.palette.text.primary,
  },
}));

const InputTooltip = ({ tooltip, trailingIcon, color }) => {
  const ref = useRef(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const clickHandler = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setTooltipOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", clickHandler, true);

    return () => {
      document.removeEventListener("click", clickHandler, true);
    };
  }, []);

  if (!tooltip && !trailingIcon) {
    return null;
  }

  return (
    <StyledGrid
      color={color}
      container
      direction="row"
      alignItems="center"
      sx={{
        width: "max-content",
      }}
    >
      <Grid item>
        <Tooltip
          ref={ref}
          content={tooltip}
          open={tooltipOpen}
          userSelect="all"
        >
          <Button
            className={classes.button}
            leadingIcon={<IconInfo />}
            onClick={() => setTooltipOpen(!tooltipOpen)}
            appearance="ghost"
            small
          />
        </Tooltip>
      </Grid>

      {trailingIcon && <Grid item>{trailingIcon}</Grid>}
    </StyledGrid>
  );
};

InputTooltip.propTypes = {
  errors: PropTypes.object,
  name: PropTypes.string,
  helperText: PropTypes.string,
  tooltip: PropTypes.node,
  label: PropTypes.string,
  trailingIcon: PropTypes.node,
};

export default InputTooltip;
