import ParentRoute from "../common/components/ParentRoute";
import { LAYOUTS, SUPPORT_ROLES } from "../common/constants";
import Login from "./components/login";

const AzureRoutes = [
  {
    path: "/azure-b2c",
    component: ParentRoute,
    layoutStyle: LAYOUTS.MENU_SCREEN,
    roles: [...SUPPORT_ROLES],
    routes: [
      {
        path: "/azure-b2c",
        component: Login,
        roles: [...SUPPORT_ROLES],
      },
    ],
  },
];

export default AzureRoutes;
