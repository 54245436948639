import { useTheme } from "@mui/material";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import { IconCrescentMoon, IconSun } from "@veneer/core";
import React, { useMemo } from "react";
import { useMode } from "../../ModeProvider";
import { componentIconToURL } from "../../common/utils";

const SwitchTheme = ({ onChange, defaultChecked }) => {
  const { mode } = useMode();
  const theme = useTheme();
  const moonIconUrl = useMemo(
    () => componentIconToURL(<IconCrescentMoon />, theme.palette.primary.main),
    [mode],
  );
  const sunIconUrl = useMemo(
    () => componentIconToURL(<IconSun />, theme.palette.primary.main),
    [mode],
  );

  const StyledSwitch = useMemo(
    () =>
      styled(Switch)(({ theme }) => ({
        padding: 8,
        width: 60,
        height: 40,
        "& .MuiSwitch-track": {
          borderRadius: 22 / 2,
          opacity: "1 !important",
          backgroundColor: "transparent !important",
          border: `1px solid ${theme.palette.foreground.default}`,
          "&::before, &::after": {
            content: '""',
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            width: 16,
            height: 16,
          },
          "&::before": {
            backgroundImage: `url(${moonIconUrl})`,
            left: 12,
          },
          "&::after": {
            backgroundImage: `url(${sunIconUrl})`,
            right: 12,
          },
        },
        "& .MuiSwitch-thumb": {
          backgroundColor: theme.palette.primary.main,
          boxShadow: "none",
          width: 17,
          height: 17,
          margin: 2,
        },
      })),
    [moonIconUrl, sunIconUrl, theme, mode],
  );

  return <StyledSwitch onChange={onChange} checked={defaultChecked} />;
};

export default React.memo(SwitchTheme);
