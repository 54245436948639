import styled from "@emotion/styled";
import { Tag } from "@veneer/core";
import PropTypes from "prop-types";
import React from "react";

// Maps the color prop to the corresponding palette and shade as this Tag will always use the same color scheme
// specified in the design system
const colorMapping = {
  help: { palette: "help", shade: "main" },
  positive: { palette: "success", shade: "main" },
  negative: { palette: "error", shade: "main" },
  neutral: { palette: "neutral", shade: "default" },
  disabled: { palette: "background", shade: "disabled" },
  warning: { palette: "warning", shade: "main" },
  primary: {
    palette: "primary",
    shade: "light",
    labelPalette: "primary",
    labelShade: "main",
  },
  background: { palette: "background", shade: "shell" },
};

const getBaseStyles = (theme) => ({
  backgroundColor: theme.palette.background.container,
  color: theme.palette.text.primary,
  height: "24px",
  boxSizing: "border-box",
  fontWeight: 500,
  borderRadius: "4px",
  svg: {
    width: "15px",
    height: "15px",
    fill: undefined,
  },
  "div.wrapper": {
    display: "flex",
    gap: "3px",
    justifyContent: "center",
    alignItems: "center",
  },
});

const getColorStyles = (theme, type, typeConfig) => {
  const { palette } = theme;

  if (type === "background") {
    return { border: `solid 2px ${palette.background.default}` };
  }

  // if label color, look for labelPalette and labelShade, otherwise use the foreground color
  // as this is the default foreground color for any given palette
  return {
    backgroundColor: palette[typeConfig.palette]?.[typeConfig.shade],
    color:
      palette[typeConfig.labelPalette]?.[typeConfig.labelShade] ||
      palette[typeConfig.palette]?.foreground,
    svg: {
      fill:
        palette[typeConfig.labelPalette]?.[typeConfig.labelShade] ||
        palette[typeConfig.palette]?.foreground,
      width: "16px", // Ensure default size, some icons differ in size
      height: "16px", // Ensure default size, some icons differ in size
    },
  };
};

const generateColorStyles = (theme, type) => {
  const typeConfig = colorMapping[type] || {};
  const baseStyles = getBaseStyles(theme);
  const colorStyles = getColorStyles(theme, type, typeConfig);

  return {
    ...baseStyles,
    ...colorStyles,
  };
};

const StyledTag = styled(Tag)(({ theme, type }) =>
  generateColorStyles(theme, type),
);

export const TagIndicator = ({
  label,
  type,
  leadingIcon,
  hasClearButton,
  iconShouldRemain,
  ...props
}) => {
  const modifiedLeadingIcon = iconShouldRemain
    ? React.cloneElement(leadingIcon, {
        style: { fill: leadingIcon.props.type },
      })
    : leadingIcon;

  return (
    <StyledTag
      // This line generates a warning in the console (it should be a string), but it is necessary to pass the custom icon to the Tag component
      // Otherwise, there is no uniformity between the different usages of the component
      label={
        <div className="wrapper">
          {modifiedLeadingIcon}
          {label}
        </div>
      }
      type={type}
      clearButton={hasClearButton}
      {...props}
    />
  );
};

TagIndicator.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    "help",
    "positive",
    "negative",
    "neutral",
    "disabled",
    "warning",
    "primary",
    "background",
  ]).isRequired,
  leadingIcon: PropTypes.node,
  hasClearButton: PropTypes.bool,
  iconShouldRemain: PropTypes.bool,
};
