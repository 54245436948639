import { IconInfo, Tooltip } from "@veneer/core";
import { Field, Form } from "react-final-form";
import { useSelector } from "react-redux";
import TextInput from "../../common/components/form/TextInput";
import { useOrgAndTenantId } from "../../common/hooks/useOrgAndTenantId";
import {
  checkExistingPool,
  composeValidators,
  required,
} from "../../common/validators";
import { validatePoolName } from "../onboardingUtils";

export default function PoolNameForm({
  onChange,
  initialValues,
  form = "poolNameForm",
}) {
  const { pools } = useSelector((state) => state.adminActions.pools);

  const { tenantId } = useOrgAndTenantId();
  const tenantPools = pools[tenantId] || [];

  return (
    <Form
      onSubmit={() => {}}
      initialValues={initialValues}
      validate={(e) => onChange(e, form)}
      render={(formMeta) => {
        return (
          <form onSubmit={formMeta.handleSubmit}>
            <Field
              name="name"
              id="poolName"
              label="Pool Name"
              validate={(value) => {
                const poolNameError = composeValidators(
                  required,
                  validatePoolName,
                )(value);
                if (poolNameError) {
                  return poolNameError;
                }

                const poolExists = checkExistingPool(tenantPools)(value);
                if (poolExists) {
                  return poolExists;
                }

                return undefined;
              }}
              render={({ input, meta }) => (
                <TextInput
                  {...input}
                  label="Pool name"
                  trailingIcon={
                    <Tooltip
                      contentHideDelayOnHover={300}
                      contentShowDelayOnHover={300}
                      placement="trailing"
                      content={
                        <>
                          Your Anyware Pool name cannot contain the following
                          characters: &lt; &gt; &amp; | &quot; ^
                        </>
                      }
                    >
                      <IconInfo style={{ marginRight: "8px" }} />
                    </Tooltip>
                  }
                  sx={{ width: "100%" }}
                  error={meta.touched && meta.error}
                  helperText={meta.touched && meta.error}
                />
              )}
            />
          </form>
        );
      }}
    />
  );
}
