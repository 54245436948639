import { useSelector } from "react-redux";
import { getIdentityId } from "../utils";

export const useAzureTenantId = () => {
  const selectedPartner = useSelector(
    (state) => state.partners.selectedPartner,
  );
  const selectedIdentity = useSelector(
    (state) => state.identities.selectedIdentity,
  );

  const tenants = useSelector((state) => state.partners.tenants);

  const azureTenantId = tenants[getIdentityId(selectedIdentity)]?.find(
    (el) => el.id === selectedPartner,
  ).customerAzureTenantId;

  return {
    azureTenantId,
  };
};
