import React from "react";
import ParentRoute from "../common/components/ParentRoute";
import { LAYOUTS } from "../common/constants";
import { componentLoader } from "./../common/components/SuspenseComponent";

const OrderSummary = React.lazy(() =>
  componentLoader(() => import("./components/OrderSummary")),
);
const RequestSent = React.lazy(() =>
  componentLoader(() => import("./components/RequestSent")),
);

const OrderSummaryRoutes = [
  {
    path: "/orderSummary",
    component: ParentRoute,
    exact: true,
    nobreadcrumbs: true,
    layoutStyle: LAYOUTS.FULL_SCREEN,
    routes: [
      {
        component: () => <OrderSummary />,
      },
    ],
  },
  {
    path: "/orderSummary/confirmation",
    component: ParentRoute,
    nobreadcrumbs: true,
    exact: true,
    layoutStyle: LAYOUTS.FULL_SCREEN,
    routes: [
      {
        component: () => <RequestSent />,
      },
    ],
  },
];

export default OrderSummaryRoutes;
