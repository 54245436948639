import React from "react";
import DashBoardRouteTitle from "../common/components/DashboardRouteTitle";
import ParentRoute from "../common/components/ParentRoute";
import { ProtectedRouteSuspenseContainer } from "../common/components/ProtectedRoute";
import { componentLoader } from "../common/components/SuspenseComponent";
import { APPLICATION_CONTEXTS, LAYOUTS } from "../common/constants";
import AddPoolAzure from "../admin-actions/pools/components/addPoolAzure";
import AddPoolsVsphere from "../admin-actions/pools/components/addPoolVsphere";

const OnboardingContainer = React.lazy(() =>
  componentLoader(() => import("./components/OnboardingContainer")),
);
const OnboardingAzureTenant = React.lazy(() =>
  componentLoader(() => import("./components/OnboardingAzureTenant")),
);
const OnboardingVSphereContainer = React.lazy(() =>
  componentLoader(() => import("./components/OnboardingVSphereContainer")),
);
const SelectOnboardingType = React.lazy(() =>
  componentLoader(() => import("./components/SelectOnboardingType")),
);

const OnboardingRoutes = [
  {
    path: "/onboarding",
    context: APPLICATION_CONTEXTS.ORGANIZATION,
    authRequired: true,
    component: ParentRoute,
    nobreadcrumbs: true,
    routeTitleComponent: () => <DashBoardRouteTitle title="Onboarding" />,
    layoutStyle: LAYOUTS.MENU_SCREEN,
    routes: [
      {
        path: "/onboarding",
        component: () => (
          <ProtectedRouteSuspenseContainer component={OnboardingContainer} />
        ),
      },
    ],
  },
  {
    path: "/onboardingAzureTenant",
    context: APPLICATION_CONTEXTS.ORGANIZATION,
    authRequired: true,
    component: ParentRoute,
    title: "Onboarding Azure Tenant",
    nobreadcrumbs: true,
    layoutStyle: LAYOUTS.MENU_SCREEN,
    routes: [
      {
        path: "/onboardingAzureTenant",
        component: () => (
          <ProtectedRouteSuspenseContainer component={OnboardingAzureTenant} />
        ),
      },
    ],
  },
  {
    path: "/dashboard/pools/addPool/Vsphere",
    context: APPLICATION_CONTEXTS.TENANT,
    authRequired: true,
    title: "Add Pool",
    component: ParentRoute,
    nobreadcrumbs: true,
    layoutStyle: LAYOUTS.MENU_SCREEN,
    routes: [
      {
        path: "/dashboard/pools/addPool/Vsphere",
        component: () => (
          <ProtectedRouteSuspenseContainer component={AddPoolsVsphere} />
        ),
      },
    ],
  },
  {
    path: "/dashboard/pools/addPool/Azure",
    context: APPLICATION_CONTEXTS.TENANT,
    authRequired: true,
    title: "Add Pool",
    component: ParentRoute,
    nobreadcrumbs: true,
    layoutStyle: LAYOUTS.MENU_SCREEN,
    routes: [
      {
        path: "/dashboard/pools/addPool/Azure",
        component: () => (
          <ProtectedRouteSuspenseContainer component={AddPoolAzure} />
        ),
      },
    ],
  },
  {
    path: "/onboardingVSphere",
    context: APPLICATION_CONTEXTS.ORGANIZATION,
    authRequired: true,
    title: "Onboarding vSphere",
    component: ParentRoute,
    nobreadcrumbs: true,
    layoutStyle: LAYOUTS.MENU_SCREEN,
    routes: [
      {
        path: "/onboardingVsphere",
        component: () => (
          <ProtectedRouteSuspenseContainer
            component={OnboardingVSphereContainer}
          />
        ),
      },
    ],
  },
  {
    path: "/selectOnboardingWizard",
    context: APPLICATION_CONTEXTS.ORGANIZATION,
    authRequired: true,
    title: "Add Tenant",
    component: ParentRoute,
    nobreadcrumbs: true,
    layoutStyle: LAYOUTS.MENU_SCREEN,
    routes: [
      {
        path: "/selectOnboardingWizard",
        component: () => (
          <ProtectedRouteSuspenseContainer component={SelectOnboardingType} />
        ),
      },
    ],
  },
];

export default OnboardingRoutes;
