import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HTTP_METHODS } from "../../common/constants";
import HTTPClient from "../../common/servicers/httpClient";

/**************************************
 *********** API ACTIONS ***************
 ***************************************/

export const getInvitations = createAsyncThunk(
  "getInvitations",
  async (
    { useCache = true, organizationId },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loadingInvites, invitations } =
      getState().dashboard.admin;
    if (!loadingInvites || requestId !== currentRequestId) {
      return;
    }
    if (useCache && invitations && invitations[organizationId]) {
      return { data: invitations[organizationId], organizationId };
    }
    try {
      const response = await new HTTPClient({
        endpoint: "/internal-support/identities/invitations",
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, organizationId };
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  },
);

export const sendInvites = createAsyncThunk(
  "sendInvite",
  async ({ payload }, { getState, requestId, rejectWithValue }) => {
    const { sendInvitesRequestId, sendingInvite } = getState().dashboard.admin;
    if (!sendingInvite || sendInvitesRequestId !== requestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: "/internal-support/identities/invitations",
        method: HTTP_METHODS.POST,
        data: payload,
      }).callAuthorizedAPI();
      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  },
);

export const resendInvite = createAsyncThunk(
  "resendInvite",
  async ({ invitationId }, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, resendingInvite } = getState().dashboard.admin;
    if (!resendingInvite || currentRequestId !== requestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/internal-support/identities/invitations/${invitationId}`,
        method: HTTP_METHODS.PUT,
      }).callAuthorizedAPI();
      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deleteInvite = createAsyncThunk(
  "deleteInvite",
  async ({ invitations }, { getState, requestId, rejectWithValue }) => {
    const { deleteInviteRequestId, deletingInvite } =
      getState().dashboard.admin;
    if (!deletingInvite || deleteInviteRequestId !== requestId) {
      return;
    }
    try {
      await new HTTPClient({
        endpoint: "/internal-support/identities/invitations",
        method: HTTP_METHODS.DELETE,
        data: invitations,
      }).callAuthorizedAPI();
      return { invitations };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteIdentity = createAsyncThunk(
  "deleteIdentity",
  async ({ identities }, { getState, requestId, rejectWithValue }) => {
    const { deleteIdentityRequestId, deletingIdentity } =
      getState().dashboard.admin;
    if (!deletingIdentity || deleteIdentityRequestId !== requestId) {
      return;
    }
    try {
      await new HTTPClient({
        endpoint: "/internal-support/identities",
        method: HTTP_METHODS.DELETE,
        data: identities,
      }).callAuthorizedAPI();
      return { identities };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getIdentity = createAsyncThunk(
  "getIdentity",
  async (
    { useCache = true, organizationId, identityId },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loading, identity } = getState().dashboard.admin;
    if (!loading || requestId !== currentRequestId) {
      return;
    }
    if (useCache && identity && identity[identityId]) {
      return { data: identity[identityId], identityId };
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/identities/identity/${organizationId}/${identityId}`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, identityId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getIdentities = createAsyncThunk(
  "getIdentities",
  async (
    { useCache = true, organizationId },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, userLoading, identities } =
      getState().dashboard.admin;
    if (!userLoading || requestId !== currentRequestId) {
      return;
    }
    if (useCache && identities && identities[organizationId]) {
      return { data: identities[organizationId], organizationId };
    }
    try {
      const response = await new HTTPClient({
        endpoint: "/internal-support/identities",
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, organizationId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const initialState = {
  invitations: [],
  identity: {},
  identities: [],
  loading: false,
  userLoading: false,
  error: null,
  currentRequestId: null,
  loaderText: null,
  sendingInvite: null,
  sendInvitesRequestId: null,
  sendInvitesError: null,
  deletingInvite: null,
  deleteInviteError: null,
  deleteInviteRequestId: null,
  deletingIdentity: null,
  deleteIdentityError: null,
  deleteIdentityRequestId: null,
  resendingInvite: null,
  resendingInviteError: null,
};

export const supportAdminSlice = createSlice({
  name: "supportAdmin",
  initialState,
  reducers: {
    resetErrors: (state) => {
      state.deleteInviteError = null;
      state.deleteIdentityError = null;
      state.sendInvitesError = null;
      state.resendingInviteError = null;
    },
  },
  extraReducers: {
    [getInvitations.pending]: (state, action) => {
      state.loadingInvites = true;
      state.currentRequestId = action.meta.requestId;
      state.deleteInviteError = null;
    },
    [getInvitations.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.loadingInvites = false;
      state.invitations = action.payload?.data
        ? action.payload.data.map((d) => {
            let temp = { ...d };
            temp.name = d.identityInvitationId;
            return temp;
          })
        : null;
    },
    [getInvitations.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.error = action.payload || true;
      state.loadingInvites = false;
    },
    [getIdentities.pending]: (state, action) => {
      state.userLoading = true;
      state.currentRequestId = action.meta.requestId;
      state.deleteIdentityError = null;
    },
    [getIdentities.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.userLoading = false;
      state.identities = action.payload?.data
        ? action.payload.data.map((d) => {
            return { ...d, email: d.username, active: true };
          })
        : null;
    },
    [getIdentities.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.error = action.payload || true;
      state.userLoading = false;
    },
    [sendInvites.pending]: (state, action) => {
      state.sendingInvite = true;
      state.sendInvitesRequestId = action.meta.requestId;
      state.sendInvitesError = null;
    },
    [sendInvites.fulfilled]: (state, action) => {
      state.sendingInvite = false;
      state.sendInvitesRequestId = null;
      state.sendInvitesError = "";
      state.invitations = action.payload?.data ? action.payload.data : null;
    },
    [sendInvites.rejected]: (state, action) => {
      state.sendingInvite = false;
      state.sendInvitesRequestId = null;
      const errorCode = action.payload?.code;
      if (errorCode === 409) {
        state.sendInvitesError = "One of the email Ids is already invited";
      } else {
        const errorMessage = action.payload?.message || action.error?.message;
        state.sendInvitesError =
          typeof errorMessage === "string"
            ? errorMessage
            : "Error sending invite";
      }
    },
    [resendInvite.pending]: (state, action) => {
      state.resendingInvite = true;
      state.currentRequestId = action.meta.requestId;
      state.resendingInviteError = null;
    },
    [resendInvite.fulfilled]: (state) => {
      state.resendingInvite = false;
      state.currentRequestId = null;
      state.resendingInviteError = "";
    },
    [resendInvite.rejected]: (state, action) => {
      state.resendingInvite = false;
      state.currentRequestId = null;
      const errorMessage = action.payload?.message || action.error?.message;
      state.resendingInviteError =
        typeof errorMessage === "string"
          ? errorMessage
          : "Error resending invite";
    },
    [deleteInvite.pending]: (state, action) => {
      state.deletingInvite = true;
      state.deleteInviteError = null;
      state.deleteInviteRequestId = action.meta.requestId;
    },
    [deleteInvite.fulfilled]: (state, action) => {
      state.deleteInviteRequestId = null;
      state.deletingInvite = false;
      state.deleteInviteError = "";
      state.invitations = state.invitations.filter(
        (i) =>
          action.payload.invitations.indexOf(i.identityInvitationId) === -1,
      );
    },
    [deleteInvite.rejected]: (state, action) => {
      state.deletingInvite = false;
      state.deleteInviteError = action.error || true;
      state.deleteInviteRequestId = null;
    },
    [deleteIdentity.pending]: (state, action) => {
      state.deletingIdentity = true;
      state.deleteIdentityError = null;
      state.deleteIdentityRequestId = action.meta.requestId;
    },
    [deleteIdentity.fulfilled]: (state, action) => {
      state.deleteIdentityRequestId = null;
      state.deletingIdentity = false;
      state.deleteIdentityError = "";
      state.identities = state.identities.filter(
        (i) => action.payload.identities.indexOf(i.identityId) === -1,
      );
    },
    [deleteIdentity.rejected]: (state, action) => {
      state.deletingIdentity = false;
      state.deleteIdentityError = action.error || true;
      state.deleteIdentityRequestId = null;
    },
    [getIdentity.pending]: (state, action) => {
      state.loading = true;
      state.currentRequestId = action.meta.requestId;
    },
    [getIdentity.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.loading = false;
      state.identity[action.payload.identityId] = action.payload?.data
        ? action.payload.data
        : null;
    },
    [getIdentity.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.error = action.payload || true;
      state.loading = false;
    },
  },
});

export const { resetErrors } = supportAdminSlice.actions;

export default supportAdminSlice.reducer;
